// assets
import aisoccerWhiteFont from 'assets/logos/font-logo/aisoccer-white-font.webp';
import aisoccerWhiteSymbol from 'assets/logos/symbol/aisoccer-white-symbol.webp';

const Unauthorized = () => {
    return (
        <div
            id="authPage"
            className="relative h-screen "
        >
            {/* 배경과 심볼 */}
            <div className="absolute top-0 left-0 z-[-1] w-full h-full">
                {/* 배경색 나누기 */}
                <div className="h-[598px] bg-primary-black"></div>
                {/* 심볼 이미지 */}
                <img
                    src={aisoccerWhiteSymbol}
                    alt="aisoccerWhiteSymbol"
                    className="absolute z-0 top-0 right-0 w-[470px]"
                />
            </div>
            <div className="absolute flex items-center justify-center h-screen">
                <div className="bg-white rounded-[14px] h-[30px]"></div>
            </div>
        </div>
    );
};

export default Unauthorized;
