// hooks
import { useState } from 'react';
// utils
import { measurementIconMatcher } from 'utils/matchers/iconMatcher';

const Scores = ({ scoreData }: any) => {
    return (
        <div className="flex flex-col gap-[10px]">
            <div className="text-subtitle-medium-bold text-secondary-dGrayDark">측정 요약</div>

            <div className="grid grid-cols-3 gap-x-[20px] gap-y-[10px]">
                {scoreData?.map((el: any) => {
                    const Icon = measurementIconMatcher(el.skill);

                    return (
                        <button
                            className={`h-[75.33px]  px-[18px] bg-[#f8f8f8] rounded-[8px] text-[#111111] flex gap-[12px] items-center`}
                        >
                            <div className="w-[40px] h-[40px] text-primary-black flex justify-center items-center">
                                {typeof Icon === 'string' ? Icon : <Icon className="" />}
                            </div>
                            <div className={`w-[166.67px] text-start text-context-regular`}>{el.skill}</div>
                            <div className={`text-title-medium-regular`}>{el.score}</div>
                        </button>
                    );
                })}
            </div>
        </div>
    );
};

export default Scores;
