const DataAnalysisFeedback = ({
    isPrint,
    value,
    setValue,
}: {
    isPrint?: boolean;
    value: string;
    setValue: (value: string) => void;
}) => {
    return (
        <div className="flex gap-[12px]">
            <textarea
                className={`h-[100px] text-context-regular ${
                    isPrint ? 'w-full' : 'w-[1128px]'
                } bg-secondary-mGrayLight rounded-[8px] resize-none p-[12px] text-secondary-lGrayDark focus:outline-primary-purple`}
                placeholder="피드백을 입력해 주세요"
            ></textarea>
            {!isPrint && (
                <button className="rounded-[8px] bg-primary-purple text-primary-white w-[200px] text-context-bold">
                    작성완료
                </button>
            )}
        </div>
    );
};

export default DataAnalysisFeedback;
