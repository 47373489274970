// commons
import CustomTable from 'components/table/CustomTable';
import { useEffect } from 'react';
// utils
import { ageMatcher } from 'utils/matchers/userInfoMatcher';
import { matchTypeMatcher } from 'utils/matchers/reportMatcher';
interface SeasonCardType {
    seasonStats: {
        competitionStats: [];
        totalStat: {
            totalAppearances: number | null;
            totalAssists: number | null;
            totalAverageRating: number | null;
            totalGoals: number | null;
        };
    };
}

const SeasonCard = ({ seasonStats }: SeasonCardType) => {
    const columns = [
        { key: 'competitionName', label: '경기명' },
        { key: 'matchType', label: '종류', sortable: false },
        { key: 'appearances', label: '출장' },
        { key: 'goals', label: '득점' },
        { key: 'assists', label: '도움' },
        { key: 'averageRating', label: '평균', sortable: false },
    ];

    const customizedRow = handleRows(seasonStats?.competitionStats?.slice(0, 4)) || [];
    return (
        <div className="w-[453px] h-[360px] bg-primary-white rounded-[10px] p-[30px] flex flex-col gap-[15px]">
            <div className="flex items-center justify-between">
                <div className="text-subtitle-medium-bold text-secondary-dGrayMid">시즌통계</div>
            </div>
            <div className="relative">
                <div className="w-full">
                    {/* overflow-y-scroll */}
                    <CustomTable
                        columns={columns}
                        rows={customizedRow?.slice(0, 4)}
                        equalWidth={false}
                        // minWidth={'min-w-[116px]'}
                    />
                </div>
            </div>
        </div>
    );
};
export default SeasonCard;
export const handleRows = (rows: any[]) => {
    return rows?.map((row) => ({
        competitionName: row?.competitionName,
        matchType: matchTypeMatcher(row?.matchType) || '',
        appearances: row?.appearances,
        goals: row?.goals,
        assists: row?.assists,
        averageRating: row?.averageRating,
    }));
};
