// hooks
import { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';

// api
import { useApiQuery, useApiMutation } from 'hooks/useDynamicApi';
// utils
import { ENDPOINT_REPORT } from 'utils/consts/apiEndpoint';
// report components
import SubstitutionEditElement from 'pages/staff/control/schedule/report/read/reportPlayer/SubstitutionEditElement';
const Substitution = () => {
    const { id } = useParams();

    // api
    const getSubstitution = useApiQuery(['getSubstitution'], ENDPOINT_REPORT.substitutions(id), 'get');

    return (
        <div className="p-[24px] flex items-center w-full border-b border-secondary-lGrayMid">
            <div className="text-center shrink-0 w-[100px] h-full text-context-regular text-secondary-dGrayMid">
                교체
            </div>
            <div className="flex flex-col gap-[10px]">
                <div className="flex gap-[10px] text-context-regular w-full bg-red">
                    <div className="w-[135px]"></div>
                    <div className="w-[380px] text-center">IN</div>
                    <div className="w-[380px] text-center">OUT</div>
                </div>
                <div className="flex flex-col gap-[10px]">
                    {getSubstitution?.data?.map((el: any) => (
                        <SubstitutionEditElement {...el} />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Substitution;

interface LineupType {
    lineupId: string;
    playerId: string;
    playerName: string;
    uniformNumber: string;
    position: string;
    rating: string;
    managerComment: string;
}

interface SubstitutionType {
    substitutionId: string;
    playerInId: string;
    playerInName: string;
    playerInUniformNumber: string;
    playerInPosition: string;
    playerOutId: string;
    playerOutName: string;
    playerOutUniformNumber: string;
    playerOutPosition: string;
    substitutionTime: string;
}
