import { FC, SVGProps } from 'react';

// icons
import { ReactComponent as UpperBalanceIcon } from 'assets/svgs/measurement/upperBalance.svg';
import { ReactComponent as VisionIcon } from 'assets/svgs/measurement/vision.svg';
import { ReactComponent as CenterGravityIcon } from 'assets/svgs/measurement/centerGravity.svg';
import { ReactComponent as WeightAxisIcon } from 'assets/svgs/measurement/weightAxis.svg';
import { ReactComponent as HeelIcon } from 'assets/svgs/measurement/heel.svg';
import { ReactComponent as KneeDistanceIcon } from 'assets/svgs/measurement/kneeDistance.svg';

import { ReactComponent as SmallFeetIcon } from 'assets/svgs/measurement/smallFeet.svg';
import { ReactComponent as ElasticityIcon } from 'assets/svgs/measurement/elasticity.svg';
import { ReactComponent as BounceRateIcon } from 'assets/svgs/measurement/bounceRate.svg';
import { ReactComponent as TopSpeedIcon } from 'assets/svgs/measurement/topSpeed.svg';
import { ReactComponent as AverageSpeedIcon } from 'assets/svgs/measurement/averageSpeed.svg';
import { ReactComponent as AccuracyIcon } from 'assets/svgs/measurement/accuracy.svg';

// Define the type for SVG components
type IconComponent = FC<SVGProps<SVGSVGElement>>;

// Define return type that can be either a React component or string
type MeasurementResult = IconComponent | string;

export const measurementIconMatcher = (icon: string): MeasurementResult => {
    switch (icon) {
        case 'armScore': //상체 밸런스
            return UpperBalanceIcon;
        case 'chinScore': // 시야
            return VisionIcon;
        case 'bendKneeScore': //무게중심
            return CenterGravityIcon;
        case 'upperBodyScore': //무게축
            return WeightAxisIcon;
        case 'steppingScore': //뒤꿈치
            return HeelIcon;
        case 'kneeScore': //무릎거리
            return KneeDistanceIcon;
        case 'stepScore': //잔발
            return SmallFeetIcon;

        case 'dashScore': //탄력성
            return ElasticityIcon;
        case 'lapMaxSpeedScore': //최고속도
            return TopSpeedIcon;
        case 'lapAvgSpeedScore': //평균속도
            return AverageSpeedIcon;
        case 'ballTouchScore': //볼터치
            return AccuracyIcon;
        case 'lineDeviationScore': //정확도
            return BounceRateIcon;
        default:
            return icon;
    }
};

export const measurementWordMatcher = (word: string) => {
    switch (word) {
        case 'armScore': //상체밸런스
            return '상체밸런스';
        case 'chinScore': // 시야
            return ' 시야';
        case 'bendKneeScore': //무게중심
            return '무게중심';
        case 'upperBodyScore': //무게축
            return '무게축';
        case 'steppingScore': //뒤꿈치
            return '뒤꿈치';
        case 'kneeScore': //무릎거리
            return '무릎거리';
        case 'stepScore': //잔발
            return '잔발';

        case 'dashScore': //탄력성
            return '탄력성';
        case 'lapMaxSpeedScore': //최고속도
            return '최고속도';
        case 'lapAvgSpeedScore': //평균속도
            return '평균속도';
        case 'ballTouchScore': //볼터치
            return '볼터치';
        case 'lineDeviationScore': //정확도
            return '정확도';
        default:
            return word;
    }
};
