// icons
import { ReactComponent as LockIcon } from 'assets/svgs/lock.svg';

const Lock = ({
    content = '측정이 필요합니다.',
    customStyle = '',
    blurStyle = 'bg-white bg-opacity-30 backdrop-blur-md',
}: {
    content?: string;
    customStyle?: string;
    blurStyle?: string;
}) => {
    return (
        <div className={`absolute inset-0 ${customStyle}  ${blurStyle}  `}>
            <div className="flex items-center justify-center h-full text-center text-context-regular">
                <div className="flex gap-[10px] items-center">
                    <LockIcon />
                    <div>{content}</div>
                </div>
            </div>
        </div>
    );
};

export default Lock;
