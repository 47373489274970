// hooks
import { useState, useEffect, useMemo } from 'react';
import { debounce } from 'lodash';
// api
import { useApiQuery, useApiMutation } from 'hooks/useDynamicApi';
import { showToast } from 'components/toast/Default';
import { departmentMatcher } from 'utils/matchers/measurementMatcher';
// utils
import { ENDPOINT_ADMIN } from 'utils/consts/apiEndpoint';
import { regionMatcher } from 'utils/matchers/userInfoMatcher';
import { adminSortMatcher } from 'utils/matchers/sortInfoMatcher';
import { roleMatcher } from 'utils/matchers/userInfoMatcher';
import { paramFormChanger } from 'utils/formChanger';
// icons
import { ReactComponent as SearchIcon } from 'assets/svgs/search.svg';
// commons
import CircleToggle from 'components/toggle/CircleToggle';
import CustomTable from 'components/table/CustomTable';
import Pagination from 'components/pagenation';
const AdminControl = () => {
    const [page, setPage] = useState(1);
    const [pagInfo, setPageInfo] = useState({
        page: 1,
        size: 20,
        totalElements: 1,
        totalPages: 1,
    });
    const [sortType, setSortType] = useState('');
    const [nameInput, setNameInput] = useState('');
    const [options, setOptions] = useState<OptionsType>({
        name: '',
        sortType: '',
    });
    const handleSort = (key: string, sort: 'ASC' | 'DESC') => {
        const customizedSort = adminSortMatcher(key, sort);
        setSortType(customizedSort);
    };

    const handleOptions = (updateData: any) => {
        setOptions((prev: any) => ({
            ...prev,
            ...updateData,
        }));
    };

    // useApiQuery
    const getAdminTeam = useApiQuery(
        ['getAdminTeam', sortType, `${page}`, `${pagInfo.size}`],
        ENDPOINT_ADMIN.adminParams(
            paramFormChanger({
                ...options,
                sortType: sortType,
                page: page,
                size: pagInfo?.size,
            })
        ),
        'get'
    );
    // Debounced function
    const [isFirstRender, setIsFirstRender] = useState(true);
    const debouncedAdminRequest = useMemo(
        () =>
            debounce(() => {
                if (!isFirstRender) {
                    // showToast("조건 검색중입니다.");
                    getAdminTeam.refetch();
                }
            }, 300), // 1초 딜레이
        [getAdminTeam, isFirstRender] // 의존성으로 리팩쳐 사용
    );
    useEffect(() => {
        setIsFirstRender(false);
    }, []);
    useEffect(() => {
        debouncedAdminRequest();
        return () => {
            debouncedAdminRequest.cancel(); // 컴포넌트가 언마운트되면 디바운스 취소
        };
    }, [options?.name]);

    useEffect(() => {
        if (getAdminTeam?.isSuccess && getAdminTeam?.data && getAdminTeam?.data?.pageInfo?.page === 1) {
            setPageInfo(getAdminTeam?.data?.pageInfo);
        }
    }, [getAdminTeam?.isSuccess, getAdminTeam?.data]);

    // useApiMutation
    const patchApproval = useApiMutation((props) => ENDPOINT_ADMIN.approval(props.userId), 'patch', {
        onSuccess: (data) => {
            const approvedState = data?.roleApproved ? '활성화' : '비활성화';
            showToast(`${data?.name} 상태가 ${approvedState} 되었습니다.`, 'success');
        },
        onError: (error) => {
            // 에러 메시지 추출
            const errorMessage = error?.data?.detailedMessage || error || '승인 상태 변경 실패했습니다.';
            showToast(`${errorMessage}`, 'error');
        },
    });
    const updateApproval = (userId: string) => {
        if (!patchApproval?.isPending) {
            showToast('승인 상태 변경중입니다.', 'info');
            patchApproval.mutate({ userId });
        }
    };

    // row data
    const customizedRow = handleRows(getAdminTeam?.data?.data, updateApproval) || [];

    return (
        <div className="flex flex-col gap-[30px] w-[1340px] m-auto">
            <div className="w-[500px] relative">
                <input
                    value={nameInput}
                    onChange={(e) => setNameInput(e.target.value)}
                    placeholder="관리자명을 검색해주세요"
                    className="inputDefaultStyle pl-[12px] pr-[30px]  w-full h-[44px] text-context-regular rounded-[8px] text-black"
                />
                <button
                    onClick={() => {
                        showToast('어드민 검색중입니다', 'info');
                        handleOptions({ name: nameInput });
                    }}
                    className="absolute right-[12px] top-[12px]"
                >
                    <SearchIcon />
                </button>
            </div>
            <CustomTable
                columns={columns}
                rows={customizedRow}
                equalWidth={false}
                minWidth={'max-w-[100px]'}
                sortInfoFunc={handleSort}
            />

            <Pagination
                page={page}
                size={pagInfo?.size}
                totalElement={pagInfo?.totalElements}
                totalPages={pagInfo?.totalPages}
                onPageChange={setPage}
            />
        </div>
    );
};

export default AdminControl;

export const columns = [
    { key: 'name', label: '이름', sortable: true },
    { key: 'department', label: '팀 유형' },
    { key: 'region', label: '지역' },
    { key: 'role', label: '역할' },
    { key: 'roleApproved', label: '활성화' },
    // { key: "link", label: "상세보기" },
];

interface OptionsType {
    name: string;
    sortType?: string;
}

export const handleRows = (rows: any[], updateApproval: (userId: string) => void) => {
    return rows?.map((row) => ({
        name: row?.name,
        department: departmentMatcher(row?.department),
        role: roleMatcher(row?.role),
        region: regionMatcher(row?.region),
        roleApproved: (
            <div className="flex justify-center w-full">
                <CircleToggle
                    state={row?.roleApproved}
                    activeFunc={() => updateApproval(row?.userId)}
                />
            </div>
        ), // row?.roleApproved,

        // link: `/admin/control/teamDetail/${row?.teamId}`, // 상세보기 링크
    }));
};

export const handleDropdownOpts = (arr: any[]) => {
    return arr?.map((arr) => ({
        value: arr?.teamId,
        label: `${arr?.teamName}${arr?.ageGroup ? `(${arr?.ageGroup})` : ''} `, //${arr?.type}
    }));
};
