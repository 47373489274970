import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// api
import { useApiMutation, useLoginMutation } from 'hooks/useDynamicApi';
// assets
import aisoccerWhiteFont from 'assets/logos/font-logo/aisoccer-white-font.webp';
import aisoccerWhiteSymbol from 'assets/logos/symbol/aisoccer-white-symbol.webp';
// utils
import { emailVerifyFunc } from 'utils/verifyFunc/userInfoVerify';
import { ENDPOINT } from 'utils/consts/apiEndpoint';
// common
import Tabs from 'components/tab/Default';
import { showToast } from 'components/toast/Default';

export type Role = 'player' | 'coach' | 'staff' | 'admin';
export type SubRole = 'coach' | 'supervision' | 'office' | 'system' | 'analysis' | 'super';
export interface AuthState {
    accessToken: string | null;
    refreshToken: string | null;
    role: Role | null;
    subRole?: SubRole | null;
    id: string | null; // 추가된 필드
    name: string | null; // 추가된 필드
}
const Email: React.FC = () => {
    const nameRegex = /^[가-힣a-zA-Z0-9!@#$%^&*()-_+=<>?\s]{2,10}$/;
    const pwRegex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d\W_]{8,15}$/;
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    const tabData = [
        {
            label: '로그인',
        },
        {
            label: '회원가입',
        },
    ];

    const [tab, setTab] = useState(tabData[0].label);
    const [email, setEmail] = useState({ value: '', state: '' });
    const [password, setPassword] = useState({ value: '', state: '' });
    const [loginError, setIsLoginError] = useState('');

    const [name, setName] = useState({ value: '', state: '' });
    const [newEmail, setNewEmail] = useState({ value: '', state: '' });
    const [fixedEmail, setFixedEmail] = useState('');
    const [verifyCode, setVerifyCode] = useState({ value: '', state: '' });
    const [isVerified, setIsVerified] = useState(false);
    const [newPassword, setNewPassword] = useState({ value: '', state: '' });
    const [rePassword, setRePassword] = useState({ value: '', state: '' });

    const navigate = useNavigate();
    const handleTabChange = (index: number) => {
        setTab(tabData[index].label); // `tabData`의 `label` 값으로 상태 변경
    };

    // useApiMutation
    const postSignInMutation = useLoginMutation(ENDPOINT.authentication.login, 'post', {
        onSuccess: (data) => {
            showToast('환영합니다!', 'success');
            navigate('/hand/initial');
        },
        onError: (error) => {
            const errorMessage =
                error?.data?.detailedMessage || error || `ID,PW를 확인해주시거나 권한 승인후 로그인해주세요`;
            showToast(errorMessage, 'error');
        },
    });

    const postNewEmailMutation = useApiMutation(ENDPOINT.email.send, 'post', {
        onSuccess: (data) => {
            showToast('이메일을 발송했습니다.', 'success');
            setNewEmail((prev) => ({
                ...prev,
                state: 'default',
            }));
            setFixedEmail(newEmail.value);
        },
        onError: (error) => {
            // 에러 메시지 추출
            showToast(`❌ 이메일 발송 실패: ${error}`, 'error');
            setNewEmail((prev) => ({
                ...prev,
                state: 'error',
            }));
        },
    });
    const postVerifyCodeMutation = useApiMutation(ENDPOINT.email.verify, 'post', {
        onSuccess: (data) => {
            showToast('인증번호가 인증되었습니다.', 'success');
            setIsVerified(true);
        },
        onError: (error) => {
            const detailError = error?.data?.detailedMessage || error || '인증번호 발송 실패하였습니다.';
            showToast(detailError, 'error');
        },
    });
    const postSignUpMutation = useApiMutation(ENDPOINT.user.register, 'post', {
        onSuccess: (data) => {
            showToast(`회원가입 되었습니다.`, 'success');
            setTab('로그인');
            navigate(`/hand/signIn/email?tab=로그인`);
        },
        onError: (error) => {
            const detailError = error?.data?.detailedMessage || error || '회원가입 실패하였습니다.';
            showToast(`${detailError}`, 'error');
        },
    });
    // SignIn func
    const sendSignIn = () => {
        if (postSignInMutation.isPending) {
            return;
        } else {
            showToast(`로그인중입니다.`, 'info');

            if (!email || !emailRegex.test(email?.value)) {
                setEmail((prev) => ({
                    ...prev,
                    state: 'error',
                }));
                showToast(`이메일 형식이 올바르지 않습니다.`, 'error');
            } else if (!password || !pwRegex.test(password?.value)) {
                setPassword((prev) => ({
                    ...prev,
                    state: 'error',
                }));
                showToast(
                    `비밀번호는 8자 이상 15자 이하이며, 최소 하나의 문자와 하나의 숫자를 포함해야 합니다.`,
                    'error'
                );
            } else {
                postSignInMutation.mutate({
                    email: email.value,
                    password: password.value,
                });
            }
        }
    };

    // SignUp func
    const sendEmail = (emailArg: string) => {
        if (!emailVerifyFunc(emailArg)) {
            setNewEmail((prev) => ({
                ...prev,
                state: 'error',
            }));
            showToast(`올바른 이메일이 아닙니다.`, 'error');
            return;
        } else postNewEmailMutation.mutate({ email: emailArg });
    };
    const sendVerifyCode = (verifyCode: string) => {
        if (fixedEmail !== newEmail.value) {
            showToast(`이메일 인증을 다시 해주세요.`, 'error');
        } else if (verifyCode.length !== 6) {
            showToast(`올바른 인증번호가 아닙니다.`, 'error');
        } else {
            postVerifyCodeMutation.mutate({
                email: fixedEmail,
                verificationCode: verifyCode,
            });
        }
    };
    const handlePassword = () => {
        if (!pwRegex.test(newPassword.value)) {
            setNewPassword((prev) => ({
                ...prev,
                state: 'error',
            }));
            showToast('비밀번호는 8자 이상 15자 이하이며, 최소 하나의 문자와 하나의 숫자를 포함해야 합니다.', 'error');
            return false;
        } else if (newPassword.value !== rePassword.value) {
            setRePassword((prev) => ({
                ...prev,
                state: 'error',
            }));
            showToast('비밀번호가 일치하지 않습니다.', 'error');
            return false;
        } else {
            return true;
        }
    };
    const sendSignUp = () => {
        if (!name.value || !nameRegex.test(name.value)) {
            setName((prev) => ({
                ...prev,
                state: 'error',
            }));
            showToast(`이름을 2자 이상 10자 이하로 작성해주세요.`, 'error');

            return;
        } else if (!newEmail.value) {
            setNewEmail((prev) => ({
                ...prev,
                state: 'error',
            }));
            showToast('이메일을 작성해주세요.', 'error');
            return;
        }
        if (!handlePassword()) return;
        if (handlePassword() && fixedEmail && verifyCode.value && name) {
            if (fixedEmail !== newEmail.value) showToast('이메일 인증을 다시 해주세요.', 'error');
            else if (!isVerified) showToast('인증번호가유효하지 않습니다.', 'error');
            else
                postSignUpMutation.mutate({
                    email: fixedEmail,
                    name: '사용자',
                    password: newPassword.value,
                    passwordRepeat: rePassword.value,
                    isVerified: isVerified,
                });
        } else showToast('올바른 입력값을 입력하세요.', 'error');
    };

    return (
        <div
            id="authPage"
            className="relative h-screen "
        >
            {/* 배경과 심볼 */}
            <div className="absolute top-0 left-0 z-[-1] w-full h-full">
                {/* 배경색 나누기 */}
                <div className="h-[598px] bg-primary-black"></div>
                {/* 심볼 이미지 */}
                <img
                    src={aisoccerWhiteSymbol}
                    alt="aisoccerWhiteSymbol"
                    className="absolute z-0 top-0 right-0 w-[470px]"
                />
            </div>
            <div className="h-screen ">
                {/* 로고를 form 상단 바로 위에 배치 */}
                <div
                    className={`flex gap-[20px] flex-col justify-center items-center  labtop:pt-[50px] pc:pt-[100px] pt-[140px] select-none`}
                >
                    <img
                        src={aisoccerWhiteFont}
                        alt="aisoccerWhiteFont"
                        className="w-[300px]"
                    />
                    <div className="text-[28px] font-semibold text-primary-white">
                        Gallopin Soccer <span className="text-primary-green">[ 수기 측정 ]</span>
                    </div>
                </div>
                <div className="flex items-center justify-center mt-[83px]">
                    <div className="relative px-[60px] py-[50px] w-full max-w-[1000px] bg-white rounded-[10px] flex flex-col justify-center">
                        <h1 className="font-bold text-center text-title-medium-bold text-secondary-lGrayMid">
                            {tab === '로그인' ? 'LOGIN' : 'JOIN US'}
                        </h1>
                        <div className="mt-[24px] flex flex-col gap-[14px]">
                            <Tabs
                                tabs={tabData}
                                tabClassName={'w-full'}
                                defaultActiveTab={0}
                                onTabChange={handleTabChange} // 함수 전달
                            />
                            {tab === '로그인' ? (
                                <div
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            sendSignIn();
                                        }
                                    }}
                                    className="flex flex-col gap-[14px]"
                                >
                                    <input
                                        type="email"
                                        value={email.value}
                                        onChange={(e) =>
                                            setEmail((prev) => ({
                                                ...prev,
                                                state: 'default',
                                                value: e.target.value, // email 필드 업데이트
                                            }))
                                        }
                                        placeholder="이메일을 입력해주세요"
                                        className={`${
                                            email?.state === 'error' ? 'inputErrorStyle' : 'inputDefaultStyle'
                                        } inputCommonStyle `}
                                    />
                                    <input
                                        type="password"
                                        placeholder="비밀번호를 입력해주세요"
                                        value={password.value}
                                        onChange={(e) =>
                                            setPassword((prev) => ({
                                                ...prev,
                                                state: 'default',
                                                value: e.target.value, // email 필드 업데이트
                                            }))
                                        }
                                        className={`${
                                            password?.state === 'error' ? 'inputErrorStyle' : 'inputDefaultStyle'
                                        } inputCommonStyle `}
                                    />
                                    <button
                                        onClick={() => sendSignIn()}
                                        className="w-full bg-primary-purple rounded-[8px] text-primary-white text-context-bold py-[12px] h-[44px]"
                                    >
                                        LOGIN
                                    </button>
                                </div>
                            ) : (
                                <div
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            sendSignUp();
                                        }
                                    }}
                                    className="flex flex-col gap-[14px] text-secondary-lGrayDark"
                                >
                                    <div className="flex flex-col gap-[8px]">
                                        <input
                                            type="name"
                                            placeholder="이름"
                                            value={name.value}
                                            onChange={(e) =>
                                                setName((prev) => ({
                                                    ...prev,
                                                    state: 'default',
                                                    value: e.target.value, // email 필드 업데이트
                                                }))
                                            }
                                            className={`${
                                                name?.state === 'error' ? 'inputErrorStyle' : 'inputDefaultStyle'
                                            } inputCommonStyle `}
                                        />
                                        <div className="flex gap-[5px]">
                                            <input
                                                type="email"
                                                value={newEmail.value}
                                                onChange={(e) =>
                                                    setNewEmail((prev) => ({
                                                        ...prev,
                                                        state: 'default',
                                                        value: e.target.value, // email 필드 업데이트
                                                    }))
                                                }
                                                placeholder="이메일"
                                                className={`${
                                                    newEmail?.state === 'error'
                                                        ? 'inputErrorStyle'
                                                        : 'inputDefaultStyle'
                                                } inputCommonStyle `}
                                            />
                                            <button
                                                type="button"
                                                onClick={() => sendEmail(newEmail.value)}
                                                className="bg-primary-purple text-white rounded-[8px] text-context-bold p-[12px] shrink-0 w-[80px] h-[44px]"
                                            >
                                                메일발송
                                            </button>
                                        </div>
                                        <div className="flex justify-between text-label-regular px-[12px]">
                                            <div>인증번호가 안 왔나요?</div>{' '}
                                            <button
                                                onClick={() => sendEmail(newEmail.value)}
                                                className="relative pb-[-5px]"
                                            >
                                                재발송
                                                <span className="absolute bottom-[2px] left-0 w-full border-b border-secondary-lGrayDark"></span>
                                            </button>
                                        </div>
                                    </div>

                                    <div className="flex gap-[5px]">
                                        <input
                                            type="string"
                                            placeholder="인증번호"
                                            value={verifyCode.value}
                                            onChange={(e) => {
                                                setVerifyCode((prev) => ({
                                                    ...prev,
                                                    state: 'default',
                                                    value: e.target.value, // email 필드 업데이트
                                                }));
                                                setIsVerified(false);
                                            }}
                                            className={`${
                                                verifyCode?.state === 'error' ? 'inputErrorStyle' : 'inputDefaultStyle'
                                            } inputCommonStyle `}
                                            autoComplete="off"
                                        />
                                        <button
                                            type="button"
                                            onClick={() => sendVerifyCode(verifyCode.value)}
                                            className="bg-primary-black text-white rounded-[8px] text-context-bold p-[12px] shrink-0 w-[80px] h-[44px]"
                                        >
                                            인증완료
                                        </button>
                                    </div>

                                    <input
                                        type="password"
                                        placeholder="비밀번호"
                                        value={newPassword.value}
                                        onChange={(e) =>
                                            setNewPassword((prev) => ({
                                                ...prev,
                                                state: 'default',
                                                value: e.target.value, // email 필드 업데이트
                                            }))
                                        }
                                        className={`${
                                            newPassword?.state === 'error' ? 'inputErrorStyle' : 'inputDefaultStyle'
                                        } inputCommonStyle `}
                                    />
                                    <input
                                        type="password"
                                        placeholder="비밀번호 재확인"
                                        value={rePassword.value}
                                        onChange={(e) =>
                                            setRePassword((prev) => ({
                                                ...prev,
                                                state: 'default',
                                                value: e.target.value, // email 필드 업데이트
                                            }))
                                        }
                                        className={`${
                                            rePassword?.state === 'error' ? 'inputErrorStyle' : 'inputDefaultStyle'
                                        } inputCommonStyle `}
                                        autoComplete="new-password"
                                    />
                                    <button
                                        onClick={() => sendSignUp()}
                                        className="w-full bg-primary-purple rounded-[8px] text-primary-white text-context-bold py-[12px] h-[44px]"
                                    >
                                        회원가입
                                    </button>
                                    <div className="flex justify-between text-label-regular">
                                        <div>회원 가입은 정책 동의를 포함합니다</div>{' '}
                                        <div className="flex gap-[8px]">
                                            <div className="relative pb-[-5px]">
                                                이용약관
                                                <span className="absolute bottom-[2px] left-0 w-full border-b border-secondary-lGrayDark"></span>
                                            </div>
                                            <div className="relative pb-[-5px]">
                                                개인정보처리방침
                                                <span className="absolute bottom-[2px] left-0 w-full border-b border-secondary-lGrayDark"></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div className="h-[20px] bg-secondary-mGrayMid"></div>
            </div>
        </div>
    );
};

export default Email;
