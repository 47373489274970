// hooks
import { useState, useEffect, useMemo, useRef } from 'react';
import { debounce } from 'lodash';
// api
import { useApiMutation } from 'hooks/useDynamicApi';
// commons
import SqureCheckbox from 'components/checkbox/SqureCheckbox';
import { showToast } from 'components/toast/Default';
// utils
import { ENDPOINT_MEASUREMENT } from 'utils/consts/apiEndpoint';
// icons
import { ReactComponent as EditPenIcon } from 'assets/svgs/editPen.svg';
import { ReactComponent as ResetIcon } from 'assets/svgs/reset.svg';
// measurement component
import { passTwoStateMatcher } from 'utils/matchers/measurementMatcher';
import Stopwatch from 'pages/hand/measurement/contents/stopwatch';
const PassTwo = ({
    measurementId,
    passTwoId,
    playerName,
    pass1Result,
    pass2Result,
    pass3Result,
    pass4Result,
    pass5Result,
    pass6Result,
    pass7Result,
    pass8Result,
    lapTime,
}: PassTwoType) => {
    const attempState = ['FAILED', 'OUTSIDE_SUCCESS', 'INSIDE_SUCCESS'];
    const inputRef = useRef<HTMLInputElement | null>(null);
    const handleButtonClick = () => {
        // ref가 현재 존재하는 경우 focus 호출
        inputRef.current?.focus();
    };
    const [editPassTwoInfo, setEditPassTwoInfo] = useState({
        measurementId: measurementId,
        passTwoId: passTwoId,
        playerName: playerName,
        pass1Result: pass1Result,
        pass2Result: pass2Result,
        pass3Result: pass3Result,
        pass4Result: pass4Result,
        pass5Result: pass5Result,
        pass6Result: pass6Result,
        pass7Result: pass7Result,
        pass8Result: pass8Result,
        lapTime: lapTime,
    });
    const handleEditPassTwoInfo = (updateData: any) => {
        setEditPassTwoInfo((prev) => ({
            ...prev,
            ...updateData,
        }));
    };
    // useApiMutation
    const patchPassTwoMeasurement = useApiMutation(ENDPOINT_MEASUREMENT.passTwo(editPassTwoInfo?.passTwoId), 'patch', {
        onSuccess: (data) => {
            // showToast("패스2 기록을 수정했습니다.", "success");
        },
        onError: (error) => {
            const errorMessage = error?.data?.detailedMessage || error || '패스2 기록수정에 실패했습니다.';
            showToast(errorMessage, 'error');
        },
    });
    const patchPassTwoReset = useApiMutation(ENDPOINT_MEASUREMENT.passTwoReset(editPassTwoInfo?.passTwoId), 'patch', {
        onSuccess: (data) => {
            showToast('패스2 기록을 초기화했습니다.', 'success');
            handleEditPassTwoInfo({
                pass1Result: 'FAILED',
                pass2Result: 'FAILED',
                pass3Result: 'FAILED',
                pass4Result: 'FAILED',
                pass5Result: 'FAILED',
                pass6Result: 'FAILED',
                pass7Result: 'FAILED',
                pass8Result: 'FAILED',
                lapTime: 0,
            });
        },
        onError: (error) => {
            const errorMessage = error?.data?.detailedMessage || error || '패스2 기록 초기화에 실패했습니다.';
            showToast(errorMessage, 'error');
        },
    });
    const editPassTwoMeasurement = () => {
        if (!patchPassTwoMeasurement?.isPending) {
            patchPassTwoMeasurement.mutate({
                pass1Result: editPassTwoInfo?.pass1Result,
                pass2Result: editPassTwoInfo?.pass2Result,
                pass3Result: editPassTwoInfo?.pass3Result,
                pass4Result: editPassTwoInfo?.pass4Result,
                pass5Result: editPassTwoInfo?.pass5Result,
                pass6Result: editPassTwoInfo?.pass6Result,
                pass7Result: editPassTwoInfo?.pass7Result,
                pass8Result: editPassTwoInfo?.pass8Result,
                lapTime: editPassTwoInfo?.lapTime,
            });
        }
    };
    const editPassTwoReset = () => {
        if (!patchPassTwoReset?.isPending) {
            showToast('패스2 측정데이터를 초기화하는 중입니다.', 'info');
            patchPassTwoReset.mutate({});
        }
    };

    // debounce
    const debouncedPatchPassTwo = useMemo(
        () =>
            debounce(() => {
                editPassTwoMeasurement();
            }, 500), // 1초 딜레이
        [patchPassTwoMeasurement]
    );
    useEffect(() => {
        if (
            editPassTwoInfo?.pass1Result === pass1Result &&
            editPassTwoInfo?.pass2Result === pass2Result &&
            editPassTwoInfo?.pass3Result === pass1Result &&
            editPassTwoInfo?.pass4Result === pass4Result &&
            editPassTwoInfo?.pass5Result === pass5Result &&
            editPassTwoInfo?.pass6Result === pass6Result &&
            editPassTwoInfo?.pass7Result === pass7Result &&
            editPassTwoInfo?.lapTime === lapTime
        ) {
            return;
        }
        debouncedPatchPassTwo();
        return () => {
            debouncedPatchPassTwo.cancel();
        };
    }, [editPassTwoInfo]);
    return (
        <>
            {attempState?.map((el, idx) => (
                <div
                    key={`${attempState}-${idx}`}
                    className={`w-full flex justify-between rounded-[8px] h-[50px] ${
                        idx % 2 === 1 ? 'bg-secondary-mGrayLight' : 'bg-primary-white'
                    }`}
                >
                    <div className="flex items-center justify-center flex-1">{passTwoStateMatcher(el)}</div>
                    <SqureCheckbox
                        checked={editPassTwoInfo?.pass1Result === el}
                        onChange={() => handleEditPassTwoInfo({ pass1Result: el })}
                        className="justify-center flex-1"
                    />
                    <SqureCheckbox
                        checked={editPassTwoInfo?.pass2Result === el}
                        onChange={() => handleEditPassTwoInfo({ pass2Result: el })}
                        className="justify-center flex-1"
                    />
                    <SqureCheckbox
                        checked={editPassTwoInfo?.pass3Result === el}
                        onChange={() => handleEditPassTwoInfo({ pass3Result: el })}
                        className="justify-center flex-1"
                    />
                    <SqureCheckbox
                        checked={editPassTwoInfo?.pass4Result === el}
                        onChange={() => handleEditPassTwoInfo({ pass4Result: el })}
                        className="justify-center flex-1"
                    />
                    <SqureCheckbox
                        checked={editPassTwoInfo?.pass5Result === el}
                        onChange={() => handleEditPassTwoInfo({ pass5Result: el })}
                        className="justify-center flex-1"
                    />
                    <SqureCheckbox
                        checked={editPassTwoInfo?.pass6Result === el}
                        onChange={() => handleEditPassTwoInfo({ pass6Result: el })}
                        className="justify-center flex-1"
                    />
                    <SqureCheckbox
                        checked={editPassTwoInfo?.pass7Result === el}
                        onChange={() => handleEditPassTwoInfo({ pass7Result: el })}
                        className="justify-center flex-1"
                    />
                    <SqureCheckbox
                        checked={editPassTwoInfo?.pass8Result === el}
                        onChange={() => handleEditPassTwoInfo({ pass8Result: el })}
                        className="justify-center flex-1"
                    />
                </div>
            ))}
            <div className="flex justify-end text-secondary-lGrayDark m-[10px] gap-[10px]">
                <div className=" flex justify-between w-full  items-center h-[50px] ">
                    <Stopwatch
                        lapTime={editPassTwoInfo?.lapTime}
                        setLapTime={(value) => handleEditPassTwoInfo({ lapTime: value })}
                    />
                    <div className="gap-[5px] flex items-center w-[150px]">
                        <div className="font-bold">랩타임</div>
                        <input
                            ref={inputRef} // ref 연결
                            value={editPassTwoInfo?.lapTime}
                            onChange={(e) => handleEditPassTwoInfo({ lapTime: e.target.value })}
                            className="h-[40px] w-[70px] focus:outline focus:outline-primary-purple focus:bg-primary-white  bg-transparent rounded-[8px] text-center"
                        />
                        <button
                            className="flex-1 text-secondary-lGrayDark hover:text-primary-purple"
                            onClick={handleButtonClick} // 버튼 클릭 시 input에 포커스
                        >
                            <EditPenIcon />
                        </button>
                    </div>
                </div>
                <button
                    className="flex items-center gap-[5px] w-[100px]"
                    onClick={editPassTwoReset}
                >
                    초기화
                    <ResetIcon className=" h-[20px] m-auto" />
                </button>
            </div>
        </>
    );
};
export default PassTwo;
interface PassTwoType {
    measurementId: string;
    passTwoId: string;
    playerName: string;
    pass1Result: 'FAILED' | 'OUTSIDE_SUCCESS' | 'INSIDE_SUCCESS';
    pass2Result: 'FAILED' | 'OUTSIDE_SUCCESS' | 'INSIDE_SUCCESS';
    pass3Result: 'FAILED' | 'OUTSIDE_SUCCESS' | 'INSIDE_SUCCESS';
    pass4Result: 'FAILED' | 'OUTSIDE_SUCCESS' | 'INSIDE_SUCCESS';
    pass5Result: 'FAILED' | 'OUTSIDE_SUCCESS' | 'INSIDE_SUCCESS';
    pass6Result: 'FAILED' | 'OUTSIDE_SUCCESS' | 'INSIDE_SUCCESS';
    pass7Result: 'FAILED' | 'OUTSIDE_SUCCESS' | 'INSIDE_SUCCESS';
    pass8Result: 'FAILED' | 'OUTSIDE_SUCCESS' | 'INSIDE_SUCCESS';
    lapTime: number;
}
