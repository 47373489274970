// hooks
import { useState, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useApiQuery } from 'hooks/useDynamicApi';
// utils
import { ENDPOINT_MEASUREMENT } from 'utils/consts/apiEndpoint';
import { paramFormChanger } from 'utils/formChanger';
// commons
import PassTwoElements from 'pages/hand/measurement/contents/passTwo/PassTwoElement';
import EmptyBar from 'components/emptyBar';
import Dropdown from 'components/dropdown/Default';
import SoccerBallLodaing from 'components/loading/SoccerBallLodaing';
interface PassTwoOptions {
    playerOptions: { label: string; value: string }[];
}

const PassTwo = ({ playerOptions }: PassTwoOptions) => {
    const [searchParams] = useSearchParams();
    const MEASUREDAT = searchParams.get('measuredAt') || '';
    const DEPARTMENT = searchParams.get('department') || '';
    const [curMeasurementId, setCurMeasurementId] = useState(playerOptions[0]?.value || '');

    const getPassTwo = useApiQuery(
        ['getPassTwo', MEASUREDAT, DEPARTMENT],
        ENDPOINT_MEASUREMENT.passTwoParams(
            paramFormChanger({
                measuredAt: MEASUREDAT,
                department: DEPARTMENT,
            })
        ),
        'get'
    );

    // 필터링된 데이터를 메모이제이션
    const filteredData = useMemo(() => {
        if (!curMeasurementId || !getPassTwo?.data) return [];
        return getPassTwo.data.filter((data: PassTwoType) => data?.measurementId === curMeasurementId);
    }, [curMeasurementId, getPassTwo.data]);

    // Dropdown onChange 핸들러 메모이제이션
    const handleMeasurementChange = useMemo(
        () => (value: string) => {
            setCurMeasurementId(value);
        },
        []
    );

    return (
        <div className="p-[30px] bg-primary-white rounded-[10px]">
            {getPassTwo?.isPending && <SoccerBallLodaing />}

            <div className="flex gap-[10px] w-[300px] items-center">
                <div className="w-[100px]">선수 이름</div>
                <Dropdown
                    options={playerOptions}
                    value={curMeasurementId}
                    onChange={handleMeasurementChange}
                />
            </div>

            <EmptyBar customStyle="h-[20px]" />
            <div className="flex bg-primary-black text-primary-white text-context-regular rounded-[8px] h-[50px]">
                {columns.map((el: { key: string; label: string }) => (
                    <div
                        key={el.key}
                        className="flex items-center justify-center w-full"
                    >
                        {el?.label}
                    </div>
                ))}
            </div>
            {filteredData.map((el: any, idx: number) => (
                <PassTwoElements
                    {...el}
                    key={`${el?.measurementId}-${idx}`}
                />
            ))}
        </div>
    );
};

export const columns = [
    { key: 'state', label: '' },
    { key: 'first', label: '1차' },
    { key: 'second', label: '2차' },
    { key: 'third', label: '3차' },
    { key: 'fourth', label: '4차' },
    { key: 'fifth', label: '5차' },
    { key: 'sixth', label: '6차' },
    { key: 'seeventh', label: '7차' },
    { key: 'eighth', label: '8차' },
];

export default PassTwo;

export interface PassTwoType {
    measurementId: string;
    PassTwoId: string;
    playerName: string;
    attempt1Result: 'FAILED' | 'OUTSIDE_SUCCESS' | 'INSIDE_SUCCESS';
    attempt2Result: 'FAILED' | 'OUTSIDE_SUCCESS' | 'INSIDE_SUCCESS';
    attempt3Result: 'FAILED' | 'OUTSIDE_SUCCESS' | 'INSIDE_SUCCESS';
    attempt4Result: 'FAILED' | 'OUTSIDE_SUCCESS' | 'INSIDE_SUCCESS';
    attempt5Result: 'FAILED' | 'OUTSIDE_SUCCESS' | 'INSIDE_SUCCESS';
    attempt6Result: 'FAILED' | 'OUTSIDE_SUCCESS' | 'INSIDE_SUCCESS';
    attempt7Result: 'FAILED' | 'OUTSIDE_SUCCESS' | 'INSIDE_SUCCESS';
    attempt8Result: 'FAILED' | 'OUTSIDE_SUCCESS' | 'INSIDE_SUCCESS';
}
