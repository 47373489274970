// hooks
import { useState, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useApiQuery, useApiMutation } from 'hooks/useDynamicApi';
// utils
import { ENDPOINT_MEASUREMENT } from 'utils/consts/apiEndpoint';
import { paramFormChanger } from 'utils/formChanger';
import { showToast } from 'components/toast/Default';
// commons
import DefaultModal from 'components/modals/DefaultModal';
import ShootingTwoElements from 'pages/hand/measurement/contents/shootingTwo/ShootingTwoElement';
import EmptyBar from 'components/emptyBar';
import Dropdown from 'components/dropdown/Default';

interface ShootingTwoOptions {
    playerOptions: { label: string; value: string }[];
}

const ShootingTwo = ({ playerOptions }: ShootingTwoOptions) => {
    const [searchParams] = useSearchParams();
    const MEASUREDAT = searchParams.get('measuredAt') || '';
    const DEPARTMENT = searchParams.get('department') || '';
    const [curMeasurementId, setCurMeasurementId] = useState(playerOptions[0]?.value || '');
    const [isConfirmModal, setIsConfirmModal] = useState(false);
    const curPlayerName = playerOptions?.find((el) => el?.value === curMeasurementId);
    const getShootingTwo = useApiQuery(
        ['getShootingTwo', MEASUREDAT, DEPARTMENT],
        ENDPOINT_MEASUREMENT.shootingTwoParams(
            paramFormChanger({
                measuredAt: MEASUREDAT,
                department: DEPARTMENT,
            })
        ),
        'get'
    );
    //useApiMutation
    const patchComplete = useApiMutation(ENDPOINT_MEASUREMENT.complete(curMeasurementId), 'patch', {
        onSuccess: (data) => {
            showToast(`${curPlayerName?.label} 선수의 측정을 완료했습니다.`, 'success');
        },
        onError: (error) => {
            const errorMessage = error?.data?.detailedMessage || error || '측정을 완료에 실패했습니다.';
            showToast(errorMessage, 'error');
        },
    });
    const submitComplete = () => {
        if (!patchComplete?.isPending) {
            showToast('측정 완료하는중입니다.', 'info');
            patchComplete?.mutate({});
        }
    };

    // 필터링된 데이터를 메모이제이션
    const filteredData = useMemo(() => {
        if (!curMeasurementId || !getShootingTwo?.data) return [];
        return getShootingTwo.data.filter((data: ShootingTwoType) => data?.measurementId === curMeasurementId);
    }, [curMeasurementId, getShootingTwo.data]);

    // Dropdown onChange 핸들러 메모이제이션
    const handleMeasurementChange = useMemo(
        () => (value: string) => {
            setCurMeasurementId(value);
        },
        []
    );

    return (
        <div className="p-[30px] bg-primary-white rounded-[10px]">
            <DefaultModal
                isShow={isConfirmModal}
                setIsShow={setIsConfirmModal}
                title={`${curPlayerName?.label} 선수의 측정을 완료하시겠습니까?`}
                activeFunc={submitComplete}
            />
            <div className="flex justify-between">
                <div className="flex gap-[10px] w-[300px] items-center">
                    <div className="w-[100px]">선수 이름</div>
                    <Dropdown
                        options={playerOptions}
                        value={curMeasurementId}
                        onChange={handleMeasurementChange}
                    />
                </div>
                <button
                    onClick={() => setIsConfirmModal(true)}
                    className="rounded-[8px] bg-primary-purple text-primary-white text-context-bold p-[12px]"
                >
                    측정 완료
                </button>
            </div>

            <EmptyBar customStyle="h-[20px]" />
            <div className="flex bg-primary-black text-primary-white text-context-regular rounded-[8px] h-[50px]">
                {columns.map((el: { key: string; label: string }) => (
                    <div
                        key={el.key}
                        className="flex items-center justify-center w-full"
                    >
                        {el?.label}
                    </div>
                ))}
            </div>
            {filteredData.map((el: any, idx: number) => (
                <ShootingTwoElements
                    {...el}
                    key={`${el?.measurementId}-${idx}`}
                />
            ))}
        </div>
    );
};

export const columns = [
    { key: 'state', label: '' },
    { key: 'first', label: '1차' },
    { key: 'second', label: '2차' },
    { key: 'third', label: '3차' },
    { key: 'fourth', label: '4차' },
    { key: 'fifth', label: '5차' },
    { key: 'sixth', label: '6차' },
];

export default ShootingTwo;

export interface ShootingTwoType {
    measurementId: string;
    shootingTwoId: string;
    playerName: string;
    attempt1Result: 'FAILED' | 'OUTSIDE_TAPE_HIT' | 'OUTSIDE_SUCCESS' | 'INSIDE_TAPE_HIT' | 'INSIDE_SUCCESS';
    attempt2Result: 'FAILED' | 'OUTSIDE_TAPE_HIT' | 'OUTSIDE_SUCCESS' | 'INSIDE_TAPE_HIT' | 'INSIDE_SUCCESS';
    attempt3Result: 'FAILED' | 'OUTSIDE_TAPE_HIT' | 'OUTSIDE_SUCCESS' | 'INSIDE_TAPE_HIT' | 'INSIDE_SUCCESS';
    attempt4Result: 'FAILED' | 'OUTSIDE_TAPE_HIT' | 'OUTSIDE_SUCCESS' | 'INSIDE_TAPE_HIT' | 'INSIDE_SUCCESS';
    attempt5Result: 'FAILED' | 'OUTSIDE_TAPE_HIT' | 'OUTSIDE_SUCCESS' | 'INSIDE_TAPE_HIT' | 'INSIDE_SUCCESS';
    attempt6Result: 'FAILED' | 'OUTSIDE_TAPE_HIT' | 'OUTSIDE_SUCCESS' | 'INSIDE_TAPE_HIT' | 'INSIDE_SUCCESS';
    lapTime: number;
}
