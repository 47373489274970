// hooks
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
// icons
import { ReactComponent as PlusIcon } from 'assets/svgs/plus.svg';
// api
import { useApiMutation } from 'hooks/useDynamicApi';
// commons
import Dropdown from 'components/dropdown/Default';
import { showToast } from 'components/toast/Default';
import ConfirmModal from 'components/modals/ConfirmModal';
// utils
import { positionShortOptions } from 'utils/consts/userInfo';
import { ENDPOINT_REPORT } from 'utils/consts/apiEndpoint';
// icons
import { ReactComponent as DeleteIcon } from 'assets/svgs/delete.svg';
const LineupEditElement = ({
    lineupId,
    position,
    playerName,
    playerId,
    uniformNumber,
    inactiveLineup,
}: {
    lineupId: string;
    position: string;
    playerId: string;
    playerName: string;
    uniformNumber: string;
    inactiveLineup: inactiveLineup[];
}) => {
    const { id } = useParams();
    const [newPlayer, setNewPlayer] = useState(playerId);
    const [newPosition, setNewPosition] = useState(position);
    const inactiveOptions = handleDropdownOpts(inactiveLineup);
    const [isDeleteModal, setIsDeleteModal] = useState(false);
    const deleteStarting = useApiMutation(ENDPOINT_REPORT.lineupId(id, lineupId), 'delete', {
        onSuccess: (data) => {
            showToast('선발 선수를 삭제하였습니다', 'success');
            setIsDeleteModal(false);
        },
        onError: (error) => {
            const detailError = error?.data?.detailedMessage || error;
            showToast(`${detailError}`, 'error');
            setIsDeleteModal(false);
        },
    });
    const patchStarting = useApiMutation(ENDPOINT_REPORT.lineupId(id, lineupId), 'patch', {
        onSuccess: (data) => {
            showToast('선발 선수를 수정하였습니다', 'success');
        },
        onError: (error) => {
            const detailError = error?.data?.detailedMessage || error;
            showToast(`${detailError}`, 'error');
            setNewPlayer(playerId);
            setNewPosition(position);
        },
    });

    const editStartingPlayer = (editPlayer: string) => {
        if (!patchStarting?.isPending && newPlayer && newPosition) {
            showToast('선발 선수를 수정하는중입니다', 'info');
            patchStarting.mutate({
                newPlayerId: editPlayer,
                newPosition: newPosition,
            });
        }
    };

    // 백엔드 수정 로직이 바뀌어야 함
    const editStartingPosition = (editPosition: string) => {
        if (!patchStarting?.isPending && newPlayer && newPosition) {
            showToast('선발 선수를 수정하는중입니다', 'info');
            patchStarting.mutate({
                newPlayerId: newPlayer,
                newPosition: editPosition,
            });
        }
    };

    const removeStarting = () => {
        if (!deleteStarting?.isPending && newPlayer && newPosition) {
            showToast('선발 선수를 삭제하는 중입니다', 'info');
            deleteStarting.mutate({});
        }
    };

    return (
        <div className="flex gap-[10px]">
            <ConfirmModal
                isShow={isDeleteModal}
                setIsShow={setIsDeleteModal}
                title={
                    <div className="text-center">
                        <div>교체, 골, 도움 승부차기 기록이 있는 선수는</div>
                        <div className="font-bold text-status-error">삭제할 수 없습니다</div>
                    </div>
                }
                activeFunc={removeStarting}
            />
            <Dropdown
                placeholder="포지션"
                options={positionShortOptions}
                value={newPosition}
                onChange={setNewPosition}
                className="w-[100px]"
                activeFunc={editStartingPosition}
            />

            <Dropdown
                placeholder={`${uniformNumber}. ${playerName}`}
                options={inactiveOptions}
                value={newPlayer}
                onChange={setNewPlayer}
                className="w-[390px]"
                activeFunc={editStartingPlayer}
            />
            <button
                onClick={() => setIsDeleteModal(true)}
                className="m-auto text-status-error rounded-[8px] w-[25px] h-[25px] shrink-0"
            >
                <DeleteIcon />
            </button>
        </div>
    );
};

export default LineupEditElement;

interface LineupType {
    lineupId: string;
    playerId: string;
    playerName: string;
    uniformNumber: string;
    position: string;
    rating: string;
    managerComment: string;
}
interface inactiveLineup {
    playerId: string;
    playerName: string;
    uniformNumber: string;
    positions: string[];
}
export const handleDropdownOpts = (arr: any[]) => {
    return arr?.map((arr) => ({
        value: arr.playerId,
        label: `${arr.uniformNumber}. ${arr.playerName}`,
    }));
};
