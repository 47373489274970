const ComparisonScoreCard = ({ comparisonScore }: any) => {
    return (
        <div className="flex flex-col gap-[10px] w-full">
            <div className="text-subtitle-medium-bold text-secondary-dGrayDark ">사용자 점수 비교</div>
            <div>
                <div className="flex border-y border-secondary-lGrayDark">
                    <div className="text-context-regular flex justify-center items-center w-[120px] h-[105px] bg-secondary-mGrayLight text-secondary-lGrayDark">
                        평균점수
                    </div>
                    <div className="flex gap-[18px] items-center justify-center m-auto text-center text-secondary-dGrayMid text-subtitle-small-bold">
                        <div className="w-[152px] flex flex-col gap-[10px]">
                            <div>내 점수</div>
                            <div>{comparisonScore?.averageScore?.playerScore}</div>
                        </div>
                        <div className="w-[152px] flex flex-col gap-[10px]">
                            <div>팀 점수</div>
                            <div>{comparisonScore?.averageScore?.teamScore}</div>
                        </div>
                        <div className="w-[152px] flex flex-col gap-[10px]">
                            <div>전국 점수</div>
                            <div>{comparisonScore?.averageScore?.nationwideScore}</div>
                        </div>
                    </div>
                </div>
                <div className="flex border-b border-secondary-lGrayDark">
                    <div className="text-context-regular flex justify-center items-center w-[120px] h-[105px] bg-secondary-mGrayLight text-secondary-lGrayDark">
                        랭킹
                    </div>
                    <div className="flex gap-[18px] items-center justify-center m-auto text-center text-secondary-dGrayMid text-subtitle-small-bold">
                        <div className="w-[152px] flex flex-col gap-[10px]">
                            <div>내 랭킹</div>
                            <div>{comparisonScore?.ranking?.playerRanking}</div>
                        </div>
                        <div className="w-[152px] flex flex-col gap-[10px]">
                            <div>팀 랭킹</div>
                            <div>{comparisonScore?.ranking?.teamRanking}</div>
                        </div>
                        <div className="w-[152px] flex flex-col gap-[10px]">
                            <div>전국 랭킹</div>
                            <div>{comparisonScore?.ranking?.nationwideRanking}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ComparisonScoreCard;
