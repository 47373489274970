// hooks
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
// api
import { useApiQuery, useApiMutation } from 'hooks/useDynamicApi';
// commons
import EmptyBar from 'components/emptyBar';
import { showToast } from 'components/toast/Default';
import PlayerSearchDropdown from 'components/dropdown/PlayerSearchDropdown';
import SoccerBallLodaing from 'components/loading/SoccerBallLodaing';
import Dropdown from 'components/dropdown/Default';
// utils
import { formatDateTimeMatcher } from 'utils/matchers/reportMatcher';
import { departmentMatcher } from 'utils/matchers/measurementMatcher';
import { ENDPOINT_MEASUREMENT } from 'utils/consts/apiEndpoint';
import { paramFormChanger } from 'utils/formChanger';
import { ReactComponent as PlusIcon } from 'assets/svgs/plus.svg';
// hand components
import PhysicalElement from 'pages/hand/measurement/contents/physical/PhysicalElement';
// redux
import { RootState } from 'store';
import { useSelector } from 'react-redux';

interface PhysicalType {
    setPlayerOptions: (options: { label: string; value: string }[]) => void;
}
const Physical = ({ setPlayerOptions }: PhysicalType) => {
    const { role, staffRole } = useSelector((state: RootState) => state.userInfo); // 인증 상태를 token 유무로 판단
    const inputStyle =
        'focus:outline focus:outline-primary-purple focus:bg-primary-white placeholder:text-secondary-lGrayDark bg-transparent inputCommonStyle focus:text-center pl-[12px] pr-[40px]';
    const [searchParams] = useSearchParams();
    const MEASUREDAT = searchParams.get('measuredAt') || '';
    const DEPARTMENT = searchParams.get('department') || '';
    const [isAdd, setIsAdd] = useState(false);
    const [newInfo, setNewInfo] = useState({
        measuredAt: MEASUREDAT,
        department: DEPARTMENT,
        userId: '',
        height: 0,
        weight: 0,
        bodyFatPercentage: 0,
    });
    const initInfo = () => {
        setNewInfo({
            measuredAt: MEASUREDAT,
            department: DEPARTMENT,
            userId: '',
            height: 0,
            weight: 0,
            bodyFatPercentage: 0,
        });
    };
    const handleNewInfo = (updateData: any) => {
        setNewInfo((prev) => ({
            ...prev,
            ...updateData,
        }));
    };

    // react qury
    const getPhysical = useApiQuery(
        ['getPhysical', MEASUREDAT, DEPARTMENT],
        ENDPOINT_MEASUREMENT.physicalParams(
            paramFormChanger({
                measuredAt: MEASUREDAT,
                department: DEPARTMENT,
            })
        ),
        'get'
    );
    // useApiMutation
    const patchPhysicalMeasurement = useApiMutation(
        (data) => ENDPOINT_MEASUREMENT.physical(data.playerId), // playerId를 동적으로 URL에 포함
        'patch',
        {
            onSuccess: (data) => {
                // showToast("신체 측정 기록을 수정했습니다.", "success");
                initInfo();
                setIsAdd(false);
            },
            onError: (error) => {
                const errorMessage = error?.data?.detailedMessage || error || '신체 측정 기록수정에 실패했습니다.';
                showToast(errorMessage, 'error');
            },
        }
    );
    const editPhysicalMeasurement = (playerId: string) => {
        if (!patchPhysicalMeasurement?.isPending) {
            patchPhysicalMeasurement.mutate({
                playerId: playerId,
                height: newInfo?.height,
                weight: newInfo?.weight,
                bodyFatPercentage: newInfo?.bodyFatPercentage,
            });
        }
    };
    useEffect(() => {
        setPlayerOptions(handleDropdownOpts(getPhysical?.data));
    }, [getPhysical?.isSuccess, getPhysical?.data]);
    // useApiMutation
    const postNewMeasurement = useApiMutation(ENDPOINT_MEASUREMENT.measurement(), 'post', {
        onSuccess: (data) => {
            showToast('측정 기록을 추가했습니다.', 'success');
            if (newInfo?.height || newInfo?.weight || newInfo?.bodyFatPercentage) {
                editPhysicalMeasurement(data?.id);
            } else {
                initInfo();
                setIsAdd(false);
            }
        },
        onError: (error) => {
            const errorMessage = error?.data?.detailedMessage || error || '측정 기록 추가에 실패했습니다.';
            showToast(errorMessage, 'error');
        },
    });

    useEffect(() => {
        if (newInfo?.userId) {
            if (!postNewMeasurement?.isPending) {
                showToast('측정 기록 추가중입니다.', 'info');
                const departmentCheck = role === 'SUPER_ADMIN' ? DEPARTMENT : null;
                postNewMeasurement.mutate({
                    measuredAt: MEASUREDAT,
                    userId: newInfo?.userId,
                    department: departmentCheck,
                });
            }
        }
    }, [newInfo?.userId]);

    return (
        <div className="p-[30px] bg-primary-white rounded-[10px]">
            {getPhysical?.isPending && <SoccerBallLodaing />}
            <div className="flex gap-[20px] justify-between items-center">
                <div className="flex gap-[10px] items-center  w-full">
                    <span className="w-[100px] text-center text-context-regular">측정일시</span>

                    <input
                        className="inputDisabledStyle inputCommonStyle"
                        value={formatDateTimeMatcher(MEASUREDAT)}
                        readOnly
                    />
                </div>
                <div className="flex gap-[10px] items-center w-full">
                    <span className="w-[100px] text-context-regular text-center">지점</span>
                    <input
                        className="inputDisabledStyle inputCommonStyle"
                        value={departmentMatcher(DEPARTMENT)}
                        readOnly
                    />
                </div>
            </div>
            <EmptyBar customStyle="h-[20px]" />
            <div className="flex mb-[2px] bg-primary-black text-primary-white text-context-regular rounded-[8px] h-[50px]">
                {columns.map((el: { key: string; label: string }) => (
                    <div
                        key={el.key}
                        className="flex items-center justify-center w-full"
                    >
                        {el?.label}
                    </div>
                ))}
                <div className="w-[350px] flex items-center justify-center">초기화</div>
            </div>
            {getPhysical?.data?.map((el: any, idx: number) => (
                <PhysicalElement
                    {...el}
                    key={el?.measurementId}
                    idx={idx}
                />
            ))}
            {isAdd ? (
                <div className="h-[50px] py-[10px] flex gap-[10px]">
                    <PlayerSearchDropdown
                        value={newInfo?.userId}
                        onChange={(value) => handleNewInfo({ userId: value })}
                    />
                    <input
                        value={newInfo?.height}
                        placeholder="cm"
                        onChange={(e) => handleNewInfo({ height: e.target.value })}
                        className={inputStyle}
                    />
                    <input
                        value={newInfo?.weight}
                        placeholder="kg"
                        className={inputStyle}
                        onChange={(e) => handleNewInfo({ weight: e.target.value })}
                    />
                    <input
                        value={newInfo?.bodyFatPercentage}
                        placeholder="%"
                        className={inputStyle}
                        onChange={(e) => handleNewInfo({ bodyFatPercentage: e.target.value })}
                    />
                    <div className="w-[250px]"></div>
                </div>
            ) : (
                <button
                    onClick={() => setIsAdd(true)}
                    className="w-full h-[44px] mt-[10px] rounded-[8px] text-context-regular text-secondary-lGrayDark gap-[10px] border border-secondary-lGrayMid flex items-center justify-center"
                >
                    <PlusIcon />
                    선수 추가
                </button>
            )}
        </div>
    );
};
export const columns = [
    { key: 'playerName', label: '이름' },
    { key: 'height', label: '키' },
    { key: 'weight', label: '몸무게' },
    { key: 'bodyFatPercentage', label: '체지방률' },
];

export const handleDropdownOpts = (arr: any[]) => {
    return arr?.map((arr) => ({
        value: arr?.measurementId, // measurementId 이다.playerId아님.
        label: `${arr?.playerName}`,
    }));
};

export default Physical;
