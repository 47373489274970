// hooks
import React, { useRef, useState, MouseEvent, useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import { useApiMutation } from 'hooks/useDynamicApi';
// commons
import EmptyBar from 'components/emptyBar';
import DatePicker from 'components/datePicker';
// api
import { useApiQuery } from 'hooks/useDynamicApi';
// icons
import { ReactComponent as PrintIcon } from 'assets/svgs/print.svg';
import { ReactComponent as CalendarIcon } from 'assets/svgs/calendar.svg';
// player components
import ProfileCard from 'pages/player/report/personalReport/profileCard';
import DataSummaryCard from 'pages/player/report/personalReport/dataSummaryCard';
import DribbleCard from 'pages/player/report/personalReport/dribbleCard';
import FirstTouchCard from 'pages/player/report/personalReport/firstTouchCard';
import PassCard from 'pages/player/report/personalReport/passCard';
import ShootingCard from 'pages/player/report/personalReport/shootingCard';
// utils
import { DASHBOARD, ENDPOINT_MEASUREMENT } from 'utils/consts/apiEndpoint';
import { formatDateTimeMatcher } from 'utils/matchers/reportMatcher';

// redux
import { RootState } from 'store'; // Redux Store 타입 정의
import { useSelector } from 'react-redux';
import { showToast } from 'components/toast/Default';

const PersonalReport = () => {
    const [searchParams] = useSearchParams();
    const dateTime = searchParams.get('dateTime') || '';
    // const measurementId = searchParams.get('measurementId') || '';
    const { measurementId = '' } = useParams();
    const playerId = searchParams.get('playerId') || '';

    const [department, setDepartment] = useState('판교점');
    const [isPrint, setIsPrint] = useState(false);
    const contentRef = useRef<HTMLDivElement>(null);
    const reactToPrintFn = useReactToPrint({
        contentRef, // 이렇게 화살표 함수로 변경
        onBeforePrint: () => {
            setIsPrint(true);
            return Promise.resolve();
        },
        onAfterPrint: () => {
            setIsPrint(false);
            return Promise.resolve();
        },
    });

    useEffect(() => {
        if (isPrint) {
            reactToPrintFn();
        }
    }, [isPrint, reactToPrintFn]);

    const handlePrintClick = () => {
        setIsPrint(true);
    };
    // const { role, id = '' } = useSelector((state: RootState) => state.userInfo); // 인증 상태를 token 유무로 판단
    // const getPlayerDashboard = useApiQuery(
    //   ["getPlayerDashboard", `${id}`],
    //   DASHBOARD.player(`${id}`),
    //   "get",
    //   {
    //     enabled: !!id,
    //   }
    // );
    // const getDashboardDetail = useApiQuery(
    //   ["getDashboardDetail", `${id}`],
    //   DASHBOARD.playerDetail(`${id}`),
    //   "get",
    //   {
    //     enabled: !!id,
    //   }
    // );
    const getMeasurementAnalysis = useApiQuery(
        ['getMeasurementAnalysis', `${measurementId}`],
        DASHBOARD.measurement(`${measurementId}`),
        'get',
        {
            enabled: !!measurementId,
        }
    );

    const {
        dribbleOneAnalysis,
        dribbleTwoAnalysis,
        firstTouchAnalysis,
        passOneAnalysis,
        passTwoAnalysis,
        physicalInfo,
        playerInfo,
        shootingOneAnalysis,
        shootingTwoAnalysis,
        skillAnalysis,
    } = getMeasurementAnalysis?.data || {};

    // console.log(skillAnalysis);

    return (
        <div>
            <div className="wrapper p-[30px] rounded-[10px] bg-primary-white ">
                {getMeasurementAnalysis?.data ? (
                    <div ref={contentRef}>
                        <div className="flex flex-col gap-[20px]">
                            <div className="flex justify-between">
                                <div className="text-subtitle-medium-bold text-secondary-dGrayDark flex gap-[20px] items-center">
                                    <div className="text-subtitle-large-bold">분석결과표</div>
                                </div>
                                {!isPrint && (
                                    <button
                                        onClick={() => setIsPrint(true)}
                                        className="flex justify-center items-center gap-[10px] w-[110px] h-[44px] rounded-[8px] bg-primary-green text-context-bold"
                                    >
                                        <span>출력하기</span>
                                        <PrintIcon />
                                    </button>
                                )}
                            </div>

                            <div className="grid grid-cols-2 gap-[20px]">
                                <div className="flex items-center w-full ">
                                    <span className="w-[100px] text-context-regular">평가 일시</span>
                                    {/* <DatePicker
                                modalBtn={ */}
                                    <div className="flex items-center text-context-bold justify-between p-[10px] w-[550px] gap-[14px] border border-primary-purple rounded-[8px]">
                                        {formatDateTimeMatcher(dateTime) || '평가날짜'}
                                        <CalendarIcon className="text-primary-purple" />
                                    </div>
                                    {/* }
                                selected={measurementDateTime}
                                setSelected={(value) => setMeasurementDateTime(value)}
                            /> */}
                                </div>
                                <div className="flex items-center">
                                    <span className="w-[100px] text-context-regular">지점</span>
                                    <input
                                        className="inputCommonStyle inputDisabledStyle"
                                        readOnly
                                        value={department}
                                    />
                                </div>
                            </div>

                            <ProfileCard
                                playerInfo={playerInfo}
                                physicalInfo={physicalInfo}
                            />
                            <DataSummaryCard
                                isPrint={isPrint}
                                skillAnalysis={skillAnalysis}
                            />
                        </div>
                        <EmptyBar customStyle="h-[48px]" />
                        <div className="flex flex-col gap-[62px]">
                            <DribbleCard
                                isPrint={isPrint}
                                dribbleAvgScore={skillAnalysis?.dribbleAvgScore}
                                dribbleOneAnalysis={dribbleOneAnalysis}
                                dribbleTwoAnalysis={dribbleTwoAnalysis}
                            />
                            <FirstTouchCard
                                isPrint={isPrint}
                                firstTouchAnalysis={firstTouchAnalysis}
                                firstTouchScore={skillAnalysis?.firstTouchScore}
                            />
                            {isPrint && <EmptyBar customStyle="h-[48px]" />}

                            <PassCard
                                isPrint={isPrint}
                                passAvgScore={skillAnalysis?.passAvgScore}
                                passOneAnalysis={passOneAnalysis}
                                passTwoAnalysis={passTwoAnalysis}
                            />
                            <ShootingCard
                                isPrint={isPrint}
                                shootingAvgScore={skillAnalysis?.shootingAvgScore}
                                shootingOneAnalysis={shootingOneAnalysis}
                                shootingTwoAnalysis={shootingTwoAnalysis}
                            />
                        </div>
                    </div>
                ) : getMeasurementAnalysis?.isPending ? (
                    <div>상세 측정 결과를 불러오는 중입니다.</div>
                ) : (
                    <div>완료되지 않은 측정입니다.</div>
                )}
            </div>
            <EmptyBar customStyle="h-[20px]" />
        </div>
    );
};

export default PersonalReport;
