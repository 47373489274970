import Swal from "sweetalert2";
import "animate.css/animate.min.css";
// icons
import { ReactComponent as InfoBoxIcon } from "assets/svgs/infoBox.svg";
import { ReactComponent as WarningBoxIcon } from "assets/svgs/warningBox.svg";
import { ReactComponent as CheckBoxIcon } from "assets/svgs/checkBox.svg";
import { ReactComponent as FailBoxIcon } from "assets/svgs/failBox.svg";
import React from "react";
import ReactDOM from "react-dom";

type IconType = React.ComponentType<React.SVGProps<SVGSVGElement>>;

interface ToastConfig {
  backgroundColor: string;
  textColor: string;
  progressBarColor: string; // 프로그레스 바 색상 추가
  Icon: IconType;
}

export const showToast = (
  message: string,
  type: "success" | "error" | "info" | "warning" = "info"
) => {
  const getConfig = (): ToastConfig => {
    switch (type) {
      case "success":
        return {
          backgroundColor: "#BBFE32",
          textColor: "#100E26",
          progressBarColor: "#24965f", // 진한 녹색
          Icon: CheckBoxIcon,
        };
      case "error":
        return {
          backgroundColor: "#EE2C36",
          textColor: "#ffffff",
          progressBarColor: "#8e3a19", // 진한 빨간색
          Icon: FailBoxIcon,
        };
      case "info":
        return {
          backgroundColor: "#242239",
          textColor: "#ffffff",
          progressBarColor: "#bfb7b3", // 진한 회색
          Icon: InfoBoxIcon,
        };
      case "warning":
        return {
          backgroundColor: "#FEE632",
          textColor: "#100E26",
          progressBarColor: "#929613", // 진한 노란색
          Icon: WarningBoxIcon,
        };
      default:
        return {
          backgroundColor: "#242239",
          textColor: "#ffffff",
          progressBarColor: "#bfb7b3",
          Icon: InfoBoxIcon,
        };
    }
  };

  const { backgroundColor, textColor, progressBarColor, Icon } = getConfig();

  // Create temporary container for icon
  const iconContainer = document.createElement("div");
  ReactDOM.render(<Icon />, iconContainer);
  const iconSvg = iconContainer.innerHTML;

  Swal.fire({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    showCloseButton: true,
    timer: 3000,
    timerProgressBar: true,
    showClass: {
      popup: "animate__animated animate__fadeInRight",
    },
    hideClass: {
      popup: "animate__animated animate__fadeOutRight",
    },
    didOpen: (toast) => {
      const toastElement = toast as HTMLElement;
      toastElement.style.padding = "15px 20px";
      toastElement.style.backgroundColor = backgroundColor;
      toastElement.style.color = textColor;
      toastElement.style.display = "flex";
      toastElement.style.alignItems = "center";
      toastElement.style.gap = "10px";
      toastElement.style.borderRadius = "8px";
      toastElement.style.fontSize = "14px";
      toastElement.style.fontWeight = "bold";
      toastElement.style.wordBreak = "keep-all";
      toastElement.style.overflowWrap = "break-word";

      // Style the close button
      const closeButton = toastElement.querySelector(
        ".swal2-close"
      ) as HTMLElement;
      if (closeButton) {
        closeButton.style.position = "absolute";
        closeButton.style.right = "8px";
        closeButton.style.top = "50%";
        closeButton.style.transform = "translateY(-50%)";
        closeButton.style.color = textColor;
        closeButton.style.fontSize = "18px";
        closeButton.style.padding = "0";
        closeButton.style.margin = "0";
      }

      // Style the progress bar
      const progressBar = toastElement.querySelector(
        ".swal2-timer-progress-bar"
      ) as HTMLElement;
      if (progressBar) {
        progressBar.style.backgroundColor = progressBarColor;
        progressBar.style.height = "3px"; // 프로그레스 바 높이 조정
      }

      // Style SVG icon
      const svgElement = toastElement.querySelector("svg");
      if (svgElement) {
        svgElement.style.width = "24px";
        svgElement.style.height = "24px";
      }
    },
    html: `
      <div style="display: flex; align-items: center; gap: 10px;">
        <div style="width: 24px; height: 24px; display: flex; align-items: center; justify-content: center;">
          ${iconSvg}
        </div>
        <span>${message}</span>
      </div>
    `,
    customClass: {
      popup: "custom-toast-popup",
    },
  });
};
