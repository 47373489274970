// hooks
import { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
// icons
import { ReactComponent as DeleteIcon } from 'assets/svgs/delete.svg';
// commons
import Dropdown from 'components/dropdown/Default';
import ConfirmModal from 'components/modals/ConfirmModal';
// utils
import { positionShortOptions } from 'utils/consts/userInfo';
import { ENDPOINT_REPORT } from 'utils/consts/apiEndpoint';
// api
import { useApiMutation } from 'hooks/useDynamicApi';
import { showToast } from 'components/toast/Default';
const SubstitutionEditElement = ({
    activeOptions,
    inactiveOptions,
    substitutionId,
    playerInId,
    playerInName,
    playerInUniformNumber,
    playerInPosition,
    playerOutId,
    playerOutName,
    playerOutUniformNumber,
    playerOutPosition,
    substitutionTime,
    getSubstitutionLength,
    idx,
}: SubstitutionType) => {
    const { id } = useParams();

    const [newSubstitutionTime, setNewSubstitutionTime] = useState(substitutionTime);
    const [newInPlayer, setNewInPlayer] = useState(playerInId);
    const [newInPlayerPos, setNewInPlayerPos] = useState(playerInPosition);
    const [newOutPlayer, setNewOutPlayer] = useState(playerOutId);
    const [newOutPlayerPos, setNewOutPlayerPos] = useState(playerOutPosition);

    return (
        <div className="flex gap-[10px]">
            <div className="w-[110px]">
                <input
                    readOnly
                    value={`${newSubstitutionTime} 분`}
                    className="inputDefaultStyle inputCommonStyle"
                />
            </div>
            <div className="w-[100px]">
                <input
                    readOnly
                    value={newInPlayerPos}
                    className="inputDefaultStyle inputCommonStyle"
                />
            </div>

            <div className="w-[270px]">
                <input
                    readOnly
                    value={newInPlayer}
                    className="inputDefaultStyle inputCommonStyle"
                />
            </div>

            <div className="w-[100px]">
                <input
                    readOnly
                    value={newOutPlayerPos}
                    className="inputDefaultStyle inputCommonStyle"
                />
            </div>

            <div className="w-[270px]">
                <input
                    readOnly
                    value={newOutPlayer}
                    className="inputDefaultStyle inputCommonStyle"
                />
            </div>
        </div>
    );
};

export default SubstitutionEditElement;

interface SubstitutionType {
    activeOptions: any;
    inactiveOptions: any;

    substitutionId: string;
    playerInId: string;
    playerInName: string;
    playerInUniformNumber: string;
    playerInPosition: string;
    playerOutId: string;
    playerOutName: string;
    playerOutUniformNumber: string;
    playerOutPosition: string;
    substitutionTime: string;

    getSubstitutionLength: string;
    idx: number;
}
