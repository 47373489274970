import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface TeamInfoState {
    ageGroup: string;
    teamId: string;
    teamName: string;
}

const initialState: TeamInfoState = {
    ageGroup: localStorage.getItem('ageGroup') ?? '',
    teamId: localStorage.getItem('teamId') ?? '',
    teamName: localStorage.getItem('teamName') ?? '',
};

const teamInfoSlice = createSlice({
    name: 'teamInfo',
    initialState,
    reducers: {
        setTeamInfoState: (state, action: PayloadAction<TeamInfoState>) => {
            state.ageGroup = action.payload.ageGroup; // id 설정
            state.teamId = action.payload.teamId; // teamId 설정
            state.teamName = action.payload.teamName;

            if (action.payload.ageGroup) localStorage.setItem('ageGroup', action.payload.ageGroup);
            if (action.payload.teamId) localStorage.setItem('teamId', String(action.payload.teamId));
            if (action.payload.teamName) localStorage.setItem('teamName', action.payload.teamName);
        },
        teamInfoRemove: (state) => {
            state.ageGroup = '';
            state.teamId = '';
            state.teamName = '';

            localStorage.removeItem('ageGroup');
            localStorage.removeItem('teamId');
            localStorage.removeItem('teamName');
        },
    },
});

export const { setTeamInfoState, teamInfoRemove } = teamInfoSlice.actions;
export default teamInfoSlice.reducer;
