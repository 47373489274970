// hooks
import { useState } from 'react';
import { useApiMutation } from 'hooks/useDynamicApi';
// player components
import TeamSearchTable from 'pages/player/join/TeamSearchTable';
// common
import EmptyBar from 'components/emptyBar';
import ConfirmModal from 'components/modals/DefaultModal';
import { showToast } from 'components/toast/Default';
// player components
import JoinSubHeader from './JoinSubHeader';
// utils
import { ENDPOINT_TEAM } from 'utils/consts/apiEndpoint';

const Join = () => {
    const [hasTeam, setHasTeam] = useState<hasTeamType | null>(null);
    // 선수는 한번에 한 팀에만 속할 수 있음

    const [deleteRequestModal, setDeleteRequestModal] = useState(false);
    const deleteTeamRequest = useApiMutation(ENDPOINT_TEAM.player.request(hasTeam?.requestId), 'delete', {
        onSuccess: (data) => {
            showToast('팀을 탈퇴 했습니다.', 'success'); //240108 - 탈퇴가 아닌 멤버쉽 요청 삭제여서 잘못된 요청임
            setHasTeam(null);
        },
        onError: (error) => {
            // 에러 메시지 추출
            const errorMessage = error?.data?.detailedMessage || error || '팀 탈퇴를 실패했습니다.'; //240108 - 탈퇴가 아닌 멤버쉽 요청 삭제여서 잘못된 요청임
            showToast(`${errorMessage}`, 'error');
        },
    });

    const removeTeamRequest = () => {
        //240108 - 탈퇴가 아닌 멤버쉽 요청 삭제여서 잘못된 요청임
        if (!deleteTeamRequest?.isPending) {
            showToast('팀을 탈퇴중입니다.', 'info');
            deleteTeamRequest?.mutate({});
        }
    };
    return (
        <div className="wrapper">
            <ConfirmModal
                isShow={deleteRequestModal}
                setIsShow={setDeleteRequestModal}
                title={'팀을 탈퇴하시겠습니까?'}
                content={''}
                activeFunc={removeTeamRequest}
            />
            <JoinSubHeader />
            <EmptyBar customStyle="h-[35px]" />
            {!hasTeam ? (
                <TeamSearchTable setHasTeam={setHasTeam} />
            ) : (
                <div className="bg-primary-white flex justify-between rounded-[10px] p-[30px]">
                    <div className="gap-[10px] flex text-subtitle-medium-bold">
                        <span className="text-secondary-dGrayMid">현재 소속된 팀은</span>
                        <span className="text-primary-purple">[{hasTeam?.teamName || '미정'}]</span>
                        <span className="text-secondary-dGrayMid">입니다</span>
                    </div>
                    {/* 
                    추후 탈퇴하기 기능 추가시 예정
                    <button
                     onClick={() => setDeleteRequestModal(true)} // 해당 모달 엔드포인트는 잘못되었음
                        onClick={() => alert('개발중입니다.')}
                        className="w-[80px] h-[44px] rounded-[8px] bg-primary-black text-primary-white text-context-bold "
                    >
                        탈퇴하기
                    </button> */}
                </div>
            )}
        </div>
    );
};

export default Join;
interface hasTeamType {
    createdAt: string;
    reason: string;
    rejectReason: string;
    requestId: string;
    status: string;
    teamAgeGroup: string;
    teamId: string;
    teamName: string;
    userAge: string;
    userId: string;
    userName: string;
}
