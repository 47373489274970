// hooks
import { useState } from 'react';
import { useParamNavigate } from 'hooks/useDynamicNavigate';
// commons
import EmptyBar from 'components/emptyBar';
import Tabs from 'components/tab/Default';
// handcomponents
import Physical from 'pages/hand/measurement/contents/physical';
import DribbleTwo from 'pages/hand/measurement/contents/dribbleTwo';
import FirstTouch from 'pages/hand/measurement/contents/firstTouch';
import PassOne from 'pages/hand/measurement/contents/passOne';
import PassTwo from 'pages/hand/measurement/contents/passTwo';
import ShootingOne from 'pages/hand/measurement/contents/shootingOne';
import ShootingTwo from 'pages/hand/measurement/contents/shootingTwo';
// assets
import aisoccerWhiteFont from 'assets/logos/font-logo/aisoccer-white-font.webp';
// icons
import { ReactComponent as CircleArrowIcon } from 'assets/svgs/circleArrow.svg';

const HandMeasurement = () => {
    const [selectedTab, setSelectedTab] = useState(0);
    const [playerCurOptions, setCurPlayerOptions] = useState<{ label: string; value: string }[]>([]);
    const tabData = [
        {
            label: '신체',
            value: 'physical',
            content: <Physical setPlayerOptions={setCurPlayerOptions} />,
        },
        {
            label: '드리블(지그재그)',
            value: 'dribble-two',
            content: <DribbleTwo />,
        },
        {
            label: '퍼스트 터치',
            value: 'first-touch',
            content: <FirstTouch playerOptions={playerCurOptions} />,
        },
        {
            label: '정적 패스',
            value: 'pass-one',
            content: <PassOne />,
        },
        {
            label: '동적 패스',
            value: 'pass-two',
            content: <PassTwo playerOptions={playerCurOptions} />,
        },
        {
            label: '슈팅1',
            value: 'shooting-one',
            content: <ShootingOne playerOptions={playerCurOptions} />,
        },
        {
            label: '슈팅2',
            value: 'shooting-two',
            content: <ShootingTwo playerOptions={playerCurOptions} />,
        },
    ];

    const handleParamNavigate = useParamNavigate();
    const handleNavigate = () => {
        // PANGYO 이후 추가 지점이 되면 하드코딩에서 변경필요
        handleParamNavigate({}, false, `/hand/records`);
    };
    return (
        <div className="handWrapper">
            <button className="flex justify-between w-full">
                <div
                    onClick={() => {
                        handleNavigate();
                    }}
                    className="text-[26px] font-bold text-white flex items-center gap-[10px]"
                >
                    <CircleArrowIcon className="" />
                    측정평가표
                </div>
                <img
                    src={aisoccerWhiteFont}
                    className="w-[140px] h-[21.04px]"
                    alt="aisoccerWhiteFont "
                />
            </button>
            <EmptyBar customStyle={'h-[30px]'} />

            <div className="w-fit">
                <Tabs
                    tabs={tabData}
                    defaultActiveTab={0}
                    onTabChange={setSelectedTab}
                    colorType="green"
                />
            </div>
            <EmptyBar customStyle="h-[20px]" />

            <div>{tabData[selectedTab]?.content}</div>
        </div>
    );
};
export default HandMeasurement;
