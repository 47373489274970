export function regionMatcher(region: string) {
  switch (region) {
    case "서울":
      return "SEOUL";
    case "경기":
      return "GYEONGGI";
    case "인천":
      return "INCHEON";
    case "대전":
      return "DAEJEON";
    case "대구":
      return "DAEGU";
    case "부산":
      return "BUSAN";
    case "강원":
      return "GANGWON";
    case "광주":
      return "GWANGJU";
    case "울산":
      return "ULSAN";
    case "경남":
      return "GYEONGNAM";
    case "경북":
      return "GYEONGBUK";
    case "전남":
      return "JEONNAM";
    case "전북":
      return "JEONBUK";
    case "제주":
      return "JEJU";
    case "SEOUL":
      return "서울";
    case "GYEONGGI":
      return "경기";
    case "INCHEON":
      return "인천";
    case "DAEJEON":
      return "대전";
    case "DAEGU":
      return "대구";
    case "BUSAN":
      return "부산";
    case "GANGWON":
      return "강원";
    case "GWANGJU":
      return "광주";
    case "ULSAN":
      return "울산";
    case "GYEONGNAM":
      return "경남";
    case "GYEONGBUK":
      return "경북";
    case "JEONNAM":
      return "전남";
    case "JEONBUK":
      return "전북";
    case "JEJU":
      return "제주";
    default:
      throw new Error("Invalid key");
  }
}

export function majorFootMatcher(majorFoot: string) {
  switch (majorFoot) {
    case "LEFT":
      return "왼발";
    case "RIGHT":
      return "오른발";
    case "BOTH":
      return "양발";
    case "왼발":
      return "LEFT";
    case "오른발":
      return "RIGHT";
    case "양발":
      return "BOTH";
    default:
      throw new Error("Invalid key");
  }
}

export function positionMatcher(position: string): string {
  switch (position) {
    case "GK":
      return "골키퍼";
    case "CB":
      return "센터백";
    case "LB":
      return "레프트 윙백";
    case "RB":
      return "라이트 윙백";
    case "CDM":
      return "수비형 미드필더";
    case "CM":
      return "중앙 미드필더";
    case "CAM":
      return "공격형 미드필더";
    case "LW":
      return "레프트 윙";
    case "RW":
      return "라이트 윙";
    case "CF":
      return "센터 포워드";
    case "골키퍼":
      return "GK";
    case "센터백":
      return "CB";
    case "레프트 윙백":
      return "LB";
    case "라이트 윙백":
      return "RB";
    case "수비형 미드필더":
      return "CDM";
    case "중앙 미드필더":
      return "CM";
    case "공격형 미드필더":
      return "CAM";
    case "레프트 윙":
      return "LW";
    case "라이트 윙":
      return "RW";
    case "센터 포워드":
      return "CF";
    default:
      throw new Error("Invalid position");
  }
}

export function playerProfileSortMatcher(
  key:
    | "name"
    | "uniformNumber"
    | "age"
    | "seasonAverageRating"
    | "totalAppearances"
    | "totalGoals"
    | "totalAssists"
    | string,
  sort: "ASC" | "DESC"
): string {
  switch (key) {
    case "uniformNumber":
      return sort === "ASC"
        ? "PLAYER_UNIFORM_NUMBER_ASC"
        : "PLAYER_UNIFORM_NUMBER_DESC";
    case "name":
      return sort === "ASC" ? "PLAYER_NAME_ASC" : "PLAYER_NAME_DESC";
    case "age":
      return sort === "ASC" ? "PLAYER_AGE_ASC" : "PLAYER_AGE_DESC";
    case "seasonAverageRating":
      return sort === "ASC" ? "PLAYER_RATING_ASC" : "PLAYER_RATING_DESC";
    case "totalAppearances":
      return sort === "ASC"
        ? "PLAYER_APPEARANCES_ASC"
        : "PLAYER_APPEARANCES_DESC";
    case "totalGoals":
      return sort === "ASC" ? "PLAYER_GOALS_ASC" : "PLAYER_GOALS_DESC";
    case "totalAssists":
      return sort === "ASC" ? "PLAYER_ASSISTS_ASC" : "PLAYER_ASSISTS_DESC";
    default:
      throw new Error("Invalid key");
  }
}

export function ageMatcher(age: number) {
  if (age < 8) return "미취학";
  else if (age <= 13) return `초${age - 7}`;
  else if (age <= 16) return `중${age - 13}`;
  else if (age <= 19) return `고${age - 16}`;
  else if (age >= 20) return `성인`;
}

export function birthYearMatcher(birthYear: number) {
  if (!birthYear) return "";
  const currentYear = new Date().getFullYear(); // 현재 연도 계산
  const age = currentYear - birthYear; // 나이 계산
  return ageMatcher(age); // 나이에 따라 결과 반환
}

export function staffRoleMatcher(staffRole: string) {
  switch (staffRole) {
    case "OFFICE":
      return "사무국";
    case "COACH":
      return "코치";
    case "MANAGER":
      return "감독";

    default:
      return staffRole;
  }
}

export function roleMatcher(role: string) {
  switch (role) {
    case "PLAYER":
      return "선수";
    case "TEAM_STAFF":
      return "팀스태프";
    case "SUPER_ADMIN":
      return "슈퍼 관리자";
    case "SYSTEM_ADMIN":
      return "시스템 관리자";
    case "DATA_ANALYST":
      return "데이터 분석관";
    default:
      return role;
  }
}

export function classTypeMatcher(type: string) {
  switch (type) {
    case "HOBBY":
      return "취미";
    case "ELITE":
      return "엘리트";
    case "취미":
      return "HOBBY";
    case "엘리트":
      return "ELITE";
    case "NONE":
      return "NONE";
    default:
      return type;
  }
}

export function genderMatcher(gender: string) {
  switch (gender) {
    case "MALE":
      return "남자";
    case "FEMALE":
      return "여자";
    case "여자":
      return "MALE";
    case "남자":
      return "MALE";
    default:
      return gender;
  }
}

export function joinStatusMatcher(joinStatus: string) {
  switch (joinStatus) {
    case "ACCEPTED":
      return "승인됨";
    case "REJECTED":
      return "거절됨";
    case "PENDING":
      return "대기중";

    default:
      return joinStatus;
  }
}
