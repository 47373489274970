// hooks
import { useState, useEffect, useMemo } from "react";
import { debounce } from "lodash";
// api
import { useApiMutation } from "hooks/useDynamicApi";
// commons
import PlayerSearchDropdown from "components/dropdown/PlayerSearchDropdown";
import { showToast } from "components/toast/Default";
// utils
import { ENDPOINT_MEASUREMENT } from "utils/consts/apiEndpoint";
// icons
import { ReactComponent as ResetIcon } from "assets/svgs/reset.svg";

interface PhysicalElementType {
  idx: number;
  measurementId: string;
  physicalId: string;
  playerName: string;
  height: number;
  weight: number;
  bodyFatPercentage: number;
}
const PhysicalElement = ({
  idx,
  measurementId,
  physicalId,
  playerName,
  height = 0,
  weight = 0,
  bodyFatPercentage = 0,
}: PhysicalElementType) => {
  const inputStyle =
    "focus:outline focus:outline-primary-purple focus:bg-primary-white placeholder:text-secondary-lGrayDark bg-transparent inputCommonStyle focus:text-center pl-[12px] pr-[40px]";
  const [editPlayerInfo, setEditPlayerInfo] = useState({
    measurementId: measurementId,
    physicalId: physicalId,
    playerId: "",
    playerName: playerName,
  });
  const [editPhysicalInfo, setEditPhysicalInfo] = useState({
    height: height,
    weight: weight,
    bodyFatPercentage: bodyFatPercentage,
  });
  const handleEditPlayerInfo = (updateData: any) => {
    setEditPlayerInfo((prev) => ({
      ...prev,
      ...updateData,
    }));
  };
  const handleEditPhysicalInfo = (updateData: any) => {
    setEditPhysicalInfo((prev) => ({
      ...prev,
      ...updateData,
    }));
  };

  // useApiMutation
  const patchPhysicalMeasurement = useApiMutation(
    ENDPOINT_MEASUREMENT.physical(editPlayerInfo?.physicalId),
    "patch",
    {
      onSuccess: (data) => {
        // showToast("신체 측정 기록을 수정했습니다.", "success");
      },
      onError: (error) => {
        const errorMessage =
          error?.data?.detailedMessage ||
          error ||
          "신체 측정 기록수정에 실패했습니다.";
        showToast(errorMessage, "error");
      },
    }
  );
  const patchPlayerMeasurement = useApiMutation(
    ENDPOINT_MEASUREMENT.playerMeasurement(editPlayerInfo?.measurementId),
    "patch",
    {
      onSuccess: (data) => {
        showToast("선수를 변경했습니다.", "success");
        handleEditPlayerInfo({
          playerName: data?.userName,
        });
      },
      onError: (error) => {
        const errorMessage =
          error?.data?.detailedMessage || error || "선수 변경에 실패했습니다.";
        showToast(errorMessage, "error");
        handleEditPlayerInfo({ playerId: "" });
      },
    }
  );
  const patchPhysicalReset = useApiMutation(
    ENDPOINT_MEASUREMENT.physicalReset(editPlayerInfo?.measurementId),
    "patch",
    {
      onSuccess: (data) => {
        showToast("신체 측정정보를 초기화했습니다.", "success");
        handleEditPhysicalInfo({
          height: 0,
          weight: 0,
          bodyFatPercentage: 0,
        });
      },
      onError: (error) => {
        const errorMessage =
          error?.data?.detailedMessage ||
          error ||
          "신체 측정정보 초기화에 실패했습니다.";
        showToast(errorMessage, "error");
      },
    }
  );
  const editPhysicalMeasurement = () => {
    if (!patchPhysicalMeasurement?.isPending) {
      patchPhysicalMeasurement.mutate({
        height: editPhysicalInfo?.height,
        weight: editPhysicalInfo?.weight,
        bodyFatPercentage: editPhysicalInfo?.bodyFatPercentage,
      });
    }
  };
  const editPlayerMeasurement = () => {
    if (!patchPlayerMeasurement?.isPending) {
      showToast("선수를 변경하는중입니다.", "info");
      patchPlayerMeasurement.mutate({
        playerId: editPlayerInfo?.playerId,
      });
    }
  };
  const editPhysicalReset = () => {
    if (!patchPhysicalReset?.isPending) {
      showToast("신체 측정데이터를 초기화하는 중입니다.", "info");
      patchPhysicalReset.mutate({});
    }
  };
  // debounce
  const debouncedPatchPhysical = useMemo(
    () =>
      debounce(() => {
        editPhysicalMeasurement();
      }, 300), // 1초 딜레이
    [patchPhysicalMeasurement]
  );
  const debouncedPatchPlayer = useMemo(
    () =>
      debounce(() => {
        editPlayerMeasurement();
      }, 300), // 1초 딜레이
    [patchPlayerMeasurement]
  );
  useEffect(() => {
    if (
      editPhysicalInfo?.height === height &&
      editPhysicalInfo?.weight === weight &&
      editPhysicalInfo?.bodyFatPercentage === bodyFatPercentage
    ) {
      return;
    }
    debouncedPatchPhysical();
    return () => {
      debouncedPatchPhysical.cancel();
    };
  }, [editPhysicalInfo]);
  useEffect(() => {
    if (editPlayerInfo?.playerId) {
      debouncedPatchPlayer();
      return () => debouncedPatchPlayer.cancel();
    }
  }, [editPlayerInfo?.playerId]);
  return (
    <div
      className={`h-[50px]  flex gap-[10px] rounded-[8px] ${
        idx % 2 === 1 ? "bg-secondary-mGrayLight" : "bg-primary-white"
      }`}
    >
      <PlayerSearchDropdown
        placeholder={
          editPlayerInfo?.playerId ? "선수이름" : editPlayerInfo?.playerName
        }
        value={editPlayerInfo?.playerId}
        onChange={(value) => handleEditPlayerInfo({ playerId: value })}
      />
      <input
        value={editPhysicalInfo?.height}
        onChange={(e) => handleEditPhysicalInfo({ height: e.target.value })}
        className={inputStyle}
      />
      <input
        value={editPhysicalInfo?.weight}
        onChange={(e) => handleEditPhysicalInfo({ weight: e.target.value })}
        className={inputStyle}
      />
      <input
        value={editPhysicalInfo?.bodyFatPercentage}
        onChange={(e) =>
          handleEditPhysicalInfo({ bodyFatPercentage: e.target.value })
        }
        className={inputStyle}
      />
      <button className="w-[250px]" onClick={editPhysicalReset}>
        <ResetIcon className="w-[20px] h-[20px] m-auto" />
      </button>
    </div>
  );
};

export default PhysicalElement;
