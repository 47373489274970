// hooks
import { useState } from "react";
import { useSearchParams, useLocation } from "react-router-dom";
// api
import { useApiQuery } from "hooks/useDynamicApi";
// commons
import LineUpIdSubNavHeader from "pages/staff/control/lineUp/[id]/LineUpIdSubNavHeader";
import EmptyBar from "components/emptyBar";
// injury components
import InjuryCurrentStateTable from "pages/staff/control/lineUp/[id]/injury/InjuryCurrentStateTable";
import InjuryHistoryTables from "pages/staff/control/lineUp/[id]/injury/injuryHistoryTables";
// utils
import { ENDPOINT_MANAGEMENT_PROFILE } from "utils/consts/apiEndpoint";
// assets
import graySymbol from "assets/logos/symbol/graySymbol.png";
// icons
import { ReactComponent as LFootIcon } from "assets/svgs/lFoot.svg";
import { ReactComponent as RFootIcon } from "assets/svgs/rFoot.svg";

const Injury = () => {
  const [searchParams] = useSearchParams();
  const paramPlayerId = searchParams.get("player") || "";
  const paramAgeGroup = searchParams.get("ageGroup") || "";

  // react qury
  const getPlayerProfile = useApiQuery(
    ["getPlayerProfile", paramPlayerId],
    ENDPOINT_MANAGEMENT_PROFILE.player(paramPlayerId),
    "get"
  );

  return (
    <div className="wrapper">
      <LineUpIdSubNavHeader />
      {/* 경력정보 */}

      <div className="flex gap-[20px]">
        <div className="w-[335px] h-fit bg-primary-white rounded-[10px] py-[30px] px-[28px]">
          <div className="flex justify-center items-center rounded-[8px] w-[230px] h-[280px] bg-secondary-mGrayLight m-auto">
            <img
              src={
                getPlayerProfile?.data?.playerInfo?.profileImageUrl ||
                graySymbol
              }
              alt="graySymbol"
              className={`${
                getPlayerProfile?.data?.playerInfo?.profileImageUrl
                  ? "w-[230px] h-[280px] object-cover rounded-[8px]"
                  : "w-[80px]"
              } cursor-pointer`}
            />
          </div>
          <div className="flex gap-[10px] items-center mt-[30px]">
            <div className="text-title-small-bold">
              {getPlayerProfile?.data?.playerInfo?.name}
            </div>
            <span className="w-[79px] h-[29px] bg-[#5E6EFF] rounded-[80px] text-[14px] font-bold flex justify-center items-center text-primary-white">
              등번호 {getPlayerProfile?.data?.playerInfo?.uniformNumber}
            </span>
          </div>
          <div className="flex flex-col items-start justify-center gap-[20px] m-auto ">
            <div className="flex ">
              <div className="h-[40px] rounded-[80px] border-l border-primary-purple border-[5px] mr-[18px]"></div>
              <div className="flex  py-[8px]">
                <div className="text-[20px] w-[70px] flex-shrink-0 font-semibold mr-[12px] text-[#868686] ">
                  포지션
                </div>
                <div className="flex gap-[5px] flex-wrap mr-[10px] font-bold ">
                  {getPlayerProfile?.data?.playerInfo?.positions?.map(
                    (el: string) => (
                      <span
                        key={el}
                        className="rounded-[80px] px-[12px] py-[4px] text-[14px] font-bold bg-[#d2d2d2] text-[#111111]"
                      >
                        {el}
                      </span>
                    )
                  )}
                </div>
              </div>
            </div>
            <div className="h-[38px] flex items-center">
              <div className="h-[40px] rounded-[80px] border-l border-primary-purple border-[5px] mr-[18px]"></div>
              <div className="text-[20px] w-[70px] flex-shrink-0 font-semibold mr-[12px] text-[#868686]">
                신장
              </div>
              <div className="text-title-small-bold mr-[10px] font-bold">
                {getPlayerProfile?.data?.playerInfo?.height}
              </div>
              <div className="text-[22px] text-[#7c7c7c]">cm</div>
            </div>
            <div className="h-[38px] flex items-center">
              <div className="h-[40px] rounded-[80px] border-l border-primary-purple border-[5px] mr-[18px]"></div>
              <div className="text-[20px] w-[70px] flex-shrink-0 font-semibold mr-[12px] text-[#868686]">
                체중
              </div>
              <div className="text-title-small-bold mr-[10px] font-bold">
                {getPlayerProfile?.data?.playerInfo?.weight}
              </div>
              <div className="text-[22px] text-[#7c7c7c]">kg</div>
            </div>
            <div className="h-[38px] flex items-center">
              <div className="h-[40px] rounded-[80px] border-l border-primary-purple border-[5px] mr-[18px]"></div>
              <div className="text-[20px] w-[70px] flex-shrink-0 font-semibold mr-[12px] text-[#868686]">
                주발
              </div>
              <div
                className={`flex items-center text-subtitle-large-regular ${
                  getPlayerProfile?.data?.playerInfo?.majorFoot === "RIGHT"
                    ? "text-secondary-lGrayMid"
                    : "text-primary-purple"
                }`}
              >
                <LFootIcon />
                <div>L</div>
              </div>
              <div className="w-[10px]"></div>
              <div
                className={`flex items-center text-subtitle-large-regular ${
                  getPlayerProfile?.data?.playerInfo?.majorFoot === "LEFT"
                    ? "text-secondary-lGrayMid"
                    : "text-primary-purple"
                }`}
              >
                <div>R</div>

                <RFootIcon />
              </div>
            </div>
          </div>
        </div>
        {/* overflow-y-scroll */}
        <div className="h-[878px] w-[1045px] flex flex-col gap-[20px]">
          <div className="w-[1045px] p-[30px] bg-primary-white rounded-[10px] ">
            <InjuryCurrentStateTable />
          </div>

          <div className="w-[1045px]  p-[30px] bg-primary-white rounded-[10px] flex flex-col gap-[20px]">
            <div className="h-[40px] gap-[10px] flex items-center justify-between">
              <div className="text-subtitle-medium-bold ">부상 이력</div>
            </div>
            <div>
              <div className="grid grid-cols-6 bg-primary-black text-primary-white rounded-[8px] h-[50px] text-context-regular">
                <div className="m-auto">최근 수정 일시</div>
                <div className="m-auto">부상내용</div>
                <div className="m-auto">치료내용</div>
                <div className="m-auto">부상일</div>
                <div className="m-auto">복귀일</div>
                <div className="m-auto">수정</div>
              </div>
              <InjuryHistoryTables
                playerName={getPlayerProfile?.data?.playerInfo?.name}
              />
            </div>
          </div>
        </div>
      </div>

      <EmptyBar customStyle={"h-[20px]"} />
    </div>
  );
};

export default Injury;
