// player components
import ShootingOneCard from 'pages/player/report/personalReport/shootingCard/ShootingOneCard';
import ShootingTwoCard from 'pages/player/report/personalReport/shootingCard/ShootingTwoCard';
// icons
import { ReactComponent as LineChartIcon } from 'assets/svgs/lineChart.svg';
// commons
import EmptyBar from 'components/emptyBar';
import Lock from 'components/lock/Default';

const ShootingCard = ({ isPrint, shootingAvgScore, shootingOneAnalysis, shootingTwoAnalysis }: any) => {
    return (
        <div className="relative p-[25px] rounded-[14px] border-2 border-secondary-dGrayMid">
            <div className="absolute top-[-32px] w-full">
                <div className="w-[426px] rounded-[80px] text-context-bold  bg-secondary-dGrayMid h-[64px] text-primary-white  py-[8px] gap-[40px] justify-center text-center top-0 m-auto flex  items-center">
                    <span className="text-subtitle-large-bold ">슈팅</span>
                    <div className="flex items-center gap-[8px] ">
                        <div className="flex items-center gap-[5px]">
                            <LineChartIcon />
                            <span className="text-context-regular">평균 점수</span>
                        </div>
                        <div className="flex items-center gap-[5px]">
                            <span className="text-subtitle-large-bold ">{shootingAvgScore?.toFixed(2) || '-'}</span>
                            <span className="text-[#868686]">점</span>
                        </div>
                    </div>
                </div>
            </div>
            <EmptyBar customStyle="h-[21px]" />
            <div className="grid grid-cols-2 gap-[35px]">
                <ShootingOneCard
                    isPrint={isPrint}
                    shootingOneAnalysis={shootingOneAnalysis}
                />
                <ShootingTwoCard
                    isPrint={isPrint}
                    shootingTwoAnalysis={shootingTwoAnalysis}
                />
                {(!shootingOneAnalysis || !shootingTwoAnalysis) && (
                    <Lock
                        content="측정이 필요합니다."
                        customStyle="top-[35px] rounded-[30px] text-primary-purple"
                    />
                )}
            </div>
        </div>
    );
};

export default ShootingCard;
