import React, { useState, useCallback } from 'react';
import { DayPicker } from 'react-day-picker';
import 'react-day-picker/style.css';

interface DatePickerType {
    defaultDate?: string;
    modalBtn: React.ReactNode;
    selected: string;
    setSelected: (selected: string) => void;
}

const DatePicker = ({ defaultDate, modalBtn, selected, setSelected }: DatePickerType) => {
    const [isShow, setIsShow] = useState(false);
    const today = new Date();

    // defaultDay를 안전하게 생성
    const defaultDay = React.useMemo(() => {
        return defaultDate ? new Date(defaultDate) : today;
    }, [defaultDate, today]);

    // `selected`를 Date 객체로 변환
    const selectedDate = React.useMemo(() => {
        return selected ? new Date(selected) : undefined;
    }, [selected]);

    // 모달 토글 핸들러를 useCallback으로 최적화
    const toggleModal = useCallback(() => {
        setIsShow((prev) => !prev);
    }, []);

    // 날짜 선택 핸들러
    const handleSelect = useCallback(
        (day: Date | undefined) => {
            if (day) {
                const year = day.getFullYear();
                const month = String(day.getMonth() + 1).padStart(2, '0');
                const date = String(day.getDate()).padStart(2, '0');
                setSelected(`${year}-${month}-${date}`);
            }
            setIsShow(false);
        },
        [setSelected]
    );
    return (
        <div className="relative">
            <div
                onClick={toggleModal}
                className="cursor-pointer"
            >
                {modalBtn}
            </div>
            {isShow && (
                <div className="absolute z-[10] right-0 p-[10px] rounded-[10px] shadow-md bg-white">
                    <DayPicker
                        mode="single"
                        captionLayout="dropdown"
                        defaultMonth={defaultDay}
                        selected={selectedDate}
                        fromYear={1940}
                        toYear={3000} // 최대 년도 지정 가능
                        toDate={today}
                        onSelect={handleSelect}
                    />
                </div>
            )}
        </div>
    );
};

export default React.memo(DatePicker);
