// hooks
import { useState, useEffect, useMemo, useRef } from 'react';
import { debounce } from 'lodash';
// api
import { useApiMutation } from 'hooks/useDynamicApi';
// commons
import SqureCheckbox from 'components/checkbox/SqureCheckbox';
import { showToast } from 'components/toast/Default';
// utils
import { ENDPOINT_MEASUREMENT } from 'utils/consts/apiEndpoint';
// icons
import { ReactComponent as EditPenIcon } from 'assets/svgs/editPen.svg';
import { ReactComponent as ResetIcon } from 'assets/svgs/reset.svg';
import Stopwatch from '../stopwatch';

const PassOneElement = ({
    idx,
    measurementId,
    passOneId,
    playerName,
    cone1Hit,
    cone2Hit,
    cone3Hit,
    cone4Hit,
    cone5Hit,
    cone6Hit,
    lapTime,
}: PassOneElementType) => {
    const [editPassOneInfo, setEditPassOneInfo] = useState({
        measurementId: measurementId,
        passOneId: passOneId,
        playerName: playerName,
        cone1Hit: cone1Hit,
        cone2Hit: cone2Hit,
        cone3Hit: cone3Hit,
        cone4Hit: cone4Hit,
        cone5Hit: cone5Hit,
        cone6Hit: cone6Hit,
        lapTime: lapTime,
    });
    const inputRef = useRef<HTMLInputElement | null>(null);
    const handleButtonClick = () => {
        // ref가 현재 존재하는 경우 focus 호출
        inputRef.current?.focus();
    };
    const handleEditPassOneInfo = (updateData: any) => {
        setEditPassOneInfo((prev) => ({
            ...prev,
            ...updateData,
        }));
    };
    // useApiMutation
    const patchPassOneMeasurement = useApiMutation(ENDPOINT_MEASUREMENT.passOne(editPassOneInfo?.passOneId), 'patch', {
        onSuccess: (data) => {
            // showToast("패스1 기록을 수정했습니다.", "success");
        },
        onError: (error) => {
            const errorMessage = error?.data?.detailedMessage || error || '패스1 기록수정에 실패했습니다.';
            showToast(errorMessage, 'error');
        },
    });
    const patchPassOneReset = useApiMutation(ENDPOINT_MEASUREMENT.passOneReset(editPassOneInfo?.passOneId), 'patch', {
        onSuccess: (data) => {
            showToast('패스1 기록을 초기화했습니다.', 'success');
            handleEditPassOneInfo({
                cone1Hit: false,
                cone2Hit: false,
                cone3Hit: false,
                cone4Hit: false,
                cone5Hit: false,
                cone6Hit: false,
                lapTime: 0,
            });
        },
        onError: (error) => {
            const errorMessage = error?.data?.detailedMessage || error || '패스1 기록 초기화에 실패했습니다.';
            showToast(errorMessage, 'error');
        },
    });
    const editPassOneMeasurement = () => {
        if (!patchPassOneMeasurement?.isPending) {
            patchPassOneMeasurement.mutate({
                cone1Hit: editPassOneInfo?.cone1Hit,
                cone2Hit: editPassOneInfo?.cone2Hit,
                cone3Hit: editPassOneInfo?.cone3Hit,
                cone4Hit: editPassOneInfo?.cone4Hit,
                cone5Hit: editPassOneInfo?.cone5Hit,
                cone6Hit: editPassOneInfo?.cone6Hit,
                lapTime: editPassOneInfo?.lapTime,
            });
        }
    };
    const editPassOneReset = () => {
        if (!patchPassOneReset?.isPending) {
            showToast('패스1 측정데이터를 초기화하는 중입니다.', 'info');
            patchPassOneReset.mutate({});
        }
    };

    // debounce
    const debouncedPatchPassOne = useMemo(
        () =>
            debounce(() => {
                editPassOneMeasurement();
            }, 500), // 1초 딜레이
        [patchPassOneMeasurement]
    );
    useEffect(() => {
        if (
            editPassOneInfo?.cone1Hit === cone1Hit &&
            editPassOneInfo?.cone2Hit === cone2Hit &&
            editPassOneInfo?.cone3Hit === cone1Hit &&
            editPassOneInfo?.cone4Hit === cone4Hit &&
            editPassOneInfo?.cone5Hit === cone5Hit &&
            editPassOneInfo?.cone6Hit === cone6Hit &&
            editPassOneInfo?.lapTime === lapTime
        ) {
            return;
        }
        debouncedPatchPassOne();
        return () => {
            debouncedPatchPassOne.cancel();
        };
    }, [editPassOneInfo]);
    return (
        <div>
            <div
                className={`w-full flex items-center justify-between rounded-[8px] h-[50px] ${
                    idx % 2 === 1 ? 'bg-secondary-mGrayLight' : 'bg-primary-white'
                }`}
            >
                <div className="flex items-center justify-center flex-1">{editPassOneInfo?.playerName}</div>
                <SqureCheckbox
                    checked={editPassOneInfo?.cone1Hit}
                    onChange={() => handleEditPassOneInfo({ cone1Hit: !editPassOneInfo?.cone1Hit })}
                    className="justify-center flex-1"
                />
                <SqureCheckbox
                    checked={editPassOneInfo?.cone2Hit}
                    onChange={() => handleEditPassOneInfo({ cone2Hit: !editPassOneInfo?.cone2Hit })}
                    className="justify-center flex-1"
                />
                <SqureCheckbox
                    checked={editPassOneInfo?.cone3Hit}
                    onChange={() => handleEditPassOneInfo({ cone3Hit: !editPassOneInfo?.cone3Hit })}
                    className="justify-center flex-1"
                />
                <SqureCheckbox
                    checked={editPassOneInfo?.cone4Hit}
                    onChange={() => handleEditPassOneInfo({ cone4Hit: !editPassOneInfo?.cone4Hit })}
                    className="justify-center flex-1"
                />
                <SqureCheckbox
                    checked={editPassOneInfo?.cone5Hit}
                    onChange={() => handleEditPassOneInfo({ cone5Hit: !editPassOneInfo?.cone5Hit })}
                    className="justify-center flex-1"
                />
                <SqureCheckbox
                    checked={editPassOneInfo?.cone6Hit}
                    onChange={() => handleEditPassOneInfo({ cone6Hit: !editPassOneInfo?.cone6Hit })}
                    className="justify-center flex-1"
                />
                <div className="flex-1 flex justify-center items-center h-[50px]">
                    <input
                        ref={inputRef} // ref 연결
                        value={editPassOneInfo?.lapTime}
                        onChange={(e) => handleEditPassOneInfo({ lapTime: e.target.value })}
                        className="h-[40px] w-[70px] focus:outline focus:outline-primary-purple focus:bg-primary-white  bg-transparent rounded-[8px] text-center text-secondary-lGrayDark"
                    />
                    <button
                        className="flex-1 text-secondary-lGrayDark hover:text-primary-purple"
                        onClick={handleButtonClick} // 버튼 클릭 시 input에 포커스
                    >
                        <EditPenIcon />
                    </button>
                </div>
                <button
                    className="flex-1"
                    onClick={editPassOneReset}
                >
                    <ResetIcon className=" h-[20px] m-auto" />
                </button>
            </div>
            <div className="flex justify-end h-[50px]">
                <Stopwatch
                    lapTime={editPassOneInfo?.lapTime}
                    setLapTime={(value) => handleEditPassOneInfo({ lapTime: value })}
                />
            </div>
        </div>
    );
};
export default PassOneElement;
interface PassOneElementType {
    idx: number;
    measurementId: number;
    passOneId: string;
    playerName: string;
    cone1Hit: boolean;
    cone2Hit: boolean;
    cone3Hit: boolean;
    cone4Hit: boolean;
    cone5Hit: boolean;
    cone6Hit: boolean;
    lapTime: number;
    timerMeasuermentId: string;
    setTimerMeasuermentId: (id: string) => void;
}
