// hooks
import { useParams } from 'react-router-dom';
// report components
import Starting from 'pages/staff/control/schedule/report/read/reportPlayer/Starting';
import Substitution from 'pages/staff/control/schedule/report/read/reportPlayer/Substitution';
import Goals from 'pages/staff/control/schedule/report/read/reportPlayer/Goals';
import Shootouts from 'pages/staff/control/schedule/report/read/reportPlayer/Shootouts';

// utils
import { ENDPOINT_REPORT } from 'utils/consts/apiEndpoint';
// api

import { useApiQuery } from 'hooks/useDynamicApi';

const ReportPlayer = ({ lineup }: { lineup: LineupType }) => {
    // api
    const { id } = useParams();

    return (
        <div className="flex h-full border-b border-secondary-lGrayMid ">
            <div className="flex w-full">
                <div className="w-[150px] h-full shrink-0  bg-secondary-mGrayLight text-secondary-lGrayDark text-context-regular flex justify-center items-center">
                    출전선수
                </div>
                <div className="w-full h-full ">
                    <Starting lineup={lineup?.startingPlayers} />
                    <Substitution />
                    <Goals />
                    <Shootouts />
                </div>
            </div>
        </div>
    );
};

export default ReportPlayer;
interface LineupType {
    startingPlayers: {
        lineupId: string;
        playerId: string;
        playerName: string;
        uniformNumber: string;
        position: string;
        rating: string;
        managerComment: string;
    }[];
    substitutePlayers: {
        lineupId: string;
        playerId: string;
        playerName: string;
        uniformNumber: string;
        position: string;
        rating: string;
        managerComment: string;
    }[];
}
