// hooks
import { useState } from 'react';
// assets
import graySymbol from 'assets/logos/symbol/graySymbol.png';
// icons
import { ReactComponent as LFootIcon } from 'assets/svgs/lFoot.svg';
import { ReactComponent as RFootIcon } from 'assets/svgs/rFoot.svg';
// common components
import Stars from 'components/stars/Default';
import SqureCheckbox from 'components/checkbox/SqureCheckbox';

// utils
import { positions } from 'utils/consts/userInfo';
import { gradeDescriptionMatcher, gradeMatcher } from 'utils/matchers/reportMatcher';
export interface PlayerInfoType {
    age: string;
    birthday: string;
    height: string;
    id: string;
    majorFoot: string;
    name: string;
    positions: string[];
    previousCareers: {
        teamName: string;
        careerDate: string;
    }[];

    profileImageUrl: null | string;
    uniformNumber: string;
    weight: string;
}
interface ProfileCardType {
    playerInfo: PlayerInfoType;
    handlePlayerInfo: (value: any) => void;
    grade: string;
    setGrade: (grade: string) => void;
    isEdit: boolean;
}
const ProfileCard = ({ playerInfo, handlePlayerInfo, grade, setGrade, isEdit }: ProfileCardType) => {
    const [selectedFoots, setSelectedFoots] = useState({
        left: false,
        right: false,
    });
    const handleMajorFoot = (foot: 'left' | 'right') => {
        setSelectedFoots((prev) => {
            const newSelected = {
                ...prev,
                [foot]: !prev[foot], // 클릭 시 상태 토글
            };
            if (newSelected.left && newSelected.right) {
                handlePlayerInfo({ majorFoot: 'BOTH' });
            } else if (newSelected.left) {
                handlePlayerInfo({ majorFoot: 'LEFT' });
            } else if (newSelected.right) {
                handlePlayerInfo({ majorFoot: 'RIGHT' });
            } else {
                handlePlayerInfo({ majorFoot: '' });
            }
            return newSelected;
        });
    };

    const handlePosition = (position: string) => {
        // 기존 positions에 해당 포지션이 있는지 확인
        const isPositionExists = playerInfo.positions.includes(position);

        // 포지션이 이미 존재하면 제거, 존재하지 않으면 추가
        const updatedPositions = isPositionExists
            ? playerInfo.positions.filter((pos) => pos !== position)
            : [...playerInfo.positions, position];
        // playerInfo 업데이트
        handlePlayerInfo({ positions: updatedPositions });
    };

    return (
        <div className="w-[927px] h-[360px] bg-primary-white rounded-[10px] p-[30px] flex gap-[30px]">
            <div className="flex justify-center items-center rounded-[8px] w-[230px] h-[280px] bg-secondary-mGrayLight">
                <img
                    src={playerInfo?.profileImageUrl || graySymbol}
                    alt="graySymbol"
                    className={`${
                        playerInfo?.profileImageUrl ? 'w-[230px] h-[280px] object-cover rounded-[8px]' : 'w-[80px]'
                    } cursor-pointer`}
                />
            </div>
            <div>
                <div className="flex gap-[10px] items-center">
                    <div className="text-title-small-bold">{playerInfo?.name}</div>
                    <span className="w-[79px] h-[29px] bg-[#5E6EFF] rounded-[80px] text-[14px] font-bold flex justify-center items-center text-primary-white">
                        등번호 {playerInfo?.uniformNumber}
                    </span>
                </div>
                {isEdit ? (
                    <div className="flex items-center gap-[5px] mt-[10px] flex-wrap ">
                        <div className="flex gap-[5px] flex-wrap mr-[10px] font-bold ">
                            {positions?.map((el: string) => (
                                <button
                                    key={el}
                                    onClick={() => handlePosition(el)}
                                    className={
                                        playerInfo?.positions?.includes(el)
                                            ? 'rounded-[80px] px-[12px] py-[4px] text-[14px] font-bold bg-[#d2d2d2] border border-[#d2d2d2] text-[#111111]'
                                            : 'rounded-[80px] px-[12px] py-[4px] text-[14px] font-bold bg-white border border-secondary-lGrayMid text-secondary-lGrayMid'
                                    }
                                >
                                    {el}
                                </button>
                            ))}
                        </div>
                    </div>
                ) : (
                    <div className="font-semiBold text-[20px] text-[#868686]">{playerInfo?.positions?.join(',')}</div>
                )}

                <div className="mt-[20px] grid grid-cols-2 w-[607px]">
                    <div className="h-[164px] flex items-start w-full">
                        <div className="flex items-center">
                            <div className="h-[42px] rounded-[80px] border-l border-primary-purple border-[5px] mr-[18px]"></div>
                            <div className="text-[20px] font-semibold mr-[12px] text-[#868686] ">등급</div>
                        </div>
                        <div className="flex flex-col justify-center items-center w-[126px] h-[200px] p-[30px] gap-[10px] ">
                            {isEdit ? (
                                <input
                                    placeholder="입력"
                                    className="h-[100px] w-[126px] text-center text-[100px] font-bold placeholder:translate-y-[-50%] placeholder:absolute placeholder:top-1/2 placeholder:left-1/2 placeholder:-translate-x-1/2 placeholder:text-[70px]"
                                    value={gradeDescriptionMatcher(grade)}
                                    onChange={(e) => {
                                        const value = e.target.value;

                                        // 대문자로 변환
                                        const upperValue = value.toUpperCase();

                                        // 유효한 입력인지 확인
                                        const validPattern = /^[A-DFS]?'?$/; // A, B, C, D, F, S와 '만 허용
                                        if (validPattern.test(upperValue)) {
                                            // '가 단독으로 입력되지 않도록 필터링
                                            if (upperValue === "'") {
                                                return;
                                            }
                                            // 유효한 경우만 업데이트
                                            setGrade(upperValue);
                                        }
                                    }}
                                />
                            ) : (
                                <div className="h-[100px] w-[126px] text-center flex justify-center items-center text-[100px] font-bold ">
                                    {gradeDescriptionMatcher(grade)}
                                </div>
                            )}

                            {/* <Stars count={4.5} />
                            <div className="text-[#7c7c7c] text-[22px]">
                                <span className="text-primary-purple text-subtitle-large-bold">4.5</span> / 5
                            </div> */}
                        </div>
                    </div>

                    <div className="w-full flex flex-col items-start justify-center gap-[20px] ">
                        <div className="h-[48px] flex items-center">
                            <div className="h-[42px] rounded-[80px] border-l border-primary-purple border-[5px] mr-[18px]"></div>
                            <div className="text-[20px] font-semibold mr-[12px] text-[#868686]">신장</div>
                            {isEdit ? (
                                <input
                                    type="number"
                                    max={300}
                                    placeholder="신장"
                                    className="text-[50px] mr-[10px] h-[50px] font-bold w-[140px]"
                                    value={playerInfo?.height}
                                    onChange={(e) => handlePlayerInfo({ height: e.target.value })}
                                />
                            ) : (
                                <div className="text-[50px] mr-[10px]  font-bold w-[80px]">{playerInfo?.height}</div>
                            )}

                            <div className="text-[22px] text-[#7c7c7c]">cm</div>
                        </div>
                        <div className="h-[48px] flex items-center">
                            <div className="h-[42px] rounded-[80px] border-l border-primary-purple border-[5px] mr-[18px]"></div>
                            <div className="text-[20px] font-semibold mr-[12px] text-[#868686]">체중</div>
                            {isEdit ? (
                                <input
                                    type="number"
                                    max={300}
                                    placeholder="체중"
                                    className="text-[50px] mr-[10px] font-bold w-[140px] h-[50px]"
                                    value={playerInfo?.weight}
                                    onChange={(e) => handlePlayerInfo({ weight: e.target.value })}
                                />
                            ) : (
                                <div className="text-[50px] mr-[10px] font-bold w-[61px]">{playerInfo?.weight}</div>
                            )}
                            <div className="text-[22px] text-[#7c7c7c]">kg</div>
                        </div>
                        <div className="h-[48px] flex items-center">
                            <div className="h-[42px] rounded-[80px] border-l border-primary-purple border-[5px] mr-[18px]"></div>
                            <div className="text-[20px] font-semibold mr-[12px] text-[#868686]">주발</div>
                            <button
                                onClick={() => isEdit && handleMajorFoot('left')}
                                className={`flex items-center text-subtitle-large-regular ${
                                    !playerInfo?.majorFoot || playerInfo?.majorFoot === 'RIGHT'
                                        ? 'text-secondary-lGrayMid'
                                        : 'text-primary-purple'
                                }`}
                            >
                                <LFootIcon />
                                <div>L</div>
                            </button>
                            <div className="w-[10px]"></div>
                            <button
                                onClick={() => isEdit && handleMajorFoot('right')}
                                className={`flex items-center text-subtitle-large-bold ${
                                    !playerInfo?.majorFoot || playerInfo?.majorFoot === 'LEFT'
                                        ? 'text-secondary-lGrayMid'
                                        : 'text-primary-purple'
                                }`}
                            >
                                <div>R</div>

                                <RFootIcon />
                            </button>
                        </div>
                    </div>
                </div>
                <div className="flex gap-[5px]">
                    <span className="text-primary-purple">* </span>
                    <span className="text-[#7c7c7c] text-[14px]  w-full">
                        S A A' B B' D C C' D F 등급만 입력 가능합니다
                    </span>
                </div>
            </div>
        </div>
    );
};

export default ProfileCard;
