// commons
import EmptyBar from 'components/emptyBar';
// admin components
import PlayerDetailSubHeader from 'pages/admin/mypage/playerDetail/PlayerDetailSubHeader';
import ProfileDetailCard from 'pages/admin/mypage/playerDetail/ProfileDetailCard';
import CareerDetailCard from 'pages/admin/mypage/playerDetail/CareerDetailCard';
// redux
import { RootState } from 'store';
import { useSelector } from 'react-redux';

const PlayerDetail = () => {
    const {
        role, // null 허용
    } = useSelector((state: RootState) => state.userInfo); // 인증 상태를 token 유무로 판단
    return (
        <div className="wrapper">
            <PlayerDetailSubHeader />
            <EmptyBar customStyle={'h-[35px]'} />
            <ProfileDetailCard />
            <EmptyBar customStyle={'h-[35px]'} />
            {(role === 'SYSTEM_ADMIN' || role === 'SUPER_ADMIN') && <CareerDetailCard />}
            <EmptyBar customStyle={'h-[20px]'} />
        </div>
    );
};

export default PlayerDetail;
