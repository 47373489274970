export const options = {
    chart: {
        type: 'donut',
    },
    plotOptions: {
        pie: {
            startAngle: -90,
            endAngle: 90,
            offsetY: 10,
            dataLabels: {
                show: false, // 숫자와 레이블 비활성화
            },
            donut: {
                size: '80%', // 도넛 크기 조정
            },
        },
    },
    fill: {
        type: 'solid',
        colors: ['#5651E3', '#E0E0E0', '#19DE93'], // 각 데이터의 색상
    },
    grid: {
        padding: {
            bottom: -100,
        },
    },
    stroke: {
        lineCap: 'round', // 양 끝을 둥글게 설정
    },
    responsive: [
        {
            breakpoint: 480,
            options: {
                chart: {
                    width: 200,
                },
                legend: {
                    show: false, // 모바일 환경에서도 레이블 숨기기
                },
            },
        },
    ],
    tooltip: {
        enabled: false,
    },
    legend: {
        show: false,
    },
    dataLabels: {
        enabled: false,
    },
};

export const series = [60, 40]; // 각 값의 비율
