// hooks
import { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
// icons
import { ReactComponent as PlusIcon } from 'assets/svgs/plus.svg';
// api
import { useApiQuery, useApiMutation } from 'hooks/useDynamicApi';
import { showToast } from 'components/toast/Default';
// utils
import { ENDPOINT_REPORT } from 'utils/consts/apiEndpoint';
// commons
import Dropdown from 'components/dropdown/Default';
// report components
import ShootoutsElement from 'pages/staff/control/schedule/report/read/reportPlayer/ShootoutsElement';

const Shootouts = () => {
    const { id } = useParams();

    const [isAdd, setIsAdd] = useState(false);
    const [newKickOrder, setNewKickOrder] = useState('');
    const [shootoutsPlayer, setShootoutsPlayer] = useState('');
    const [success, setSuccess] = useState('');

    // api
    const getShootouts = useApiQuery(['getShootouts'], ENDPOINT_REPORT.shootouts(id), 'get');

    return (
        <div className="p-[24px] flex items-center w-full ">
            <div className="text-center shrink-0 w-[100px] h-full text-context-regular text-secondary-dGrayMid">
                승부차기
            </div>
            <div className="flex flex-col gap-[10px]">
                {getShootouts?.data?.length > 0 && (
                    <div className="flex gap-[10px]">
                        <div className="w-[70px] text-center">순서</div>
                        <div className="w-[420px] text-center">득점</div>
                        <div className="w-[130px] text-center">성공 여부</div>
                    </div>
                )}
                <div className="gap-[10px] flex flex-col">
                    {getShootouts?.data?.map((el: ShootoutsType) => (
                        <ShootoutsElement shootouts={el} />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Shootouts;

interface LineupType {
    lineupId: string;
    playerId: string;
    playerName: string;
    uniformNumber: string;
    position: string;
    rating: string;
    managerComment: string;
}

export const handleDropdownOpts = (arr: any[]) => {
    return arr?.map((arr) => ({
        value: arr.playerId,
        label: `${arr.uniformNumber}. ${arr.playerName}`,
    }));
};

interface ShootoutsType {
    id: string;
    kicker: {
        playerId: string;
        playerName: string;
        uniformNumber: string;
        position: string;
    };
    kickOrder: string;
    success: boolean;
}
