// hooks
import { useNavigate } from "react-router-dom";
// commons
import Dropdown from "components/dropdown/Default";
import { useState } from "react";
// utils
import { sortings } from "utils/consts/measurement";
import { showToast } from "components/toast/Default";
// icons
import { ReactComponent as QuestionIcon } from "assets/svgs/question.svg";
import { ReactComponent as QuestionWhiteIcon } from "assets/svgs/questionWhite.svg";
// redux
import { RootState } from "store"; // Redux Store 타입 정의
import { useSelector } from "react-redux";
const RecommandCard = ({ playerInfo }: any) => {
  const { role } = useSelector((state: RootState) => state.userInfo); // 인증 상태를 token 유무로 판단
  const [infoShow, setInfoShow] = useState(false);
  const navigate = useNavigate();

  return (
    <div>
      {infoShow ? (
        <div className="rounded-[10px] bg-secondary-dGrayMid p-[20px] w-full">
          <div className="flex items-center justify-between mb-[20px]">
            <div className="text-[20px] font-semibold text-primary-white flex gap-[4px] items-center ">
              <button
                onClick={() => setInfoShow(!infoShow)}
                className="text-primary-white"
              >
                <QuestionWhiteIcon />
              </button>
              <span className="text-primary-white">
                {playerInfo?.playerName}
              </span>
              님에게 딱! 맞는 운동 영상을 추천해드릴께요!
            </div>
            <button
              onClick={() => navigate("/player/video")}
              className="p-[12px] bg-primary-purple text-primary-white text-context-bold rounded-[8px]"
            >
              추천받기
            </button>
          </div>
          <div className="text-white ">
            <div>
              축구 실력 향상을 위한 최적의 프로세스는{" "}
              <span className="font-bold text-primary-green">{`[ 측정 > 개선 운동 > 측정 > 개선운동]`}</span>{" "}
              입니다
            </div>
            <div>
              이 과정을 거찬 선수들은 평균적으로 20% 더 빠른 기량 향상을 보였죠.
            </div>
            <div>
              다음 측정까지 진행할 수 있는 셀프 개선 운동 영상을 추천해
              드릴게요!
            </div>
          </div>
        </div>
      ) : (
        <div className="rounded-[10px] bg-primary-white p-[20px] flex justify-between items-center w-full">
          <div className="text-[20px] font-semibold text-secondary-dGrayDark flex gap-[4px] items-center">
            <button
              onClick={() => setInfoShow(!infoShow)}
              className="text-primary-purple"
            >
              <QuestionIcon />
            </button>
            <span className="text-primary-purple">
              {playerInfo?.playerName}
            </span>
            님에게 딱! 맞는 운동 영상을 추천해드릴께요!
          </div>
          <button
            onClick={() =>
              role === "PLAYER"
                ? navigate("/player/video")
                : showToast(
                    `${playerInfo?.playerName}님께만 추천되는 영상입니다.`,
                    "info"
                  )
            }
            className="p-[12px] bg-primary-black text-primary-white text-context-bold rounded-[8px]"
          >
            추천받기
          </button>
        </div>
      )}
    </div>
  );
};

export default RecommandCard;
