import { useState } from 'react';
// api
import { useApiMutation } from 'hooks/useDynamicApi';
// icons
import { ReactComponent as DeleteBoxIcon } from 'assets/svgs/deleteBox.svg';
import { ReactComponent as CloseIcon } from 'assets/svgs/close.svg';
import { ReactComponent as SearchIcon } from 'assets/svgs/search.svg';
//utils
import { ENDPOINT_TEAM } from 'utils/consts/apiEndpoint';
import { regionOptions, ageGroupOptions, soccerTypeOptions } from 'utils/consts/userInfo';
// commons
import { showToast } from 'components/toast/Default';
import Dropdown from 'components/dropdown/Default';
import TeamStaffSearchModal from 'components/modals/TeamStaffSearchModal';
interface TeamAddModalType {
    modalBtn?: string | React.ReactNode;
}
const TeamAddModal = ({ modalBtn }: TeamAddModalType) => {
    const [isShow, setIsShow] = useState(false);

    const [newTeamInfo, setNewTeamInfo] = useState<TeamInfo>({
        name: '',
        ageGroup: '',
        type: '',
        region: '',
        teamStaffIdx: [],
    });

    const handleInfo = (updateData: any) => {
        setNewTeamInfo((prev: any) => ({
            ...prev,
            ...updateData,
        }));
    };
    // 팀스태프 추가

    const handleTeamStaff = (newStaff: TeamStaff) => {
        setNewTeamInfo((prev) => ({
            ...prev,
            teamStaffIdx: [...prev.teamStaffIdx, newStaff],
        }));
    };
    // 팀스태프 제거
    const removeTeamStaffIdx = (id: number) => {
        setNewTeamInfo((prev) => ({
            ...prev,
            teamStaffIdx: prev.teamStaffIdx.filter((staff: TeamStaff) => staff?.userId !== id),
        }));
    };
    // useApiMutation
    const postTeamMutation = useApiMutation(ENDPOINT_TEAM.admin.super.team, 'post', {
        onSuccess: (data) => {
            showToast('팀이 생성되었습니다.', 'success');
            setIsShow(false);
        },
        onError: (error) => {
            const errorMessage = error?.data?.detailedMessage || error?.data?.message || '팀생성에 실패했습니다.';
            showToast(errorMessage, 'error');
        },
    });

    // handler
    const createTeam = () => {
        if (!postTeamMutation?.isPending) {
            if (!newTeamInfo?.name || !newTeamInfo?.ageGroup || !newTeamInfo?.type || !newTeamInfo?.region) {
                showToast('필수 값을 입력해주세요.', 'warning');
                return;
            }
            showToast('팀을 생성하는 중입니다.', 'info');
            postTeamMutation.mutate({
                name: newTeamInfo?.name,
                ageGroup: newTeamInfo?.ageGroup,
                type: newTeamInfo?.type,
                region: newTeamInfo?.region,
                teamStaffIds:
                    newTeamInfo?.teamStaffIdx?.length < 1
                        ? null
                        : newTeamInfo.teamStaffIdx.map((staff) => staff.userId),
            });
        }
    };

    return (
        <>
            <button onClick={() => setIsShow(true)}>{modalBtn}</button>
            {isShow && (
                <div
                    onClick={() => setIsShow(false)}
                    className="fixed left-0 z-[100] top-0 flex justify-center items-center w-full h-full bg-[#00000050]"
                >
                    <div
                        className=" bg-white rounded-[10px]  items-center p-[50px]"
                        onClick={(e) => e.stopPropagation()}
                    >
                        <div className="w-[400px] flex flex-col justify-center gap-[14px]">
                            <div className="flex justify-between text-secondary-dGrayMid text-subtitle-medium-bold">
                                <span>팀생성</span>
                                <button onClick={() => setIsShow(false)}>
                                    <CloseIcon className="text-secondary-lGrayDark" />
                                </button>
                            </div>
                            <div>
                                <input
                                    value={newTeamInfo?.name}
                                    onChange={(e) => handleInfo({ name: e.target.value })}
                                    placeholder="팀명"
                                    maxLength={50}
                                    className="inputDefaultStyle inputCommonStyle"
                                />
                            </div>
                            <Dropdown
                                placeholder="유형"
                                options={soccerTypeOptions}
                                value={newTeamInfo?.type}
                                onChange={(value) => handleInfo({ type: value })}
                            />
                            <Dropdown
                                placeholder="연령대"
                                options={ageGroupOptions}
                                value={newTeamInfo?.ageGroup}
                                onChange={(value) => handleInfo({ ageGroup: value })}
                            />

                            <Dropdown
                                placeholder="지역"
                                options={regionOptions}
                                value={newTeamInfo?.region}
                                onChange={(value) => handleInfo({ region: value })}
                            />
                            <TeamStaffSearchModal
                                mdoalBtn={
                                    <div className="flex justify-between inputDefaultStyle p-[12px] w-full h-[44px] text-context-regular rounded-[8px] text-secondary-lGrayDark">
                                        팀스태프
                                        <SearchIcon />
                                    </div>
                                }
                                handleTeam={(value) => handleTeamStaff(value)} // teamStaffIdx 추가
                            />
                            {/* 선택된 팀스태프 */}
                            <div className="flex gap-[5px] flex-wrap ">
                                {newTeamInfo?.teamStaffIdx?.map((el: TeamStaff) => (
                                    <button
                                        key={el?.userId}
                                        className="flex items-center bg-[#d2d2d2] rounded-[80px] px-[12px] py-[4px] text-[14px] font-bold"
                                    >
                                        {el?.name}
                                        <CloseIcon
                                            className="w-[20px] h-[20px] "
                                            onClick={() => removeTeamStaffIdx(el?.userId)} // teamStaffIdx에서 제거
                                        />
                                    </button>
                                ))}
                            </div>
                            <button
                                className="p-[12px] w-full text-white bg-primary-purple rounded-[8px]"
                                onClick={createTeam}
                            >
                                팀 생성
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default TeamAddModal;
interface TeamStaff {
    userId: number;
    email: string | null;
    name: string | null;
    lastFourDigits: string | null;
}

interface TeamInfo {
    name: string;
    ageGroup: string;
    type: string;
    region: string;
    teamStaffIdx: TeamStaff[]; // teamStaffIdx 타입 명시
}
