export const barOptions = {
    chart: {
        type: 'bar',
        toolbar: {
            show: false,
        },
        zoom: {
            enabled: false, // 확대 비활성화
        },
    },
    plotOptions: {
        bar: {
            horizontal: false,
            columnWidth: '14px',
            borderRadius: 4,
            borderRadiusApplication: 'end',
        },
    },
    colors: ['#5E6EFF', '#C4C3D2', '#BBFE32'], // 막대 색상 설정
    states: {
        hover: {
            filter: {
                type: 'darken',
                value: 0.2, // 0부터 1 사이의 값. 높을수록 더 어두워집니다.
            },
        },
    },
    dataLabels: {
        enabled: false,
    },
    stroke: {
        show: true,
        width: 2,
        colors: ['transparent'],
    },
    legend: {
        position: 'left', // 왼쪽에 배치
        horizontalAlign: 'left', // 왼쪽 정렬
        offsetY: 0, // 위에서부터의 거리 조절
        offsetX: -36, // 왼쪽에서부터의 거리 조절
        markers: {
            width: 14, // 마커 너비
            height: 14, // 마커 높이
            shape: 'circle', // 마커 모양
        },
        labels: {
            colors: '#717591', // 여기에 원하는 색상을 지정하세요
            fontSize: '16px', // 텍스트 크기 지정
        },
        itemMargin: {
            vertical: 5, // 아이템 간 세로 간격
        },
        onItemClick: {
            toggleDataSeries: true, // 클릭 시 해당 시리즈 토글 가능
        },
        onItemHover: {
            highlightDataSeries: true, // 호버 시 해당 시리즈 하이라이트
        },
    },
    xaxis: {
        categories: [
            '상체 밸런스',
            '시야',
            '무게중심',
            '무게축',
            '뒤꿈치',
            '무릎거리',
            '잔발',
            '탄력성',
            '최고속도',
            '평균속도',
            '볼터치',
            '정확도',
        ],
        show: true,

        style: {
            colors: '#5E6EFF', // 텍스트 색상
            fontSize: '10px',
        },
        tooltip: {
            enabled: true,
        },
        labels: {
            rotate: 0, // 라벨 회전 방지
            formatter: function (val: string) {
                return `${val}`;
            },
            style: {},
        },
    },
    yaxis: {
        title: {
            // text: '$ (thousands)',
        },
    },
    fill: {
        opacity: 1,
    },
    tooltip: {
        enabled: true,
        shared: false, // 하단 기본 툴팁 비활성화

        custom: function ({ series, seriesIndex, dataPointIndex, w }: any) {
            // 툴팁에 표시할 값 가져오기
            const value = series[seriesIndex][dataPointIndex];
            return `
            <div>
                <div 
                style="position: relative; display: inline-block;background: #111111; color: white;padding: 8px 10px;border-radius: 8px; font-size: 14px; font-weight: bold; text-align: center;white-space: nowrap;"><span>${value}</span>
                </div>
                <!-- 삼각형 화살표 -->
                <div style="margin-top:-5px; margin-left:13px;"><svg width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.61747 8.77598C7.81877 9.87418 6.18122 9.87418 5.38253 8.77598L1.31007 3.17634C0.348657 1.8544 1.29297 -2.44633e-07 2.92754 -1.01734e-07L11.0725 6.10318e-07C12.707 7.53217e-07 13.6513 1.85441 12.6899 3.17634L8.61747 8.77598Z" fill="#111111"/></svg></div></div>
            `;
        },
    },
    markers: {
        shape: 'circle', // 마커 모양
    },
};

export const barSeries = [
    {
        name: '홍길동',
        data: [76, 85, 90, 98, 87, 95, 91, 10, 94, 91, 94, 94],
    },
    {
        name: '팀 평균',
        data: [44, 55, 57, 56, 61, 58, 63, 60, 66, 63, 60, 66],
    },

    {
        name: '전국 평균',
        data: [35, 41, 36, 26, 45, 48, 52, 53, 41, 52, 53, 41],
    },
];
