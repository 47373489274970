// hooks
import { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
// icons
import { ReactComponent as DeleteIcon } from 'assets/svgs/delete.svg';
// api
import { useApiQuery, useApiMutation } from 'hooks/useDynamicApi';
import { showToast } from 'components/toast/Default';
// utils
import { ENDPOINT_REPORT } from 'utils/consts/apiEndpoint';
// commons
import Dropdown from 'components/dropdown/Default';

const ShootoutsElement = ({ shootouts, lineupOptions, newKickOrder }: ShootoutsElementProps) => {
    const { id } = useParams();
    const [editKickOrder, setEditKickOrder] = useState(shootouts?.kickOrder);
    const [editShootoutsPlayer, setEditShootoutsPlayer] = useState(shootouts?.kicker?.playerId);
    const [editSuccess, setEditSuccess] = useState(shootouts?.success ? 'success' : 'fail');

    const deleteShootout = useApiMutation(ENDPOINT_REPORT.shootoutId(id, shootouts?.id), 'delete', {
        onSuccess: (data) => {
            showToast('승부차기를 삭제하였습니다', 'success');
        },
        onError: (error) => {
            const detailError = error?.data?.detailedMessage || error;
            showToast(`${detailError}`, 'error');
        },
    });
    const patchShootout = useApiMutation(ENDPOINT_REPORT.shootoutId(id, shootouts?.id), 'patch', {
        onSuccess: (data) => {
            showToast('승부차기를 수정하였습니다', 'success');
        },
        onError: (error) => {
            const detailError = error?.data?.detailedMessage || error;
            showToast(`${detailError}`, 'error');
            setEditShootoutsPlayer(shootouts?.kicker?.playerId);
            setEditSuccess(shootouts?.success ? 'success' : 'fail');
        },
    });

    const editShootoutPlayer = () => {
        if (!patchShootout?.isPending && editShootoutsPlayer && editSuccess) {
            showToast('승부차기를 수정하는중입니다', 'info');
            patchShootout.mutate({
                kickerId: editShootoutsPlayer,
                success: editSuccess === 'success',
            });
        }
    };

    const removeShootout = () => {
        if (!deleteShootout?.isPending) {
            showToast('승부차기 정보를 삭제하는 중입니다', 'info');
            deleteShootout.mutate({});
        }
    };

    useEffect(() => {
        if (!patchShootout?.isPending && editKickOrder && editShootoutsPlayer && editSuccess) {
            const curShootout = shootouts?.success ? 'success' : 'fail';
            if (
                editKickOrder === shootouts?.kickOrder &&
                editSuccess === curShootout &&
                editShootoutsPlayer === shootouts?.kicker?.playerId
            ) {
                return;
            } else {
                editShootoutPlayer();
            }
        }
    }, [editShootoutsPlayer, editKickOrder, editSuccess]);
    return (
        <div className="flex gap-[10px]">
            <div className="w-[70px] flex justify-center rounded-[8px] bg-primary-black text-white items-center">
                {editKickOrder}
            </div>
            <Dropdown
                placeholder="선수를 선택하세요"
                options={lineupOptions}
                value={editShootoutsPlayer}
                onChange={setEditShootoutsPlayer}
                className="w-[500px]"
            />{' '}
            <Dropdown
                placeholder="성공 여부"
                options={[
                    { label: '성공', value: 'success' },
                    { label: '실패', value: 'fail' },
                ]}
                value={editSuccess}
                onChange={setEditSuccess}
                className="w-[130px]"
            />
            {Number(newKickOrder) === Number(shootouts?.kickOrder) + 1 && (
                <button
                    onClick={removeShootout}
                    className="text-status-error p-[6px]"
                >
                    <DeleteIcon />
                </button>
            )}
        </div>
    );
};
export default ShootoutsElement;

export interface ShootoutsType {
    id: string;
    kicker: {
        playerId: string;
        playerName: string;
        uniformNumber: string;
        position: string;
    };
    kickOrder: string;
    success: boolean;
}
interface ShootoutsElementProps {
    shootouts: ShootoutsType;
    lineupOptions: { value: string; label: string }[];
    newKickOrder: string;
}
