// hooks
import { useState, useEffect } from 'react';
import { useNavigate, useSearchParams, useParams, useLocation } from 'react-router-dom';
import { useParamNavigate } from 'hooks/useDynamicNavigate';
// api
import { useApiQuery } from 'hooks/useDynamicApi';
// icons
import { ReactComponent as HomeIcon } from 'assets/svgs/home.svg';
import { ReactComponent as CircleArrowIcon } from 'assets/svgs/circleArrow.svg';
// redux
import { RootState } from 'store';
import { useSelector } from 'react-redux';
// utils
import { skillStateMeasurementMatcher } from 'utils/matchers/measurementMatcher';

const MeasurementSubHeader = ({ userInfo }: { userInfo?: any[] }) => {
    const [searchParams] = useSearchParams();
    const { reportType } = useParams();
    const location = useLocation().pathname;
    const locationList = location?.split('/');

    const handleParamNavigate = useParamNavigate();
    const { role, staffRole } = useSelector((state: RootState) => state.userInfo);
    const { ageGroup } = useSelector((state: RootState) => state.teamInfo);
    const navigate = useNavigate();

    const handleHomeNavigate = (param: string) => {
        const adminHome = `/admin/control`;
        const playerHome = `/player/measurement`;
        const teamStaffHome = `/staff/control/lineUp`;
        handleParamNavigate(
            {
                control: param,
            },
            false,
            role === 'PLAYER' ? playerHome : role === 'TEAM_STAFF' ? teamStaffHome : adminHome
        );
    };

    return (
        <div className="wrapper">
            <div className="flex items-center justify-between">
                <div className="flex items-start justify-center h-[79px] flex-col gap-[15px]">
                    <div className="flex gap-[8px] items-center">
                        <button onClick={() => handleHomeNavigate('player')}>
                            <HomeIcon className="text-white mr-[6px]" />
                        </button>
                        <div className="bg-secondary-lGrayDark w-[3px] h-[3px] rounded-full text-label-regular"></div>
                        <div className="text-white text-label-regular">측정</div>
                    </div>
                    <div className="flex items-center gap-[6px]">
                        {locationList[4] && (
                            <button onClick={() => navigate(-1)}>
                                <CircleArrowIcon className="text-white" />
                            </button>
                        )}
                        {locationList[4] ? (
                            <div className="text-primary-white text-[26px] font-bold">
                                {skillStateMeasurementMatcher(locationList[4])} 점수 확인
                            </div>
                        ) : (
                            <div className="flex gap-[10px]">
                                <div className="text-[26px] text-primary-green font-bold">{ageGroup}</div>
                                <div className="text-primary-white text-[26px] font-bold">선수단</div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MeasurementSubHeader;
