// hooks
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
// api
import { useApiQuery } from 'hooks/useDynamicApi';
// staff component
import MeasurementSubHeader from 'pages/staff/measurement/control/MeasurementSubHeader';
import MeasurementDataSummary from 'pages/staff/measurement/control/MeasurementDataSummary';
// utils
import { ENDPOINT_MASUREMENT_STATS } from 'utils/consts/apiEndpoint';
import { DribbleOneSortMatcher } from 'utils/matchers/sortInfoMatcher';
// reudx
import { useSelector } from 'react-redux';
import { RootState } from 'store';
// components
import EmptyBar from 'components/emptyBar';
import CustomTable from 'components/table/CustomTable';

const DribbleOneDetail = () => {
    const { role, staffRole } = useSelector((state: RootState) => state.userInfo); // 인증 상태를 token 유무로 판단
    const [sortType, setSortType] = useState('');
    const [searchParams] = useSearchParams();
    const teamId = searchParams.get('teamId') || '';

    // const { teamId, teamName, ageGroup } =
    //   useSelector((state: RootState) => state.teamInfo) || {};
    const getTeamMeasurement = useApiQuery(
        ['getTeamMeasurement', teamId, sortType],
        `${ENDPOINT_MASUREMENT_STATS.dribbleOne(teamId)}?sortType=${sortType}`,
        'get',
        { enabled: !!teamId }
    );
    const handleSort = (key: string, sort: 'ASC' | 'DESC') => {
        const customizedSort = DribbleOneSortMatcher(key, sort);
        setSortType(customizedSort);
    };
    return (
        <div className="wrapper">
            <MeasurementSubHeader />
            <EmptyBar customStyle="h-[35px]" />

            <div className="bg-primary-white rounded-[10px] flex flex-col gap-[10px] p-[30px]">
                <div className="text-subtitle-medium-bold ">점수 확인</div>
                <CustomTable
                    columns={columns}
                    rows={getTeamMeasurement?.data || []}
                    equalWidth={false}
                    sortInfoFunc={handleSort}
                />
                {getTeamMeasurement?.data < 1 && (
                    <div className="text-center text-secondary-lGrayDark py-[10px]">마지막 측정 기록입니다.</div>
                )}
            </div>
            <EmptyBar customStyle="h-[20px]" />
        </div>
    );
};

export default DribbleOneDetail;

export const columns = [
    { key: 'uniformNumber', label: '등번호', sortable: true },
    { key: 'playerName', label: '이름', sortable: false },
    { key: 'averageScore', label: '에잇라운드', sortable: true },
    { key: 'armScore', label: '상체밸런스', sortable: true },
    { key: 'chinScore', label: '시야', sortable: true },
    { key: 'bendKneeScore', label: '무게중심', sortable: true },
    { key: 'upperBodyScore', label: '무게축', sortable: true },
    { key: 'steppingScore', label: '뒤꿈치', sortable: true },
    { key: 'dashScore', label: '탄력성', sortable: true },
    { key: 'lapMaxSpeedScore', label: '최고속도', sortable: true },
    { key: 'lapAvgSpeedScore', label: '평균속도', sortable: true },
    { key: 'lineDeviationScore', label: '정확도', sortable: true },
    { key: 'ballTouchScore', label: '볼터치', sortable: true },
];
