// hooks
import { useState, useEffect, useMemo, useRef } from 'react';
import { debounce } from 'lodash';
// api
import { useApiMutation } from 'hooks/useDynamicApi';
// commons
import SqureCheckbox from 'components/checkbox/SqureCheckbox';
import { showToast } from 'components/toast/Default';
// utils
import { shootingTwoStateMatcher } from 'utils/matchers/measurementMatcher';
import { ENDPOINT_MEASUREMENT } from 'utils/consts/apiEndpoint';
// icons
import { ReactComponent as EditPenIcon } from 'assets/svgs/editPen.svg';
import { ReactComponent as ResetIcon } from 'assets/svgs/reset.svg';
// measurement component
import Stopwatch from 'pages/hand/measurement/contents/stopwatch';

const ShootingTwo = ({
    measurementId,
    shootingTwoId,
    playerName,
    shooting1Result,
    shooting2Result,
    shooting3Result,
    shooting4Result,
    shooting5Result,
    shooting6Result,
    lapTime,
}: ShootingTwoType) => {
    const attempState = ['FAILED', 'OUTSIDE_TAPE_HIT', 'OUTSIDE_SUCCESS', 'INSIDE_TAPE_HIT', 'INSIDE_SUCCESS'];
    const inputRef = useRef<HTMLInputElement | null>(null);
    const handleButtonClick = () => {
        // ref가 현재 존재하는 경우 focus 호출
        inputRef.current?.focus();
    };
    const [editShootingTwoInfo, setEditShootingTwoInfo] = useState({
        measurementId: measurementId,
        shootingTwoId: shootingTwoId,
        playerName: playerName,
        shooting1Result: shooting1Result,
        shooting2Result: shooting2Result,
        shooting3Result: shooting3Result,
        shooting4Result: shooting4Result,
        shooting5Result: shooting5Result,
        shooting6Result: shooting6Result,
        lapTime: lapTime,
    });
    const SHOOTINGCOUNT = 6;
    const handleEditShootingTwoInfo = (updateData: any) => {
        setEditShootingTwoInfo((prev) => ({
            ...prev,
            ...updateData,
        }));
    };
    // useApiMutation
    const patchShootingTwoMeasurement = useApiMutation(
        ENDPOINT_MEASUREMENT.shootingTwo(editShootingTwoInfo?.shootingTwoId),
        'patch',
        {
            onSuccess: (data) => {
                // showToast("슈팅2 기록을 수정했습니다.", "success");
            },
            onError: (error) => {
                const errorMessage = error?.data?.detailedMessage || error || '슈팅2 기록수정에 실패했습니다.';
                showToast(errorMessage, 'error');
            },
        }
    );
    const patchShootingTwoReset = useApiMutation(
        ENDPOINT_MEASUREMENT.shootingTwoReset(editShootingTwoInfo?.shootingTwoId),
        'patch',
        {
            onSuccess: (data) => {
                showToast('슈팅2 기록을 초기화했습니다.', 'success');
                handleEditShootingTwoInfo({
                    shooting1Result: 'FAILED',
                    shooting2Result: 'FAILED',
                    shooting3Result: 'FAILED',
                    shooting4Result: 'FAILED',
                    shooting5Result: 'FAILED',
                    shooting6Result: 'FAILED',
                    lapTime: 0,
                });
            },
            onError: (error) => {
                const errorMessage = error?.data?.detailedMessage || error || '슈팅2 기록 초기화에 실패했습니다.';
                showToast(errorMessage, 'error');
            },
        }
    );
    const editShootingTwoMeasurement = () => {
        if (!patchShootingTwoMeasurement?.isPending) {
            patchShootingTwoMeasurement.mutate({
                shooting1Result: editShootingTwoInfo?.shooting1Result,
                shooting2Result: editShootingTwoInfo?.shooting2Result,
                shooting3Result: editShootingTwoInfo?.shooting3Result,
                shooting4Result: editShootingTwoInfo?.shooting4Result,
                shooting5Result: editShootingTwoInfo?.shooting5Result,
                shooting6Result: editShootingTwoInfo?.shooting6Result,
                lapTime: editShootingTwoInfo?.lapTime,
            });
        }
    };
    const editShootingTwoReset = () => {
        if (!patchShootingTwoReset?.isPending) {
            showToast('슈팅2 측정데이터를 초기화하는 중입니다.', 'info');
            patchShootingTwoReset.mutate({});
        }
    };

    // debounce
    const debouncedPatchShootingTwo = useMemo(
        () =>
            debounce(() => {
                editShootingTwoMeasurement();
            }, 500), // 1초 딜레이
        [patchShootingTwoMeasurement]
    );
    useEffect(() => {
        if (
            editShootingTwoInfo?.shooting1Result === shooting1Result &&
            editShootingTwoInfo?.shooting2Result === shooting2Result &&
            editShootingTwoInfo?.shooting3Result === shooting1Result &&
            editShootingTwoInfo?.shooting4Result === shooting4Result &&
            editShootingTwoInfo?.shooting5Result === shooting5Result &&
            editShootingTwoInfo?.shooting6Result === shooting6Result &&
            editShootingTwoInfo?.lapTime === lapTime
        ) {
            return;
        }
        debouncedPatchShootingTwo();
        return () => {
            debouncedPatchShootingTwo.cancel();
        };
    }, [editShootingTwoInfo]);
    return (
        <>
            {attempState?.map((el, idx) => (
                <div
                    key={`${attempState}-${idx}`}
                    className={`w-full flex justify-between rounded-[8px] h-[50px] ${
                        idx % 2 === 1 ? 'bg-secondary-mGrayLight' : 'bg-primary-white'
                    }`}
                >
                    <div className="flex items-center justify-center flex-1">{shootingTwoStateMatcher(el)}</div>
                    <SqureCheckbox
                        checked={editShootingTwoInfo?.shooting1Result === el}
                        onChange={() => handleEditShootingTwoInfo({ shooting1Result: el })}
                        className="justify-center flex-1"
                    />
                    <SqureCheckbox
                        checked={editShootingTwoInfo?.shooting2Result === el}
                        onChange={() => handleEditShootingTwoInfo({ shooting2Result: el })}
                        className="justify-center flex-1"
                    />
                    <SqureCheckbox
                        checked={editShootingTwoInfo?.shooting3Result === el}
                        onChange={() => handleEditShootingTwoInfo({ shooting3Result: el })}
                        className="justify-center flex-1"
                    />
                    <SqureCheckbox
                        checked={editShootingTwoInfo?.shooting4Result === el}
                        onChange={() => handleEditShootingTwoInfo({ shooting4Result: el })}
                        className="justify-center flex-1"
                    />
                    <SqureCheckbox
                        checked={editShootingTwoInfo?.shooting5Result === el}
                        onChange={() => handleEditShootingTwoInfo({ shooting5Result: el })}
                        className="justify-center flex-1"
                    />
                    <SqureCheckbox
                        checked={editShootingTwoInfo?.shooting6Result === el}
                        onChange={() => handleEditShootingTwoInfo({ shooting6Result: el })}
                        className="justify-center flex-1"
                    />
                </div>
            ))}
            <div className="flex justify-between items-center text-secondary-lGrayDark m-[10px] gap-[10px]">
                <Stopwatch
                    lapTime={editShootingTwoInfo?.lapTime}
                    setLapTime={(value) => handleEditShootingTwoInfo({ lapTime: value })}
                />
                <div className="flex justify-center items-center h-[50px] gap-[5px]">
                    <div className="font-bold">랩타임</div>
                    <input
                        ref={inputRef} // ref 연결
                        value={editShootingTwoInfo?.lapTime}
                        onChange={(e) => handleEditShootingTwoInfo({ lapTime: e.target.value })}
                        className="h-[40px] w-[70px] focus:outline focus:outline-primary-purple focus:bg-primary-white  bg-transparent rounded-[8px] text-center"
                    />
                    <button
                        className="flex-1 text-secondary-lGrayDark hover:text-primary-purple"
                        onClick={handleButtonClick} // 버튼 클릭 시 input에 포커스
                    >
                        <EditPenIcon />
                    </button>
                    <button
                        className="flex items-center gap-[5px]"
                        onClick={editShootingTwoReset}
                    >
                        초기화
                        <ResetIcon className=" h-[20px] m-auto" />
                    </button>
                </div>
            </div>
        </>
    );
};
export default ShootingTwo;
interface ShootingTwoType {
    measurementId: string;
    shootingTwoId: string;
    playerName: string;
    shooting1Result: 'FAILED' | 'OUTSIDE_TAPE_HIT' | 'OUTSIDE_SUCCESS' | 'INSIDE_TAPE_HIT' | 'INSIDE_SUCCESS';
    shooting2Result: 'FAILED' | 'OUTSIDE_TAPE_HIT' | 'OUTSIDE_SUCCESS' | 'INSIDE_TAPE_HIT' | 'INSIDE_SUCCESS';
    shooting3Result: 'FAILED' | 'OUTSIDE_TAPE_HIT' | 'OUTSIDE_SUCCESS' | 'INSIDE_TAPE_HIT' | 'INSIDE_SUCCESS';
    shooting4Result: 'FAILED' | 'OUTSIDE_TAPE_HIT' | 'OUTSIDE_SUCCESS' | 'INSIDE_TAPE_HIT' | 'INSIDE_SUCCESS';
    shooting5Result: 'FAILED' | 'OUTSIDE_TAPE_HIT' | 'OUTSIDE_SUCCESS' | 'INSIDE_TAPE_HIT' | 'INSIDE_SUCCESS';
    shooting6Result: 'FAILED' | 'OUTSIDE_TAPE_HIT' | 'OUTSIDE_SUCCESS' | 'INSIDE_TAPE_HIT' | 'INSIDE_SUCCESS';
    lapTime: number;
}
