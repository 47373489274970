export const matchDurationOptions = [
    { value: 'MINUTES_40', label: '40 분' },
    { value: 'MINUTES_50', label: '50 분' },
    { value: 'MINUTES_60', label: '60 분' },
    { value: 'MINUTES_70', label: '70 분' },
    { value: 'MINUTES_80', label: '80 분' },
    { value: 'MINUTES_90 ', label: '90 분' },
];

export const matchTypeOptions = [
    { value: 'LEAGUE', label: '리그' },
    { value: 'TOURNAMENT', label: '대회' },
    { value: 'FRIENDLY', label: '친선' },
];
