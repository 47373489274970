// hooks
import { useNavigate, useLocation } from 'react-router-dom';
import { useParamNavigate } from 'hooks/useDynamicNavigate';
// icons
import { ReactComponent as HomeIcon } from 'assets/svgs/home.svg';
import { ReactComponent as CircleArrowIcon } from 'assets/svgs/circleArrow.svg';
// redux
import { RootState } from 'store';
import { useSelector } from 'react-redux';

const PersonalReportHeader = ({ playerName }: { playerName: String }) => {
    const handleParamNavigate = useParamNavigate();
    const { role } = useSelector((state: RootState) => state.userInfo);
    const navigate = useNavigate();
    const locationList = useLocation()?.pathname?.split('/');

    const handleHomeNavigate = () => {
        const adminHome = `/admin/control`;
        const playerHome = `/player/measurement`;
        const teamStaffHome = `/staff/control/lineUp?`;
        const handHome = `/hand/records`;
        handleParamNavigate(
            {},
            false,
            locationList[1] === 'hand'
                ? handHome
                : role === 'PLAYER'
                ? playerHome
                : role === 'TEAM_STAFF'
                ? teamStaffHome
                : adminHome
        );
    };

    return (
        <div className="wrapper">
            <div className="flex items-center justify-between">
                <div className="flex items-start justify-center h-[79px] flex-col gap-[15px]">
                    <div className="flex gap-[8px] items-center">
                        <button onClick={() => handleHomeNavigate()}>
                            <HomeIcon className="text-white " />
                        </button>
                        <div className="w-[3px] h-[3px] rounded-full bg-secondary-lGrayDark"></div>
                        <div className="text-white text-label-regular">측정</div>
                        <div className="w-[3px] h-[3px] rounded-full bg-secondary-lGrayDark"></div>
                        <div className="text-white text-label-regular">{playerName || '분석'}</div>
                    </div>
                    <div className="flex items-center gap-[6px]">
                        <button onClick={() => navigate(-1)}>
                            <CircleArrowIcon className="text-white" />
                        </button>
                        <div className="text-[26px] text-white font-bold">
                            {locationList[2] === 'feedback' ? '피드백관리' : '분석'}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PersonalReportHeader;
