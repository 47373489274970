// hooks
import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import axiosInstance from "api/axiosInstance";
import { useInfiniteQuery } from "@tanstack/react-query";
import { useInView } from "react-intersection-observer";
// utils
import { ENDPOINT_INJURY } from "utils/consts/apiEndpoint";
// api
import { useApiMutation } from "hooks/useDynamicApi";
// icons
import { ReactComponent as PlusIcon } from "assets/svgs/plus.svg";
import { ReactComponent as CalendarIcon } from "assets/svgs/calendar.svg";
// commons
import DatePicker from "components/datePicker";
import { showToast } from "components/toast/Default";
// inturyHistory compnents
import History from "pages/staff/control/lineUp/[id]/injury/injuryHistoryTables/history";
interface InjuryEditTableType {
  playerName?: string;
}
const InjuryEditTable = ({ playerName }: InjuryEditTableType) => {
  const { ref, inView } = useInView();
  const [searchParams] = useSearchParams();
  const paramPlayerId = searchParams.get("player") || "";
  const [isAddingNew, setIsAddingNew] = useState(false);
  const [createForm, setCreateForm] = useState({
    injuryDate: "",
    returnDate: "",
    injuryDescription: "",
    treatmentDescription: "",
  });

  const {
    data,
    error,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    status,
  } = useInfiniteQuery({
    queryKey: ["injuries", paramPlayerId],
    queryFn: ({ pageParam = 1 }) =>
      axiosInstance
        .get(
          `${ENDPOINT_INJURY.playerHistory(
            paramPlayerId
          )}?page=${pageParam}&size=10`
        )
        .then((response) => response.data),
    initialPageParam: 1,
    getNextPageParam: (lastPage) =>
      lastPage?.sliceInfo?.hasNext ? lastPage?.sliceInfo?.page + 1 : undefined,
  });
  // useApiMutation
  const postInjuryHistory = useApiMutation(ENDPOINT_INJURY.history(), "post", {
    onSuccess: (data) => {
      showToast("새로운 부상이력이 등록되었습니다.", "success");
    },
    onError: (error) => {
      const errorMessage =
        error?.data?.detailedMessage ||
        error ||
        "부상이력 등록에 실패했습니다.";
      showToast(errorMessage, "error");
    },
  });
  useEffect(() => {
    if (inView && hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [inView, hasNextPage, isFetchingNextPage]);
  // handle
  const createInjuryHistory = () => {
    const currentTime = new Date().toLocaleString("en-US", {
      timeZone: "Asia/Seoul",
    });
    const newInjuryBody = {
      playerId: paramPlayerId,
      playerName: playerName,
      injuryDescription: createForm.injuryDescription,
      treatmentDescription: createForm.treatmentDescription,
      injuryDate: createForm.injuryDate,
      returnDate: createForm.returnDate,
      updatedAt: currentTime,
    };
    showToast(`부상 이력 추가중입니다.`, "info");
    postInjuryHistory.mutate(newInjuryBody);
  };

  return (
    <div className="">
      <div className="text-context-regular">
        {data?.pages.map((page, pageIdx) =>
          page.data.map((el: InjuryHistoryType, dataIdx: number) => (
            <History key={el.id} dataIdx={dataIdx} {...el} />
          ))
        )}
        {isAddingNew && (
          <div className="grid grid-cols-6 text-secondary-dGrayMid h-[50px] w-full bg-[]">
            <div className="flex items-center justify-center font-bold text-primary-purple">
              NEW
            </div>
            <textarea
              className="bg-transparent focus:outline-none resize-none p-[14px] text-center"
              value={createForm.injuryDescription}
              onChange={(e) =>
                setCreateForm((prev) => ({
                  ...prev,
                  injuryDescription: e.target.value,
                }))
              }
              placeholder="부상 내용"
            />
            <textarea
              className="bg-transparent focus:outline-none resize-none p-[14px] text-center"
              value={createForm.treatmentDescription}
              onChange={(e) =>
                setCreateForm((prev) => ({
                  ...prev,
                  treatmentDescription: e.target.value,
                }))
              }
              placeholder="치료 내용"
            />
            <div>
              <DatePicker
                modalBtn={
                  <div className="flex items-center justify-center p-[10px] gap-[14px]">
                    {createForm.injuryDate || "부상일"}
                    <CalendarIcon />
                  </div>
                }
                selected={createForm.injuryDate}
                setSelected={(date) =>
                  setCreateForm((prev) => ({ ...prev, injuryDate: date }))
                }
              />
            </div>
            <div>
              <DatePicker
                modalBtn={
                  <div className="flex items-center justify-center p-[10px] gap-[14px]">
                    {createForm.returnDate || "복귀일"}
                    <CalendarIcon />
                  </div>
                }
                selected={createForm.returnDate}
                setSelected={(date) =>
                  setCreateForm((prev) => ({ ...prev, returnDate: date }))
                }
              />
            </div>
            <div className="flex items-center justify-center gap-2">
              <button
                onClick={() => {
                  createInjuryHistory();
                  setIsAddingNew(false);
                }}
                className="bg-primary-purple rounded-[8px] text-primary-white px-4 h-[44px]"
              >
                저장
              </button>
              <button
                onClick={() => setIsAddingNew(false)}
                className="bg-secondary-lGrayDark rounded-[8px] text-primary-white px-4 h-[44px]"
              >
                취소
              </button>
            </div>
          </div>
        )}
        <div ref={ref} className="h-4" />
      </div>
      {isFetching && !isFetchingNextPage && (
        <div className="py-2 text-center text-secondary-lGrayDark">
          Loading...
        </div>
      )}
      {isFetchingNextPage && (
        <div className="py-2 text-center text-secondary-lGrayDark">
          다음 페이지 로딩 중...
        </div>
      )}
      {!isFetching && !isFetchingNextPage && !hasNextPage && (
        <div className="py-2 text-center text-secondary-lGrayDark">
          마지막 데이터 입니다.
        </div>
      )}
      <button
        onClick={() => setIsAddingNew(true)}
        className="h-[50px] w-full rounded-[8px] border border-secondary-lGrayMid text-secondary-lGrayDark flex justify-center items-center gap-[10px] text-context-regular mt-[24px]"
      >
        <PlusIcon />
        부상 이력 추가
      </button>
    </div>
  );
};

export default InjuryEditTable;

interface InjuryHistoryType {
  id: number;
  injuryDate: string;
  injuryDescription: string;
  playerId: number;
  playerName: string;
  returnDate: string;
  treatmentDescription: string;
  updatedAt: string;
}
