// hooks
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
// icons
import { ReactComponent as PlusIcon } from "assets/svgs/plus.svg";
// api
import { useApiMutation } from "hooks/useDynamicApi";
// commons
import Dropdown from "components/dropdown/Default";
import { showToast } from "components/toast/Default";
import LineupEditElement from "pages/staff/control/schedule/report/post/reportPlayer/LineupEditElement";
import ConfirmModal from "components/modals/ConfirmModal";
// utils
import { positionShortOptions } from "utils/consts/userInfo";
import { ENDPOINT_REPORT } from "utils/consts/apiEndpoint";

const Starting = ({ lineup, activeLineup, inactiveLineup }: StartingType) => {
  const { id } = useParams();

  const [isAdd, setIsAdd] = useState(false);
  const [newPlayer, setNewPlayer] = useState("");
  const [newPosition, setNewPosition] = useState("");
  const inactiveOptions = handleDropdownOpts(inactiveLineup) || [];

  // useApiMutation
  const postStarting = useApiMutation(ENDPOINT_REPORT.starting(id), "post", {
    onSuccess: (data) => {
      showToast("선발 선수를 추가하였습니다", "success");
      setNewPlayer("");
      setNewPosition("");
      setIsAdd(false);
    },
    onError: (error) => {
      const detailError = error?.data?.detailedMessage || error;
      setNewPlayer("");
      setNewPosition("");
      showToast(`${detailError}`, "error");
    },
  });

  // submit Func
  const createStarting = () => {
    showToast("선발 선수를 추가하는중입니다", "info");
    if (!postStarting?.isPending && newPlayer && newPosition) {
      postStarting.mutate({
        playerId: newPlayer,
        position: newPosition,
      });
    }
  };
  useEffect(() => {
    if (newPosition && newPlayer) {
      createStarting();
    }
  }, [newPosition, newPlayer]);
  return (
    <div className="p-[24px] flex items-center w-full border-b border-secondary-lGrayMid">
      <div className="text-center shrink-0 w-[100px] h-full text-context-regular text-secondary-dGrayMid">
        선발
      </div>
      <div className="flex flex-col gap-[10px]">
        <div className="flex flex-col gap-[10px]">
          {lineup?.map((el: LineupType) => (
            <LineupEditElement
              {...el}
              key={el?.playerId}
              inactiveLineup={inactiveLineup}
            />
          ))}
        </div>
        <div className="flex flex-col gap-[10px]">
          {(isAdd || lineup?.length < 1) && (
            <div className="flex gap-[10px]">
              <Dropdown
                placeholder="포지션"
                options={positionShortOptions}
                value={newPosition}
                onChange={setNewPosition}
                className="w-[100px]"
              />
              <Dropdown
                placeholder="선수를 입력하세요"
                options={inactiveOptions}
                value={newPlayer}
                onChange={setNewPlayer}
                className="w-[390px]"
              />
            </div>
          )}
          <button
            onClick={() => setIsAdd(true)}
            className="w-[500px] h-[44px] rounded-[8px] text-context-regular text-secondary-lGrayDark gap-[10px] border border-secondary-lGrayMid flex items-center justify-center"
          >
            <PlusIcon />
            선발추가
          </button>
        </div>
      </div>
    </div>
  );
};

export default Starting;

interface StartingType {
  lineup: LineupType[];
  activeLineup: {
    playerId: string;
    playerName: string;
    uniformNumber: string;
    positions: string[];
  };
  inactiveLineup: {
    playerId: string;
    playerName: string;
    uniformNumber: string;
    positions: string[];
  }[];
}

interface LineupType {
  lineupId: string;
  playerId: string;
  playerName: string;
  uniformNumber: string;
  position: string;
  rating: string;
  managerComment: string;
}

export const handleDropdownOpts = (arr: any[]) => {
  return arr?.map((arr) => ({
    value: arr?.playerId,
    label: `${arr?.uniformNumber}. ${arr?.playerName}`,
  }));
};
