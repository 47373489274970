import { useState } from 'react';
// api
import { useApiMutation } from 'hooks/useDynamicApi';
// icons
import { ReactComponent as DeleteBoxIcon } from 'assets/svgs/deleteBox.svg';
import { ReactComponent as CloseIcon } from 'assets/svgs/close.svg';
import { ReactComponent as SearchIcon } from 'assets/svgs/search.svg';
//utils
import { ENDPOINT_TEAM, ENDPOINT } from 'utils/consts/apiEndpoint';
import { regionOptions, ageGroupOptions, soccerTypeOptions } from 'utils/consts/userInfo';
// commons
import { showToast } from 'components/toast/Default';
import Dropdown from 'components/dropdown/Default';
// import TeamStaffSearchModal from 'components/modals/TeamStaffSearchModal';
interface PlayerAddModalType {
    modalBtn?: string | React.ReactNode;
}
const PlayerAddModal = ({ modalBtn }: PlayerAddModalType) => {
    const [isShow, setIsShow] = useState(false);

    const [newPlayerInfo, setNewPlayerInfo] = useState<PlayerInfo>({
        email: '',
        name: '',
        password: 'test1234!',
        passwordRepeat: 'test1234!',
        isVerified: true,
    });

    const handleInfo = (updateData: any) => {
        setNewPlayerInfo((prev: any) => ({
            ...prev,
            ...updateData,
        }));
    };

    const postSignUpMutation = useApiMutation(ENDPOINT.user.register, 'post', {
        onSuccess: (data) => {
            showToast(`선수를 회원가입 시켰습니다.`, 'success');
            setNewPlayerInfo({
                email: '',
                name: '',
                password: 'test1234!',
                passwordRepeat: 'test1234!',
                isVerified: true,
            });
            setIsShow(false);
        },
        onError: (error) => {
            const errorMessage = error?.data?.detailedMessage || error || '회원가입 실패했습니다.';
            showToast(`${errorMessage}`, 'error');
        },
    });

    // handler
    const createPlyaer = () => {
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        if (!emailRegex) {
            showToast('올바른 이메일을 입력해주세요.', 'warning');
            return;
        }
        if (!postSignUpMutation?.isPending) {
            if (
                !newPlayerInfo?.name ||
                !newPlayerInfo?.email ||
                !newPlayerInfo?.password ||
                !newPlayerInfo?.passwordRepeat
            ) {
                showToast('필수 값을 입력해주세요.', 'warning');
                return;
            }
            if (newPlayerInfo?.password !== newPlayerInfo?.passwordRepeat) {
                showToast('비밀번호가 일치하지 않습니다.', 'warning');
                return;
            }
            showToast('선수를 가입시키는 중입니다.', 'info');
            postSignUpMutation.mutate({
                name: newPlayerInfo?.name,
                email: newPlayerInfo?.email,
                password: newPlayerInfo?.password,
                passwordRepeat: newPlayerInfo?.passwordRepeat,
                isVerified: true,
            });
        }
    };

    return (
        <>
            <button onClick={() => setIsShow(true)}>{modalBtn}</button>
            {isShow && (
                <div
                    onClick={() => setIsShow(false)}
                    className="fixed left-0 z-[100] top-0 flex justify-center items-center w-full h-full bg-[#00000050]"
                >
                    <div
                        className=" bg-white rounded-[10px]  items-center p-[50px]"
                        onClick={(e) => e.stopPropagation()}
                    >
                        <div className="w-[400px] flex flex-col justify-center gap-[14px]">
                            <div className="flex justify-between text-secondary-dGrayMid text-subtitle-medium-bold">
                                <span>선수 회원가입</span>
                                <button onClick={() => setIsShow(false)}>
                                    <CloseIcon className="text-secondary-lGrayDark" />
                                </button>
                            </div>
                            <div>
                                <input
                                    value={newPlayerInfo?.name}
                                    onChange={(e) => handleInfo({ name: e.target.value })}
                                    placeholder="선수명"
                                    maxLength={50}
                                    className="inputDefaultStyle inputCommonStyle"
                                />
                            </div>
                            <div>
                                <input
                                    value={newPlayerInfo?.email}
                                    onChange={(e) => handleInfo({ email: e.target.value })}
                                    placeholder="이메일"
                                    maxLength={50}
                                    className="inputDefaultStyle inputCommonStyle"
                                />
                            </div>
                            <div>
                                <input
                                    value={newPlayerInfo?.password}
                                    onChange={(e) => handleInfo({ password: e.target.value })}
                                    placeholder="비밀번호"
                                    maxLength={50}
                                    className="inputDefaultStyle inputCommonStyle"
                                />
                            </div>
                            <div>
                                <input
                                    value={newPlayerInfo?.passwordRepeat}
                                    onChange={(e) => handleInfo({ passwordRepeat: e.target.value })}
                                    placeholder="비밀번호 확인"
                                    maxLength={50}
                                    className="inputDefaultStyle inputCommonStyle"
                                />
                            </div>
                            <button
                                className="p-[12px] w-full text-white bg-primary-purple rounded-[8px]"
                                onClick={createPlyaer}
                            >
                                선수 회원 가입
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default PlayerAddModal;
interface TeamStaff {
    userId: number;
    email: string | null;
    name: string | null;
    lastFourDigits: string | null;
}

interface PlayerInfo {
    email: string;
    name: string;
    password: string;
    passwordRepeat: string;
    isVerified: boolean;
}
