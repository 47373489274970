// hooks
import { useState, useEffect } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { useParamNavigate } from "hooks/useDynamicNavigate";
// api
import { useApiQuery } from "hooks/useDynamicApi";
// icons
import { ReactComponent as HomeIcon } from "assets/svgs/home.svg";
import { ReactComponent as CircleArrowIcon } from "assets/svgs/circleArrow.svg";
// common
import Dropdown from "components/dropdown/Default";
// utils
import { ENDPOINT_TEAM } from "utils/consts/apiEndpoint";
import {
  dropDownFormChanger,
  dropDownFormChangerType,
} from "utils/formChanger";
// redux
import { RootState } from "store";
import { useSelector } from "react-redux";
interface LineUpIdSubHeaderType {
  playerInfo: any;
  seasonStats: any;
  dropdownPlayerOpts: { value: string; label: string }[];
}
const LineUpIdSubHeader = ({
  playerInfo,
  seasonStats,
  dropdownPlayerOpts,
}: LineUpIdSubHeaderType) => {
  const handleParamNavigate = useParamNavigate();
  const {
    totalAppearances = 0,
    totalGoals = 0,
    totalAssists = 0,
    totalAverageRating = 0.0,
  } = seasonStats?.totalStats || {};
  const { staffRole } = useSelector((state: RootState) => state.userInfo);

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const paramYear = searchParams.get("year");
  const [player, setPlayer] = useState(playerInfo?.id);
  const [teamName, setTeamName] = useState("TEAM");
  const [ageGroup, setAgeGroup] = useState("");
  const [ageGroupOpts, setAgeGroupsOpts] = useState<dropDownFormChangerType[]>(
    []
  );

  // react useApiQuery
  const { data, isLoading, isSuccess, isError, error, refetch } = useApiQuery(
    ["ageGroup"],
    ENDPOINT_TEAM.staff.team.ageGroups,
    "get",
    {
      enabled: staffRole === "OFFICE",
    }
  );

  useEffect(() => {
    if (isSuccess && data) {
      const { teamName, ageGroups } = data;
      setTeamName(teamName);
      setAgeGroup(ageGroups[0]);
      const newAgeGroupOpts = dropDownFormChanger(ageGroups);
      setAgeGroupsOpts(newAgeGroupOpts);
    }
  }, [data]);
  useEffect(() => {
    if (ageGroup && player && paramYear)
      handleParamNavigate({
        ageGroup: ageGroup,
        player: player,
        year: paramYear,
      });
  }, [player]);
  const handleAgeGroupNavigate = () => {
    if (ageGroup && player && paramYear)
      handleParamNavigate(
        {
          ageGroup: ageGroup,
          player: player,
          year: paramYear,
        },
        false,
        `/staff/control/lineUp`
      );
  };

  const handleHomeNavigate = () => {
    if (ageGroup && paramYear)
      handleParamNavigate(
        {
          ageGroup: ageGroup,
          year: paramYear,
        },
        false,
        `/staff/control/lineUp`
      );
  };

  return (
    <div className="">
      <div className="flex items-center justify-between gap-[220px]">
        <div className="flex flex-col gap-[15px]">
          <div className="flex gap-[8px] items-center">
            <button onClick={handleHomeNavigate}>
              <HomeIcon className="text-white" />
            </button>
            <div className="w-[3px] h-[3px] rounded-full bg-secondary-lGrayDark"></div>
            <Dropdown
              options={ageGroupOpts}
              value={ageGroup}
              placeholder="연령"
              onChange={setAgeGroup}
              activeFunc={handleAgeGroupNavigate}
              className={
                " text-label-regular border rounded-[8px] border-primary-white"
              }
              innerClassName="px-[6px] py-[8px] text-label-regular"
            />
            <div className="w-[3px] h-[3px] rounded-full bg-secondary-lGrayDark"></div>

            <Dropdown
              options={dropdownPlayerOpts}
              value={player}
              placeholder={
                playerInfo?.uniformNumber
                  ? `${playerInfo?.uniformNumber}. ${playerInfo?.name}`
                  : "선수명"
              }
              onChange={setPlayer}
              className={
                " text-label-regular w-[110px] border rounded-[8px] border-primary-white"
              }
              innerClassName="px-[6px] py-[8px] text-label-regular"
            />
          </div>
          <div className="flex items-center gap-[10px]">
            <button onClick={() => navigate(-1)}>
              <CircleArrowIcon className="text-white" />
            </button>
            <div className="text-[26px] font-bold text-white">
              {playerInfo?.name}
            </div>
          </div>
        </div>
        <div className="flex  gap-[80px] ">
          <div className="flex gap-[18px]  h-[48px] text-[#868686]">
            <div className="w-[5px] bg-primary-green rounded-[5px]"></div>

            <div className="flex gap-[15px] items-center">
              <div className="flex items-center gap-[35px] ">
                <div className="flex items-center gap-[5px]">
                  <span className="text-[22px] ">{"출장"}</span>
                  <span className="text-[50px] text-primary-green font-bold">
                    {totalAppearances}
                  </span>
                </div>
                <div className="flex items-center gap-[5px]">
                  <span className="text-[22px]">{"평점"}</span>
                  <span className="text-[50px] text-primary-white font-bold">
                    {totalAverageRating.toFixed(2)}
                  </span>
                </div>
                <div className="flex items-center gap-[5px]">
                  <span className="text-[22px]">{"득점"}</span>
                  <span className="text-[50px] text-status-error font-bold">
                    {totalGoals}
                  </span>
                </div>
                <div className="flex items-center gap-[5px]">
                  <span className="text-[22px]">{"도움"}</span>
                  <span className="text-[50px] text-primary-white font-bold">
                    {totalAssists}
                  </span>
                </div>
                {/* <MoreNavBtn navigateUrl="/staff/control/lineUp/schedule" /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LineUpIdSubHeader;
