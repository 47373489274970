// hooks
import { useState } from "react";
// common
import EmptyBar from "components/emptyBar";
// commons
import Tooltip from "components/tooltip";

const GroupReportTable = ({
  columns,
  rows,
  average,
  equalWidth = false,
}: {
  columns: any[];
  rows: any[];
  average: any[];
  equalWidth?: boolean;
}) => {
  const [tooltipContent, setTooltipContent] = useState<string | null>(null);
  const handleMouseEnter = (event: React.MouseEvent, content: string) => {
    setTooltipContent(content);
  };

  // 툴팁이 사라질 때 설정
  const handleMouseLeave = () => {
    setTooltipContent(null);
  };

  return (
    <div className="overflow-x-auto ">
      <table className={`w-full text-center  text-secondary-dGrayMid`}>
        <thead className="text-context-regular ">
          <tr className="bg-secondary-mGrayLight text-secondary-lGrayDark border border-y-secondary-mGrayDark h-[50px] ">
            {columns.map((column) => (
              <th
                key={column.key}
                className={`${column === columns[0] ? "" : ""} ${
                  column === columns[columns.length - 1] ? "" : ""
                }`}
                style={{
                  width: equalWidth ? `${100 / columns.length}%` : "",
                }}
              >
                <div className="flex items-center w-fit px-[10px] m-auto gap-[5px]">
                  {column.label}
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="text-context-bold">
          {average.map((row, index) => (
            <tr
              key={`${columns.keys}-average-${index}`}
              className={`${
                index % 10 >= 5 ? " bg-secondary-mGrayLight" : ""
              } h-[50px] border-y border-secondary-mGrayDark`}
            >
              {columns.map((column, idx) => (
                <td
                  onMouseEnter={(e) => handleMouseEnter(e, row[column.key])}
                  onMouseLeave={handleMouseLeave}
                  className={`
                                ${
                                  column.fixedWidth && `${column.fixedWidth} `
                                }`}
                >
                  {row[column.key]}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
        <EmptyBar customStyle="h-[20px]" />
        <tbody className="text-context-bold">
          {rows?.map((row, index) => (
            <tr
              key={`${columns.keys}-${index}`}
              className={`${
                index % 10 >= 5 ? " bg-secondary-mGrayLight" : ""
              } h-[50px] border-y border-secondary-mGrayDark`}
            >
              {columns?.map((column, idx) => (
                <td
                  onMouseEnter={(e) => handleMouseEnter(e, row[column.key])}
                  onMouseLeave={handleMouseLeave}
                  className="relative group"
                >
                  {row[column.key]}
                  <span className="w-full  hidden group-hover:block top-[-30px] absolute">
                    <Tooltip tip={`${row[column.key]} 설명`} />
                  </span>
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default GroupReportTable;
