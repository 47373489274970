// utils
import { useParamNavigate } from "hooks/useDynamicNavigate";
/// icons
import { ReactComponent as UserIcon } from "assets/svgs/user.svg";
import { ReactComponent as OutIcon } from "assets/svgs/out.svg";
// redux
import { RootState } from "store";
import { useDispatch } from "react-redux";
import { userInfoRemove } from "store/userInfoSlice";
import { teamInfoRemove } from "store/teamInfoSlice";
import { useLogout } from "hooks/useLogout";
import { logout } from "store/authSlice";

const MypageNavigateModal = ({
  parentUrl,
  isShow,
  setIsShow,
}: {
  parentUrl: string;
  isShow: boolean;
  setIsShow: (show: boolean) => void;
}) => {
  const dispatch = useDispatch();

  const handleParamNavigate = useParamNavigate();
  const handleNavigate = (navigateUrl: string) => {
    handleParamNavigate({}, false, navigateUrl);
  };

  const logoutFunc = useLogout();
  const handleLogout = () => {
    dispatch(userInfoRemove());
    dispatch(teamInfoRemove());
    dispatch(logout());
    logoutFunc();
  };
  return (
    <>
      {isShow && (
        <div
          onClick={() => setIsShow(false)}
          className="absolute z-[10]  right-0 top-[60px] w-[174px] rounded-[10px] bg-primary-white border text-secondary-lGrayMid border-primary-purple"
        >
          <button
            onClick={() => handleNavigate(`${parentUrl}/mypage`)}
            className="w-full rounded-[10px] p-[12px] flex gap-[10px] hover:text-primary-purple text-context-regular hover:bg-[#EDECFF]"
          >
            <UserIcon />
            <div>마이페이지</div>
          </button>
          <button
            onClick={handleLogout}
            className="w-full rounded-[10px] p-[12px] flex gap-[10px] hover:text-primary-purple text-context-regular hover:bg-[#EDECFF]"
          >
            <OutIcon />
            <div>로그아웃</div>
          </button>
        </div>
      )}
      {isShow && (
        <div
          onClick={() => setIsShow(false)}
          className="w-screen fixed z-[1] h-screen"
        ></div>
      )}
    </>
  );
};

export default MypageNavigateModal;
