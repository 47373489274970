import React, { useEffect, useState } from "react";

const CircleToggle = ({ state = false, activeFunc }: CircleToggleType) => {
  const [isOn, setIsOn] = useState(state);

  const handleToggleChange = () => {
    if (activeFunc) activeFunc();
    setIsOn((prev) => {
      const newState = !prev;
      return newState;
    });
  };
  useEffect(() => {
    setIsOn(state);
  }, [state]);

  return (
    <div
      className={`w-[50px] h-[24px] px-[4px] flex items-center rounded-full cursor-pointer transition-colors ${
        isOn ? "bg-primary-purple" : "bg-secondary-dGrayLight"
      }`}
      onClick={handleToggleChange}
    >
      <div
        className={`w-[20px] h-[20px]  bg-white rounded-full transition-transform ${
          isOn ? "translate-x-[23px]" : "translate-x-0"
        }`}
      />
    </div>
  );
};

export default CircleToggle;

interface CircleToggleType {
  state: boolean;
  activeFunc?: () => void;
}
