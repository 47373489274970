// hooks
import { useLocation } from "react-router-dom";
// reudx
import { useSelector } from "react-redux";
import { RootState } from "store"; // Redux Store 타입 정의
// layouts
import PlayerLayout from "components/layouts/playerLayout";
import StaffLayout from "components/layouts/staffLayout";
import AdminLayout from "components/layouts/adminLayout";
import HandLayout from "components/layouts/handLayout";
const Layout = () => {
  const location = useLocation().pathname;
  const { role, staffRole } = useSelector((state: RootState) => state.userInfo); // 인증 상태를 token 유무로 판단
  const exceptionRoute = [
    "/",
    "/landing",
    "/signIn",
    "/signIn/email",
    "/login/oauth",
    "/login/authentication-failure",
    "/initial",
    "/hand/signIn",
    "/hand/signIn/email",
    "/hand/initial",
  ];
  // hand로 시작하는 경로 확인
  const isHandRoute = /^\/hand\//.test(location);
  return (
    <>
      {exceptionRoute.includes(location) ? (
        <></>
      ) : isHandRoute ? (
        <HandLayout /> // hand로 시작하는 경로에 HandLayout 적용 : role === "PLAYER" ? (
      ) : role === "PLAYER" ? (
        <PlayerLayout />
      ) : role === "TEAM_STAFF" ? (
        <StaffLayout />
      ) : role === "SUPER_ADMIN" ||
        role === "SYSTEM_ADMIN" ||
        role === "DATA_ANALYST" ? (
        <AdminLayout />
      ) : (
        <></>
      )}
    </>
  );
};

export default Layout;
