import axios from 'axios';
import store from '../store';

const axiosFormDataInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    timeout: 5000,
    headers: {
        'Content-Type': 'multipart/form-data', // FormData 기본 헤더
    },
});

axiosFormDataInstance.interceptors.request.use(
    (config) => {
        const accessToken = store.getState().auth.accessToken;
        if (accessToken) {
            config.headers.Authorization = `Bearer ${accessToken}`;
        }
        return config;
    },
    (error) => Promise.reject(error)
);

axiosFormDataInstance.interceptors.response.use(
    (response) => response,
    (error) => {
        console.error('[Response Error]', error.response || error.message);
        if (error?.status === 401) {
            // 로그아웃 로직 추가
            console.log('권한이 없습니다.');
            localStorage.clear(); // 전체 클리어 또는 선택적 클리어
        } else if (error?.status === 403) {
            console.log('접근 권한이 없습니다.');
        } else {
            const customError = {
                status: error.response?.status || 'Unknown',
                message: error.response?.data?.message || error.message,
                reason: error.response?.data?.errors?.[0]?.reason || error.response,
            };
            return Promise.reject(customError);
        }
        return Promise.reject(error);
    }
);

export default axiosFormDataInstance;
