// hooks
import { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
// api
import { useApiQuery } from 'hooks/useDynamicApi';
// player components
import RecordCard from 'pages/player/measurement/analysis/recordCard';
import SummaryCard from 'pages/player/measurement/analysis/summaryCard';
import AnalyzeCard from 'pages/player/measurement/analysis/analysisCard';
import RecommandCard from 'pages/player/measurement/analysis/recommandCard';
// common
import MainSubHeader from 'pages/player/components/MainSubHeader';
import EmptyBar from 'components/emptyBar';
import SoccerBallLodaing from 'components/loading/SoccerBallLodaing';
// utils
import { DASHBOARD, ENDPOINT_MEASUREMENT } from 'utils/consts/apiEndpoint';
// redux
import { RootState } from 'store'; // Redux Store 타입 정의
import { useSelector } from 'react-redux';

const Analysis = () => {
    const { role } = useSelector((state: RootState) => state.userInfo); // 인증 상태를 token 유무로 판단
    const [searchParams] = useSearchParams();

    const { measurementId = '' } = useParams();
    const dribbleOneId = searchParams.get('dribbleOneId') || '';

    const playerId = searchParams.get('playerId') || '';

    const getPlayerDashboard = useApiQuery(
        ['getPlayerDashboard', `${playerId}`],
        DASHBOARD.player(`${playerId}`),
        'get',
        {
            enabled: !!playerId && !dribbleOneId,
        }
    );

    const getMeasurementAnalysis = useApiQuery(
        ['getMeasurementAnalysis', `${measurementId}`],
        DASHBOARD.measurement(`${measurementId}`),
        'get',
        {
            enabled: !!measurementId && !dribbleOneId,
        }
    );
    console.log(!!measurementId && !dribbleOneId);
    const { rankingInfo, playerInfo } = getPlayerDashboard?.data || {};
    const {
        dribbleOneAnalysis,
        dribbleTwoAnalysis,
        firstTouchAnalysis,
        passOneAnalysis,
        passTwoAnalysis,
        physicalInfo,
        shootingOneAnalysis,
        shootingTwoAnalysis,
        skillAnalysis,
    } = getMeasurementAnalysis?.data || {};

    const [page, setPage] = useState(1);
    const PAGE_SIZE = 7;
    useEffect(() => {
        if (skillAnalysis?.sequenceNumber) {
            const INIT_PAGE = Math.ceil(skillAnalysis?.sequenceNumber / PAGE_SIZE);
            setPage(INIT_PAGE || 1);
        }
    }, [skillAnalysis?.sequenceNumber]);
    const [sortType, setSortType] = useState('ID_ASC');
    const getDribbleOneHistory = useApiQuery(
        ['getDribbleOneHistory', `${dribbleOneAnalysis?.dribbleOneId || dribbleOneId}`, `${page}`, sortType],
        `${ENDPOINT_MEASUREMENT.dribbleOneHistory(
            dribbleOneAnalysis?.dribbleOneId || dribbleOneId
        )}?page=${page}&size=${PAGE_SIZE}&sortType=${sortType}`,
        'get',
        {
            enabled: !!dribbleOneAnalysis?.dribbleOneId || !!dribbleOneId,
        }
    );

    return (
        <div className="gap-[20px] flex flex-col wrapper ">
            {/* {getMeasurementAnalysis?.isPending || <SoccerBallLodaing />} */}
            <MainSubHeader
                playerInfo={playerInfo}
                rankingInfo={rankingInfo}
                measurementId={measurementId}
            />
            {(getMeasurementAnalysis?.data && getMeasurementAnalysis?.isSuccess) || dribbleOneId ? (
                <div className="gap-[20px] flex flex-col ">
                    <div className="flex gap-[20px]">
                        <RecordCard
                            recordData={getDribbleOneHistory?.data?.data}
                            sortType={sortType}
                            setSortType={setSortType}
                            sliceInfo={getDribbleOneHistory?.data?.sliceInfo}
                            page={page}
                            setPage={setPage}
                        />
                        {/* <SummaryCard analysisData={analysisData} /> */}
                    </div>
                    <AnalyzeCard dribbleOneId={dribbleOneAnalysis?.dribbleOneId || dribbleOneId} />
                    <RecommandCard playerInfo={playerInfo} />
                    <EmptyBar customStyle="h-[20px]" />
                </div>
            ) : getMeasurementAnalysis?.isError ? (
                <div className="gap-[20px] flex flex-col ">
                    <div className="bg-primary-white rounded-[10px] p-[30px]">완료되지 않은 측정입니다.</div>
                </div>
            ) : (
                <SoccerBallLodaing />
            )}
        </div>
    );
};

export default Analysis;
