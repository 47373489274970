// hooks
import { useEffect, useState } from 'react';
// icons
import { ReactComponent as CircleArrowIcon } from 'assets/svgs/circleArrow.svg';
interface TimeSelectorType {
    selectedTime: string;
    setSelectedTime: (time: string) => void;
}

const TimeSelector = ({ selectedTime, setSelectedTime }: TimeSelectorType) => {
    // 초기 세팅
    const getNearest30MinuteTime = () => {
        const now = new Date();
        const minutes = now.getMinutes();
        const roundedMinutes = minutes < 30 ? '00' : '30';
        const hours = String(minutes < 30 ? now.getHours() : now.getHours()).padStart(2, '0');
        return `${hours}:${roundedMinutes}`;
    };

    useEffect(() => {
        // Set the  close Time
        const nearest30MinuteTime = getNearest30MinuteTime();
        setSelectedTime(nearest30MinuteTime);
    }, []);

    return (
        <div className="relative">
            <select
                value={selectedTime}
                onChange={(e) => setSelectedTime(e.target.value)}
                className="w-full px-[10px] h-[44px] rounded-[8px] text-white appearance-none"
                style={{
                    colorScheme: 'dark',
                    paddingRight: '16px', // 화살표를 안쪽으로 땡기기
                }}
            >
                {Array.from({ length: 48 }, (_, i) => {
                    const hours = String(Math.floor(i / 2)).padStart(2, '0');
                    const minutes = i % 2 === 0 ? '00' : '30';
                    return (
                        <option
                            key={i}
                            value={`${hours}:${minutes}`}
                        >
                            {`${hours}:${minutes}`}
                        </option>
                    );
                })}
            </select>
            {/* Custom Icon */}
            <CircleArrowIcon
                className="absolute text-white transform -rotate-90 -translate-y-1/2 pointer-events-none right-3 top-1/2"
                width={16}
                height={16}
            />
        </div>
    );
};

export default TimeSelector;
