import { useState } from "react";
// icons
import { ReactComponent as DeleteBoxIcon } from "assets/svgs/deleteBox.svg";

interface ConfirmModalType {
  isShow: boolean;
  setIsShow: (state: boolean) => void;
  title?: string | React.ReactNode;
  content?: string | React.ReactNode;
  deleteBtnText?: string;
  activeFunc?: () => void;
}
const ConfirmModal = ({
  isShow = false,
  setIsShow,
  title = "삭제하시겠습니까?",
  content,
  deleteBtnText = "삭제",
  activeFunc,
}: ConfirmModalType) => {
  const handleModal = () => {
    if (activeFunc) activeFunc();
    setIsShow(false);
  };
  return (
    <>
      {isShow && (
        <div
          onClick={() => setIsShow(false)}
          className="fixed left-0 z-[100] top-0 flex justify-center items-center w-full h-full bg-[#00000050]"
        >
          <div
            onClick={(e) => e.stopPropagation()}
            className=" bg-white rounded-[10px]  items-center p-[60px] "
          >
            <div className="w-[400px] flex flex-col justify-center gap-[14px]">
              <DeleteBoxIcon className="m-auto" />

              <div className="text-secondary-dGrayDark  text-[18px] m-auto">
                {title}
              </div>
              <div>{content}</div>

              <div className="flex text-context-bold w-full  gap-[10px]">
                <button
                  className="p-[12px] w-full bg-status-error text-primary-white rounded-[8px]"
                  onClick={handleModal}
                >
                  {deleteBtnText}
                </button>
                <button
                  className="p-[12px] w-full text-white bg-secondary-dGrayLight rounded-[8px]"
                  onClick={() => setIsShow(false)}
                >
                  취소
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ConfirmModal;
