// hooks
import { useState } from 'react';
// utils
import { pwVerifyFunc } from 'utils/verifyFunc/userInfoVerify';
import { ENDPOINT } from 'utils/consts/apiEndpoint';
// commons
import { showToast } from 'components/toast/Default';
// api
import { useApiMutation } from 'hooks/useDynamicApi';

interface PasswordChangeType {
    modalBtn: React.ReactNode;
}
const PasswordChange = ({ modalBtn }: PasswordChangeType) => {
    const [isShow, setIsShow] = useState(false);
    const [newPassword, setNewPassword] = useState({
        value: '',
        state: 'default',
    });
    const [newRePassword, setNewRePassword] = useState({
        value: '',
        state: 'default',
    });
    // handle
    const handlePW = () => {
        if (!pwVerifyFunc(newPassword.value)) {
            setNewPassword((prev) => ({
                ...prev,
                state: 'error',
            }));
            showToast(
                `비밀번호는 8자 이상 15자 이하이며, 최소 하나의 문자와 하나의 숫자를 포함해야 합니다.`,
                'warning'
            );
        } else if (newPassword.value !== newRePassword.value) {
            setNewRePassword((prev) => ({
                ...prev,
                state: 'error',
            }));
            showToast(`비밀번호가 일치하지 않습니다`, 'warning');
        } else {
            patchPlayerProfile.mutate({
                newPassword: newPassword,
                newPasswordRepeat: newRePassword,
            });
        }
    };
    // useApiMutation
    const patchPlayerProfile = useApiMutation(ENDPOINT.user.password_initial, 'patch', {
        onSuccess: (data) => {
            showToast('비밀번호가 변경되었습니다.', 'success');
        },
        onError: (error) => {
            // 에러 메시지 추출
            const errorMessage = error?.data?.detailedMessage || error || '비밀번호 변경 실패했습니다.';
            showToast(`${errorMessage}`, 'error');
        },
    });

    return (
        <div>
            <div onClick={() => setIsShow(!isShow)}>{modalBtn}</div>
            {isShow && (
                <div className="fixed inset-0 flex items-center justify-center w-screen h-screen bg-[#00000050]">
                    <div className="bg-primary-white rounded-[10px] p-[30px] flex flex-col gap-[14px] w-[400px]">
                        <div className="text-[20px] font-bold">비밀번호 변경</div>
                        <input
                            placeholder="새 비밀번호"
                            type="password"
                            value={newPassword.value || ''} // null 체크
                            onChange={(e) =>
                                setNewPassword((prev) => ({
                                    ...prev,
                                    value: e.target.value,
                                    state: 'default',
                                }))
                            }
                            className={`${
                                newPassword.state === 'error' ? 'inputErrorStyle' : 'inputDefaultStyle'
                            } inputCommonStyle`}
                        />
                        <input
                            placeholder="새 비밀번호 재확인"
                            type="password"
                            value={newRePassword.value || ''} // null 체크
                            onChange={(e) => setNewRePassword((prev) => ({ ...prev, value: e.target.value }))}
                            className={`${
                                newRePassword.state === 'error' ? 'inputErrorStyle' : 'inputDefaultStyle'
                            } inputCommonStyle`}
                        />
                        <div className="flex justify-end gap-[4px] font-bold">
                            <button
                                onClick={() => setIsShow(false)}
                                className="px-[14px] py-[7px] rounded-[8px] bg-secondary-lGrayLight"
                            >
                                취소
                            </button>
                            <button
                                onClick={handlePW}
                                className="px-[14px] py-[7px] rounded-[8px] bg-primary-purple text-primary-white"
                            >
                                변경
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default PasswordChange;
