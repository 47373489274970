// hooks
import { useState } from 'react';
// api
import { useApiMutation } from 'hooks/useDynamicApi';
// commons
import Dropdown from 'components/dropdown/Default';
import { showToast } from 'components/toast/Default';
// admin components
import TeamSearchModal from 'components/modals/TeamSearchModal';
// utils
import { regionOptions, roleOptions, staffRoleOptions } from 'utils/consts/userInfo';
import { ENDPOINT } from 'utils/consts/apiEndpoint';
// icons
import { ReactComponent as SearchIcon } from 'assets/svgs/search.svg';
import { ReactComponent as CloseIcon } from 'assets/svgs/close.svg';

const RoleChangeCard = () => {
    const [userId, setUserId] = useState('');
    const [options, setOptions] = useState({
        name: '',
        region: '',
        role: '',
        staffRole: '',
        teamInfo: [] as teamType[], // 팀 정보 배열로 관리
    });
    const handleOptions = (updateData: any) => {
        setOptions((prev: any) => ({
            ...prev,
            ...updateData,
        }));
    };

    // 팀 추가 핸들러
    const handleTeam = (team: teamType) => {
        if (!options?.teamInfo?.find((info) => info?.teamId === team?.teamId)) {
            setOptions({
                ...options,
                teamInfo: [...options.teamInfo, team],
            });
        }
    };
    // 태그 삭제 핸들러
    const removeTag = (id: string) => {
        setOptions({
            ...options,
            teamInfo: options.teamInfo.filter((team) => team.teamId !== id),
        });
    };
    // useApiMutation
    const patchRole = useApiMutation(ENDPOINT.user.role(userId), 'patch', {
        onSuccess: (data) => {
            showToast('사용자 권한이 부여되었습니다.', 'success');
        },
        onError: (error) => {
            // 에러 메시지 추출
            const errorMessage = error?.data?.detailedMessage || error || '사용자 권한이 부여에 실패했습니다.';
            showToast(`${errorMessage}`, 'error');
        },
    });
    const updateRole = () => {
        if (!patchRole?.isPending) {
            showToast(`권한을 부여하는 중입니다.`, 'info');

            patchRole?.mutate({
                name: options?.name,
                region: options?.region,
                role: options?.role,
                staffRole: options?.staffRole ? options?.staffRole : null,
                teamIds: options?.teamInfo.map((team) => Number(team.teamId)), // teamId 값만 추출하여 숫자 배열로 변환
            });
        }
    };

    return (
        <div className="bg-white p-[30px] rounded-[10px]">
            <div className="text-subtitle-small-bold text-secondary-dGrayDark flex gap-[20px] items-center">
                <div>유저 권한 부여</div>
            </div>
            <div className="flex gap-[10px] mt-[20px]">
                <div className="flex items-center w-[300px] gap-[10px]">
                    <div className="shrink-0 text-context-bold text-center w-[60px]">USER ID</div>
                    <div className="items-center flex gap-[5px] w-[300px]">
                        <input
                            placeholder="userId"
                            value={userId}
                            onChange={(e) => setUserId(e.target.value)}
                            className="inputDefaultStyle p-[12px] w-full h-[44px] text-context-regular rounded-[8px] text-black"
                        />
                    </div>
                </div>
                <div className="flex items-center w-[300px] gap-[10px]">
                    <div className="shrink-0 text-context-bold text-center w-[60px]">이름</div>
                    <div className="items-center flex gap-[5px] w-[300px]">
                        <input
                            placeholder="이름"
                            value={options?.name}
                            onChange={(e) => handleOptions({ name: e.target.value })}
                            className="inputDefaultStyle p-[12px] w-full h-[44px] text-context-regular rounded-[8px] text-black"
                        />
                    </div>
                </div>
                <div className="flex items-center w-[300px] gap-[10px]">
                    <div className="shrink-0 text-context-bold text-center w-[60px]">지역</div>
                    <Dropdown
                        placeholder="지역"
                        options={regionOptions}
                        value={options?.region}
                        onChange={(value) => handleOptions({ region: value })}
                        className="w-[300px]"
                    />
                </div>
                <div className="flex items-center w-[300px] gap-[10px]">
                    <div className="shrink-0 text-context-bold text-center w-[60px]">역할</div>
                    <Dropdown
                        placeholder="역할"
                        options={roleOptions}
                        value={options?.role}
                        onChange={(value) => handleOptions({ role: value })}
                        className="w-[300px]"
                    />
                </div>
            </div>
            {options?.role === 'TEAM_STAFF' && (
                <div className="mt-[20px] flex gap-[10px]">
                    <div className="flex items-center w-[300px] gap-[10px]">
                        <div className="shrink-0 text-context-bold text-center w-[60px]">팀 스태프 역할</div>
                        <Dropdown
                            placeholder="팀 스태프 역할"
                            options={staffRoleOptions}
                            value={options?.staffRole}
                            onChange={(value) => handleOptions({ staffRole: value })}
                            className="w-[300px]"
                        />
                    </div>
                    <div className="flex gap-2">
                        <div className="items-center flex gap-[5px] w-[300px]">
                            <div className="shrink-0 text-context-bold text-center w-[65px]">팀 배정</div>
                            <TeamSearchModal
                                mdoalBtn={
                                    <div className="text-start relative inputDefaultStyle p-[12px] w-[230px] h-[44px] text-context-regular rounded-[8px] text-black">
                                        팀 검색
                                        <SearchIcon className="absolute right-[12px] top-[12px]" />
                                    </div>
                                }
                                handleTeam={handleTeam}
                            />
                        </div>

                        <div className="flex flex-wrap gap-2">
                            {options.teamInfo.map((el) => (
                                <div
                                    key={el?.teamId}
                                    className="flex items-center px-2 text-white bg-black rounded-[8px]"
                                >
                                    <div className="">{el?.teamName}</div>
                                    <button
                                        onClick={() => removeTag(el.teamId!)}
                                        className="font-bold text-white p-[4px]"
                                    >
                                        <CloseIcon className="w-[20px] h-[20px]" />
                                    </button>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            )}
            <div className="flex justify-center mt-[20px]">
                <button
                    onClick={updateRole}
                    className="rounded-[8px] bg-primary-purple text-white text-context-bold p-[12px] w-[300px]"
                >
                    권한 부여
                </button>
            </div>
        </div>
    );
};

export default RoleChangeCard;
interface teamType {
    ageGroup: string;
    bestSkill: null | string;
    measurementDate: null | string;
    memberCount: null | number;
    overallScore: null | number;
    region: null | string;
    teamId: string;
    teamName: string;
    type: null | string;
    worstSkill: null | string;
}
