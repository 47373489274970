// hooks
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
// api
import { useApiQuery } from "hooks/useDynamicApi";
// common
import Dropdown from "components/dropdown/Default";
// utils
import { useParamNavigate } from "hooks/useDynamicNavigate";
import {
  dropDownFormChanger,
  dropDownFormChangerType,
} from "utils/formChanger";
import { ENDPOINT_TEAM } from "utils/consts/apiEndpoint";
// redux
import { RootState } from "store";
import { useSelector } from "react-redux";
/// icons
import { ReactComponent as HomeIcon } from "assets/svgs/home.svg";

const RatingSubHeader = ({
  period,
}: {
  period: { label: string; value: string };
}) => {
  const { staffRole } = useSelector((state: RootState) => state.userInfo);
  const [searchParams] = useSearchParams();
  const handleParamNavigate = useParamNavigate();
  const getAgeGroup = useApiQuery(
    ["ageGroup"],
    ENDPOINT_TEAM.staff.team.ageGroups,
    "get"
  );
  const paramYear = searchParams.get("year") || "";

  const [teamName, setTeamName] = useState("TEAM");
  const [ageGroup, setAgeGroup] = useState("");
  const [ageGroupOpts, setAgeGroupsOpts] = useState<dropDownFormChangerType[]>(
    []
  );

  useEffect(() => {
    if (getAgeGroup?.isSuccess && getAgeGroup?.data) {
      const { teamName, ageGroups } = getAgeGroup?.data;
      setTeamName(teamName);
      setAgeGroup(ageGroups[0]);
      const newAgeGroupOpts = dropDownFormChanger(ageGroups);
      setAgeGroupsOpts(newAgeGroupOpts);
    }
  }, [getAgeGroup?.data]);
  useEffect(() => {
    if (ageGroup)
      handleParamNavigate({
        ageGroup: ageGroup,
      });
  }, [ageGroup]);
  const handleHomeNavigate = () => {
    if (ageGroup && paramYear)
      handleParamNavigate(
        {
          ageGroup: ageGroup,
          year: paramYear,
        },
        false,
        `/staff/control/lineUp`
      );
  };

  return (
    <div className="h-[80px]">
      <div className="flex items-center justify-between">
        <div className="flex items-start justify-center h-[79px] flex-col gap-[15px]">
          <div className="flex text-white items-center gap-[8px]">
            <button onClick={handleHomeNavigate}>
              <HomeIcon className="text-white mr-[6px]" />
            </button>
            <div className="w-[3px] h-[3px] rounded-full bg-secondary-lGrayDark"></div>

            <div>선수단</div>
            <div className="w-[3px] h-[3px] rounded-full bg-secondary-lGrayDark"></div>

            <div>선수평가</div>
          </div>
          {staffRole !== "OFFICE" ? (
            <div className="flex gap-[10px]">
              <div className="text-primary-white text-[26px] font-bold">
                선수단
              </div>
              <div className="text-[26px] text-primary-green font-bold">
                {ageGroup}
              </div>
            </div>
          ) : (
            <div className="flex items-start justify-center h-[79px] flex-col gap-[15px]">
              <div className="flex items-center gap-[6px]">
                <Dropdown
                  options={ageGroupOpts}
                  value={ageGroup}
                  placeholder="연령"
                  onChange={setAgeGroup}
                  className={
                    " text-label-regular border rounded-[8px] border-primary-white"
                  }
                  innerClassName="px-[6px] py-[8px] text-label-regular"
                />

                <div className="text-[26px] text-white font-bold">선수단</div>
              </div>
            </div>
          )}
        </div>

        <div className="flex gap-[80px] ">
          {/*
                    2차 개발
                    <div className=" flex gap-[18px]  h-[48px] text-[#868686]">
                        <div className="w-[5px] bg-primary-green rounded-[5px]"></div>
                        <div className="flex gap-[80px] items-center">
                            <div
                                className="w-[60px] text-primary-white font-semibold leading-[24px]"
                                style={{ wordBreak: 'keep-all', overflowWrap: 'break-word' }} //한국어 띄어쓰기 단위로 줄바꿈
                            >
                                {period?.label}
                            </div>
                            <div className="flex items-center gap-[36px]">
                                <div className="flex items-center gap-[10px]">
                                    <span className="text-[22px]">{'평균 등급'}</span>
                                    <span className="text-[50px] text-primary-green font-bold">{60}</span>
                                </div>
                                <div className="flex items-center gap-[10px]">
                                    <span className="text-[22px]">{'리그 출전'}</span>
                                    <span className="text-[50px] text-primary-white font-bold">{7.67}</span>
                                </div>
                                <div className="flex items-center gap-[10px]">
                                    <span className="text-[22px]">{'평가 완료'}</span>
                                    <span className="text-[50px] text-primary-white font-bold">{1}</span>
                                </div>
                            </div>
                        </div>
                    </div> */}
        </div>
      </div>
    </div>
  );
};

export default RatingSubHeader;
