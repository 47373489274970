// hooks
import { useState } from "react";
// admin component
import VideoSubHeader from "pages/player/video/VideoSubHeader";
import VideoCard from "pages/admin/video/VideoCard";
// commons
import EmptyBar from "components/emptyBar";
import Pagination from "components/pagenation";
import Preparing from "components/loading/Preparing";
const Video = () => {
  const [page, setPage] = useState(1);

  return (
    <div className="wrapper">
      <VideoSubHeader />
      <EmptyBar customStyle="h-[35px]" />
      <div className="p-[30px] relative bg-primary-white rounded-[10px] flex flex-wrap gap-[20px]">
        <Preparing title="추천 영상 준비중입니다." />
        {videos?.map((el) => (
          <VideoCard key={el.id} {...el} />
        ))}
        <EmptyBar customStyle="h-[32px]" />
        <div className="m-auto">
          <Pagination
            page={page}
            size={10}
            totalElement={10}
            totalPages={10}
            onPageChange={setPage}
          />
        </div>
      </div>
      <EmptyBar customStyle="h-[20px]" />
    </div>
  );
};

export default Video;

export const videos = [
  { id: 1, title: "DAY1 무릎 추천 영상", tags: ["무릎", "하체훈련", "기초"] },
  { id: 2, title: "DAY2 무릎 추천 영상", tags: ["무릎", "하체훈련", "기초"] },
  { id: 3, title: "DAY3 무릎 추천 영상", tags: ["무릎", "하체훈련", "기초"] },
  { id: 4, title: "DAY4 무릎 추천 영상", tags: ["무릎", "하체훈련", "기초"] },
  { id: 5, title: "DAY5 무릎 추천 영상", tags: ["무릎", "하체훈련", "기초"] },
  { id: 6, title: "DAY6 무릎 추천 영상", tags: ["무릎", "하체훈련", "기초"] },
];
