// hooks
import { useNavigate, useSearchParams, useParams } from "react-router-dom";
import { useParamNavigate } from "hooks/useDynamicNavigate";
// icons
import { ReactComponent as HomeIcon } from "assets/svgs/home.svg";
import { ReactComponent as CircleArrowIcon } from "assets/svgs/circleArrow.svg";
// redux
import { RootState } from "store";
import { useSelector } from "react-redux";
interface ReportHeaderType {
  wins: number;
  draws: number;
  losses: number;
  winRate: number;
  winRateChange: number;
}
const ReportHeader = ({ userInfo }: { userInfo?: any[] }) => {
  const [searchParams] = useSearchParams();
  const { reportType } = useParams();

  const handleParamNavigate = useParamNavigate();
  const { staffRole } = useSelector((state: RootState) => state.userInfo);
  const navigate = useNavigate();

  const handleHomeNavigate = (param: string) => {
    handleParamNavigate(
      {
        control: param,
      },
      false,
      `/admin/control`
    );
  };

  return (
    <div className="wrapper">
      <div className="flex items-center justify-between">
        <div className="flex items-start justify-center h-[79px] flex-col gap-[15px]">
          <div className="flex gap-[8px] items-center">
            <button onClick={() => handleHomeNavigate("player")}>
              <HomeIcon className="text-white mr-[6px]" />
            </button>
            <div className="bg-secondary-lGrayDark w-[3px] h-[3px] rounded-full text-label-regular"></div>
            <div className="text-white text-label-regular">리포트</div>
            {reportType && (
              <div className="flex gap-[8px] items-center">
                <div className="bg-secondary-lGrayDark w-[3px] h-[3px] rounded-full text-label-regular"></div>
                <div className="text-white text-label-regular">{"발행"}</div>
              </div>
            )}
          </div>
          <div className="flex items-center gap-[6px]">
            <button onClick={() => navigate(-1)}>
              <CircleArrowIcon className="text-white" />
            </button>
            <div className="text-[26px] text-white font-bold">
              {reportType === "group"
                ? "그룹 리포트"
                : reportType === "personal"
                ? "개인 리포트"
                : "리포트"}
            </div>
          </div>
        </div>
        {!reportType && (
          <button
            onClick={() =>
              userInfo?.length && userInfo?.length > 1
                ? navigate("/admin/report/group")
                : navigate("/admin/report/personal")
            }
            className="text-context-bold flex bg-primary-green text-primary-black rounded-[8px] w-[146px] h-[44px] p-[12px]"
          >
            리포트 발행하기
            <CircleArrowIcon className="rotate-180" />
          </button>
        )}
      </div>
    </div>
  );
};

export default ReportHeader;
