// hooks
import { useState, useEffect, useMemo } from 'react';
import { debounce } from 'lodash';
// api
import { useApiMutation } from 'hooks/useDynamicApi';
// commons
import SqureCheckbox from 'components/checkbox/SqureCheckbox';
import { showToast } from 'components/toast/Default';
// utils
import { ENDPOINT_MEASUREMENT } from 'utils/consts/apiEndpoint';
// icons
import { ReactComponent as EditPenIcon } from 'assets/svgs/editPen.svg';
import { ReactComponent as ResetIcon } from 'assets/svgs/reset.svg';
// utils
import { shootingOneStateMatcher } from 'utils/matchers/measurementMatcher';
const ShootingOne = ({
    measurementId,
    shootingOneId,
    playerName,
    shooting1Result,
    shooting2Result,
    shooting3Result,
    shooting4Result,
    shooting5Result,
    shooting6Result,
}: ShootingOneType) => {
    const attempState = ['SIGNAL_FAIL', 'ATTEMPT', 'TAPE_HIT', 'SUCCESS'];
    const [editShootingOneInfo, setEditShootingOneInfo] = useState({
        measurementId: measurementId,
        shootingOneId: shootingOneId,
        playerName: playerName,
        shooting1Result: shooting1Result,
        shooting2Result: shooting2Result,
        shooting3Result: shooting3Result,
        shooting4Result: shooting4Result,
        shooting5Result: shooting5Result,
        shooting6Result: shooting6Result,
    });
    const handleEditShootingOneInfo = (updateData: any) => {
        setEditShootingOneInfo((prev) => ({
            ...prev,
            ...updateData,
        }));
    };
    // useApiMutation
    const patchShootingOneMeasurement = useApiMutation(
        ENDPOINT_MEASUREMENT.shootingOne(editShootingOneInfo?.shootingOneId),
        'patch',
        {
            onSuccess: (data) => {
                // showToast("슈팅1 기록을 수정했습니다.", "success");
            },
            onError: (error) => {
                const errorMessage = error?.data?.detailedMessage || error || '슈팅1 기록수정에 실패했습니다.';
                showToast(errorMessage, 'error');
            },
        }
    );
    const patchShootingOneReset = useApiMutation(
        ENDPOINT_MEASUREMENT.shootingOneReset(editShootingOneInfo?.shootingOneId),
        'patch',
        {
            onSuccess: (data) => {
                showToast('슈팅1 기록을 초기화했습니다.', 'success');
                handleEditShootingOneInfo({
                    shooting1Result: 'SIGNAL_FAIL',
                    shooting2Result: 'SIGNAL_FAIL',
                    shooting3Result: 'SIGNAL_FAIL',
                    shooting4Result: 'SIGNAL_FAIL',
                    shooting5Result: 'SIGNAL_FAIL',
                    shooting6Result: 'SIGNAL_FAIL',
                });
            },
            onError: (error) => {
                const errorMessage = error?.data?.detailedMessage || error || '슈팅1 기록 초기화에 실패했습니다.';
                showToast(errorMessage, 'error');
            },
        }
    );
    const editShootingOneMeasurement = () => {
        if (!patchShootingOneMeasurement?.isPending) {
            patchShootingOneMeasurement.mutate({
                shooting1Result: editShootingOneInfo?.shooting1Result,
                shooting2Result: editShootingOneInfo?.shooting2Result,
                shooting3Result: editShootingOneInfo?.shooting3Result,
                shooting4Result: editShootingOneInfo?.shooting4Result,
                shooting5Result: editShootingOneInfo?.shooting5Result,
                shooting6Result: editShootingOneInfo?.shooting6Result,
            });
        }
    };
    const editShootingOneReset = () => {
        if (!patchShootingOneReset?.isPending) {
            showToast('슈팅1 측정데이터를 초기화하는 중입니다.', 'info');
            patchShootingOneReset.mutate({});
        }
    };

    // debounce
    const debouncedPatchShootingOne = useMemo(
        () =>
            debounce(() => {
                editShootingOneMeasurement();
            }, 500), // 1초 딜레이
        [patchShootingOneMeasurement]
    );
    useEffect(() => {
        if (
            editShootingOneInfo?.shooting1Result === shooting1Result &&
            editShootingOneInfo?.shooting2Result === shooting2Result &&
            editShootingOneInfo?.shooting3Result === shooting1Result &&
            editShootingOneInfo?.shooting4Result === shooting4Result &&
            editShootingOneInfo?.shooting5Result === shooting5Result &&
            editShootingOneInfo?.shooting6Result === shooting6Result
        ) {
            return;
        }
        debouncedPatchShootingOne();
        return () => {
            debouncedPatchShootingOne.cancel();
        };
    }, [editShootingOneInfo]);
    return (
        <>
            {attempState?.map((el, idx) => (
                <div
                    key={`${attempState}-${idx}`}
                    className={`w-full flex justify-between rounded-[8px] h-[50px] ${
                        idx % 2 === 1 ? 'bg-secondary-mGrayLight' : 'bg-primary-white'
                    }`}
                >
                    <div className="flex items-center justify-center flex-1">{shootingOneStateMatcher(el)}</div>
                    <SqureCheckbox
                        checked={editShootingOneInfo?.shooting1Result === el}
                        onChange={() => handleEditShootingOneInfo({ shooting1Result: el })}
                        className="justify-center flex-1"
                    />
                    <SqureCheckbox
                        checked={editShootingOneInfo?.shooting2Result === el}
                        onChange={() => handleEditShootingOneInfo({ shooting2Result: el })}
                        className="justify-center flex-1"
                    />
                    <SqureCheckbox
                        checked={editShootingOneInfo?.shooting3Result === el}
                        onChange={() => handleEditShootingOneInfo({ shooting3Result: el })}
                        className="justify-center flex-1"
                    />
                    <SqureCheckbox
                        checked={editShootingOneInfo?.shooting4Result === el}
                        onChange={() => handleEditShootingOneInfo({ shooting4Result: el })}
                        className="justify-center flex-1"
                    />
                    <SqureCheckbox
                        checked={editShootingOneInfo?.shooting5Result === el}
                        onChange={() => handleEditShootingOneInfo({ shooting5Result: el })}
                        className="justify-center flex-1"
                    />
                    <SqureCheckbox
                        checked={editShootingOneInfo?.shooting6Result === el}
                        onChange={() => handleEditShootingOneInfo({ shooting6Result: el })}
                        className="justify-center flex-1"
                    />
                </div>
            ))}
            <div className="flex justify-end text-secondary-lGrayDark m-[10px] gap-[10px]">
                <button
                    className="flex items-center gap-[5px]"
                    onClick={editShootingOneReset}
                >
                    초기화
                    <ResetIcon className=" h-[20px] m-auto" />
                </button>
            </div>
        </>
    );
};
export default ShootingOne;
interface ShootingOneType {
    measurementId: string;
    shootingOneId: string;
    playerName: string;
    shooting1Result: 'SIGNAL_FAIL' | 'ATTEMPT' | 'TAPE_HIT' | 'SUCCESS';
    shooting2Result: 'SIGNAL_FAIL' | 'ATTEMPT' | 'TAPE_HIT' | 'SUCCESS';
    shooting3Result: 'SIGNAL_FAIL' | 'ATTEMPT' | 'TAPE_HIT' | 'SUCCESS';
    shooting4Result: 'SIGNAL_FAIL' | 'ATTEMPT' | 'TAPE_HIT' | 'SUCCESS';
    shooting5Result: 'SIGNAL_FAIL' | 'ATTEMPT' | 'TAPE_HIT' | 'SUCCESS';
    shooting6Result: 'SIGNAL_FAIL' | 'ATTEMPT' | 'TAPE_HIT' | 'SUCCESS';
}
