// hooks
import { useState, useEffect, useMemo } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { debounce } from 'lodash';
// utils
import { ENDPOINT_REPORT } from 'utils/consts/apiEndpoint';
// api
import { useApiMutation } from 'hooks/useDynamicApi';
import { showToast } from 'components/toast/Default';

const StartingRating = ({
    lineupId,
    playerId,
    playerName,
    uniformNumber,
    position,
    rating,
    managerComment,
}: StartingRatingType) => {
    const { id } = useParams();

    const [editPlayerName, setEditPlayerName] = useState(playerName);
    const [editManagerComment, setEditManagerComment] = useState(managerComment || '');
    const [editRating, setEditRating] = useState(rating);

    return (
        <div className="flex gap-[10px]">
            <div className="w-[200px]">
                <input
                    readOnly
                    value={editPlayerName}
                    className="inputCommonStyle inputDefaultStyle"
                />
            </div>
            <div className="w-[569px]">
                <input
                    readOnly
                    type="text"
                    maxLength={500}
                    value={editManagerComment}
                    className="inputCommonStyle inputDefaultStyle"
                />
            </div>
            <div className="w-[200px]">
                <input
                    readOnly
                    type="number"
                    max={10}
                    min={0}
                    value={editRating}
                    className="inputCommonStyle inputDefaultStyle"
                />
            </div>
        </div>
    );
};

export default StartingRating;

interface StartingRatingType {
    lineupId: string;
    playerId: string;
    playerName: string;
    uniformNumber: string;
    position: string;
    rating: string;
    managerComment: string | null;
}
