// hooks
import { useState, useEffect, useRef } from "react";
import {
  useNavigate,
  useSearchParams,
  useParams,
  useLocation,
} from "react-router-dom";
import { useParamNavigate } from "hooks/useDynamicNavigate";
// api
import { useApiFormDataMutation } from "hooks/useDynamicApi";
// icons
import { ReactComponent as HomeIcon } from "assets/svgs/home.svg";
import { ReactComponent as CircleArrowIcon } from "assets/svgs/circleArrow.svg";
import { ReactComponent as PlusIcon } from "assets/svgs/plus.svg";
// redux
import { RootState } from "store";
import { useSelector } from "react-redux";
// utils
import { skillStateBgMatcher } from "utils/matchers/measurementMatcher";
import { ENDPOINT_TEAM } from "utils/consts/apiEndpoint";
// commons
import { showToast } from "components/toast/Default";

const TeamDetailSubHeader = ({ userInfo }: { userInfo?: any[] }) => {
  const [searchParams] = useSearchParams();
  const { teamId = "" } = useParams();
  const location = useLocation().pathname;
  const locationList = location?.split("/");

  const handleParamNavigate = useParamNavigate();
  const { ageGroup } = useSelector((state: RootState) => state.teamInfo);
  const navigate = useNavigate();

  const handleHomeNavigate = (param: string) => {
    handleParamNavigate(
      {
        control: param,
      },
      false,
      `/admin/control`
    );
  };
  const patchTeamEmblem = useApiFormDataMutation(
    ENDPOINT_TEAM.emblem(teamId),
    "patch",
    {
      onSuccess: (data) => {
        showToast("팀 엠블럼을 등록했습니다.", "success");
        // setIsEdit(false);
      },
      onError: (error) => {
        const errorMessage =
          error?.data?.detailedMessage ||
          error ||
          "팀 엠블럼을 등록 실패했습니다.";
        showToast(`${errorMessage}`, "error");
      },
    }
  );

  // 이미지 업로드
  const [previewImage, setPreviewImage] = useState<string | null>(null);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const handleImageClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click(); // 숨겨진 input 클릭
    }
  };
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      // 프리뷰 이미지 설정
      const reader = new FileReader();
      reader.onload = () => {
        setPreviewImage(reader.result as string);
      };
      reader.readAsDataURL(file);

      // FormData 생성 및 API 요청
      const formData = new FormData();
      formData.append("image", file);
      patchTeamEmblem.mutate(formData);
    }
  };
  return (
    <div className="wrapper">
      <div className="flex items-center justify-between">
        <div className="flex items-start justify-center h-[79px] flex-col gap-[15px]">
          <div className="flex gap-[8px] items-center">
            <button onClick={() => handleHomeNavigate("player")}>
              <HomeIcon className="text-white mr-[6px]" />
            </button>
            <div className="bg-secondary-lGrayDark w-[3px] h-[3px] rounded-full text-label-regular"></div>
            <div className="text-white text-label-regular">유저관리</div>
            <div className="bg-secondary-lGrayDark w-[3px] h-[3px] rounded-full text-label-regular"></div>
            <div className="text-white text-label-regular">팀관리</div>
          </div>
          <div className="flex items-center gap-[6px]">
            {locationList[4] && (
              <button onClick={() => navigate(-1)}>
                <CircleArrowIcon className="text-white" />
              </button>
            )}
            {locationList[4] ? (
              <div className="text-primary-white text-[26px] font-bold">
                팀명
              </div>
            ) : (
              <div className="flex gap-[10px]">
                <div className="text-[26px] text-primary-green font-bold">
                  {ageGroup}
                </div>
                <div className="text-primary-white text-[26px] font-bold">
                  선수단
                </div>
              </div>
            )}
          </div>
        </div>

        <button
          onClick={handleImageClick}
          className="bg-primary-white p-[10px] gap-[2px] w-[79px] h-[79px] justify-center rounded-[8px] flex items-center text-secondary-lGrayDark "
        >
          {previewImage ? (
            <img
              src={previewImage}
              alt="previewImage"
              className="object-cover"
            />
          ) : (
            <>
              <PlusIcon />
              <span className="text-[13px] font-bold">엠블럼</span>
              <input
                type="file"
                accept=".jpg, .jpeg, .png, .webp, .heic, .heif"
                className="hidden "
                ref={fileInputRef}
                onChange={handleFileChange}
              />
            </>
          )}
        </button>
      </div>
    </div>
  );
};

export default TeamDetailSubHeader;
