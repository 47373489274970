const ProfileCard = ({ playerInfo, physicalInfo }: any) => {
    return (
        <div className="rounded-[14px] bg-[#f8f8f8] p-[18px] flex gap-[20px]">
            <div className="w-[636px]  flex flex-col gap-[5px]">
                <div className="text-title-small-bold">{playerInfo?.playerName}</div>
                <div className="flex gap-[10px]">
                    <span className="text-context-regular text-[#868686]">생년월일</span>
                    <span className="text-context-bold">{playerInfo?.birthday}</span>
                </div>
            </div>
            <div className="w-[636px] flex gap-[12px]">
                <div className="w-[204px] ">
                    <div className="text-[20px] font-semibold text-[#868686]">신장</div>
                    <div className="flex gap-[5px] items-center">
                        <span className="text-title-small-bold">{physicalInfo?.height || '-'}</span>
                        <span className="text-[22px] text-[#7c7c7c]">cm</span>
                    </div>
                </div>
                <div className="w-[204px] ">
                    <div className="text-[20px] font-semibold text-[#868686]">체중</div>
                    <div className="flex gap-[5px] items-center">
                        <span className="text-title-small-bold">{physicalInfo?.weight || '-'}</span>
                        <span className="text-[22px] text-[#7c7c7c]">kg</span>
                    </div>
                </div>
                <div className="w-[204px] ">
                    <div className="text-[20px] font-semibold text-[#868686]">체지방률</div>
                    <div className="flex gap-[5px] items-center">
                        <span className="text-title-small-bold">{physicalInfo?.bodyFatPercentage || '-'}</span>
                        <span className="text-[22px] text-[#7c7c7c]">%</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProfileCard;
