// hooks
import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
// assets
import aisoccerWhiteFont from 'assets/logos/font-logo/aisoccer-white-font.webp';
// icons
import { ReactComponent as BarChartIcon } from 'assets/svgs/barChart.svg';
import { ReactComponent as InfiniteIcon } from 'assets/svgs/infinite.svg';
import { ReactComponent as GroupIcon } from 'assets/svgs/group.svg';
import { ReactComponent as ProfileIcon } from 'assets/svgs/profile.svg';
import { ReactComponent as ArrowIcon } from 'assets/svgs/arrow.svg';
import VideoIcon from 'assets/svgs/video.png';
import VideoActiveIcon from 'assets/svgs/videoActive.png';
// common components
import GreenToggle from 'components/toggle/GreenToggle';
import MypageNavigateModal from 'components/modals/MypageNavigateModal';
// redux
import { RootState } from 'store'; // Redux Store 타입 정의
import { useSelector } from 'react-redux';
const MainSubHeader = () => {
    const { id } = useSelector((state: RootState) => state.userInfo); // 인증 상태를 token 유무로 판단
    const initialSubTab = { title: '개요', value: 'outline', link: '/player/measurement' };
    const TEAM_NAME = localStorage.getItem('teamName');
    const NAME = localStorage.getItem('name');
    const location = useLocation().pathname;
    const locataionList = location.split('/');
    const [tab, setTab] = useState(locataionList[2]);
    const [mypageModal, setMypageModal] = useState(false);
    const [selectedSubTab, setSelectedSubTab] = useState<selectedType>(initialSubTab);

    const navigate = useNavigate();
    const tabList = [
        {
            title: '측정',
            link: '/player/measurement',
            value: 'measurement',
            icon: <BarChartIcon />,
            subTabOptions: [
                { title: '개요', value: 'outline', link: '/player/measurement' },
                { title: '분석', value: 'analysis', link: '/player/measurement/analysis/1' },
            ],
        },
        {
            title: '에잇라운드',
            link: `/player/eightRound/report/${id}`,
            value: 'eightRound',
            icon: <InfiniteIcon />,
        },
        {
            title: '팀가입',
            link: '/player/join',
            value: 'join',
            icon: <GroupIcon />,
        },
        {
            title: '추천영상',
            link: '/player/video',
            value: 'video',
            icon:
                tab === 'video' ? (
                    <img
                        src={VideoActiveIcon}
                        alt={'VideoActiveIcon'}
                    />
                ) : (
                    <img
                        src={VideoIcon}
                        alt="VideoIcon"
                    />
                ),
        },
    ];
    useEffect(() => {
        if (locataionList[2]) {
            setTab(locataionList[2]);
        }
    }, [locataionList]);

    return (
        <header className="h-[100px] flex justify-between items-center">
            <img
                onClick={() => {
                    navigate('/player/measurement');
                    setSelectedSubTab(initialSubTab);
                }}
                src={aisoccerWhiteFont}
                alt="aisoccerWhiteFont"
                className="w-[173px]"
            />
            <div className="flex w-[540px] items-center text-secondary-mGrayDark text-subtitle-small-bold gap-[40px]">
                {tabList?.map((el) => (
                    <div
                        key={el.value}
                        className="flex gap-[20px] items-center "
                    >
                        <button
                            className={`flex gap-[10px] items-center ${
                                el.value === tab ? 'text-primary-green' : 'text-secondary-mGrayDark'
                            }`}
                            onClick={() => {
                                navigate(el.link);
                                setSelectedSubTab(initialSubTab);
                            }}
                        >
                            {el.icon}
                            <span className="">{el.title}</span>
                        </button>
                        {/* {el?.subTabOptions && tab === el.value && (
                            <GreenToggle
                                options={el.subTabOptions}
                                selected={selectedSubTab}
                                setSelected={(selected) => {
                                    setSelectedSubTab(selected);
                                    if (selected.link) {
                                        navigate(selected.link);
                                    }
                                }}
                            />
                        )} */}
                    </div>
                ))}
            </div>
            <div className="relative">
                <button
                    onClick={() => setMypageModal(!mypageModal)}
                    className="flex gap-[10px] items-center "
                >
                    <ProfileIcon className="w-[40px] h-[40px]" />
                    <div className="flex flex-col items-start font-bold">
                        <div className="text-[14px] text-primary-green">{TEAM_NAME}</div>
                        <div className="text-[18px] text-white">{`${NAME}(선수)`}</div>
                    </div>
                    <ArrowIcon className={`text-white ${mypageModal && 'rotate-180'}`} />
                </button>
                <MypageNavigateModal
                    parentUrl="/player"
                    isShow={mypageModal}
                    setIsShow={setMypageModal}
                />
            </div>
        </header>
    );
};

export default MainSubHeader;

interface selectedType {
    title: string;
    value: string;
    link?: string;
}
interface TabItem {
    title: string;
    link: string;
    value: string;
    icon: React.ReactNode;
    subTabOptions?: selectedType[];
}
