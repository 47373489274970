// hooks
import React, { useRef, useState, MouseEvent, useEffect } from 'react';
import { useReactToPrint } from 'react-to-print';
// admin components
import ReportSubHeader from 'pages/admin/report/ReportHeader';
import GroupReportTable from 'pages/admin/report/reportPrint/groupReport/GroupReportTable';
import DataAnalysisFeedback from 'pages/admin/report/reportPrint/groupReport/DataAnalysisFeedback';
import DataSummaryCard from 'pages/admin/report/reportPrint/groupReport/DataSummaryCard';
import ScoresCard from 'pages/admin/report/reportPrint/groupReport/ScoresCard';
import ComparisonGraphCard from 'pages/admin/report/reportPrint/groupReport/ComparisonGraphCard';
import ComparisonScoreCard from 'pages/admin/report/reportPrint/groupReport/ComparisonScoreCard';
import FeedbackCard from 'pages/admin/report/reportPrint/groupReport/FeedbackCard';
import RecommandExerciseCard from 'pages/admin/report/reportPrint/groupReport/RecommandExerciseCard';

// icons
import { ReactComponent as PrintIcon } from 'assets/svgs/print.svg';

const GroupReport = () => {
    const [dataAnalysisFeedback, setDataAnalysisFeedback] = useState('');
    const [isPrint, setIsPrint] = useState(false);

    const contentRef = useRef<HTMLDivElement>(null);
    const reactToPrintFn = useReactToPrint({
        contentRef, // 이렇게 화살표 함수로 변경
        onBeforePrint: () => {
            setIsPrint(true);
            return Promise.resolve();
        },
        onAfterPrint: () => {
            setIsPrint(false);
            return Promise.resolve();
        },
    });

    useEffect(() => {
        if (isPrint) {
            reactToPrintFn();
        }
    }, [isPrint, reactToPrintFn]);

    const handlePrintClick = () => {
        setIsPrint(true);
    };

    return (
        <div className="wrapper bg-primary-white rounded-[10px] p-[30px]">
            <div
                ref={contentRef}
                className="flex flex-col gap-[20px]"
            >
                <div className="flex justify-between">
                    <div className="text-subtitle-medium-bold text-secondary-dGrayDark flex gap-[20px] items-center">
                        <div>드리블1 측정결과 내용</div>
                        <div className="flex gap-[12px] items-center">
                            <span className="text-context-regular text-[#868686]">24.07.03</span>
                            <span className="text-[#111111] text-context-bold">12회</span>
                        </div>
                    </div>
                    {!isPrint && (
                        <button
                            onClick={() => setIsPrint(true)}
                            className="flex justify-center items-center gap-[10px] w-[110px] h-[44px] rounded-[8px] bg-primary-green text-context-bold"
                        >
                            <span>발행하기</span>
                            <PrintIcon />
                        </button>
                    )}
                </div>
                <DataSummaryCard analysisData={analysisData?.summary} />
                <ScoresCard scoreData={analysisData?.detailPart} />
                <ComparisonGraphCard isPrint={isPrint} />
                <div className="flex gap-[20px]">
                    <ComparisonScoreCard comparisonScore={analysisData?.comparisonScore} />
                    <FeedbackCard feedback={analysisData?.feedback} />
                </div>
                <div className="flex flex-col gap-[10px]">
                    <div className="text-subtitle-medium-bold text-secondary-dGrayDark">전문성 관련 리포트</div>
                    <GroupReportTable
                        columns={columns}
                        rows={rows}
                        average={average}
                    />
                </div>
                <RecommandExerciseCard recommandData={analysisData?.recommandData} />

                {isPrint && !dataAnalysisFeedback ? (
                    <></>
                ) : (
                    <div className="flex flex-col gap-[10px]">
                        <div className="text-subtitle-medium-bold">데이터 분석관 피드백</div>
                        <DataAnalysisFeedback
                            isPrint={isPrint}
                            value={dataAnalysisFeedback}
                            setValue={setDataAnalysisFeedback}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

export default GroupReport;

export const columns = [
    { key: 'index', label: 'No.' },
    { key: 'name', label: '이름' },
    { key: 'ranking', label: '랭크' },
    { key: 'total', label: '전체 점수' },
    { key: 'footAction', label: '잔발' },
    { key: 'bestSpeed', label: '최고 속도' },
    { key: 'averageSpeed', label: '평균 속도' },
    { key: 'accuracy', label: '정확도' },
    { key: 'bounce', label: '이탈율' },
];

export const average = [
    {
        index: '',
        name: '팀평균',
        ranking: '',
        total: 70,
        footAction: 59.1,
        bestSpeed: 59.1,
        averageSpeed: <span className="text-primary-purple">80.5</span>,
        accuracy: <span className="text-status-error">80.5</span>,
        bounce: 80.6,
    },
];

export const rows = [
    {
        index: 1,
        name: '홍길동',
        ranking: 1,
        total: 50,
        footAction: 59.1,
        bestSpeed: 59.1,
        averageSpeed: 80.5,
        accuracy: 70,
        bounce: 80.6,
    },
    {
        index: 1,
        name: '홍길동',
        ranking: 1,
        total: 50,
        footAction: 59.1,
        bestSpeed: 59.1,
        averageSpeed: 80.5,
        accuracy: 70,
        bounce: 80.6,
    },
    {
        index: 1,
        name: '홍길동',
        ranking: 1,
        total: 50,
        footAction: 59.1,
        bestSpeed: 59.1,
        averageSpeed: 80.5,
        accuracy: 70,
        bounce: 80.6,
    },
    {
        index: 1,
        name: '홍길동',
        ranking: 1,
        total: 50,
        footAction: 59.1,
        bestSpeed: 59.1,
        averageSpeed: 80.5,
        accuracy: 70,
        bounce: 80.6,
    },
    {
        index: 1,
        name: '홍길동',
        ranking: 1,
        total: 50,
        footAction: 59.1,
        bestSpeed: 59.1,
        averageSpeed: 80.5,
        accuracy: 70,
        bounce: 80.6,
    },
    {
        index: 1,
        name: '홍길동',
        ranking: 1,
        total: 50,
        footAction: 59.1,
        bestSpeed: 59.1,
        averageSpeed: 80.5,
        accuracy: 70,
        bounce: 80.6,
    },
    {
        index: 1,
        name: '홍길동',
        ranking: 1,
        total: 50,
        footAction: 59.1,
        bestSpeed: 59.1,
        averageSpeed: 80.5,
        accuracy: 70,
        bounce: 80.6,
    },
    {
        index: 1,
        name: '홍길동',
        ranking: 1,
        total: 50,
        footAction: 59.1,
        bestSpeed: 59.1,
        averageSpeed: 80.5,
        accuracy: 70,
        bounce: 80.6,
    },
    {
        index: 1,
        name: '홍길동',
        ranking: 1,
        total: 50,
        footAction: 59.1,
        bestSpeed: 59.1,
        averageSpeed: 80.5,
        accuracy: 70,
        bounce: 80.6,
    },
    {
        index: 1,
        name: '홍길동',
        ranking: 1,
        total: 50,
        footAction: 59.1,
        bestSpeed: 59.1,
        averageSpeed: 80.5,
        accuracy: 70,
        bounce: 80.6,
    },
    {
        index: 1,
        name: '홍길동',
        ranking: 1,
        total: 50,
        footAction: 59.1,
        bestSpeed: 59.1,
        averageSpeed: 80.5,
        accuracy: 70,
        bounce: 80.6,
    },
    {
        index: 1,
        name: '홍길동',
        ranking: 1,
        total: 50,
        footAction: 59.1,
        bestSpeed: 59.1,
        averageSpeed: 80.5,
        accuracy: 70,
        bounce: 80.6,
    },
    {
        index: 1,
        name: '홍길동',
        ranking: 1,
        total: 50,
        footAction: 59.1,
        bestSpeed: 59.1,
        averageSpeed: 80.5,
        accuracy: 70,
        bounce: 80.6,
    },
    {
        index: 1,
        name: '홍길동',
        ranking: 1,
        total: 50,
        footAction: 59.1,
        bestSpeed: 59.1,
        averageSpeed: 80.5,
        accuracy: 70,
        bounce: 80.6,
    },
    {
        index: 1,
        name: '홍길동',
        ranking: 1,
        total: 50,
        footAction: 59.1,
        bestSpeed: 59.1,
        averageSpeed: 80.5,
        accuracy: 70,
        bounce: 80.6,
    },
    {
        index: 1,
        name: '홍길동',
        ranking: 1,
        total: 50,
        footAction: 59.1,
        bestSpeed: 59.1,
        averageSpeed: 80.5,
        accuracy: 70,
        bounce: 80.6,
    },
    {
        index: 1,
        name: '홍길동',
        ranking: 1,
        total: 50,
        footAction: 59.1,
        bestSpeed: 59.1,
        averageSpeed: 80.5,
        accuracy: 70,
        bounce: 80.6,
    },
    {
        index: 1,
        name: '홍길동',
        ranking: 1,
        total: 50,
        footAction: 59.1,
        bestSpeed: 59.1,
        averageSpeed: 80.5,
        accuracy: 70,
        bounce: 80.6,
    },
];

export const analysisData = {
    id: '1',
    number: 1,
    date: '24.10.27',
    score: 82.0,
    increase: 5.7,
    summary: {
        best: '드리블',
        worst: '퍼스트터치',
    },
    feedback:
        '훈련하시느라 수고하셨습니다. 이번 훈련에서는 무릎 점수가 많이 나아진 것으로 보이네요. 지금처럼 무릎을 신경쓰며 트레이닝 해보세요! 다만 스텝의 보폭이 너무 부족합니다.',
    skillAnalysis: {
        best: '드리블',
        bestFeedback:
            '훈련하시느라 수고하셨습니다. 이번 훈련에서는 무릎 점수가 많이 나아진 것으로 보이네요.이번 훈련에서는 무릎 점수가 많이 나아진 것으로 보이네요.이번 훈련에서는 무릎 점수가 많이 나아진 것으로 보이네요.이번 훈련에서는 무릎 점수가 많이 나아진 것으로 보이네요.이번 훈련에서는 무릎 점수가 많이 나아진 것으로 보이네요.이번 훈련에서는 무릎 점수가 많이 나아진 것으로 보이네요.이번 훈련에서는 무릎 점수가 많이 나아진 것으로 보이네요.이번 훈련에서는 무릎 점수가 많이 나아진 것으로 보이네요. 지금처럼 무릎을 신경쓰며 트레이닝 해보세요! 다만 스텝의 보폭이 너무 부족합니다.',
        worst: '퍼스트터치',
        worstFeedback:
            '훈련하시느라 수고하셨습니다. 이번 훈련에서는 무릎 점수가 많이 나아진 것으로 보이네요. 지금처럼 무릎을 신경쓰며 트레이닝 해보세요! 다만 스텝의 보폭이 너무 부족합니다.',
    },
    detailPart: [
        { skill: '상체밸런스', score: 85.3 },
        { skill: '시야', score: 85.3 },
        { skill: '무게중심', score: 85.3 },
        { skill: '무게축', score: 85.3 },
        { skill: '뒤꿈치', score: 85.3 },
        { skill: '무릎거리', score: 85.3 },
        { skill: '잔발', score: 85.3 },
        { skill: '탄력성', score: 85.3 },
        { skill: '최고속도', score: 85.3 },
        { skill: '평균속도', score: 85.3 },
        { skill: '정확도', score: 85.3 },
        { skill: '이탈율', score: 85.3 },
    ],
    comparisonScore: {
        averageScore: {
            playerScore: 62.23,
            teamScore: 62.23,
            nationwideScore: 83,
        },
        ranking: {
            playerRanking: '3%',
            teamRanking: '3%',
            nationwideRanking: '5%',
        },
    },
    recommandData: {
        videoNumber: [24, 22, 20],
        solution: '각 매일 3세트식 운동',
        solutionTime: '20분 소요',
    },
};
