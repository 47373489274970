// hooks
import { useState, useEffect } from 'react';
import { useNavigate, useSearchParams, useParams } from 'react-router-dom';
// admin components
import ReportSubHeader from 'pages/admin/report/ReportHeader';
import GroupReport from 'pages/admin/report/reportPrint/groupReport';
import PersonalReport from 'pages/admin/report/reportPrint/personalReport';
// commons
import EmptyBar from 'components/emptyBar';
const ReportPrint = () => {
    const { reportType } = useParams();
    return (
        <div className="wrapper ">
            <ReportSubHeader />
            <EmptyBar customStyle="h-[35px]" />
            <div className="rounded-[10px] p-[30px] bg-primary-white">
                {reportType === 'group' ? <GroupReport /> : <PersonalReport />}
            </div>
            <EmptyBar customStyle="h-[20px]" />
        </div>
    );
};

export default ReportPrint;
