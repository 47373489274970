import loadinBall from 'assets/gif/loadingBall.gif';
import loading from 'assets/gif/loading.gif';

const SoccerBallLodaing = ({
    isFixed = true,
    textStyle = 'text-primary-black',
    content = 'Loading...',
}: {
    isFixed?: boolean;
    textStyle?: string;
    content?: string;
}) => {
    return (
        <div
            className={`${
                isFixed ? 'fixed top-0 left-0 z-[10] w-screen h-screen' : ''
            } items-center flex justify-center`}
        >
            <div>
                <img
                    src={loading}
                    alt="loadinBall"
                    className="w-[150px]"
                />
                <div className={`flex justify-center space-x-1 ${textStyle}`}>
                    {content}
                    {/* {['L', 'o', 'a', 'd', 'i', 'n', 'g', '.'].map((char, index) => (
                        <span
                            key={index}
                            className={`text-center animate-bounce-letter`}
                            style={{
                                animationDelay: `${index * 0.2}s`,
                            }}
                        >
                            {char}
                        </span>
                    ))} */}
                </div>
            </div>
        </div>
    );
};

export default SoccerBallLodaing;
