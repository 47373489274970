// hooks
import { useParams, useLocation } from 'react-router-dom';
import { useState, useEffect, useMemo } from 'react';
import { debounce } from 'lodash';
// utils
import { ENDPOINT_REPORT } from 'utils/consts/apiEndpoint';
// api
import { useApiMutation } from 'hooks/useDynamicApi';
import { showToast } from 'components/toast/Default';
const ReportReview = ({ overallReview }: { overallReview: string }) => {
    const { id } = useParams();
    const [editReview, setEditReview] = useState(overallReview);
    useEffect(() => {
        if (overallReview) {
            setEditReview(overallReview);
        }
    }, [overallReview]);
    const patchOverallReview = useApiMutation(ENDPOINT_REPORT.review(id), 'patch', {
        onSuccess: (data) => {
            console.log('총평 수정 성공');
        },
        onError: (error) => {
            const detailError = error?.data?.detailedMessage || error;
            showToast(`${detailError}`, 'error');
        },
    });

    const editOverallReview = () => {
        patchOverallReview.mutate({
            overallReview: editReview,
        });
    };
    // `editRatingReview`를 debounced로 생성
    const debouncedEditRatingReview = useMemo(
        () =>
            debounce(() => {
                editOverallReview();
            }, 1000), // 1초 뒤에 실행
        [patchOverallReview] // 의존성에서 필요 요소만 포함
    );

    useEffect(() => {
        if (editReview === overallReview) return;
        if (editReview) {
            debouncedEditRatingReview();
        }
        return () => {
            debouncedEditRatingReview.cancel();
        };
    }, [editReview]);

    return (
        <div className="flex h-full ">
            <div className="flex w-full">
                <div className="w-[150px]  h-full shrink-0  bg-secondary-mGrayLight text-secondary-lGrayDark text-context-regular flex justify-center items-center">
                    경기총평
                </div>
                <div className="w-full p-[24px]">
                    <textarea
                        value={editReview}
                        onChange={(e) => setEditReview(e.target.value)}
                        maxLength={2000}
                        className="w-[1149px] focus:outline-none rounded-[8px] p-[10px] bg-secondary-mGrayLight resize-none h-[150px]"
                    ></textarea>
                </div>
            </div>
        </div>
    );
};

export default ReportReview;
