// hooks
import { useState, useEffect } from 'react';
import { useApiQuery, useApiMutation } from 'hooks/useDynamicApi';
// commons
import EmptyBar from 'components/emptyBar';
import Dropdown from 'components/dropdown/Default';
import { showToast } from 'components/toast/Default';
// modals
import PasswordChange from 'components/modals/PasswordChange';
// assets
import graySymbol from 'assets/logos/symbol/graySymbol.png';
// staff components
import MypageSubHeader from 'pages/staff/mypage/MySubHeader';
// icons
import { ReactComponent as EditPenIcon } from 'assets/svgs/editPen.svg';
// redux
import { useSelector } from 'react-redux';
import { RootState } from 'store'; // Redux Store 타입 정의
// utils
import { roleMatcher, regionMatcher } from 'utils/matchers/userInfoMatcher';
import { regionOptions } from 'utils/consts/userInfo';
import { ENDPOINT } from 'utils/consts/apiEndpoint';
interface MyInfoField {
    value: string | string[] | null; // teams가 배열일 수 있으므로
    state: 'disabled' | 'default' | 'error';
}

interface MyInfo {
    [key: string]: MyInfoField;
}

const Mypage = () => {
    const {
        name,
        id,
        email,
        profileImageUrl,
        role, // null 허용
        staffRole, // 이미 null 허용됨
        status,
    } = useSelector((state: RootState) => state.userInfo); // 인증 상태를 token 유무로 판단
    const [isEdit, setIsEdit] = useState(false);

    const playerInputs = [
        { label: '이름', value: 'name' },
        { label: '이메일', value: 'email' },
        { label: '지역', value: 'region' },
        { label: '역할', value: 'role' },
    ];
    const [myInfo, setMyInfo] = useState<MyInfo>({
        name: { value: name, state: 'default' },
        region: { value: '', state: 'default' },
        email: { value: email, state: 'default' },
        role: { value: role, state: 'disabled' },
    });
    const handleInputChange = (field: string, value: string) => {
        setMyInfo((prev) => ({
            ...prev,
            [field]: {
                ...prev[field],
                value: value,
            },
        }));
    };
    const getMyInfo = useApiQuery(['getMyInfo'], ENDPOINT.user.user, 'get'); // 상세정보 조회 아직 안함
    useEffect(() => {
        if ((getMyInfo?.data && getMyInfo?.isSuccess) || !isEdit) {
            setMyInfo((prev) => ({
                ...prev,
                name: { value: getMyInfo?.data?.name, state: 'default' },
                region: { value: getMyInfo?.data?.region, state: 'default' },
                email: { value: getMyInfo?.data?.email, state: 'disabled' },
                role: { value: getMyInfo?.data?.role, state: 'disabled' },
            }));
        }
    }, [getMyInfo?.data, getMyInfo?.isSuccess, isEdit]);
    const patchProfile = useApiMutation(ENDPOINT.user.staffProfile, 'patch', {
        onSuccess: (data) => {
            showToast(`프로필을 수정했습니다.`, 'success');
            setIsEdit(false);
        },
        onError: (error) => {
            const errorMessage = error?.data?.detailedMessage || error || '프로필 수정 실패했습니다.';
            showToast(`${errorMessage}.`, 'error');
        },
    });
    const updateProfile = () => {
        if (!patchProfile.isPending) {
            if (role === 'DATA_ANALYST' || role === 'SYSTEM_ADMIN' || role === 'TEAM_STAFF') {
                showToast(`프로필을 수정중입니다.`, 'info');
                patchProfile.mutate({
                    name: myInfo?.name?.value,
                    region: myInfo?.region?.value,
                });
            } else {
                showToast(`팀 스태프, 데이터 분석관, 시스템어드민만 프로필을 수정할 수 있습니다.`, 'warning');
                return;
            }
        }
    };
    return (
        <div className="wrapper">
            <EmptyBar customStyle={'h-[35px]'} />
            <MypageSubHeader />
            <EmptyBar customStyle={'h-[35px]'} />
            <div className="bg-primary-white rounded-[10px] p-[30px] h-[400px] flex gap-[30px]">
                <div className="flex justify-center items-center rounded-[8px] w-[230px] h-[280px] bg-secondary-mGrayLight">
                    <img
                        src={profileImageUrl || graySymbol}
                        alt="graySymbol"
                        className={`${
                            profileImageUrl ? 'w-[230px] h-[280px] object-cover rounded-[8px]' : 'w-[80px]'
                        } cursor-pointer`}
                    />
                </div>
                <div className="flex-col flex gap-[24px] w-[1080px]">
                    <div className="h-[64px] mb-[24px] flex justify-between items-end">
                        <div>
                            <div className="text-title-small-bold">{name}</div>
                            <div className="text-[#868686] text-[20px] font-semibold flex  items-center">
                                <div className="flex gap-[10px]">
                                    <span>{roleMatcher(role || '')}</span>
                                    <button
                                        onClick={() => setIsEdit(!isEdit)}
                                        className={`ml-[4px] ${
                                            isEdit ? 'text-primary-purple' : 'text-secondary-mGrayDark'
                                        }`}
                                    >
                                        <EditPenIcon />
                                    </button>
                                </div>
                            </div>
                        </div>
                        {isEdit && (
                            <div className="flex gap-[8px]">
                                <button
                                    onClick={updateProfile}
                                    className="w-[80px] h-[44px] bg-primary-purple p-[12px] rounded-[8px] text-primary-white text-context-bold"
                                >
                                    저장하기
                                </button>
                                <button
                                    onClick={() => setIsEdit(false)}
                                    className="w-[80px] h-[44px] bg-secondary-dGrayLight p-[12px] rounded-[8px] text-primary-white text-context-bold"
                                >
                                    취소하기
                                </button>
                            </div>
                        )}
                    </div>
                    <div className="grid grid-cols-2 gap-[14px]">
                        {playerInputs.map((el) => (
                            <div className="flex gap-[14px] items-center">
                                <div className="text-subtitle-small-bold w-[100px] text-center">{el.label}</div>
                                {el?.value === 'region' && isEdit ? (
                                    <Dropdown
                                        options={regionOptions}
                                        defaultValue="지역"
                                        value={el.value && (myInfo[el.value]?.value as string)}
                                        onChange={(value) => handleInputChange(el.value, value)}
                                    />
                                ) : (
                                    <input
                                        readOnly={!isEdit || myInfo[el.value].state === 'disabled'}
                                        value={myInfo[el.value]?.value || ''} // null 체크
                                        onChange={(e) => handleInputChange(el.value, e.target.value)}
                                        className={`${
                                            !isEdit || myInfo[el.value].state === 'disabled'
                                                ? 'inputDisabledStyle'
                                                : 'inputDefaultStyle'
                                        } inputCommonStyle`}
                                    />
                                )}
                            </div>
                        ))}
                    </div>
                    {(status === 'SUPER_ADMIN_PENDING_PASSWORD' ||
                        status === 'SYSTEM_ADMIN_PENDING_PASSWORD' ||
                        status === 'DATA_ANALYST_PENDING_PASSWORD') && (
                        <div className="flex justify-end w-full">
                            <PasswordChange
                                modalBtn={
                                    <button className="p-[12px] rounded-[8px] bg-primary-purple text-primary-white font-bold">
                                        비밀번호 변경
                                    </button>
                                }
                            />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};
export default Mypage;
