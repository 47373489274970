const GoalsElement = ({ goalTime, scorer, assistProvider }: any) => {
    return (
        <div className="">
            <div className="flex gap-[10px]">
                <div className="w-[110px]">
                    <input
                        value={goalTime}
                        readOnly
                        className="inputDefaultStyle inputCommonStyle"
                    />
                </div>
                <div className="w-[381px]">
                    <input
                        value={scorer?.playerName}
                        readOnly
                        className="inputDefaultStyle inputCommonStyle"
                    />
                </div>
                <div className="w-[381px]">
                    <input
                        value={assistProvider?.playerName}
                        readOnly
                        className="inputDefaultStyle inputCommonStyle"
                    />
                </div>
            </div>
        </div>
    );
};

export default GoalsElement;

export interface GoalsElementType {
    goalId: string;
    goalTime: string;
    scorer: {
        playerId: string;
        playerName: string;
        uniformNumber: string;
        position: string;
    };
    assistProvider: {
        playerId: string;
        playerName: string;
        uniformNumber: string;
        position: string;
    };
    lineupOptions: { label: string; value: string }[];
}
