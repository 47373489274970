// hooks
import { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
// icons
import { ReactComponent as PlusIcon } from 'assets/svgs/plus.svg';
// commons
import Dropdown from 'components/dropdown/Default';
// api
import { useApiQuery, useApiMutation } from 'hooks/useDynamicApi';
import { showToast } from 'components/toast/Default';
// utils
import { ENDPOINT_REPORT } from 'utils/consts/apiEndpoint';
// report components
import GoalsElement from 'pages/staff/control/schedule/report/post/reportPlayer/GoalsElement';
import { GoalsElementType } from 'pages/staff/control/schedule/report/post/reportPlayer/GoalsElement';

const Goals = ({ lineup, activeLineup, inactiveLineup }: SubstitutionProps) => {
    const location = useLocation();
    const combinedLineup = activeLineup?.concat(inactiveLineup);
    const allLineupOptions = handleDropdownOpts(combinedLineup) || [];
    const { id } = useParams();
    const [isAdd, setIsAdd] = useState(false);
    const [soccerId, setSoccerId] = useState('');
    const [assistProviderId, setAssistProviderId] = useState('');
    const [goalTime, setGoalTime] = useState('');
    // api
    const getGoals = useApiQuery(['getGoals'], ENDPOINT_REPORT.goals(id), 'get');
    // useApiMutation
    const postGoal = useApiMutation(ENDPOINT_REPORT.goal(id), 'post', {
        onSuccess: (data) => {
            showToast('득점 기록이 등록되었습니다', 'success');
            setIsAdd(false);
            setSoccerId('');
            setAssistProviderId('');
            setGoalTime('');
        },
        onError: (error) => {
            const errorMessage = error?.data?.detailedMessage || error || '득점 기록에 실패했습니다.';
            showToast(`${errorMessage}`, 'error');
            setSoccerId('');
            setAssistProviderId('');
            setGoalTime('');
        },
    });
    useEffect(() => {
        if (soccerId && goalTime && !postGoal.isPending) {
            postGoal.mutate({
                scorerId: soccerId,
                assistProviderId: assistProviderId,
                goalTime: goalTime,
            });
        }
    }, [soccerId, assistProviderId, goalTime, assistProviderId]);

    return (
        <div className="p-[24px] flex items-center w-full border-b border-secondary-lGrayMid">
            <div className="text-center shrink-0 w-[100px] h-full text-context-regular text-secondary-dGrayMid">
                득점
            </div>
            <div className="flex flex-col gap-[10px]">
                <div>
                    {(getGoals?.data || isAdd) && (
                        <div className="flex gap-[10px] text-context-regular w-full bg-red">
                            <div className="w-[500px] text-center ">득점</div>
                            <div className="w-[381px] text-center ">도움</div>
                        </div>
                    )}
                </div>
                <div>
                    <div className="flex flex-col gap-[10px]">
                        {getGoals?.data?.map((el: GoalsElementType) => (
                            <GoalsElement
                                key={el?.goalId}
                                {...el}
                                lineupOptions={allLineupOptions}
                            />
                        ))}
                    </div>
                </div>
                <div>
                    {(isAdd || getGoals?.data?.length < 1) && (
                        <div className="flex flex-col gap-[10px]">
                            <div className="flex gap-[10px]">
                                <div className="w-[110px] rounded-[8px] bg-primary-black flex  text-white items-center">
                                    <input
                                        type="text"
                                        // min={minTime}
                                        value={goalTime}
                                        onChange={(e) => setGoalTime(e.target.value)}
                                        className="px-[12px] w-[70px] h-[44px] text-white bg-transparent focus:outline-none"
                                        style={{
                                            colorScheme: 'dark', // 아이콘과 드롭다운 UI를 다크 테마처럼 변경
                                        }}
                                    />
                                    <div className="px-[10px] text-white">분</div>
                                </div>

                                <Dropdown
                                    placeholder="선수를 선택하세요"
                                    options={allLineupOptions}
                                    value={soccerId}
                                    onChange={setSoccerId}
                                    className="w-[381px]"
                                />

                                <Dropdown
                                    placeholder="선수를 선택하세요"
                                    options={allLineupOptions}
                                    value={assistProviderId}
                                    onChange={setAssistProviderId}
                                    className="w-[381px]"
                                />
                            </div>
                        </div>
                    )}
                </div>
                <button
                    onClick={() => setIsAdd(true)}
                    className="w-[500px] h-[44px] rounded-[8px] text-context-regular text-secondary-lGrayDark gap-[10px] border border-secondary-lGrayMid flex items-center justify-center"
                >
                    <PlusIcon />
                    득점추가{' '}
                </button>
            </div>
        </div>
    );
};

export default Goals;

interface SubstitutionProps {
    lineup: LineupType[];
    activeLineup: {
        playerId: string;
        playerName: string;
        uniformNumber: string;
        positions: string[];
    }[];
    inactiveLineup: {
        playerId: string;
        playerName: string;
        uniformNumber: string;
        positions: string[];
    }[];
}
interface LineupType {
    lineupId: string;
    playerId: string;
    playerName: string;
    uniformNumber: string;
    position: string;
    rating: string;
    managerComment: string;
}

export const handleDropdownOpts = (arr: any[]) => {
    return arr?.map((arr) => ({
        value: arr?.playerId,
        label: `${arr?.uniformNumber}. ${arr?.playerName}`,
    }));
};
