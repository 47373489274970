const RecommandExerciseCard = ({ recommandData }: any) => {
    return (
        <div>
            <div className="text-subtitle-medium-bold text-secondary-dGrayDark">측정 요약</div>

            <div className="flex gap-[12px] w-full">
                <div className="w-full flex justify-center gap-[8px] items-center bg-[#F8F8F8] h-[65px] rounded-[14px]">
                    <div className="text-context-regular">추천 트레이닝 영상</div>
                    {recommandData?.videoNumber?.map((el: any, idx: number) => (
                        <span
                            key={idx}
                            className="bg-primary-green font-bold text-[14px] text-secondary-dGrayMid py-[4px] px-[12px] rounded-[80px]"
                        >
                            {el} 번
                        </span>
                    ))}
                </div>
                <div className="w-full flex justify-center gap-[8px] items-center bg-[#F8F8F8] h-[65px] rounded-[14px]">
                    <div className="text-context-regular">{recommandData?.solution}</div>
                    <span className="bg-primary-green font-bold text-[14px] text-secondary-dGrayMid py-[4px] px-[12px] rounded-[80px]">
                        {recommandData?.solutionTime}
                    </span>
                </div>
            </div>
        </div>
    );
};

export default RecommandExerciseCard;
