// hooks
import { useNavigate } from 'react-router-dom';
// assets
import aisoccerWhiteFont from 'assets/logos/font-logo/aisoccer-white-font.webp';
import aisoccerWhiteSymbol from 'assets/logos/symbol/aisoccer-white-symbol.webp';
// redux
import { useDispatch } from 'react-redux';
import { userInfoRemove } from 'store/userInfoSlice';
import { teamInfoRemove } from 'store/teamInfoSlice';
import { useLogout } from 'hooks/useLogout';
import { logout } from 'store/authSlice';

const Unauthorized = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const logoutFunc = useLogout();
    const handleLogout = () => {
        dispatch(userInfoRemove());
        dispatch(teamInfoRemove());
        dispatch(logout());
        logoutFunc();
    };
    return (
        <div className="fixed inset-0 flex items-center justify-center bg-gradient-to-br from-gray-100 to-gray-300">
            <div className="absolute top-0 left-0 z-[-1] w-full h-full">
                {/* 배경색 나누기 */}
                <div className="h-[400px] bg-primary-black"></div>
                {/* 심볼 이미지 */}
                <img
                    src={aisoccerWhiteSymbol}
                    alt="aisoccerWhiteSymbol"
                    className="absolute z-0 top-0 right-0 w-[470px]"
                />
            </div>
            <div className="bg-white shadow-2xl rounded-[14px] p-[30px] text-center max-w-[400px]">
                {/* 404 Title */}
                <div className="text-[100px] font-extrabold text-gray-700 relative mb-4">
                    404
                    <span className="absolute top-[20%] left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-[40px] text-primary-red opacity-70 animate-pulse">
                        😢
                    </span>
                </div>
                {/* Page Not Found Text */}
                <div className="mb-6 text-lg text-gray-600">잘못된 접근입니다. 페이지를 찾을 수 없습니다.</div>
                {/* Logout Button */}
                <button
                    onClick={handleLogout}
                    className="px-6 py-2 text-white transition-all duration-300 rounded-md shadow bg-primary-purple bg-primary-blue hover:shadow-lg hover:bg-primary-dark-blue"
                >
                    로그아웃
                </button>
            </div>
        </div>
    );
};

export default Unauthorized;
