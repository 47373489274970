// hooks
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
// api
import { useApiQuery } from 'hooks/useDynamicApi';
// utils
import { ENDPOINT_MEASUREMENT } from 'utils/consts/apiEndpoint';
import { paramFormChanger } from 'utils/formChanger';
// hand components
import PassOneElements from 'pages/hand/measurement/contents/passOne/PassOneElement';
// commons
import SoccerBallLodaing from 'components/loading/SoccerBallLodaing';

const DribbleOne = () => {
    const [searchParams] = useSearchParams();
    const MEASUREDAT = searchParams.get('measuredAt') || '';
    const DEPARTMENT = searchParams.get('department') || '';
    // react qury
    const getPassOne = useApiQuery(
        ['getPassOne', MEASUREDAT, DEPARTMENT],
        ENDPOINT_MEASUREMENT.passOneParams(
            paramFormChanger({
                measuredAt: MEASUREDAT,
                department: DEPARTMENT,
            })
        ),
        'get'
    );

    return (
        <div className="p-[30px] bg-primary-white rounded-[10px]">
            {getPassOne?.isPending && <SoccerBallLodaing />}
            <div className="flex bg-primary-black text-primary-white text-context-regular rounded-[8px] h-[50px]">
                {columns.map((el: { key: string; label: string }) => (
                    <div
                        key={el.key}
                        className="flex items-center justify-center w-full"
                    >
                        {el?.label}
                    </div>
                ))}
                <div className="flex items-center justify-center w-full">초기화</div>
            </div>
            {getPassOne?.data?.map((el: any, idx: number) => (
                <PassOneElements
                    {...el}
                    key={el?.measurementId}
                    idx={idx}
                    // timerMeasuermentId={timerMeasuermentId}
                    // setTimerMeasuermentId={setTimerMeasuermentId}
                />
            ))}
        </div>
    );
};
export const columns = [
    { key: 'playerName', label: '이름' },
    { key: 'con1', label: '콘1' },
    { key: 'con2', label: '콘2' },
    { key: 'con3', label: '콘3' },
    { key: 'con4', label: '콘4' },
    { key: 'con5', label: '콘5' },
    { key: 'con6', label: '콘6' },
    { key: 'lapTime', label: '랩타임' },
];

export default DribbleOne;
