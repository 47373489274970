// hooks
import { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useParamNavigate } from "hooks/useDynamicNavigate";
// api
import { useApiQuery } from "hooks/useDynamicApi";
// icons
import { ReactComponent as HomeIcon } from "assets/svgs/home.svg";
import { ReactComponent as CircleArrowIcon } from "assets/svgs/circleArrow.svg";

// redux
import { RootState } from "store";
import { useSelector } from "react-redux";

const PlayerDetailSubHeader = () => {
  const { role, staffRole } = useSelector((state: RootState) => state.userInfo); // 인증 상태를 token 유무로 판단

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const handleParamNavigate = useParamNavigate();
  const handleHomeNavigate = (navigateUrl: string) => {
    handleParamNavigate({}, false, navigateUrl);
  };

  return (
    <div className="wrapper">
      <div className="flex items-center justify-between">
        <div className="flex items-start justify-center h-[79px] flex-col gap-[15px]">
          <div className="flex gap-[8px] items-center">
            <button
              onClick={() =>
                role === "PLAYER"
                  ? handleHomeNavigate("/player/measurement")
                  : role === "TEAM_STAFF"
                  ? handleHomeNavigate("/staff/control/lineUp")
                  : handleHomeNavigate(`/admin/control`)
              }
            >
              <HomeIcon className="text-white mr-[6px]" />
            </button>
            <div className="w-[3px] h-[3px] rounded-full bg-secondary-lGrayDark"></div>
            <div className="text-white text-label-regular">선수관리</div>
          </div>
          <div className="flex items-center gap-[6px]">
            <button onClick={() => navigate(-1)}>
              <CircleArrowIcon className="text-white" />
            </button>
            <div className="text-[26px] text-white font-bold">상세 프로필</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlayerDetailSubHeader;
