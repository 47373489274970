// hooks
import { useState, useEffect, useMemo, useRef } from 'react';
import { debounce } from 'lodash';
// api
import { useApiMutation } from 'hooks/useDynamicApi';
// commons
import SqureCheckbox from 'components/checkbox/SqureCheckbox';
import { showToast } from 'components/toast/Default';
import Stopwatch from 'pages/hand/measurement/contents/stopwatch';
// utils
import { ENDPOINT_MEASUREMENT } from 'utils/consts/apiEndpoint';
// icons
import { ReactComponent as ResetIcon } from 'assets/svgs/reset.svg';
import { ReactComponent as EditPenIcon } from 'assets/svgs/editPen.svg';

const DribbleTwoElement = ({
    idx,
    playerName,
    measurementId,
    dribbleTwoId,
    cone1Passed = true,
    cone2Passed = true,
    cone3Passed = true,
    cone4Passed = true,
    cone5Passed = true,
    cone6Passed = true,
    cone7Passed = true,
    cone8Passed = true,
    cone9Passed = true,
    cone10Passed = true,
    lapTime,
}: DribbleTwoElementType) => {
    const inputRef = useRef<HTMLInputElement | null>(null);
    const handleButtonClick = () => {
        // ref가 현재 존재하는 경우 focus 호출
        inputRef.current?.focus();
    };

    const [editDribbleTwoInfo, setEditDribbleTwoInfo] = useState({
        measurementId: measurementId,
        dribbleTwoId: dribbleTwoId,
        playerName: playerName,
        cone1Passed: cone1Passed,
        cone2Passed: cone2Passed,
        cone3Passed: cone3Passed,
        cone4Passed: cone4Passed,
        cone5Passed: cone5Passed,
        cone6Passed: cone6Passed,
        cone7Passed: cone7Passed,
        cone8Passed: cone8Passed,
        cone9Passed: cone9Passed,
        cone10Passed: cone10Passed,
        lapTime: lapTime,
    });
    // 특정 체크박스 상태를 업데이트하는 함수
    const handleEditDribbleTwoInfo = (updateData: any) => {
        setEditDribbleTwoInfo((prev) => ({
            ...prev,
            ...updateData,
        }));
    };

    // useApiMutation
    const patchDribbleTwoMeasurement = useApiMutation(
        ENDPOINT_MEASUREMENT.dribbleTwo(editDribbleTwoInfo?.dribbleTwoId),
        'patch',
        {
            onSuccess: (data) => {
                // showToast("드리블2 기록을 수정했습니다.", "success");
            },
            onError: (error) => {
                const errorMessage = error?.data?.detailedMessage || error || '드리블2 기록수정에 실패했습니다.';
                showToast(errorMessage, 'error');
            },
        }
    );
    const patchDribbleTwoReset = useApiMutation(
        ENDPOINT_MEASUREMENT.dribbleTwoReset(editDribbleTwoInfo?.dribbleTwoId),
        'patch',
        {
            onSuccess: (data) => {
                showToast('드리블2 기록을 초기화했습니다.', 'success');
                handleEditDribbleTwoInfo({
                    cone1Passed: true,
                    cone2Passed: true,
                    cone3Passed: true,
                    cone4Passed: true,
                    cone5Passed: true,
                    cone6Passed: true,
                    cone7Passed: true,
                    cone8Passed: true,
                    cone9Passed: true,
                    cone10Passed: true,
                });
            },
            onError: (error) => {
                const errorMessage = error?.data?.detailedMessage || error || '드리블2 기록 초기화에 실패했습니다.';
                showToast(errorMessage, 'error');
            },
        }
    );
    const editDribbleTwoMeasurement = () => {
        if (!patchDribbleTwoMeasurement?.isPending) {
            // showToast("드리블2 기록을 변경하는중입니다.", "info");
            patchDribbleTwoMeasurement.mutate({
                cone1Passed: editDribbleTwoInfo?.cone1Passed,
                cone2Passed: editDribbleTwoInfo?.cone2Passed,
                cone3Passed: editDribbleTwoInfo?.cone3Passed,
                cone4Passed: editDribbleTwoInfo?.cone4Passed,
                cone5Passed: editDribbleTwoInfo?.cone5Passed,
                cone6Passed: editDribbleTwoInfo?.cone6Passed,
                cone7Passed: editDribbleTwoInfo?.cone7Passed,
                cone8Passed: editDribbleTwoInfo?.cone8Passed,
                cone9Passed: editDribbleTwoInfo?.cone9Passed,
                cone10Passed: editDribbleTwoInfo?.cone10Passed,
                lapTime: editDribbleTwoInfo?.lapTime,
            });
        }
    };
    const editDribbleTwoReset = () => {
        if (!patchDribbleTwoReset?.isPending) {
            showToast('신체 측정데이터를 초기화하는 중입니다.', 'info');
            patchDribbleTwoReset.mutate({});
        }
    };
    // debounce
    const debouncedPatchDribbleTwo = useMemo(
        () =>
            debounce(() => {
                editDribbleTwoMeasurement();
            }, 500), // 1초 딜레이
        [patchDribbleTwoMeasurement]
    );
    useEffect(() => {
        if (
            editDribbleTwoInfo?.cone1Passed === cone1Passed &&
            editDribbleTwoInfo?.cone2Passed === cone2Passed &&
            editDribbleTwoInfo?.cone3Passed === cone3Passed &&
            editDribbleTwoInfo?.cone4Passed === cone4Passed &&
            editDribbleTwoInfo?.cone5Passed === cone5Passed &&
            editDribbleTwoInfo?.cone6Passed === cone6Passed &&
            editDribbleTwoInfo?.cone7Passed === cone7Passed &&
            editDribbleTwoInfo?.cone8Passed === cone8Passed &&
            editDribbleTwoInfo?.cone9Passed === cone9Passed &&
            editDribbleTwoInfo?.cone10Passed === cone10Passed &&
            editDribbleTwoInfo?.lapTime === lapTime
        ) {
            return;
        }
        debouncedPatchDribbleTwo();
        return () => {
            debouncedPatchDribbleTwo.cancel();
        };
    }, [editDribbleTwoInfo]);
    return (
        <div>
            <div
                className={`w-full flex justify-between rounded-[8px] h-[50px] ${
                    idx % 2 === 1 ? 'bg-secondary-mGrayLight' : 'bg-primary-white'
                }`}
            >
                <div className="flex items-center justify-center flex-1">{editDribbleTwoInfo?.playerName}</div>
                <SqureCheckbox
                    checked={editDribbleTwoInfo.cone1Passed}
                    onChange={() =>
                        handleEditDribbleTwoInfo({
                            cone1Passed: !editDribbleTwoInfo?.cone1Passed,
                        })
                    }
                    className="justify-center flex-1"
                />
                <SqureCheckbox
                    checked={editDribbleTwoInfo.cone2Passed}
                    onChange={() =>
                        handleEditDribbleTwoInfo({
                            cone2Passed: !editDribbleTwoInfo?.cone2Passed,
                        })
                    }
                    className="justify-center flex-1"
                />
                <SqureCheckbox
                    checked={editDribbleTwoInfo.cone3Passed}
                    onChange={() =>
                        handleEditDribbleTwoInfo({
                            cone3Passed: !editDribbleTwoInfo?.cone3Passed,
                        })
                    }
                    className="justify-center flex-1"
                />
                <SqureCheckbox
                    checked={editDribbleTwoInfo.cone4Passed}
                    onChange={() =>
                        handleEditDribbleTwoInfo({
                            cone4Passed: !editDribbleTwoInfo?.cone4Passed,
                        })
                    }
                    className="justify-center flex-1"
                />
                <SqureCheckbox
                    checked={editDribbleTwoInfo.cone5Passed}
                    onChange={() =>
                        handleEditDribbleTwoInfo({
                            cone5Passed: !editDribbleTwoInfo?.cone5Passed,
                        })
                    }
                    className="justify-center flex-1"
                />
                <SqureCheckbox
                    checked={editDribbleTwoInfo.cone6Passed}
                    onChange={() =>
                        handleEditDribbleTwoInfo({
                            cone6Passed: !editDribbleTwoInfo?.cone6Passed,
                        })
                    }
                    className="justify-center flex-1"
                />
                <SqureCheckbox
                    checked={editDribbleTwoInfo.cone7Passed}
                    onChange={() =>
                        handleEditDribbleTwoInfo({
                            cone7Passed: !editDribbleTwoInfo?.cone7Passed,
                        })
                    }
                    className="justify-center flex-1"
                />
                <SqureCheckbox
                    checked={editDribbleTwoInfo.cone8Passed}
                    onChange={() =>
                        handleEditDribbleTwoInfo({
                            cone8Passed: !editDribbleTwoInfo?.cone8Passed,
                        })
                    }
                    className="justify-center flex-1"
                />
                <SqureCheckbox
                    checked={editDribbleTwoInfo.cone9Passed}
                    onChange={() =>
                        handleEditDribbleTwoInfo({
                            cone9Passed: !editDribbleTwoInfo?.cone9Passed,
                        })
                    }
                    className="justify-center flex-1"
                />
                <SqureCheckbox
                    checked={editDribbleTwoInfo.cone10Passed}
                    onChange={() =>
                        handleEditDribbleTwoInfo({
                            cone10Passed: !editDribbleTwoInfo?.cone10Passed,
                        })
                    }
                    className="justify-center flex-1 "
                />
                <div className="flex-1 flex justify-center items-center h-[50px]">
                    <input
                        ref={inputRef} // ref 연결
                        value={editDribbleTwoInfo.lapTime}
                        onChange={(e) =>
                            handleEditDribbleTwoInfo({
                                lapTime: e.target.value,
                            })
                        }
                        className="h-[40px] text-center w-full text-secondary-lGrayDark focus:outline focus:outline-primary-purple focus:bg-primary-white  bg-transparent rounded-[8px]"
                    />
                    <button
                        className="flex-1 text-secondary-lGrayDark hover:text-primary-purple"
                        onClick={handleButtonClick} // 버튼 클릭 시 input에 포커스
                    >
                        <EditPenIcon />
                    </button>
                </div>
                <button
                    className="flex-1"
                    onClick={editDribbleTwoReset}
                >
                    <ResetIcon className=" h-[20px] m-auto" />
                </button>
            </div>
            <div className=" flex justify-end w-full  items-center h-[50px] ">
                <Stopwatch
                    lapTime={editDribbleTwoInfo?.lapTime}
                    setLapTime={(value) => handleEditDribbleTwoInfo({ lapTime: value })}
                />
            </div>
        </div>
    );
};

export default DribbleTwoElement;

interface DribbleTwoElementType {
    idx: number;
    measurementId: string;
    dribbleTwoId: string;
    playerName: boolean;
    cone1Passed: boolean;
    cone2Passed: boolean;
    cone3Passed: boolean;
    cone4Passed: boolean;
    cone5Passed: boolean;
    cone6Passed: boolean;
    cone7Passed: boolean;
    cone8Passed: boolean;
    cone9Passed: boolean;
    cone10Passed: boolean;
    lapTime: number;
}
