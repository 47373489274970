// hooks
import { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useParamNavigate } from "hooks/useDynamicNavigate";
// api
import { useApiQuery } from "hooks/useDynamicApi";
// icons
import { ReactComponent as HomeIcon } from "assets/svgs/home.svg";
import { ReactComponent as CircleArrowIcon } from "assets/svgs/circleArrow.svg";
// redux
import { RootState } from "store";
import { useSelector } from "react-redux";
// admin components
import VideoAddModal from "pages/admin/video/VideoAddModal";

interface VideoSubHeaderType {
  wins: number;
  draws: number;
  losses: number;
  winRate: number;
  winRateChange: number;
}
const VideoSubHeader = () => {
  const [searchParams] = useSearchParams();
  const handleParamNavigate = useParamNavigate();
  const { staffRole } = useSelector((state: RootState) => state.userInfo);
  const navigate = useNavigate();

  const handleHomeNavigate = (param: string) => {
    handleParamNavigate(
      {
        control: param,
      },
      false,
      `/admin/control`
    );
  };

  return (
    <div className="wrapper">
      <div className="flex items-center justify-between">
        <div className="flex items-start justify-center h-[79px] flex-col gap-[15px]">
          <div className="flex gap-[8px] items-center">
            <button onClick={() => handleHomeNavigate("player")}>
              <HomeIcon className="text-white mr-[6px]" />
            </button>
            <div className="w-[3px] h-[3px] rounded-full bg-secondary-lGrayDark"></div>
            <div className="text-white text-label-regular">
              추천 동영상 관리
            </div>
          </div>
          <div className="flex items-center gap-[6px]">
            <button onClick={() => navigate(-1)}>
              <CircleArrowIcon className="text-white" />
            </button>
            <div className="text-[26px] text-white font-bold">
              추천 동영상 관리
            </div>
          </div>
        </div>
        <VideoAddModal
          modalBtn={
            <button className="bg-primary-green rounded-[8px] text-primary-black p-[12px] text-context-bold">
              영상 추가
            </button>
          }
        />
      </div>
    </div>
  );
};

export default VideoSubHeader;
