// hooks
import { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
// icons
import { ReactComponent as PlusIcon } from 'assets/svgs/plus.svg';
// api
import { useApiQuery, useApiMutation } from 'hooks/useDynamicApi';
import { showToast } from 'components/toast/Default';
// utils
import { ENDPOINT_REPORT } from 'utils/consts/apiEndpoint';
// commons
import Dropdown from 'components/dropdown/Default';
// report components
import ShootoutsElement from 'pages/staff/control/schedule/report/post/reportPlayer/ShootoutsElement';

const Shootouts = ({ lineup, activeLineup, inactiveLineup }: SubstitutionProps) => {
    const { id } = useParams();
    const lineupOptions = handleDropdownOpts(activeLineup) || [];

    const [isAdd, setIsAdd] = useState(false);
    const [newKickOrder, setNewKickOrder] = useState('');
    const [shootoutsPlayer, setShootoutsPlayer] = useState('');
    const [success, setSuccess] = useState('');

    // api
    const getShootouts = useApiQuery(['getShootouts'], ENDPOINT_REPORT.shootouts(id), 'get');
    useEffect(() => {
        if (getShootouts?.data && getShootouts?.isSuccess) {
            setNewKickOrder(getShootouts?.data?.length + 1);
        }
    }, [getShootouts?.data, getShootouts?.isSuccess]);

    // useApiMutation
    const postShootout = useApiMutation(ENDPOINT_REPORT.shootout(id), 'post', {
        onSuccess: (data) => {
            showToast('승부차기 등록이 등록되었습니다', 'success');
            setIsAdd(false);
            setShootoutsPlayer('');
            setSuccess('');
        },
        onError: (error) => {
            const errorMessage = error?.data?.detailedMessage || error || '승부차기 등록에 실패했습니다.';
            showToast(`${errorMessage}`, 'error');
            setShootoutsPlayer('');
            setSuccess('');
        },
    });
    useEffect(() => {
        if (shootoutsPlayer && success && newKickOrder && !postShootout.isPending) {
            postShootout.mutate({
                kickerId: shootoutsPlayer,
                kickOrder: newKickOrder,
                success: success === 'success',
            });
        }
    }, [shootoutsPlayer, success, newKickOrder]);
    return (
        <div className="p-[24px] flex items-center w-full ">
            <div className="text-center shrink-0 w-[100px] h-full text-context-regular text-secondary-dGrayMid">
                승부차기
            </div>
            <div className="flex flex-col gap-[10px]">
                <div className="flex gap-[10px]">
                    <div className="w-[70px] text-center">순서</div>
                    <div className="w-[420px] text-center">득점</div>
                    <div className="w-[130px] text-center">성공 여부</div>
                </div>
                <div className="gap-[10px] flex flex-col">
                    {getShootouts?.data?.map((el: ShootoutsType) => (
                        <ShootoutsElement
                            key={el.id}
                            shootouts={el}
                            lineupOptions={lineupOptions}
                            newKickOrder={newKickOrder}
                        />
                    ))}
                </div>
                <div className="">
                    {(isAdd || getShootouts?.data?.length < 1) && (
                        <div className="flex gap-[10px]">
                            <div className="w-[70px] flex justify-center rounded-[8px] bg-primary-black text-white items-center">
                                {newKickOrder}
                            </div>
                            <Dropdown
                                placeholder="선수를 선택하세요"
                                options={lineupOptions}
                                value={shootoutsPlayer}
                                onChange={setShootoutsPlayer}
                                className="w-[420px]"
                            />{' '}
                            <Dropdown
                                placeholder="성공 여부"
                                options={[
                                    { label: '성공', value: 'success' },
                                    { label: '실패', value: 'fail' },
                                ]}
                                value={success}
                                onChange={setSuccess}
                                className="w-[130px]"
                            />
                        </div>
                    )}
                </div>
                <button
                    onClick={() => setIsAdd(true)}
                    className="w-[500px] h-[44px] rounded-[8px] text-context-regular text-secondary-lGrayDark gap-[10px] border border-secondary-lGrayMid flex items-center justify-center"
                >
                    <PlusIcon />
                    득점 추가{' '}
                </button>
            </div>
        </div>
    );
};

export default Shootouts;

interface SubstitutionProps {
    lineup: LineupType[];
    activeLineup: {
        playerId: string;
        playerName: string;
        uniformNumber: string;
        positions: string[];
    }[];
    inactiveLineup: {
        playerId: string;
        playerName: string;
        uniformNumber: string;
        positions: string[];
    }[];
}
interface LineupType {
    lineupId: string;
    playerId: string;
    playerName: string;
    uniformNumber: string;
    position: string;
    rating: string;
    managerComment: string;
}

export const handleDropdownOpts = (arr: any[]) => {
    return arr?.map((arr) => ({
        value: arr.playerId,
        label: `${arr.uniformNumber}. ${arr.playerName}`,
    }));
};

interface ShootoutsType {
    id: string;
    kicker: {
        playerId: string;
        playerName: string;
        uniformNumber: string;
        position: string;
    };
    kickOrder: string;
    success: boolean;
}
