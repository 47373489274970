const FeedbackCard = ({ feedback }: any) => {
    return (
        <div className="flex flex-col gap-[10px] w-full">
            <div className="text-subtitle-medium-bold text-secondary-dGrayDark">데이터 분석관 피드백</div>
            <textarea
                value={feedback}
                className="h-[210px] resize-none rounded-[8px] p-[12px] bg-secondary-mGrayLight text-secondary-lGrayDark text-context-regular"
            ></textarea>
        </div>
    );
};

export default FeedbackCard;
