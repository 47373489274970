import React from 'react';
// icons
import { ReactComponent as CircleArrowIcon } from 'assets/svgs/circleArrow.svg';
import { ReactComponent as DoubleArrowIcon } from 'assets/svgs/doubleArrow.svg';

const Pagination = ({
    page,
    size,
    totalElement,
    totalPages,
    onPageChange,
}: {
    page: number;
    size: number;
    totalElement: number;
    totalPages: number;
    onPageChange: (newPage: number) => void;
}) => {
    const MAX_PAGE_DISPLAY = 5; // 페이지 번호를 몇 개까지 보여줄지 설정

    const getDisplayedPages = () => {
        const pages = [];
        const startPage = Math.max(1, page - Math.floor(MAX_PAGE_DISPLAY / 2));
        const endPage = Math.min(totalPages, startPage + MAX_PAGE_DISPLAY - 1);

        for (let i = startPage; i <= endPage; i++) {
            pages.push(i);
        }
        return pages;
    };

    const displayedPages = getDisplayedPages();

    return (
        <div className="flex items-center justify-center space-x-2 mt-[5px]">
            {/* First and Previous Buttons */}
            <button
                className="w-[30px] h-[30px] rounded-[5px] disabled:text-secondary-lGrayMid"
                disabled={page === 1}
                onClick={() => onPageChange(1)}
            >
                <DoubleArrowIcon />
            </button>
            <button
                className="w-[30px] h-[30px] rounded-[5px] flex justify-center items-center disabled:text-secondary-lGrayMid"
                disabled={page === 1}
                onClick={() => onPageChange(page - 1)}
            >
                <CircleArrowIcon />
            </button>

            {/* Page Numbers */}
            {displayedPages.map((p) => (
                <button
                    key={p}
                    className={`w-[30px] h-[30px]   rounded-[5px] ${
                        p === page
                            ? 'bg-primary-green text-primary-black'
                            : 'bg-secondary-mGrayLight text-secondary-lGrayDark'
                    }`}
                    onClick={() => onPageChange(p)}
                >
                    {p}
                </button>
            ))}

            {/* Ellipsis */}
            {totalPages > displayedPages[displayedPages.length - 1] && <span>...</span>}

            {/* Next and Last Buttons */}
            <button
                className="w-[30px] h-[30px] rounded-[5px] flex justify-center items-center disabled:text-secondary-lGrayMid"
                disabled={page === totalPages}
                onClick={() => onPageChange(page + 1)}
            >
                <CircleArrowIcon className="rotate-180" />
            </button>
            <button
                className="w-[30px] h-[30px] rounded-[5px] disabled:text-secondary-lGrayMid"
                disabled={page === totalPages}
                onClick={() => onPageChange(totalPages)}
            >
                <DoubleArrowIcon className="rotate-180" />
            </button>
        </div>
    );
};

export default Pagination;
