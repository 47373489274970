// hooks
import React, { useState, useRef, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { useSearchParams } from 'react-router-dom';
import axiosInstance from 'api/axiosInstance';
import { useInfiniteQuery } from '@tanstack/react-query';
// api
import { useApiMutation } from 'hooks/useDynamicApi';
// icons
import { ReactComponent as CheckIcon } from 'assets/svgs/check.svg';
import { ReactComponent as ArrowIcon } from 'assets/svgs/arrow.svg';
import { ReactComponent as PlusIcon } from 'assets/svgs/plus.svg';
// utils
import { ENDPOINT_EVALUATION } from 'utils/consts/apiEndpoint';
// redux
import { RootState } from 'store';
import { useSelector } from 'react-redux';
// commons
import { showToast } from 'components/toast/Default';
import PeriodEditModal from 'pages/staff/control/rating/PeriodEditModal';
import PeriodAddModal from 'pages/staff/control/rating/PeriodAddModal';
import ConfirmModal from 'components/modals/ConfirmModal';
// icons
import { ReactComponent as EditPenIcon } from 'assets/svgs/editPen.svg';
import { ReactComponent as DeleteIcon } from 'assets/svgs/delete.svg';

interface DropdownProps {
    paramTeamId: string;
    paramKey?: string;
    period: any;
    currentPeriodInfo: any;
    setPeriod: (period: any) => void;
    placeholder?: string;
    disabled?: boolean;
    error?: boolean;
    className?: string;
}

const Dropdown: React.FC<DropdownProps> = ({
    paramTeamId,
    period,
    currentPeriodInfo,
    setPeriod,
    placeholder = '선택해주세요',
    disabled = false,
    className = '',
}) => {
    const { ref, inView } = useInView();
    const {
        inputEndDate = '',
        inputStartDate = '',
        periodEndDate = '',
        periodId = '',
        periodNumber = '',
        periodStartDate = '',
        year = '',
    } = currentPeriodInfo || {};
    const toDay = new Date();
    const toDayYear = String(toDay.getFullYear());
    const { staffRole } = useSelector((state: RootState) => state.userInfo);
    const [searchParams] = useSearchParams();
    const paramAgeGroup = searchParams.get('ageGroup') || '';
    const paramYear = searchParams.get('year') || toDayYear;
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef<HTMLDivElement>(null);
    const [deleteModalShow, setDeleteModalShow] = useState(false);

    const { data, error, fetchNextPage, hasNextPage, isFetching, isFetchingNextPage, status, refetch } =
        useInfiniteQuery({
            queryKey: ['getEvaluationTeamPeriod', paramYear],
            queryFn: ({ pageParam = 1 }) =>
                axiosInstance
                    .get(`${ENDPOINT_EVALUATION.team(paramTeamId)}?page=${pageParam}&size=10`)
                    .then((response) => response.data),
            initialPageParam: 1,
            getNextPageParam: (lastPage) => (lastPage?.sliceInfo?.hasNext ? lastPage?.sliceInfo?.page + 1 : undefined),
        });
    useEffect(() => {
        if (inView && hasNextPage && !isFetchingNextPage) {
            fetchNextPage();
        }
    }, [inView, hasNextPage, isFetchingNextPage]);

    const handleToggle = () => {
        if (!disabled) {
            setIsOpen(!isOpen);
        }
    };

    const handleOptionSelect = (optionValue: any, idx: number) => {
        if (idx === 0) {
            // setIsOpen(false);
            return;
        }
        if (!disabled) {
            sessionStorage.setItem('period', optionValue?.value);
            setPeriod(optionValue);
            setIsOpen(false);
        }
    };

    // 드롭다운 상태에 따른 스타일
    const getDropdownStyles = () => {
        if (disabled) {
            return 'bg-secondary-mGrayLight border border-secondary-mGrayDark text-secondary-mGrayDark text-context-bold';
        }
        if (error) {
            return 'bg-primary-white border border-status-error text-status-error text-context-bold';
        }
        if (isOpen) {
            return 'bg-primary-purple text-primary-white text-context-bold';
        }
        return 'bg-primary-black text-primary-white text-context-bold';
    };

    // useApiMutation
    const postEvaluationPeriod = useApiMutation(ENDPOINT_EVALUATION.period(), 'post', {
        onSuccess: (data) => {
            refetch();
            showToast('평가기간이 생성되었습니다.', 'success');
        },
        onError: (error) => {
            // 에러 메시지 추출
            const detailError = error?.data?.detailedMessage || error;
            showToast(`${detailError || error || '평가기간 생성 실패했습니다.'}`, 'error');
        },
    });
    const patchEvaluationPeriod = useApiMutation(ENDPOINT_EVALUATION.period(periodId), 'patch', {
        onSuccess: (data) => {
            refetch();
            showToast('평가기간이 수정되었습니다.', 'success');
        },
        onError: (error) => {
            // 에러 메시지 추출
            const detailError = error?.data?.detailedMessage || error;
            showToast(`${detailError || error?.detailedMessage || '평가기간 수정 실패했습니다.'}`, 'error');
        },
    });
    const deleteEvaluationPeriod = useApiMutation(ENDPOINT_EVALUATION.period(periodId), 'delete', {
        onSuccess: (data) => {
            showToast('평가기간이 삭제 되었습니다.', 'success');
            setPeriod({ label: '', value: '' });
        },
        onError: (error) => {
            // 에러 메시지 추출
            const detailError = error?.data?.detailedMessage || error || '평가기간 삭제 실패했습니다.';
            showToast(detailError, 'error');
        },
    });

    // handleFunc
    const createEvalutationPeriod = ({
        newInputEndDate,
        newInputStartDate,
        newPeriodEndDate,
        newPeriodStartDate,
        newPeriodNumber,
        newYear,
    }: NewPeriodInfoType) => {
        if (
            !newInputEndDate ||
            !newInputStartDate ||
            !newPeriodEndDate ||
            !newPeriodStartDate ||
            !newPeriodNumber ||
            !newYear
        ) {
            setIsOpen(false);

            showToast('필수 값을 입력해주세요', 'error');
            return;
        }
        if (!postEvaluationPeriod.isPending) {
            showToast('평가기간 생성중입니다', 'info');
            postEvaluationPeriod.mutate({
                teamId: paramTeamId,
                periodNumber: newPeriodNumber,
                year: newYear,
                periodStartDate: newPeriodStartDate,
                periodEndDate: newPeriodEndDate,
                inputStartDate: newInputStartDate,
                inputEndDate: newInputEndDate,
            });
        }
    };
    const updateEvaluationPeriod = ({
        newInputEndDate,
        newInputStartDate,
        newPeriodEndDate,
        newPeriodStartDate,
        newPeriodNumber,
        newYear,
    }: NewPeriodInfoType) => {
        if (
            !newInputEndDate ||
            !newInputStartDate ||
            !newPeriodEndDate ||
            !newPeriodStartDate ||
            !newPeriodNumber ||
            !newYear
        ) {
            showToast('필수 값을 입력해주세요', 'error');
            return;
        }
        if (!patchEvaluationPeriod.isPending && periodId) {
            showToast('평가기간 수정중입니다', 'info');
            patchEvaluationPeriod.mutate({
                periodNumber: newPeriodNumber,
                year: newYear,
                periodStartDate: newPeriodStartDate,
                periodEndDate: newPeriodEndDate,
                inputStartDate: newInputStartDate,
                inputEndDate: newInputEndDate,
            });
        }
    };

    const removeEvaluationPeriod = () => {
        if (!deleteEvaluationPeriod.isPending && periodId) showToast('평가기간 삭제중입니다', 'info');
        deleteEvaluationPeriod.mutate({});
    };

    // data
    const flattenedRows = [
        {
            value: 'new',
            label: (
                <PeriodAddModal
                    modalBtn={
                        <div
                            onClick={(e) => e.preventDefault()}
                            className="flex gap-[4px] w-[115px]"
                        >
                            <span>차수추가</span> <PlusIcon className="flex text-secondary-lGrayMid" />
                        </div>
                    }
                    editValue={currentPeriodInfo}
                    activeFunc={createEvalutationPeriod}
                />
            ),
        },
        ...(data?.pages.flatMap((page) =>
            page.data.map((item: any) => ({
                value: item.id,
                label: `${item.year} ${item.periodNumber}차`,
            }))
        ) || []),
    ];

    useEffect(() => {
        if (data && !isFetching && flattenedRows?.length > 1) {
            const periodId = sessionStorage.getItem('period');
            if (periodId) {
                const periodInfo = flattenedRows?.filter((el) => el?.value === Number(periodId));
                setPeriod(periodInfo[0]);
                return;
            }
            sessionStorage.setItem('period', flattenedRows[1]?.value);
            setPeriod(flattenedRows[1]);
        }
    }, [data, isFetching]);
    // 외부 클릭 시 드롭다운 닫기
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
                setIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className="flex gap-[20px] items-center">
            <div
                ref={dropdownRef}
                className={`relative  ${className ? className : 'w-full'}`}
            >
                {/* 드롭다운 헤더 */}
                <div
                    onClick={handleToggle}
                    className={`
          flex justify-between items-center gap-[12px]
          px-[12px] py-[12px] rounded-[8px]
          cursor-pointer
          transition-all duration-200
          ${getDropdownStyles()}
        `}
                >
                    {period?.label ? period?.label : '차수 선택'}
                    <ArrowIcon
                        className={`
            transition-transform duration-200
            ${isOpen ? 'rotate-180' : 'rotate-0'}
          `}
                    />
                </div>

                {/* 드롭다운 리스트 */}
                {isOpen && (
                    <div
                        className="
            absolute z-10 w-full
            border border-primary-purple
            rounded-[10px]
            max-h-60 overflow-y-auto mt-[5px]
          "
                    >
                        {flattenedRows?.map((option, idx) => (
                            <div
                                key={option?.value}
                                onClick={() => handleOptionSelect(option, idx)}
                                className={`
                px-[12px] py-[12px]  text-context-regular
                flex justify-between items-center gap-[12px]
                cursor-pointer
                hover:bg-[#EDECFF] text-primary-purple
                transition-colors duration-200
                ${period?.value === option?.value ? 'bg-[#EDECFF]' : 'bg-primary-white'}
              `}
                            >
                                {option?.label}
                                {period?.value === option?.value && <CheckIcon />}
                            </div>
                        ))}
                        <div ref={ref} />
                    </div>
                )}
            </div>
            {periodId && (
                <div className="flex flex-col">
                    <div className="flex gap-[10px] text-[16px] font-semibold">
                        <span className="text-[#868686]">차수기간</span>
                        <div className="flex gap-[5px]">
                            <span>{periodStartDate}</span>
                            <span className="text-[#868686]">~</span>
                            <span>{periodEndDate}</span>
                        </div>
                    </div>
                    <div className="flex gap-[10px] text-[16px] font-semibold">
                        <span className="text-[#868686]">입력기간</span>
                        <div className="flex gap-[5px]">
                            <span>{inputStartDate}</span>
                            <span className="text-[#868686]">~</span>
                            <span>{inputEndDate}</span>
                        </div>
                    </div>
                </div>
            )}
            {periodId && (
                <div className="flex gap-[4px] items-center">
                    <PeriodEditModal
                        modalBtn={
                            <button className="">
                                <EditPenIcon className="text-primary-purple mt-[6px]" />
                            </button>
                        }
                        editValue={currentPeriodInfo}
                        activeFunc={updateEvaluationPeriod}
                    />
                    <button onClick={() => setDeleteModalShow(!deleteModalShow)}>
                        <DeleteIcon className="text-secondary-lGrayDark hover:text-status-error w-[22px] h-[22px] mb-[2px]" />
                    </button>
                </div>
            )}
            <ConfirmModal
                isShow={deleteModalShow}
                setIsShow={setDeleteModalShow}
                title="현재 평가기간을 삭제하시겠습니까?"
                activeFunc={removeEvaluationPeriod}
            />
        </div>
    );
};

export default Dropdown;

// 사용 방법
// const [selectedValueDD, setSelectedValueDD] = useState('');

// const options = [
//     { value: 'option1', label: 'selcted List1' },
//     { value: 'option2', label: '옵션 2' },
//     { value: 'option3', label: '옵션 3' },
// ];

// <Dropdown
// options={options}
// value={selectedValueDD}
// onChange={setSelectedValueDD}
// />

// {/* 비활성화된 드롭다운 */}
// <Dropdown
// options={options}
// value={selectedValueDD}
// onChange={setSelectedValueDD}
// disabled
// />

// {/* 에러 상태의 드롭다운 */}
// <Dropdown
// options={options}
// value={selectedValueDD}
// onChange={setSelectedValueDD}
// error
// />

interface NewPeriodInfoType {
    newInputEndDate: string;
    newInputStartDate: string;
    newPeriodEndDate: string;
    newPeriodStartDate: string;
    newPeriodNumber: number;
    newYear: string;
}
