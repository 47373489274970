// hooks
import { useParamNavigate } from 'hooks/useDynamicNavigate';
import { useState, useEffect } from 'react';
// api
import { useApiQuery, useApiMutation } from 'hooks/useDynamicApi';
// commons
import DatePicker from 'components/datePicker';
import Dropdown from 'components/dropdown/Default';
import CustomTable from 'components/table/CustomTable';
import EmptyBar from 'components/emptyBar';
import { showToast } from 'components/toast/Default';
import Pagination from 'components/pagenation';
import ConfirmModal from 'components/modals/ConfirmModal';
import TimeSelector from 'components/dropdown/TimeSelector';
import DefaultModal from 'components/modals/DefaultModal';
// icons
import { ReactComponent as CalendarIcon } from 'assets/svgs/calendar.svg';
import { ReactComponent as PlusIcon } from 'assets/svgs/plus.svg';
import { ReactComponent as EditPenIcon } from 'assets/svgs/editPen.svg';
import { ReactComponent as DeleteIcon } from 'assets/svgs/delete.svg';
// utils
import { paramFormChanger } from 'utils/formChanger';
import { ENDPOINT_MEASUREMENT } from 'utils/consts/apiEndpoint';
import { departmentMatcher } from 'utils/matchers/measurementMatcher';
import { departmentOptions } from 'utils/consts/measurement';
// assets
import aisoccerWhiteFont from 'assets/logos/font-logo/aisoccer-white-font.webp';
// redux
import { RootState } from 'store';
import { useSelector } from 'react-redux';
const HandRecords = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    const [measurementDate, setMeasurementDate] = useState(`${year}-${month}-${day}`);
    const [measurementTime, setMeasurementTime] = useState('');
    const [department, setDepartment] = useState('PANGYO');
    const [isDeleteShow, setIsDeleteShow] = useState(false);
    const [deleteId, setDeleteId] = useState('');
    const [reason, setReason] = useState('');
    const [page, setPage] = useState(1);
    const [newTime, setNewTime] = useState('');
    const [timeAddModal, setTimeAddModal] = useState(false);
    const [pagInfo, setPageInfo] = useState({
        page: 1,
        size: 20,
        totalElements: 1,
        totalPages: 1,
    });
    const { role, staffRole } = useSelector((state: RootState) => state.userInfo); // 인증 상태를 token 유무로 판단
    const handleParamNavigate = useParamNavigate();
    const handleNavigate = (measuredAt: string, department = 'PANGYO', navigateUrl = '') => {
        // PANGYO 이후 추가 지점이 되면 하드코딩에서 변경필요
        handleParamNavigate({ measuredAt: measuredAt, department: department }, false, navigateUrl);
    };
    // useApiMutation
    const getMeasurementTimes = useApiQuery(
        ['getMeasurementTimes', measurementDate, department],
        ENDPOINT_MEASUREMENT.measurementTimeParams(
            paramFormChanger({ date: `${measurementDate}`, department: role === 'SUPER_ADMIN' ? department : '' })
        ),
        'get',
        {
            enabled: !!measurementDate, // falsy 값이면 쿼리 실행 안 함
        }
    );
    const getMeasurementRecord = useApiQuery(
        ['getMeasurementRecord', measurementDate, measurementTime, department],
        ENDPOINT_MEASUREMENT.measurementDateParams(
            paramFormChanger({
                measuredAt: `${measurementDate}T${measurementTime}`,
                department: role === 'SUPER_ADMIN' ? department : '',
            })
        ),
        'get',
        {
            enabled: !!measurementDate && !!measurementTime, // falsy 값이면 쿼리 실행 안 함
        }
    );
    const [timeOptions, setTimeOptions] = useState<{ value: string; label: string }[]>([]);
    useEffect(() => {
        if (getMeasurementTimes?.data && getMeasurementTimes?.isSuccess) {
            setTimeOptions(handleDropdownOpts(getMeasurementTimes?.data));
        }
    }, [getMeasurementTimes?.data, getMeasurementTimes?.isSuccess]);
    const activeTimeAddModal = () => {
        // 새로운 시간 옵션 생성
        const newTimeOption = {
            value: newTime,
            label: newTime,
        };
        // 중복 체크
        const isDuplicate = timeOptions.some((option) => option.value === newTime);
        if (!isDuplicate) {
            // 새로운 옵션을 기존 배열에 추가
            setTimeOptions((prev) => [...prev, newTimeOption]);
        }
        setMeasurementTime(newTime);
        setTimeAddModal(false); // 모달 닫기
    };

    const customizedRows = handleRows(
        getMeasurementRecord?.data,
        setIsDeleteShow,
        setDeleteId,
        handleNavigate,
        measurementDate,
        measurementTime,
        department
    );

    useEffect(() => {
        if (
            getMeasurementRecord?.isSuccess &&
            getMeasurementRecord?.data &&
            getMeasurementRecord?.data?.pageInfo?.page === 1
        ) {
            setPageInfo(getMeasurementRecord?.data?.pageInfo);
        }
    }, [getMeasurementRecord?.isSuccess, getMeasurementRecord?.data]);

    const deleteMeasurement = useApiMutation(ENDPOINT_MEASUREMENT.measurement(deleteId), 'delete', {
        onSuccess: (data) => {
            showToast('측정 기록을 삭제했습니다.', 'success');
            setReason('');
        },
        onError: (error) => {
            const errorMessage = error?.data?.detailedMessage || error || '측정 기록 삭제에 실패했습니다.';
            showToast(errorMessage, 'error');
        },
    });

    const removeMeasurement = () => {
        if (!deleteMeasurement?.isPending) {
            showToast('측정 기록을 삭제중입니다.', 'info');
            deleteMeasurement?.mutate({ reason: reason });
        }
    };
    return (
        <div className="handWrapper">
            <ConfirmModal
                isShow={isDeleteShow}
                setIsShow={setIsDeleteShow}
                title={
                    <div>
                        <div>
                            측정기록 삭제시,
                            <span className="font-bold text-status-error">복구할 수 없습니다.</span>
                        </div>
                    </div>
                }
                content={
                    <div>
                        <input
                            value={reason}
                            onChange={(e) => setReason(e.target.value)}
                            className="inputDefaultStyle inputCommonStyle"
                            placeholder="기록 삭제 사유 (100글자 이하)"
                            maxLength={100}
                        />
                    </div>
                }
                deleteBtnText="삭제"
                activeFunc={removeMeasurement}
            />
            <DefaultModal
                isShow={timeAddModal}
                setIsShow={setTimeAddModal}
                title="새로운 측정을 기록할 시간을 선택해주세요"
                content={
                    <div className="w-full">
                        <TimeSelector
                            selectedTime={newTime}
                            setSelectedTime={setNewTime}
                        />
                    </div>
                }
                activeFunc={activeTimeAddModal}
            />
            <div className="flex justify-between">
                {role === 'SUPER_ADMIN' ? (
                    <div className="w-[150px]">
                        <Dropdown
                            placeholder={departmentMatcher(department)}
                            onChange={(value) => setDepartment(value)}
                            options={departmentOptions}
                            className="text-[20px]"
                        />
                    </div>
                ) : (
                    <div className="text-[26px] font-bold text-white">판교점</div>
                )}
                <img
                    onClick={() => handleNavigate(`${measurementDate}T${measurementTime}`, department, `/hand/signIn`)}
                    src={aisoccerWhiteFont}
                    className="w-[140px] h-[21.04px]"
                    alt="aisoccerWhiteFont "
                />
            </div>
            <EmptyBar customStyle={'h-[30px]'} />
            <div className="bg-primary-white rounded-[10px] p-[30px]">
                <div className="flex gap-[10px] items-center">
                    <div className="text-context-bold">측정일시</div>

                    <DatePicker
                        modalBtn={
                            <div className="flex items-center justify-between p-[10px] gap-[14px] w-[204px] h-[44px] bg-secondary-mGrayLight rounded-[8px]">
                                {measurementDate || '측정일시'}
                                <CalendarIcon />
                            </div>
                        }
                        selected={measurementDate}
                        setSelected={setMeasurementDate}
                    />
                    <Dropdown
                        options={timeOptions}
                        value={measurementTime}
                        onChange={setMeasurementTime}
                        className="w-[96px]"
                        placeholder="시간"
                    />
                    <button
                        onClick={() => setTimeAddModal(true)}
                        className="text-secondary-lGrayDark gap-[5px] rounded-[8px] flex items-center justify-center "
                    >
                        <PlusIcon />
                        <span>시간</span>
                    </button>
                </div>

                <EmptyBar customStyle="h-[20px]" />
                <CustomTable
                    columns={columns}
                    rows={customizedRows}
                    equalWidth={false}
                />

                <button
                    onClick={() =>
                        measurementDate && measurementTime
                            ? handleNavigate(`${measurementDate}T${measurementTime}`, department, `/hand/measurement`)
                            : showToast('측정일과 시간을 선택해주세요', 'warning')
                    }
                    className="w-full h-[44px] mt-[10px] rounded-[8px] text-context-regular text-secondary-lGrayDark gap-[10px] border border-secondary-lGrayMid flex items-center justify-center"
                >
                    <PlusIcon />
                    수기 측정 추가
                </button>
                <EmptyBar customStyle="h-[32px]" />
                <div className="m-auto">
                    <Pagination
                        page={page}
                        size={pagInfo?.size}
                        totalElement={pagInfo?.totalElements}
                        totalPages={pagInfo?.totalPages}
                        onPageChange={setPage}
                    />
                </div>
            </div>
        </div>
    );
};

export default HandRecords;
const columns = [
    { key: 'playerName', label: '이름' },
    { key: 'measuredDate', label: '측정일' },
    { key: 'measuredTime', label: '측정시간' },
    { key: 'department', label: '지점' },
    { key: 'link', label: '상세보기' },
    { key: 'edit', label: '수정' },
    { key: 'delete', label: '삭제' },
];

export const handleRows = (
    rows: any[],
    setIsDeleteShow: (show: boolean) => void,
    setDeleteId: (id: string) => void,
    handleNavigate: any,
    measurementDate?: string,
    measurementTime?: string,
    department = ''
) => {
    return rows?.map((row) => ({
        playerName: row?.playerName,
        measuredDate: measurementDate,
        measuredTime: measurementTime,
        department: departmentMatcher(department),
        link: `/hand/measurement/${row?.id}?dateTime=${measurementDate}T${measurementTime}`,
        // 상세보기 링크 /admin/control/measurement/48?measurementId=17&playerId=48&dateTime=2025-01-14T11%3A00%3A00
        edit: (
            <button
                onClick={() => handleNavigate(`${measurementDate}T${measurementTime}`, department, `/hand/measurement`)}
                className={`ml-[4px] hover:text-primary-purple text-secondary-mGrayDark`}
            >
                <EditPenIcon />
            </button>
        ),
        delete: (
            <button
                onClick={() => {
                    setIsDeleteShow(true);
                    setDeleteId(row?.id);
                }}
                className={`ml-[4px] text-status-error`}
            >
                <DeleteIcon />
            </button>
        ),
    }));
};

export const handleDropdownOpts = (arr: any[]) => {
    return arr?.map((arr) => ({
        value: arr?.measuredAt?.slice(11, 16),
        label: `${arr?.measuredAt?.slice(11, 16)}`, //${arr?.type}
    }));
};
