// hooks
import { useState } from 'react';
// api
import { useApiQuery, useApiMutation } from 'hooks/useDynamicApi';
// utils
import { ENDPOINT_TEAM } from 'utils/consts/apiEndpoint';
import { formatDateTimeMatcher } from 'utils/matchers/reportMatcher';
import { joinStatusMatcher } from 'utils/matchers/userInfoMatcher';
import { ageMatcher } from 'utils/matchers/userInfoMatcher';
import { playerMembershipSortMatcher } from 'utils/matchers/sortInfoMatcher';
//staff compontns
import RegistrationHeader from 'pages/staff/measurement/registration/RegistrationSubHeader';
// common
import EmptyBar from 'components/emptyBar';
import MoreNavBtn from 'components/button/MoreNavBtn';
import CustomTable from 'components/table/CustomTable';
import Pagination from 'components/pagenation';
import { showToast } from 'components/toast/Default';
import ConfirmModal from 'components/modals/ConfirmModal';
import DefaultModal from 'components/modals/DefaultModal';
// icons
import { ReactComponent as SearchIcon } from 'assets/svgs/search.svg';

const Registration = () => {
    const [moreSeasonData, setMoreSeasonData] = useState(false);
    const [requestId, setRequestId] = useState('');
    const [deletePlayerId, setdeletePlayerId] = useState('');
    const [deletePlayerModal, setDeletePlayerModal] = useState(false);
    const [requestStatus, setRequestStatus] = useState<'ACCEPTED' | 'REJECTED' | 'PENDING'>('PENDING');
    const [approvalModal, setApprovalModal] = useState(false);
    const [reason, setReason] = useState('');
    const [rejectModal, setRejectModal] = useState(false);

    const [playerName, setPlayerName] = useState('');
    const [fixedPlayerName, setFixedPlayerName] = useState('');
    const [sortType, setSortType] = useState('');
    const [page, setPage] = useState(1);
    const [pagInfo, setPageInfo] = useState({
        page: 1,
        size: 10,
        totalElements: 1,
        totalPages: 1,
    });
    const handleSort = (key: string, sort: 'ASC' | 'DESC') => {
        const customizedSort = playerMembershipSortMatcher(key, sort);
        setSortType(customizedSort);
    };
    // useApiMutation
    const getTeamRequest = useApiQuery(['getTeamRequest'], ENDPOINT_TEAM.staff.team.request, 'get');
    const getTeamMembershipPlayer = useApiQuery(
        ['getTeamMembershipPlayer', fixedPlayerName, sortType, `${page}`, `${pagInfo?.size}`],
        ENDPOINT_TEAM.staff.team.playerParams({
            playerName: fixedPlayerName,
            sortType: sortType,
            page: page,
            size: pagInfo?.size,
        }),
        'get'
    );

    // useApiMutation
    const patchTeamRequest = useApiMutation(ENDPOINT_TEAM.player.request(requestId), 'patch', {
        onSuccess: (data) => {
            showToast(`가입 ${joinStatusMatcher(requestStatus)} 되었습니다.`, 'success');
        },
        onError: (error) => {
            // 에러 메시지 추출
            const errorMessage =
                error?.data?.detailedMessage || error || `가입 ${joinStatusMatcher(requestStatus)} 실패 했습니다.`;

            showToast(`${errorMessage}.`, 'error');
        },
    });
    const patchMembershipPlayer = useApiMutation(ENDPOINT_TEAM.staff.team.release(deletePlayerId), 'patch', {
        onSuccess: (data) => {
            showToast(`선수를 방출했습니다.`, 'success');
        },
        onError: (error) => {
            // 에러 메시지 추출
            const errorMessage = error?.data?.detailedMessage || error || `선수 방출 실패했습니다.`;
            showToast(`${errorMessage}.`, 'error');
        },
    });
    const updateTeamRequest = () => {
        if (!patchTeamRequest?.isPending) {
            showToast('가입 상태 변경중입니다.', 'info');
            patchTeamRequest?.mutate({
                status: requestStatus,
                rejectReason: 'string',
            });
        }
    };
    const updateMembershipPlayer = () => {
        if (!patchMembershipPlayer?.isPending) {
            showToast('선수 방출중입니다.', 'info');
            patchMembershipPlayer?.mutate({});
        }
    };
    const readyRows = handleReadyRows(
        getTeamRequest?.data,
        setRequestId,
        setRequestStatus,
        setApprovalModal,
        setRejectModal
    );
    // row data
    const customizedRow =
        handleMembershipRows(getTeamMembershipPlayer?.data?.data, setDeletePlayerModal, setdeletePlayerId) || [];

    return (
        <div className="wrapper">
            <ConfirmModal
                isShow={rejectModal}
                setIsShow={setRejectModal}
                title={'가입을 거절하시겠습니까?'}
                content={
                    <div>
                        <input
                            value={reason}
                            onChange={(e) => setReason(e.target.value)}
                            className="inputDefaultStyle inputCommonStyle"
                            placeholder="가입 거절 사유 (100글자 이하)"
                            maxLength={100}
                        />
                    </div>
                }
                deleteBtnText="거절"
                activeFunc={updateTeamRequest}
            />
            <DefaultModal
                isShow={approvalModal}
                setIsShow={setApprovalModal}
                title={'가입을 승인하시겠습니까?'}
                content={''}
                activeFunc={updateTeamRequest}
            />
            <ConfirmModal
                isShow={deletePlayerModal}
                setIsShow={setDeletePlayerModal}
                title={'선수를 방출하시겠습니까?'}
                deleteBtnText="방출"
                activeFunc={updateMembershipPlayer}
            />
            <RegistrationHeader />
            <EmptyBar customStyle="h-[35px]" />
            <div className="flex flex-col gap-[20px]">
                <div className="bg-primary-white rounded-[10px] p-[30px] relative">
                    <div className="flex items-center justify-between">
                        <div className="gap-[10px] flex items-center text-subtitle-medium-bold text-secondary-dGrayMid">
                            <span>가입 대기</span>
                            <span className="text-primary-purple">{`[${getTeamRequest?.data?.length || 0}명]`}</span>
                            <span>입니다</span>
                        </div>
                        <MoreNavBtn
                            type="white"
                            direction={moreSeasonData ? 'up' : 'down'}
                            activeFunc={() => setMoreSeasonData(!moreSeasonData)}
                        />
                    </div>
                    {moreSeasonData && (
                        <div className="bg-primary-white rounded-[10px] absolute top-0 left-0 z-[1] w-full border-2 border-primary-purple p-[30px]">
                            <div>
                                <div className="flex items-center justify-between">
                                    <div className="gap-[10px] flex items-center text-subtitle-medium-bold text-secondary-dGrayMid">
                                        <span>가입 대기</span>
                                        <span className="text-primary-purple">{`[${
                                            getTeamRequest?.data?.length || 0
                                        }명]`}</span>
                                        <span>입니다</span>
                                    </div>
                                    <MoreNavBtn
                                        type="white"
                                        direction={moreSeasonData ? 'up' : 'down'}
                                        activeFunc={() => setMoreSeasonData(!moreSeasonData)}
                                    />
                                </div>
                                <div className="mt-[20px]">
                                    <CustomTable
                                        columns={readyColumns}
                                        rows={readyRows}
                                        equalWidth={false}
                                        minWidth="max-w-[200px]"
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                <div className="bg-primary-white rounded-[10px] p-[30px] flex flex-col gap-[20px]">
                    <div className="gap-[10px] justify-between flex items-center text-subtitle-medium-bold text-secondary-dGrayMid">
                        선수 명단
                        <div className="relative w-[500px] h-[44px]">
                            <input
                                className="pl-[12px] pr-[40px] w-full h-[44px] text-context-regular rounded-[8px] text-black inputDefaultStyle"
                                placeholder="이름을 입력해주세요"
                                value={playerName}
                                onChange={(e) => setPlayerName(e.target.value)}
                            />
                            <button
                                onClick={() => setFixedPlayerName(playerName)}
                                className="absolute right-[12px] top-[12px]"
                            >
                                <SearchIcon />
                            </button>
                        </div>
                    </div>
                    <CustomTable
                        columns={columns}
                        rows={customizedRow}
                        sortInfoFunc={handleSort}
                    />
                    <Pagination
                        page={page}
                        size={pagInfo?.size}
                        totalElement={pagInfo?.totalElements}
                        totalPages={pagInfo?.totalPages}
                        onPageChange={setPage}
                    />
                </div>
            </div>
        </div>
    );
};

export default Registration;

export const columns = [
    { key: 'playerName', label: '이름', sortable: true },
    { key: 'age', label: '나이', sortable: true },
    { key: 'uniformNumber', label: '등번호', sortable: true },
    { key: 'height', label: '키' },
    { key: 'weight', label: '몸무게' },
    { key: 'teamName', label: '팀 이름' },
    { key: 'teamAgeGroup', label: '연령' },
    { key: 'release', label: '방출하기' },
];
export const readyColumns = [
    { key: 'createdAt', label: '요청일' },
    { key: 'userName', label: '이름' },
    { key: 'userAge', label: '나이' },
    { key: 'teamName', label: '팀 이름' },
    { key: 'teamAgeGroup', label: '연령' },
    { key: 'reason', label: '가입 사유' },
    { key: 'approve', label: '승인' },
];
const handleReadyRows = (
    rows: any[],
    setRequestId: (requestId: string) => void,
    setRequestStatus: (status: 'ACCEPTED' | 'REJECTED' | 'PENDING') => void,
    setApprovalModal: (show: boolean) => void,
    setRejectModal: (show: boolean) => void
) => {
    return rows?.map((row) => ({
        createdAt: formatDateTimeMatcher(row?.createdAt),
        userName: row?.userName,
        userAge: row?.userAge,
        teamName: row?.teamName,
        teamAgeGroup: row?.teamAgeGroup,
        reason: <div className="w-[140px] m-auto">{row?.reason}</div>,
        approve: (
            <div>
                {row?.status === 'PENDING' ? (
                    <div className="flex gap-[10px] w-[110px] m-auto">
                        <button
                            onClick={() => {
                                setRequestId(row?.requestId);
                                setRequestStatus('ACCEPTED');
                                setApprovalModal(true);
                            }}
                            className="p-[12px] shrink-0 text-white text-context-bold bg-primary-purple rounded-[8px]"
                        >
                            승인
                        </button>
                        <button
                            onClick={() => {
                                setRequestId(row?.requestId);
                                setRequestStatus('REJECTED');
                                setRejectModal(true);
                            }}
                            className="p-[12px] shrink-0 text-white text-context-bold bg-secondary-lGrayDark rounded-[8px]"
                        >
                            거절
                        </button>
                    </div>
                ) : (
                    joinStatusMatcher(row?.status)
                )}
            </div>
        ),
    }));
};

const handleMembershipRows = (
    rows: any[],
    setDeletePlayerModal: (show: boolean) => void,
    setdeletePlayerId: (playerId: string) => void
) => {
    return rows?.map((row) => ({
        playerName: row?.playerName,
        age: `${row?.age}세 (${ageMatcher(row?.age)})`,
        uniformNumber: row?.uniformNumber,
        height: row?.height,
        weight: row?.weight,
        teamName: row?.teamName,
        teamAgeGroup: row?.teamAgeGroup,
        release: (
            <button
                onClick={() => {
                    setDeletePlayerModal(true);
                    setdeletePlayerId(row?.playerId);
                }}
                className="p-[12px] shrink-0 text-white text-context-bold bg-secondary-lGrayDark rounded-[8px]"
            >
                방출하기
            </button>
        ),
    }));
};
