// hooks
import { useLocation } from 'react-router-dom';
import { useSearchParams } from 'react-router-dom';
// hooks
import { useParamNavigate } from 'hooks/useDynamicNavigate';

// commons
import MoreNavBtn from 'components/button/MoreNavBtn';
import CustomTable from 'components/table/CustomTable';
// utils
import { injuryStateBgMatcher, injuryMatcher } from 'utils/matchers/injuryMatcher';
interface CurrentInjuryType {
    status: 'TRAINING' | 'REHABILITATION' | 'INJURED' | 'HOSPITAL' | 'HOME';
    expectedReturnDate: string;
    injuryDescription: string;
}
interface injuryInfoType {
    currentInjury: CurrentInjuryType;
    yearlyInjuries: [
        {
            InjuryHistoryInjuryHistory: {
                injuryDate: [];
                injuryDescription: [];
                recoveryDays: [];
            };
        }
    ];
}

const InjuryCard = ({ injuryInfo }: { injuryInfo: injuryInfoType }) => {
    const location = useLocation().pathname;
    const [searchParams] = useSearchParams();
    const handleParamNavigate = useParamNavigate();

    const { currentInjury, yearlyInjuries } = injuryInfo || {};
    const customizedRow = handleRows(yearlyInjuries);
    const paramYear = searchParams.get('year') || '';
    const paramAgeGroup = searchParams.get('ageGroup') || '';
    const paramPlayerId = searchParams.get('player') || '';

    const handleMoreBtnNavigate = () => {
        if (paramAgeGroup && paramPlayerId && paramYear)
            handleParamNavigate(
                {
                    ageGroup: paramAgeGroup,
                    player: paramPlayerId,
                    year: paramYear,
                },
                false,
                `${location}/injury`
            );
    };

    const columns = [
        { key: 'injuryDate', label: '날짜' },
        { key: 'injuryDescription', label: '부상 내용' },
        { key: 'recoveryDays', label: '기간' },
    ];

    return (
        <div className="w-[690px] h-[370px] bg-primary-white rounded-[10px] p-[30px] flex flex-col gap-[15px]">
            <div className="text-subtitle-medium-bold h-[40px] flex justify-between items-center">
                <div className="text-secondary-dGrayMid">부상정보</div>
                <MoreNavBtn
                    type="purple"
                    activeFunc={handleMoreBtnNavigate}
                />
            </div>
            <div className="flex gap-[20px]">
                <div className="flex gap-[10px] w-[134px] items-center">
                    <div className="text-subtitle-medium-bold"> 현재상태</div>{' '}
                    <div
                        className={`${injuryStateBgMatcher(
                            currentInjury?.status || 'TRAINING'
                        )} rounded-[80px] h-[29px] w-[49px]  text-[14px] font-bold flex justify-center items-center `}
                    >
                        {injuryMatcher(currentInjury?.status || 'TRAINING')}
                    </div>
                </div>
                <div className="font-semibold text-subtitle-small-bold">
                    {currentInjury?.expectedReturnDate && (
                        <div className="w-full">
                            <span className="text-secondary-mGrayDark mr-[10px]">복귀예정</span>
                            <span className="text-secondary-dGrayDark">{currentInjury?.expectedReturnDate}</span>
                        </div>
                    )}
                    {currentInjury?.injuryDescription && (
                        <div className="w-full mt-[5px]">
                            <span className="text-secondary-mGrayDark mr-[10px]">부상내용</span>
                            <span className="text-secondary-dGrayDark">{currentInjury?.injuryDescription}</span>
                        </div>
                    )}
                </div>
            </div>
            <div className="flex flex-col gap-[10px] mt-[10px]">
                <div className="flex gap-[10px] items-center">
                    <div className="text-subtitle-medium-bold">올해 총 부상 기록</div>{' '}
                    <div className="bg-status-error rounded-[80px] py-[4px] px-[12px] text-primary-white text-[14px] font-bold">
                        {yearlyInjuries?.length || 0}
                    </div>
                </div>
                {yearlyInjuries?.length > 0 && (
                    <div className="">
                        <CustomTable
                            columns={columns}
                            rows={customizedRow}
                            equalWidth={true}
                            minWidth={'min-w-[116px]'}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};
export default InjuryCard;

export const handleRows = (rows: any[]) => {
    return rows?.map((row) => ({
        injuryDate: row?.injuryDate,
        injuryDescription: row?.injuryDescription,
        recoveryDays: row?.recoveryDays,
    }));
};
