// hooks
import { useEffect, useState } from "react";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
// api
import { useApiQuery } from "hooks/useDynamicApi";
// common
import Dropdown from "components/dropdown/Default";
// utils
import { useParamNavigate } from "hooks/useDynamicNavigate";
import { ENDPOINT_TEAM } from "utils/consts/apiEndpoint";
import {
  dropDownFormChanger,
  dropDownFormChangerType,
} from "utils/formChanger";
// icons
import { ReactComponent as HomeIcon } from "assets/svgs/home.svg";
import { ReactComponent as CircleArrowIcon } from "assets/svgs/circleArrow.svg";
// redux
import { RootState } from "store";
import { useSelector } from "react-redux";
const ReportPostSubHear = () => {
  const { staffRole } = useSelector((state: RootState) => state.userInfo);
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const paramAgeGroup = searchParams.get("ageGroup") || "";
  const paramPlayerId = searchParams.get("player") || "";
  const paramYear = searchParams.get("year");
  const [ageGroup, setAgeGroup] = useState(paramAgeGroup);
  const [ageGroupOpts, setAgeGroupsOpts] = useState<dropDownFormChangerType[]>(
    []
  );
  const handleParamNavigate = useParamNavigate();

  // react useApiQuery
  const getAgeGroup = useApiQuery(
    ["ageGroup"],
    ENDPOINT_TEAM.staff.team.ageGroups,
    "get",
    {
      enabled: staffRole === "OFFICE",
    }
  );
  useEffect(() => {
    if (getAgeGroup?.isSuccess && getAgeGroup?.data) {
      const { teamName, ageGroups } = getAgeGroup?.data;
      setAgeGroup(ageGroups[0]);
      const newAgeGroupOpts = dropDownFormChanger(ageGroups);
      setAgeGroupsOpts(newAgeGroupOpts);
    }
  }, [getAgeGroup?.data]);

  const handleHomeNavigate = () => {
    if (ageGroup && paramYear)
      handleParamNavigate(
        {
          ageGroup: ageGroup,
          year: paramYear,
        },
        false,
        `/staff/control/lineUp`
      );
  };

  return (
    <div className="flex flex-col gap-[15px]">
      <div className="flex gap-[8px] items-center">
        <button onClick={handleHomeNavigate}>
          <HomeIcon className="text-white" />
        </button>
        <div className="w-[3px] h-[3px] rounded-full bg-secondary-lGrayDark"></div>

        <Dropdown
          options={ageGroupOpts}
          value={ageGroup}
          placeholder="연령"
          onChange={setAgeGroup}
          activeFunc={handleHomeNavigate}
          className={
            " text-label-regular border rounded-[8px] border-primary-white"
          }
          innerClassName="px-[6px] py-[8px] text-label-regular"
        />
        <div className="text-white text-label-regular">일정</div>
      </div>
      <div className="flex items-center gap-[10px]">
        <button onClick={() => navigate(-1)}>
          <CircleArrowIcon className="text-white" />
        </button>
        <div className="text-[26px] font-bold text-white">일정</div>
      </div>
    </div>
  );
};

export default ReportPostSubHear;
