// icons
import { ReactComponent as ArrowRightIcon } from 'assets/svgs/arrowRight.svg';

export const areaOptions = {
    // width: '300px', // 전체 너비의 90%로 줄이기
    height: 'auto', // 높이를 350에서 300으로 줄이기
    chart: {
        id: 'areaChart',
        type: 'area',
        toolbar: {
            show: false,
        },
        zoom: {
            enabled: false, // 드래그 확대 비활성화
        },

        // events: {
        //   // 차트 전체 클릭 이벤트 추가
        //   click: (event: any, chartContext: any, config: any) => {
        //     if (config.dataPointIndex !== undefined) {
        //       const url = `/player/measurement/analysis`;
        //       window.location.href = `${url}/${456}`;
        //     }
        //   },
        // },
    },
    forecastDataPoints: {},

    stroke: {
        width: 1,
        curve: 'straight', // straight
        colors: ['#000000'], // 선 색상 변경
        dashArray: 2, // 점선 제거
    },
    xaxis: {
        type: 'date',
        categories: [
            '2024-01-12', // 날짜 형식 변경
            '2024-01-13', // 날짜 형식 변경
            '2024-01-14', // 날짜 형식 변경
            '2024-01-15', // 날짜 형식 변경
            '2024-01-16',
            '2024-01-17',
            '2024-01-18',
            '2024-01-19',
            '2024-01-20',
        ],
        tooltip: {
            enabled: true,
            // formatter: undefined,
            // offsetY: 0,
            // style: {
            //   background: "#5E6EFF", // 보라색 배경
            //   fontSize: 0,
            //   fontFamily: 0,
            // },
            // // 이 툴팁 디자인을 배경은 보라색, 텍스트는 하얀색으로 바꾸고 싶어. 말풍선 모양이 아닌 그냥 네모로
            // theme: "dark",
        },
        labels: {
            rotate: 0, // 라벨 회전 방지
            formatter: function (val: string) {
                const date = new Date(val);
                const month = String(date.getMonth() + 1).padStart(2, '0'); // 월 두 자리로 표시
                const day = String(date.getDate()).padStart(2, '0'); // 일 두 자리로 표시
                return `${month}.${day}`; // "MM.DD" 형식
            },
            style: {},
        },
    },
    yaxis: {
        min: 0, // 최소값
        max: 100, // 최대값
        tickAmount: 5, // 간격 개수 (0부터 시작해서 5개)
        labels: {
            formatter: (value: number) => value.toString(), // 값 그대로 표시
        },
    },
    fill: {
        type: 'gradient', // 단색 설정
        colors: ['#5E6EFF'], // 단색 채우기 색상
        gradient: {
            shade: 'dark',
            gradientToColors: ['#B8AFFF'], // 끝 색상 (주황색)
            gradientFromColors: ['#B8AFFF'], // 시작 색상 (주황색)
            shadeIntensity: 1,
            type: 'vertical',
            opacityFrom: 0.6, // 시작 투명도
            opacityTo: 0, // 끝 투명도
        },
    },

    title: {
        show: false, // 제목 숨김
    },
    grid: {
        row: {
            opacity: 0.5,
            // colors: ['#ccc234', 'transparent'], // takes an array which will be repeated on columns
        },
        borderColor: '#DCDCDC', // 그리드 선 제거는 transparent
    },
    markers: {
        show: false, // 이걸 커스텀하고싶어
        hover: {
            size: 3, // 호버 시 점 크기 0
        },
        strokeWidth: 3, // 마커 테두리 두께
        strokeColors: ['#5e6eff'], // 보라색 테두리 색상
        fillOpacity: 1, // 내부 채우기 불투명도
        colors: ['#FFFFFF'], // 내부 채우기 색상
        shape: 'circle', // 마커 모양
    },
    dataLabels: {
        enabled: false,
        textAnchor: 'middle',
        dropShadow: {
            enabled: false, // 드롭 섀도우 비활성화 (잘리는 현상 방지)
        },
        background: {
            enabled: true,
            foreColor: '#ffffff',
            padding: 10,
            borderRadius: 10,
            borderWidth: 1,
            borderColor: '#111111',
            opacity: 1,
        },
        // 호버 시 dataLabels 활성화
        hover: {
            enabled: true,
            show: true,
            onSeries: undefined, // 모든 시리즈에 적용
        },
    },
    events: {},
    tooltip: {
        enabled: true, // 기본 툴팁 완전 비활성화
        shared: false, // 하단 기본 툴팁 비활성화
        custom: function ({ series, seriesIndex, dataPointIndex, w }: any) {
            // 툴팁에 표시할 값 가져오기
            const value = series[seriesIndex][dataPointIndex];
            // 툴팁의 x축 좌표
            const xPosition = w.globals.pointsArray[seriesIndex][dataPointIndex];
            const yPosition = w.globals.pointsArray[seriesIndex][dataPointIndex][1];
            const dynamicY = yPosition < 30 ? 100 : 0;
            // 그래프 전체 너비
            const chartWidth = w.globals.svgWidth;
            return `<div style="width:165px; height:200px; position: relative">
      <div style="width:165px; height:60px; display: flex; align-items: center;
        justify-content: space-between; gap: 10px; background: #ffffff; border: 2px solid #6B5BFF;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); border-radius: 12px; padding: 6px 19px; font-size: 16px;
        font-weight: bold; color: #6B5BFF; position:absolute; left:${xPosition[0]}px; top:${dynamicY}px; zIndex: 10;
      ">
        <span>${value}점</span>
        <button style="
          background: #6B5BFF; color: #ffffff; font-size: 12px; font-weight: bold; border: none;
          border-radius: 80px; padding: 8px 12px; cursor: pointer;">
          분석하기
        </button>
        <!-- 말풍선 아래 뾰족한 부분 -->
        <div style="
          position: absolute;
          ${dynamicY ? 'top: -7.8px' : 'bottom: -7.4px'};
          left: 47%;
          transform: translateX(-50%);
          width: 12px;
          height: 12px;
          background: #ffffff;
          border-left: 2px solid #6B5BFF;
          border-bottom: 2px solid #6B5BFF;
          transform:  ${dynamicY ? 'rotate(135deg)' : 'rotate(-45deg)'};">
        </div>
      </div>

      </div>
    `;
        },
    },
};
export const areaSeries = [
    {
        name: 'Sales',
        data: [40, 3, 70, 60, 90, 80, 70, 60, 100],
    },
];
