// commons
import MainHeader from "pages/staff/components/Header";
import EmptyBar from "components/emptyBar";
// assets
import aisoccerWhiteSymbol from "assets/logos/symbol/aisoccer-white-symbol.webp";
const StaffLayout = () => {
  return (
    <div className="wrapper">
      <div className="absolute top-0 left-0 z-[-1] w-full h-full min-w-[1400px]">
        <div className="h-[400px] bg-primary-black min-w-[1400px]"></div>
        <img
          src={aisoccerWhiteSymbol}
          alt="aisoccerWhiteSymbol"
          className="absolute z-0 top-0 right-0 w-[470px]"
        />
      </div>
      <MainHeader />
      <EmptyBar customStyle={"h-[30px]"} />
    </div>
  );
};

export default StaffLayout;
