// hooks
import { useState, useRef, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
// utils
import { injuryMatcher, injuryStateBgMatcher } from 'utils/matchers/injuryMatcher';
import { ENDPOINT_INJURY } from 'utils/consts/apiEndpoint';
// api
import { useApiQuery, useApiMutation } from 'hooks/useDynamicApi';
// icons
import { ReactComponent as EditPenIcon } from 'assets/svgs/editPen.svg';
import { ReactComponent as CalendarIcon } from 'assets/svgs/calendar.svg';
// commons
import { showToast } from 'components/toast/Default';
import DatePicker from 'components/datePicker';
import ConfirmModal from 'components/modals/ConfirmModal';
const InjuryCurrentStateTable = () => {
    const [searchParams] = useSearchParams();
    const paramPlayerId = searchParams.get('player') || '';
    const [currentStateEdit, setCurrentStateEdit] = useState(false);
    const [deleteModalShow, setDeleteModalShow] = useState(false);
    // react useApiQuery
    const getInjury = useApiQuery(['getInjury', paramPlayerId], ENDPOINT_INJURY.current(paramPlayerId), 'get', {
        staleTime: 0, // 신선한 데이터로 간주
        refetchOnMount: true,
    });

    // useApiMutation
    const postInjury = useApiMutation(ENDPOINT_INJURY.current(), 'post', {
        onSuccess: (data) => {
            showToast('현재 부상상태가 등록되었습니다.', 'success');
            setCurrentStateEdit(false);
        },
        onError: (error) => {
            const detailError = error?.data?.detailedMessage || error || `현재 부상상태 등록에 실패했습니다.`;
            showToast(detailError, 'error');
        },
    });
    const patchInjury = useApiMutation(ENDPOINT_INJURY.current(paramPlayerId), 'patch', {
        onSuccess: (data) => {
            showToast('현재 부상상태가 업데이트 되었습니다.', 'success');
            setCurrentStateEdit(false);
        },
        onError: (error) => {
            const detailError = error?.data?.detailedMessage || error || `현재 부상상태 변경 실패했습니다.`;
            showToast(detailError, 'error');
        },
    });
    const deleteInjury = useApiMutation(ENDPOINT_INJURY.current(paramPlayerId), 'delete', {
        onSuccess: (data) => {
            handleInit();
            showToast('현재 부상상태가 업데이트 되었습니다.', 'success');
            setCurrentStateEdit(false);
        },
        onError: (error) => {
            const detailError = error?.data?.detailedMessage || error || `현재 부상상태를 삭제 할 수 없습니다.`;
            showToast(detailError, 'error');
        },
    });
    // state
    const [injuryDate, setInjuryDate] = useState(getInjury?.data?.injuryDate);
    const [expectedReturnDate, setExpectedReturnDate] = useState(getInjury?.data?.expectedReturnDate);
    const [injuryInfo, setInjuryInfo] = useState({
        status: getInjury?.data?.status || 'TRAINING',
        injuryDescription: getInjury?.data?.injuryDescription,
        currentCondition: getInjury?.data?.currentCondition,
        treatmentDescription: getInjury?.data?.treatmentDescription,
    });
    useEffect(() => {
        if (getInjury?.isSuccess && getInjury?.data) {
            setInjuryInfo(getInjury?.data);
            setInjuryDate(getInjury?.data?.injuryDate);
            setExpectedReturnDate(getInjury?.data?.expectedReturnDate);
        }
        if (getInjury?.isError && getInjury?.data) {
            handleInit();
        }
    }, [getInjury?.isSuccess, getInjury?.isError, getInjury?.data]);

    // handle
    const updateInjuryState = (field: string, value: string) => {
        if (field === 'status' && value === 'TRAINING') {
            handleInit();
        }
        setInjuryInfo((prev) => ({
            ...prev,
            [field]: value, // 특정 필드만 업데이트
        }));
    };
    const handleInjurySubmit = () => {
        if (!patchInjury.isPending) {
            const injuryBody = {
                playerId: paramPlayerId,
                status: injuryInfo.status,
                injuryDescription: injuryInfo.injuryDescription,
                currentCondition: injuryInfo.currentCondition,
                treatmentDescription: injuryInfo.treatmentDescription,
                injuryDate: injuryDate || null,
                expectedReturnDate: expectedReturnDate,
            };
            if (getInjury?.data && getInjury?.status === 'success') {
                showToast(`현재 부상 정보 업데이트 중입니다.`, 'info');
                if (injuryInfo.status === 'TRAINING') {
                    // POST 훈련의 경우, 현재 부상상태 아예 삭제
                    deleteInjury.mutate({});
                    return;
                }

                patchInjury.mutate(injuryBody);
            } else {
                if (injuryInfo.status === 'TRAINING') {
                    // PATCH 훈련의 경우, 현재 부상상태 초기화
                    setCurrentStateEdit(false);
                    return;
                } else {
                    showToast(`현재 부상 정보 업데이트 중입니다.`, 'info');
                    postInjury.mutate(injuryBody);
                }
            }
        }
    };
    const removeInjury = () => {
        if (!deleteInjury?.isPending) {
            showToast('부상 정보 업데이트 중입니다', 'info');
            deleteInjury.mutate({});
        }
    };

    const injuryStates = [
        { key: 'TRAINING', label: '훈련' },
        { key: 'REHABILITATION', label: '재활' },
        { key: 'INJURED', label: '부상' },
        { key: 'HOSPITAL', label: '병원' },
        { key: 'HOME', label: '귀가' },
    ];

    const tableHeaderStyle =
        'bg-primary-black text-primary-white h-[50px] text-context-regular flex justify-center items-center';
    const tableBodyCheckStyle = 'h-[50px] text-context-regular flex justify-center items-center';
    const tableTextAreaStyle =
        'text-context-regular w-[147.5px] resize-none focus:outline-none h-auto pt-[10px] text-center overflow-hidden';
    const textareaRefs = useRef<HTMLTextAreaElement[]>([]);
    useEffect(() => {
        if (getInjury?.data?.status && textareaRefs?.current?.length > 0) {
            textareaRefs.current.forEach((ref) => {
                if (ref) {
                    ref.style.height = 'auto';
                    ref.style.height = `${ref.scrollHeight}px`;
                }
            });
        }
    }, [injuryInfo.injuryDescription, injuryInfo.currentCondition, injuryInfo.treatmentDescription]);
    // init
    const handleInit = () => {
        setInjuryDate('');
        setExpectedReturnDate('');
        setInjuryInfo({
            status: 'TRAINING',
            injuryDescription: '',
            currentCondition: '',
            treatmentDescription: '',
        });
    };
    return (
        <div className="w-[985px] flex flex-col gap-[20px]">
            <ConfirmModal
                isShow={deleteModalShow}
                setIsShow={setDeleteModalShow}
                title="현재 부상정보를 삭제하시겠습니까?"
                activeFunc={removeInjury}
            />
            <div className="text-subtitle-medium-bold h-[40px] justify-between flex items-center">
                <div className="gap-[10px] flex items-center">
                    <div>현재 정보</div>
                    <span
                        className={`w-[49px] h-[29px] rounded-[80px] text-[14px] font-bold  flex justify-center items-center ${injuryStateBgMatcher(
                            injuryInfo?.status || 'TRAINING'
                        )}`}
                    >
                        {injuryMatcher(injuryInfo?.status || 'TRAINING')}
                    </span>
                </div>
                {currentStateEdit ? (
                    <div className="flex gap-[4px]">
                        <button
                            onClick={() => setDeleteModalShow(true)}
                            className="bg-secondary-lGrayDark rounded-[8px] text-primary-white w-[52px] h-[44px] text-context-bold"
                        >
                            삭제
                        </button>
                        <button
                            onClick={() => {
                                handleInjurySubmit();
                            }}
                            className="bg-primary-purple rounded-[8px] text-primary-white w-[52px] h-[44px] text-context-bold"
                        >
                            저장
                        </button>
                    </div>
                ) : (
                    <button onClick={() => setCurrentStateEdit(true)}>
                        <EditPenIcon className="text-primary-purple" />
                    </button>
                )}
            </div>
            <div className="flex">
                {injuryStates.map((el, idx) => (
                    <div key={el.key}>
                        <div
                            className={`${tableHeaderStyle} ${
                                idx === 0 && 'rounded-bl-[8px] rounded-tl-[8px]'
                            } min-w-[50px] `}
                        >
                            <div>{el.label}</div>
                        </div>
                        <button
                            onClick={(e) => currentStateEdit && updateInjuryState('status', el.key)}
                            className={`${tableBodyCheckStyle} min-w-[50px] `}
                        >
                            {el.key === injuryInfo?.status && '⚫'}
                        </button>
                    </div>
                ))}
                <div>
                    <div className={`${tableHeaderStyle} min-w-[147.5px]`}>
                        <div>부상내용</div>
                    </div>
                    <textarea
                        ref={(el) => (textareaRefs.current[0] = el!)}
                        readOnly={!currentStateEdit}
                        value={injuryInfo.injuryDescription}
                        onChange={(e) => updateInjuryState('injuryDescription', e.target.value)}
                        className={`${tableTextAreaStyle}`}
                    />
                </div>
                <div>
                    <div className={`${tableHeaderStyle} min-w-[147.5px]`}>
                        <div>부상 현재 상태</div>
                    </div>
                    <textarea
                        ref={(el) => (textareaRefs.current[1] = el!)}
                        readOnly={!currentStateEdit}
                        value={injuryInfo.currentCondition}
                        onChange={(e) => updateInjuryState('currentCondition', e.target.value)}
                        className={`${tableTextAreaStyle}`}
                    />
                </div>
                <div>
                    <div className={`${tableHeaderStyle} min-w-[147.5px]`}>
                        <div>치료 내용</div>
                    </div>
                    <textarea
                        ref={(el) => (textareaRefs.current[2] = el!)}
                        readOnly={!currentStateEdit}
                        value={injuryInfo.treatmentDescription}
                        onChange={(e) => updateInjuryState('treatmentDescription', e.target.value)}
                        className={`${tableTextAreaStyle}`}
                    />
                </div>
                <div>
                    <div className={`${tableHeaderStyle}  w-[147.5px]`}>
                        <div>부상일</div>
                    </div>
                    {/* <textarea
                        value={injuryInfo.injuryDate}
                        onChange={(e) => updateInjuryState('injuryDate', e.target.value)}
                        className={`${tableTextAreaStyle} w-[147.5px] resize-none`}
                    /> */}

                    {!currentStateEdit ? (
                        <div className="flex items-center justify-center gap-[14px] p-[10px] w-[147.5px] ">
                            {injuryDate ? injuryDate : '부상일'}
                            <CalendarIcon />
                        </div>
                    ) : (
                        <DatePicker
                            modalBtn={
                                <div className="flex items-center p-[10px] justify-center gap-[14px] w-[147.5px]">
                                    {injuryDate ? injuryDate : '부상일'}
                                    <CalendarIcon />
                                </div>
                            }
                            selected={injuryDate}
                            setSelected={setInjuryDate}
                        />
                    )}
                </div>
                <div>
                    <div className={`${tableHeaderStyle} min-w-[70px] rounded-tr-[8px] rounded-br-[8px] w-[147.5px]`}>
                        <div>복귀예정일</div>
                    </div>
                    {!currentStateEdit ? (
                        <div className="flex items-center p-[10px] gap-[14px] w-[147.5px]">
                            {expectedReturnDate ? expectedReturnDate : '복귀 예정일'}
                            <CalendarIcon />
                        </div>
                    ) : (
                        <DatePicker
                            modalBtn={
                                <div className="flex items-center p-[10px] gap-[14px] w-[147.5px]">
                                    {expectedReturnDate ? expectedReturnDate : '복귀 예정일'}
                                    <CalendarIcon />
                                </div>
                            }
                            selected={expectedReturnDate}
                            setSelected={setExpectedReturnDate}
                        />
                    )}
                </div>
                {/* <div>
                    <div className={`${tableHeaderStyle} min-w-[50px]`}>
                        <div>치료</div>
                    </div>
                    <input
                                                   value={injuryInfo.currentCondition}
                                   onChange={(e) => updateInjuryState('currentCondition', e.target.value)}
                    className={`${tableTextAreaStyle} w-[50px]`} />
                </div>
                <div>
                    <div className={`${tableHeaderStyle} min-w-[70px] rounded-tr-[8px] rounded-br-[8px]`}>
                        <div>마사지</div>
                    </div>
                    <input
                                                   value={injuryInfo.currentCondition}
                                   onChange={(e) => updateInjuryState('currentCondition', e.target.value)}
                    className={`${tableTextAreaStyle} w-[70px]`} />
                </div> */}
            </div>
        </div>
    );
};

export default InjuryCurrentStateTable;
