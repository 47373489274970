// hooks
import React from 'react';
// icons
import { ReactComponent as CheckIcon } from 'assets/svgs/check.svg';

interface SqureCheckboxProps {
    checked: boolean;
    onChange: (checked: boolean) => void;
    disabled?: boolean;
    label?: string;
    className?: string;
}

const SqureCheckbox = ({ checked, onChange, disabled = false, label, className = '' }: SqureCheckboxProps) => {
    const handleClick = () => {
        if (!disabled) {
            onChange(!checked);
        }
    };

    return (
        <div
            className={`inline-flex items-center gap-2 cursor-pointer ${
                disabled ? 'cursor-not-allowed' : ''
            } ${className}`}
            onClick={(e) => {
                e.stopPropagation(); // 부모로 전파 중단
                handleClick(); // 상태 변경
            }}
        >
            <div
                className={`
          w-[24px] h-[24px] flex items-center justify-center rounded-[6px]
          border transition-colors duration-200
          ${
              checked && !disabled
                  ? 'bg-primary-black border border-primary-black'
                  : disabled
                  ? 'border border-secondary-mGrayDark'
                  : 'border border-primary-black bg-primary-white'
          }
        `}
            >
                {checked && !disabled && (
                    <span className="text-primary-white w-[14.4px] h-[10.8px] ">
                        <CheckIcon />
                    </span>
                )}
            </div>
            {label && (
                <span
                    onClick={(e) => e.stopPropagation()} // 이벤트 전파 중단
                    className={`select-none ${disabled ? 'text-secondary-mGrayDark' : 'text-primary-black'}`}
                >
                    {label}
                </span>
            )}
        </div>
    );
};

export default SqureCheckbox;

/*
// 사용방법
<Checkbox
checked={isChecked}
onChange={setIsChecked}
label="기본 체크박스"
/> */
