import { useState } from 'react';
import StatusLabel from 'components/label/Default';
import { useParams, useSearchParams } from 'react-router-dom';
import { useApiMutation } from 'hooks/useDynamicApi';
import { showToast } from 'components/toast/Default';
import { ENDPOINT_MEASUREMENT } from 'utils/consts/apiEndpoint';
// redux
import { RootState } from 'store';
import { useSelector } from 'react-redux';
// icons
import { ReactComponent as CloseIcon } from 'assets/svgs/close.svg';

const DataSummaryCard = ({ isPrint, skillAnalysis }: any) => {
    const [searchParams] = useSearchParams();
    const dateTime = searchParams.get('dateTime') || '';
    //   const measurementId = searchParams.get("measurementId") || "";
    const { measurementId = '' } = useParams();

    const { role, staffRole } = useSelector((state: RootState) => state.userInfo); // 인증 상태를 token 유무로 판단
    const [feedback, setFeedback] = useState(skillAnalysis?.feedback);
    const [editFeedback, setEditFeedback] = useState(false);
    // 수정필요 0114.평균을 프론트에서 구해야 하는지?
    const totalAvg = (
        (skillAnalysis?.dribbleAvgScore +
            skillAnalysis?.firstTouchScore +
            skillAnalysis?.shootingAvgScore +
            skillAnalysis?.passAvgScore) /
        4
    ).toFixed(1);
    // useApiMutation
    const patchFeedback = useApiMutation(ENDPOINT_MEASUREMENT.feedback(measurementId), 'post', {
        onSuccess: (data) => {
            showToast('분석관 피드백이작성되었습니다.', 'success');
            setEditFeedback(false);
        },
        onError: (error) => {
            // 에러 메시지 추출
            const errorMessage = error?.data?.detailedMessage || error || '분석관 피드백 작성에 실패했습니다.';
            showToast(`${errorMessage}`, 'error');
        },
    });
    const updateFeedback = () => {
        if (
            (role === 'SUPER_ADMIN' ||
                // role === "SYSTEM_ADMIN" ||
                role === 'DATA_ANALYST') &&
            !patchFeedback?.isPending &&
            measurementId
        ) {
            showToast(`피드백 작성중입니다.`, 'info');

            patchFeedback.mutate({
                content: feedback,
            });
        }
    };
    return (
        <div className="gap-[20px] grid grid-cols-4">
            <div className="grid col-span-1">
                <div className="text-subtitle-medium-bold flex flex-col gap-[10px]">총점</div>
                <div className=" h-[200px] flex flex-col items-center justify-center">
                    <div>
                        <span className="text-context-bold">
                            {skillAnalysis?.sequenceNumber ? `${skillAnalysis?.sequenceNumber} 차` : ''}
                        </span>
                        <span className="text-context-regular text-[#868686] ml-[5px]">{dateTime?.slice(0, 10)}</span>
                    </div>
                    <div className="text-[80px] font-bold ">{totalAvg}</div>
                    <div>
                        <StatusLabel status={skillAnalysis?.scoreDiffFromPrev > 0 ? 'up' : 'down'}>
                            {skillAnalysis?.scoreDiffFromPrev || '준비중'}
                        </StatusLabel>
                    </div>
                </div>
            </div>
            <div className="grid col-span-1">
                <div className="text-subtitle-medium-bold">요약</div>
                <div className="h-[210px]  bg-[#f8f8f8] rounded-[14px] flex flex-col items-center gap-[20px] justify-center">
                    <div className="flex flex-col gap-[8px] justify-center items-center">
                        <div className="text-context-regular">가장 우수한 기술은</div>
                        <div className="px-[12px] py-[4px] bg-primary-purple text-primary-white rounded-[80px] w-fit text-[14px] font-bold">
                            {skillAnalysis?.bestSkill}
                        </div>
                    </div>
                    <div className="flex flex-col gap-[8px] justify-center items-center">
                        <div className="text-context-regular"> 개선이 필요한 기술은</div>
                        <div className="px-[12px] py-[4px] bg-secondary-dGrayLight rounded-[80px] w-fit text-white text-[14px] font-bold">
                            {skillAnalysis?.weakestSkill}
                        </div>
                    </div>
                </div>
            </div>
            <div className="relative grid col-span-2">
                <div className="text-subtitle-medium-bold">분석관 피드백</div>
                <div
                    className={
                        // isPrint ? "" :
                        'h-[210px]'
                    }
                >
                    {editFeedback ? (
                        <div className="w-[660px] absolute top-0 z-[10] bg-secondary-dGrayMid rounded-[10px] p-[20px]">
                            <button
                                className="absolute right-[20px]  text-primary-white top-[16px] "
                                onClick={() => setEditFeedback(false)}
                            >
                                <CloseIcon className="w-[25px] h-[25px]" />
                            </button>
                            <div className="h-[20px] text-context-regular text-center text-primary-white mb-[18px]">
                                분석관 피드백
                            </div>

                            <textarea
                                className="
                custom-scroll-container
                bg-secondary-mGrayLight h-[160px] w-[620px] rounded-[8px] resize-none p-[12px]"
                                value={feedback}
                                onChange={(e) => setFeedback(e.target.value)}
                            ></textarea>
                            <button
                                className="bg-primary-purple text-primary-white  w-full p-[12px] rounded-[8px] mt-[18px] text-context-bold"
                                onClick={updateFeedback}
                            >
                                작성완료
                            </button>
                        </div>
                    ) : (
                        // : isPrint ? (
                        //   <div
                        //     className={
                        //       "bg-secondary-mGrayLight w-full h-fit rounded-[8px] resize-none p-[12px] text-secondary-lGrayDark"
                        //     }
                        //     onClick={() => {
                        //       if (
                        //         role === "SUPER_ADMIN" ||
                        //         role === "SYSTEM_ADMIN" ||
                        //         role === "DATA_ANALYST"
                        //       ) {
                        //         setEditFeedback(true);
                        //       }
                        //     }}
                        //   >
                        //     {feedback}
                        //   </div>
                        // )
                        <textarea
                            className={
                                'custom-scroll-container bg-secondary-mGrayLight w-full h-[210px] rounded-[8px] resize-none p-[12px] text-secondary-lGrayDark overflow-y-auto'
                            }
                            onClick={() => {
                                if (
                                    role === 'SUPER_ADMIN' ||
                                    // role === "SYSTEM_ADMIN" ||
                                    role === 'DATA_ANALYST'
                                ) {
                                    setEditFeedback(true);
                                }
                            }}
                            readOnly
                            value={feedback}
                        ></textarea>
                    )}
                </div>
            </div>
        </div>
    );
};

export default DataSummaryCard;
