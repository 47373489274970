const ReportReview = ({ overallReview }: { overallReview: string }) => {
    return (
        <div className="flex h-full ">
            <div className="flex w-full">
                <div className="w-[150px]  h-full shrink-0  bg-secondary-mGrayLight text-secondary-lGrayDark text-context-regular flex justify-center items-center">
                    경기총평
                </div>
                <div className="w-full p-[24px]">
                    <textarea
                        defaultValue={overallReview}
                        readOnly
                        maxLength={2000}
                        className="w-[1149px] focus:outline-none rounded-[8px] p-[10px] bg-secondary-mGrayLight resize-none h-[150px]"
                    ></textarea>
                </div>
            </div>
        </div>
    );
};

export default ReportReview;
