import { useState } from 'react';
// api
import { useApiMutation } from 'hooks/useDynamicApi';
// icons
import { ReactComponent as CloseIcon } from 'assets/svgs/close.svg';
import { ReactComponent as ImageIcon } from 'assets/svgs/image.svg';
import { ReactComponent as SearchIcon } from 'assets/svgs/search.svg';
import { ReactComponent as SmallCloseIcon } from 'assets/svgs/smallClose.svg';
// commons
import { showToast } from 'components/toast/Default';
interface VideoAddModalType {
    modalBtn?: string | React.ReactNode;
}
const VideoAddModal = ({ modalBtn }: VideoAddModalType) => {
    const [isShow, setIsShow] = useState(false);
    const [thumbnail, setThumbnail] = useState<File | null>(null);
    const [curTag, setCurTag] = useState('');
    const [newVideoInfo, setNewVideoInfo] = useState({
        url: '',
        title: '',
        tags: [],
    });
    const handleInfo = (updateData: any) => {
        setNewVideoInfo((prev: any) => ({
            ...prev,
            ...updateData,
        }));
    };
    const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            setThumbnail(file);
        }
    };

    const handleTagRemove = (tagToRemove: string) => {
        handleInfo({
            tags: newVideoInfo?.tags.filter((tag: string) => tag !== tagToRemove),
        });
    };

    // handler
    const createTeam = () => {
        if (!newVideoInfo?.url || !thumbnail || !newVideoInfo?.title || !(newVideoInfo?.tags?.length < 1)) {
            showToast('필수 값을 입력해주세요.', 'warning');
            return;
        }
        // post 요청
    };

    return (
        <>
            <button onClick={() => setIsShow(true)}>{modalBtn}</button>
            {isShow && (
                <div
                    onClick={() => setIsShow(false)}
                    className="fixed left-0 z-[100] top-0 flex justify-center items-center w-full h-full bg-[#00000050]"
                >
                    <div
                        className=" bg-white rounded-[10px]  items-center p-[50px]"
                        onClick={(e) => e.stopPropagation()}
                    >
                        <div className="w-[400px] flex flex-col justify-center gap-[14px]">
                            <div className="flex justify-between text-secondary-dGrayMid text-subtitle-medium-bold">
                                <span>영상 추가</span>
                                <button onClick={() => setIsShow(false)}>
                                    <CloseIcon className="text-secondary-lGrayDark" />
                                </button>
                            </div>
                            <div>
                                <input
                                    value={newVideoInfo?.url}
                                    onChange={(e) => handleInfo({ url: e.target.value })}
                                    placeholder="영상 url"
                                    className="inputDefaultStyle inputCommonStyle"
                                />
                            </div>
                            <label className="flex items-center justify-between inputDefaultStyle inputCommonStyle">
                                <span className="text-secondary-lGrayDark">썸네일 이미지</span>
                                <ImageIcon />
                                <input
                                    type="file"
                                    accept=".jpg, .jpeg, .png, .webp, .heic, .heif"
                                    className="hidden"
                                    onChange={handleImageChange}
                                />
                            </label>
                            <div>
                                <input
                                    value={newVideoInfo?.title}
                                    onChange={(e) => handleInfo({ title: e.target.value })}
                                    placeholder="제목"
                                    maxLength={300}
                                    className="inputDefaultStyle inputCommonStyle"
                                />
                            </div>
                            <div className="relative">
                                <input
                                    placeholder="해시태그"
                                    maxLength={50}
                                    value={curTag}
                                    onChange={(e) => setCurTag(e.target.value)}
                                    className="inputDefaultStyle p-[12px] w-full h-[44px] text-context-regular rounded-[8px] text-black"
                                />
                                <button
                                    onClick={() => {
                                        if (curTag) {
                                            handleInfo({ tags: [...newVideoInfo?.tags, curTag] });
                                            setCurTag(''); // 태그 입력 필드 초기화
                                        }
                                    }}
                                >
                                    <SearchIcon className="top-[12px] right-[12px] absolute" />
                                </button>
                            </div>
                            <div className="flex gap-[5px] flex-wrap w-[400px]">
                                {newVideoInfo?.tags?.map((el: string, idx: number) => (
                                    <span
                                        key={idx}
                                        className="bg-[#d2d2d2] text-[#111111] text-[14px] font-bold px-[12px] py-[4px] rounded-[80px] flex items-center"
                                    >
                                        #{el}
                                        <SmallCloseIcon
                                            onClick={() => handleTagRemove(el)} // 태그 클릭 시 제거
                                            className="w-[14px] h-[14px] text-secondary-lGrayDark"
                                        />
                                    </span>
                                ))}
                            </div>
                            <button
                                className="p-[12px] w-full text-white bg-primary-purple rounded-[8px]"
                                onClick={createTeam}
                            >
                                영상 추가
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default VideoAddModal;
