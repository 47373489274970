// admin components
import HandReport from "pages/hand/report/handReport";
// commons
import EmptyBar from "components/emptyBar";
const Report = () => {
  return (
    <div className="handWrapper ">
      <EmptyBar customStyle="h-[35px]" />
      <div className="rounded-[10px] p-[30px] bg-primary-white">
        <HandReport />
      </div>
      <EmptyBar customStyle="h-[20px]" />
    </div>
  );
};

export default Report;
