// hooks
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// api
import { useApiQuery } from 'hooks/useDynamicApi';
// apexChart
import ApexChart from 'components/chart/ApexChart';
import { barOptions } from 'pages/player/measurement/main/barOpt';
import { areaOptions, areaSeries } from 'pages/player/measurement/main/areaOpt';
import { coupleBarOptions, coupleBarSeries } from 'pages/player/measurement/main/coupleBarOpt';
// commons
import Footer from 'components/footer/Default';
import MainSubHeader from 'pages/player/components/MainSubHeader';
import EmptyBar from 'components/emptyBar';
import Tabs from 'components/tab/Default';
import StatusLabel from 'components/label/Default';
import SoccerBallLodaing from 'components/loading/SoccerBallLodaing';
import AreaChartTip from 'components/tooltip/AreaChartTip';
// utils
import { skills, skillTab } from 'utils/consts/measurement';
import { DASHBOARD } from 'utils/consts/apiEndpoint';
// redux
import { RootState } from 'store'; // Redux Store 타입 정의
import { useSelector } from 'react-redux';

const MeasurementMain = () => {
    const navigate = useNavigate();
    const { role, id = '' } = useSelector((state: RootState) => state.userInfo); // 인증 상태를 token 유무로 판단
    const [toolTipShow, setToolTipShow] = useState(true);
    const [tab, setTab] = useState(0);
    const getPlayerDashboard = useApiQuery(['getPlayerDashboard', `${id}`], DASHBOARD.player(`${id}`), 'get', {
        enabled: !!id,
    });
    const getDashboardDetail = useApiQuery(['getDashboardDetail', `${id}`], DASHBOARD.playerDetail(`${id}`), 'get', {
        enabled: !!id,
    });

    const { playerInfo, rankingInfo, recentMeasurements }: PlayerDashboardType = getPlayerDashboard?.data || {};
    const { skillComparison, skillHistories, overallAnalysis }: DashboardDetailType = getDashboardDetail?.data || {};

    const customizedXaxis = handleXaxis(skillHistories) || [];
    const customizedBarData = handleBarData(skillHistories) || [];
    const customizedBarOption = {
        ...barOptions,
        xaxis: {
            ...barOptions?.xaxis, // 기존 옵션 병합
            categories: customizedXaxis[0]?.categories ? customizedXaxis[0]?.categories : [],
        },
    };
    const customizedAreaData = handleAreaData(recentMeasurements) || [];
    const customizedAreaOption = {
        ...areaOptions,
        xaxis: {
            ...areaOptions?.xaxis, // 기존 옵션 병합
            categories: customizedXaxis[0]?.categories ? customizedXaxis[0]?.categories : [],
        },
        chart: {
            ...areaOptions?.chart,
            events: {
                mouseMove: function () {
                    // 초기 설정: 마지막 데이터 포인트에 툴팁 표시
                    if (toolTipShow) {
                        setToolTipShow(false);
                        console.log(1);
                    }
                },
                markerClick: function (event: any, chartContext: any, { seriesIndex, dataPointIndex }: any) {
                    const url = `/player/measurement/report`;
                    // const url = `/player/measurement/analysis`;
                    navigate(
                        `${url}/${recentMeasurements[dataPointIndex]?.measurementId}?dateTime=${customizedXaxis[0]?.categories[dataPointIndex]}&playerId=${id}`
                    );
                },
            },
        },
    };

    const customizedCoupleBarData = skillComparison
        ? handleCoupleBarData(skillComparison?.previousScores, skillComparison?.currentScores)
        : handleCoupleBarData(initialSkillComparison?.previousScores, initialSkillComparison?.currentScores);

    return (
        <div className="wrapper">
            {(getPlayerDashboard?.isPending || getDashboardDetail?.isPending) && <SoccerBallLodaing />}
            <MainSubHeader rankingInfo={rankingInfo} />
            <EmptyBar customStyle={'h-[35px]'} />

            <div className="flex flex-col gap-[20px] ">
                <div className="flex justify-between w-full">
                    <div
                        id="main-area-chart"
                        className="w-[927px] h-[360px] bg-white rounded-[10px] flex"
                    >
                        <div className="text-subtitle-medium-bold text-[20px] pl-[30px] py-[30px] shrink-0">
                            종합 측정 추이
                        </div>
                        <div className="p-[12px] cursor-pointer  w-[800px] flex justify-center relative items-center mt-[5px]">
                            <ApexChart
                                customType={'area'}
                                customOptions={customizedAreaOption}
                                customSeries={customizedAreaData[0] ? [customizedAreaData[0]] : []}
                                height={310}
                            />
                            {getPlayerDashboard?.data?.recentMeasurements?.length > 0 && toolTipShow && (
                                <div className="absolute top-[20px]">
                                    <AreaChartTip value={''} />
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="relative w-[453px] h-[360px] bg-white rounded-[10px] py-[30px]">
                        <div className="absolute text-subtitle-medium-bold px-[30px]">이전 측정과 비교</div>
                        <div
                            id="main-coupleBar-chart"
                            className="w-[400px] mt-[10px] ml-[17px]"
                        >
                            <ApexChart
                                customType={'bar'}
                                customOptions={coupleBarOptions}
                                customSeries={customizedCoupleBarData}
                                height={250}
                            />
                        </div>
                        <div className="absolute bottom-[30px] w-[393px] flex justify-between items-center ml-[30px]">
                            <div className="text-[14px] text-secondary-lGrayDark">
                                <div className="flex gap-[10px] items-center">
                                    <div className="w-[14px] h-[14px] rounded-[4px] bg-primary-purple"></div>
                                    <div>최근 측정</div>
                                </div>
                                <div className="flex gap-[10px] items-center">
                                    <div className="w-[14px] h-[14px] rounded-[4px] bg-[#B1B1B1]"></div>
                                    <div>이전 측정</div>
                                </div>
                            </div>
                            <div className="text-context-regular flex items-center gap-[6px]">
                                이전 측정에 대한 개선도 <StatusLabel status={'up'}>준비중</StatusLabel>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex justify-between w-full">
                    <div className=" bg-white rounded-[10px] mb-[15px] h-[374px] w-[927px]">
                        <div className="text-subtitle-medium-bold text-[20px] pl-[30px] pr-[30px] pt-[30px] pb-[20px] shrink-0 flex justify-between items-center">
                            <span>기술별 점수 추이</span>
                            <Tabs
                                tabs={skillTab}
                                defaultActiveTab={0}
                                onTabChange={(index) => setTab(index)}
                            />
                        </div>
                        <div className="flex items-center justify-between ">
                            <div
                                id="main-bar-chart"
                                className="w-[905px] pl-[15px] mt-[-10px] "
                            >
                                <ApexChart
                                    customType={'bar'}
                                    customOptions={customizedBarOption}
                                    customSeries={customizedBarData[tab] ? [customizedBarData[tab]] : []}
                                    height={270}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="w-[453px] h-[374px] bg-white p-[30px] rounded-[10px]">
                        <div className="text-subtitle-medium-bold mb-[4px]">AI 요약</div>
                        <div className="rounded-[14px] bg-[#f8f8f8] p-[18px] grid grid-cols-2">
                            <div className="h-[111px] flex flex-col items-center justify-center gap-[8px]">
                                <div>가장 우수한 기술은</div>
                                <div className="px-[12px] py-[4px] bg-primary-purple text-primary-white text-context-bold rounded-[80px]">
                                    {overallAnalysis?.bestSkill || '측청필요'}
                                </div>
                            </div>
                            <div className="h-[111px] flex flex-col items-center justify-center gap-[8px]">
                                <div>개선이 필요한 기술은</div>
                                <div className="px-[12px] py-[4px] bg-secondary-dGrayLight text-primary-white text-context-bold rounded-[80px]">
                                    {overallAnalysis?.weakestSkill || '측청필요'}
                                </div>
                            </div>
                            <div className="h-[111px] flex flex-col items-center justify-center gap-[8px]">
                                <div>가장 많이 성장한 기술은</div>
                                <div className="px-[12px] py-[4px] bg-status-correct text-primary-white text-context-bold rounded-[80px]">
                                    {overallAnalysis?.mostImprovedSkill || '측청필요'}
                                </div>
                            </div>
                            <div className="h-[111px] flex flex-col items-center justify-center gap-[8px]">
                                <div>이전 측정과 비교</div>
                                <StatusLabel
                                    status={'up'}
                                    customColor="bg-status-correct text-primary-white"
                                >
                                    준비중
                                </StatusLabel>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <EmptyBar customStyle="h-[20px]" />
            <Footer />
        </div>
    );
};

export default MeasurementMain;

// 변환 로직
const handleBarData = (skillHistories: SkillHistory[]): ChartData[] => {
    return skillHistories?.map((record) => ({
        name: record?.skillType,
        data: record?.records?.map((rec) => rec?.score) || [], // records에서 score 값만 가져오기
    }));
};
const handleXaxis = (skillHistories: SkillHistory[]): any[] => {
    return skillHistories?.map((record) => ({
        categories: record?.records?.map((rec) => rec?.measuredAt), // records에서 score 값만 가져오기
    }));
};

const handleAreaData = (measurements: RecentMeasurementsType[]) => {
    if (!measurements || measurements.length === 0) return [];
    // 결과 반환
    return [
        {
            name: '종합 측정 추이', // 시리즈 이름
            data: measurements.map((measurement) => measurement.overallScore), // overallScore 배열로 변환
        },
    ];
};
const handleCoupleBarData = (previousScores: ComparisonType, currentScores: ComparisonType) => {
    return [
        {
            name: '이전측정',
            data: [
                previousScores?.dribbleAvg || 0,
                previousScores?.passAvg || 0,
                previousScores?.shootingAvg || 0,
                previousScores?.firstTouch || 0,
            ],
        },
        {
            name: '최근측정',
            data: [
                currentScores?.dribbleAvg || 0,
                currentScores?.passAvg || 0,
                currentScores?.shootingAvg || 0,
                currentScores?.firstTouch || 0,
            ],
        },
    ];
};
interface PlayerDashboardType {
    playerInfo: {
        playerId: number;
        playerName: string;
        birthday: string | null;
        profileImageUrl: string | null;
    };
    rankingInfo: {
        overallRank: {
            currentRank: number | null;
            previousRank: number | null;
            totalCount: number | null;
            rankChange: number | null;
        };
        sameAgeRank: {
            currentRank: number | null;
            previousRank: number | null;
            totalCount: number | null;
            rankChange: number | null;
        };
        bestScore: {
            score: number | null;
            topPercentile: number | null;
        };
    };
    recentMeasurements: [
        {
            measurementId: number;
            overallScore: number;
            measuredAt: string;
        }
    ];
}

interface DashboardDetailType {
    skillComparison: {
        currentScores: {
            dribbleAvg: number;
            passAvg: number;
            shootingAvg: number;
            firstTouch: number;
        };
        previousScores: {
            dribbleAvg: number;
            passAvg: number;
            shootingAvg: number;
            firstTouch: number;
        };
    } | null;
    skillHistories: [
        {
            skillType: string;
            records: [
                {
                    score: number;
                    measuredAt: string;
                }
            ];
        }
    ];
    overallAnalysis: {
        bestSkill: string;
        weakestSkill: string;
        mostImprovedSkill: string;
    };
}

interface Record {
    score: number;
    measuredAt: string;
}

interface SkillHistory {
    skillType: string;
    records: Record[];
}

interface ChartData {
    name: string;
    data: number[];
}

interface ComparisonType {
    dribbleAvg: number;
    passAvg: number;
    shootingAvg: number;
    firstTouch: number;
}

interface RecentMeasurementsType {
    measuredAt: string;
    measurementId: number;
    overallScore: number;
}

export const initialSkillComparison = {
    currentScores: {
        dribbleAvg: 0,
        passAvg: 0,
        shootingAvg: 0,
        firstTouch: 0,
    },
    previousScores: {
        dribbleAvg: 0,
        passAvg: 0,
        shootingAvg: 0,
        firstTouch: 0,
    },
};
