// hooks
import { useState, useEffect } from 'react';
import { useNavigate, useSearchParams, useParams } from 'react-router-dom';
import { useParamNavigate } from 'hooks/useDynamicNavigate';
// api
import { useApiQuery } from 'hooks/useDynamicApi';
// icons
import { ReactComponent as HomeIcon } from 'assets/svgs/home.svg';
import { ReactComponent as CircleArrowIcon } from 'assets/svgs/circleArrow.svg';
// redux
import { RootState } from 'store';
import { useSelector } from 'react-redux';
interface RegistrationHeaderType {
    wins: number;
    draws: number;
    losses: number;
    winRate: number;
    winRateChange: number;
}
const RegistrationHeader = ({ userInfo }: { userInfo?: any[] }) => {
    const [searchParams] = useSearchParams();

    const handleParamNavigate = useParamNavigate();
    const { staffRole } = useSelector((state: RootState) => state.userInfo);
    const navigate = useNavigate();

    const handleHomeNavigate = (param: string) => {
        handleParamNavigate({}, false, `/staff/control/lineup`);
    };

    return (
        <div className="wrapper">
            <div className="flex items-center justify-between">
                <div className="flex items-start justify-center h-[79px] flex-col gap-[15px]">
                    <div className="flex gap-[8px] items-center">
                        <button onClick={() => handleHomeNavigate('player')}>
                            <HomeIcon className="text-white mr-[6px]" />
                        </button>
                        <div className="bg-secondary-lGrayDark w-[3px] h-[3px] rounded-full text-label-regular"></div>
                        <div className="text-white text-label-regular">측정</div>
                        <div className="flex gap-[8px] items-center">
                            <div className="bg-secondary-lGrayDark w-[3px] h-[3px] rounded-full text-label-regular"></div>
                            <div className="text-white text-label-regular">{'선수가입'}</div>
                        </div>
                    </div>
                    <div className="flex items-center gap-[6px]">
                        <button onClick={() => navigate(-1)}>
                            <CircleArrowIcon className="text-white" />
                        </button>
                        <div className="text-[26px] text-white font-bold">가입</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RegistrationHeader;
