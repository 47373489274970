// hooks
import { useEffect, useState } from 'react';
// commons
import EmptyBar from 'components/emptyBar';
import DatePicker from 'components/datePicker';
import Dropdown from 'components/dropdown/Default';
// utils
import { matchDurationOptions, matchTypeOptions } from 'utils/consts/reportInfo';
import { matchDurationMatcher } from 'utils/matchers/reportMatcher';
// icons
import { ReactComponent as CalendarIcon } from 'assets/svgs/calendar.svg';
const ReportProfile = ({ stateInfo, reportProfile, handleInfo }: ReportProfileTypeType) => {
    const [date, setDate] = useState(stateInfo?.matchDateTime);

    const handleMatchDuration = (duration: String) => {
        handleInfo('profile', { matchDuration: duration });
    };
    const handleMatchType = (matchType: String) => {
        handleInfo('profile', { matchType: matchType });
    };

    const [result, setResult] = useState('');
    useEffect(() => {
        if (reportProfile?.opponentScore < reportProfile?.teamScore) {
            setResult('승');
        } else if (reportProfile?.opponentScore === reportProfile?.teamScore) {
            if (!reportProfile?.penaltyShootoutOpponentScore || !reportProfile?.penaltyShootoutTeamScore)
                setResult('무');
            else {
                if (reportProfile?.penaltyShootoutOpponentScore < reportProfile?.penaltyShootoutTeamScore) {
                    setResult('승');
                } else if (reportProfile?.penaltyShootoutOpponentScore > reportProfile?.penaltyShootoutTeamScore) {
                    setResult('패');
                } else setResult('무');
            }
        } else if (reportProfile?.opponentScore > reportProfile?.teamScore) {
            setResult('패');
        } else {
            setResult('무');
        }
    }, [
        reportProfile?.opponentScore,
        reportProfile?.teamScore,
        reportProfile?.penaltyShootoutOpponentScore,
        reportProfile?.penaltyShootoutTeamScore,
    ]);
    return (
        <div className="h-[216px] border-y border-secondary-lGrayMid flex">
            <div className="w-[150px] shrink-0 h-full bg-secondary-mGrayLight text-secondary-lGrayDark text-context-regular flex justify-center items-center">
                경기정보
            </div>
            <div className="p-[24px]">
                <div className="flex gap-[24px]">
                    <div className="flex items-center gap-[10px] ">
                        <div className="text-center w-[100px] flex-shrink-0 text-context-regular text-secondary-dGrayMid">
                            경기일시
                        </div>
                        <div className="inputDefaultStyle p-[12px] w-[164px] h-[44px] text-context-regular text-secondary-lGrayDark rounded-[8px] flex justify-between items-center">
                            {date?.slice(0, 10) || '경기일시'}
                            <CalendarIcon />
                        </div>
                        {/*
                        수정 안됨 => 추후 기능 열수도 있음
                        <DatePicker
                            modalBtn={
                                <div className="inputDefaultStyle p-[12px] w-[170px] h-[44px] text-context-regular rounded-[8px] flex justify-between items-center">
                                    {date?.slice(0, 10) || '경기일시'}
                                    <CalendarIcon />
                                </div>
                            }
                            selected={date}
                            setSelected={setDate}
                        /> */}
                        <div className="bg-primary-black relative text-white rounded-[8px]">
                            <input
                                type="time"
                                readOnly
                                value={date?.slice(11, 16)}
                                className="p-[12px] w-[134px] h-[44px] text-white bg-transparent"
                                style={{
                                    colorScheme: 'dark', // 아이콘과 드롭다운 UI를 다크 테마처럼 변경
                                }}
                            />
                        </div>
                    </div>
                    <div className="flex items-center gap-[10px]">
                        <div className="text-center w-[100px] flex-shrink-0 text-context-regular text-secondary-dGrayMid">
                            경기시간
                        </div>
                        <Dropdown
                            className="w-[200px]"
                            placeholder={matchDurationMatcher(reportProfile?.matchDuration) || '경기시간'}
                            options={matchDurationOptions}
                            value={reportProfile?.matchDuration}
                            onChange={handleMatchDuration}
                        />
                    </div>
                </div>
                <EmptyBar customStyle="h-[18px]" />

                <div className="flex items-center gap-[24px]">
                    <div className="flex items-center gap-[10px] w-[420px]">
                        <div className="text-center shrink-0 w-[100px] text-context-regular text-secondary-dGrayMid">
                            상대팀
                        </div>
                        <input
                            maxLength={100}
                            placeholder="상대팀"
                            value={reportProfile?.opponentTeam}
                            onChange={(e) => handleInfo('profile', { opponentTeam: e.target.value })}
                            className="inputDefaultStyle inputCommonStyle"
                        />
                    </div>
                    <div className="flex items-center gap-[10px]">
                        <div className="text-center w-[100px] shrink-0 text-context-regular text-secondary-dGrayMid">
                            대회/리그 이름
                        </div>
                        <div className="w-[140px]">
                            <Dropdown
                                className=""
                                placeholder="대회"
                                options={matchTypeOptions}
                                value={reportProfile?.matchType}
                                onChange={handleMatchType}
                            />
                        </div>

                        <div className="w-[450px]">
                            <input
                                placeholder="대회/리그 이름"
                                value={reportProfile?.competitionName}
                                onChange={(e) => handleInfo('profile', { competitionName: e.target.value })}
                                className="inputDefaultStyle inputCommonStyle"
                            />
                        </div>
                    </div>
                </div>
                <EmptyBar customStyle="h-[18px]" />
                <div className="flex gap-[24px]">
                    <div className="flex items-center gap-[10px] w-[420px]">
                        <span className="text-center w-[100px] shrink-0 text-context-regular text-secondary-dGrayMid">
                            <div>경기결과</div>
                            <div>(우리:상대)</div>
                        </span>
                        <input
                            placeholder="우리"
                            value={reportProfile?.teamScore}
                            onChange={(e) => {
                                const value = e.target.value;
                                if (/^\d*$/.test(value)) {
                                    // 정규식을 사용해 숫자인지 체크
                                    handleInfo('profile', { teamScore: value });
                                }
                            }}
                            className="inputDefaultStyle inputCommonStyle"
                        />
                        <div>:</div>
                        <input
                            placeholder="상대"
                            value={reportProfile?.opponentScore}
                            onChange={(e) => {
                                const value = e.target.value;
                                if (/^\d*$/.test(value)) {
                                    // 정규식을 사용해 숫자인지 체크
                                    handleInfo('profile', { opponentScore: value });
                                }
                            }}
                            className="inputDefaultStyle inputCommonStyle"
                        />
                        <button
                            className={`px-[12px] py-[4px] rounded-[80px] shrink-0 ${
                                result === '승'
                                    ? 'bg-primary-purple text-white'
                                    : result === '패'
                                    ? 'bg-status-error text-white'
                                    : 'bg-[#d2d2d2] text-[#111111]'
                            }`}
                        >
                            {result ? result : '미정'}
                        </button>
                    </div>

                    <div className="flex items-center gap-[10px] w-[420px]">
                        <span className="text-center w-[100px] shrink-0 text-context-regular text-secondary-dGrayMid">
                            <div>승부차기</div>
                            <div>(우리:상대)</div>
                        </span>
                        <input
                            placeholder="우리"
                            value={reportProfile?.penaltyShootoutTeamScore || ''}
                            onChange={(e) => {
                                const value = e.target.value;
                                if (/^\d*$/.test(value)) {
                                    // 정규식을 사용해 숫자인지 체크
                                    handleInfo('profile', { penaltyShootoutTeamScore: value });
                                }
                            }}
                            className="inputDefaultStyle inputCommonStyle"
                        />
                        <div>:</div>
                        <input
                            placeholder="상대"
                            value={reportProfile?.penaltyShootoutOpponentScore || ''}
                            onChange={(e) => {
                                const value = e.target.value;
                                if (/^\d*$/.test(value)) {
                                    // 정규식을 사용해 숫자인지 체크
                                    handleInfo('profile', {
                                        penaltyShootoutOpponentScore: value,
                                    });
                                }
                            }}
                            className="inputDefaultStyle inputCommonStyle"
                        />
                    </div>

                    <div className="flex items-center gap-[10px] w-[261px]">
                        <span className="text-center shrink-0 w-[100px] text-context-regular text-secondary-dGrayMid">
                            포메이션
                        </span>
                        <input
                            placeholder="포메이션"
                            value={reportProfile?.formation}
                            onChange={(e) => handleInfo('profile', { formation: e.target.value })}
                            className="inputDefaultStyle inputCommonStyle"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ReportProfile;

interface ReportProfileTypeType {
    stateInfo: {
        id: string;
        matchDateTime: string;
        matchType: string;
        seasonYear: string;
        teamName: string;
    };
    reportProfile: {
        matchDateTime: string;
        opponentTeam: string;
        matchType: string;
        competitionName: string;
        teamScore: number;
        opponentScore: number;
        penaltyShootoutTeamScore: number | string | null;
        penaltyShootoutOpponentScore: number | string | null;
        matchDuration: string;
        matchResult: string;
        formation: string;
        overallReview: string;
        seasonYear: string;
    };
    handleInfo: (type: string, value: any) => void;
}
