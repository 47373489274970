// hooks
import { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
// icons
import { ReactComponent as PlusIcon } from "assets/svgs/plus.svg";
// commons
import Dropdown from "components/dropdown/Default";
// utils
import { positionShortOptions } from "utils/consts/userInfo";
// api
import { useApiQuery, useApiMutation } from "hooks/useDynamicApi";
import { showToast } from "components/toast/Default";
// utils
import { ENDPOINT_REPORT } from "utils/consts/apiEndpoint";
// report components
import SubstitutionEditElement from "pages/staff/control/schedule/report/post/reportPlayer/SubstitutionEditElement";
const Substitution = ({
  lineup,
  activeLineup,
  inactiveLineup,
}: SubstitutionProps) => {
  const location = useLocation();
  const inactiveOptions = handleDropdownOpts(inactiveLineup) || [];
  const activeOptions = handleDropdownOpts(activeLineup) || [];

  const { id } = useParams();
  const [isAdd, setIsAdd] = useState(false);
  const [substitutionTime, setSubstitutionTime] = useState("");
  const [inPlayer, setInPlayer] = useState("");
  const [inPlayerPos, setInPlayerPos] = useState("");

  const [outPlayer, setOutPlayer] = useState("");
  const [outPositionPos, setOutPositionPos] = useState("");
  const [isDeleteModal, setIsDeleteModal] = useState(false);

  // api
  const getSubstitution = useApiQuery(
    ["getSubstitution"],
    ENDPOINT_REPORT.substitutions(id),
    "get"
  );

  // useApiMutation
  const postSubstitution = useApiMutation(
    ENDPOINT_REPORT.substitution(id),
    "post",
    {
      onSuccess: (data) => {
        showToast("선수교체 등록이 등록되었습니다", "success");
        setIsAdd(false);
        setSubstitutionTime("");
        setInPlayer("");
        setInPlayerPos("");
        setOutPlayer("");
        setOutPositionPos("");
      },
      onError: (error) => {
        const errorMessage =
          error?.data?.detailedMessage ||
          error ||
          "선수교체 등록에 실패했습니다.";
        setSubstitutionTime("");
        setInPlayer("");
        setInPlayerPos("");
        setOutPlayer("");
        setOutPositionPos("");
        showToast(`${errorMessage}`, "error");
      },
    }
  );
  // const handleTimeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   const selectedTime = e.target.value; // 사용자가 선택한 시간

  //   if (selectedTime < minTime) {
  //     alert(`${minTime} 이후의 시간만 선택 가능합니다.`);
  //     return;
  //   }
  //   setSubstitutionTime(selectedTime);
  // };
  useEffect(() => {
    if (
      substitutionTime &&
      inPlayer &&
      outPlayer &&
      inPlayerPos &&
      outPositionPos &&
      !postSubstitution.isPending
    ) {
      postSubstitution.mutate({
        playerInId: inPlayer,
        playerInPosition: inPlayerPos,
        playerOutId: outPlayer,
        playerOutPosition: outPositionPos,
        substitutionTime: substitutionTime,
      });
    }
  }, [substitutionTime, inPlayer, outPlayer, inPlayerPos, outPositionPos]);

  return (
    <div className="p-[24px] flex items-center w-full border-b border-secondary-lGrayMid">
      <div className="text-center shrink-0 w-[100px] h-full text-context-regular text-secondary-dGrayMid">
        교체
      </div>
      <div className="flex flex-col gap-[10px]">
        <div className="flex gap-[10px] text-context-regular w-full bg-red">
          <div className="w-[135px]"></div>
          <div className="w-[380px] text-center">IN</div>
          <div className="w-[380px] text-center">OUT</div>
        </div>
        <div className="flex flex-col gap-[10px]">
          {getSubstitution?.data?.map((el: SubstitutionType, idx: number) => (
            <SubstitutionEditElement
              {...el}
              key={el?.substitutionId}
              activeOptions={activeOptions}
              inactiveOptions={inactiveOptions}
              getSubstitutionLength={getSubstitution?.data?.length}
              idx={idx}
            />
          ))}
        </div>

        {(isAdd || getSubstitution?.data?.length < 1) && (
          <div className="flex flex-col gap-[10px]">
            <div className="flex gap-[10px]">
              <div className="w-[110px] rounded-[8px] bg-primary-black flex justify-between text-white items-center">
                <input
                  type="text"
                  // min={minTime}
                  value={substitutionTime}
                  onChange={(e) => setSubstitutionTime(e.target.value)}
                  className="px-[12px] w-[60px] h-[44px] text-white bg-transparent focus:outline-none"
                  style={{
                    colorScheme: "dark", // 아이콘과 드롭다운 UI를 다크 테마처럼 변경
                  }}
                />
                <div className="px-[10px]">분</div>
              </div>

              <Dropdown
                placeholder="포지션"
                options={positionShortOptions}
                value={inPlayerPos}
                onChange={setInPlayerPos}
                className="w-[100px]"
              />
              <Dropdown
                placeholder="선수를 입력하세요"
                options={inactiveOptions}
                value={inPlayer}
                onChange={setInPlayer}
                className="w-[270px]"
              />
              <Dropdown
                placeholder="포지션"
                options={positionShortOptions}
                value={outPositionPos}
                onChange={setOutPositionPos}
                className="w-[100px]"
              />
              <Dropdown
                placeholder="선수를 입력하세요"
                options={activeOptions}
                value={outPlayer}
                onChange={setOutPlayer}
                className="w-[270px]"
              />
            </div>
          </div>
        )}
        <button
          onClick={() => setIsAdd(true)}
          className="w-[500px] h-[44px]  rounded-[8px] text-context-regular text-secondary-lGrayDark gap-[10px] border border-secondary-lGrayMid flex items-center justify-center"
        >
          <PlusIcon />
          교체추가{" "}
        </button>
      </div>
    </div>
  );
};

export default Substitution;

interface SubstitutionProps {
  lineup: LineupType[];
  activeLineup: {
    playerId: string;
    playerName: string;
    uniformNumber: string;
    positions: string[];
  }[];
  inactiveLineup: {
    playerId: string;
    playerName: string;
    uniformNumber: string;
    positions: string[];
  }[];
}
interface LineupType {
  lineupId: string;
  playerId: string;
  playerName: string;
  uniformNumber: string;
  position: string;
  rating: string;
  managerComment: string;
}

interface SubstitutionType {
  substitutionId: string;
  playerInId: string;
  playerInName: string;
  playerInUniformNumber: string;
  playerInPosition: string;
  playerOutId: string;
  playerOutName: string;
  playerOutUniformNumber: string;
  playerOutPosition: string;
  substitutionTime: string;
}

export const handleDropdownOpts = (arr: any[]) => {
  return arr?.map((arr) => ({
    value: arr?.playerId,
    label: `${arr?.uniformNumber}. ${arr?.playerName}`,
  }));
};
