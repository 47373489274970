// hooks
import { useEffect, useState } from 'react';
import { useParamNavigate } from 'hooks/useDynamicNavigate';
import { useNavigate, useSearchParams } from 'react-router-dom';
// admin components
import TeamControl from 'pages/admin/userControl/teamControl';
import PlayerControl from 'pages/admin/userControl/playerControl';
import AdminControl from 'pages/admin/userControl/adminControl';
import RoleChangeCard from 'pages/admin/userControl/adminControl/RoleChangeCard';
// common
import Tabs from 'components/tab/Default';
import EmptyBar from 'components/emptyBar';
import SoccerBallLodaing from 'components/loading/SoccerBallLodaing';
// admin components
import ControlSubHeader from 'pages/admin/userControl/ControlHeader';
// redux
import { RootState } from 'store';
import { useSelector } from 'react-redux';

const UserControl = () => {
    const [tab, setTab] = useState(0);
    const [searchParams] = useSearchParams();
    const { role } = useSelector((state: RootState) => state.userInfo);
    const controlTab = role === 'SUPER_ADMIN' ? superAdminTab?.slice() : adminTab?.slice();
    const control = searchParams.get('control') || 'player';
    const handleParamNavigate = useParamNavigate();
    const handleHomeNavigate = (param: string) => {
        handleParamNavigate(
            {
                control: param,
            },
            false,
            `/admin/control`
        );
    };
    useEffect(() => {
        const currentTab = controlTab[tab];
        if (currentTab.param === control) return;
        handleHomeNavigate(currentTab.param);
    }, [tab]);

    return (
        <div className="wrapper">
            <ControlSubHeader />
            <EmptyBar customStyle="h-[35px]" />
            <div className="p-[30px] bg-primary-white rounded-[10px]">
                <div className="w-fit mb-[20px]">
                    <Tabs
                        tabs={controlTab}
                        defaultActiveTab={0}
                        onTabChange={(index) => setTab(index)}
                    />
                </div>
                {control === 'player' ? <PlayerControl /> : control === 'team' ? <TeamControl /> : <AdminControl />}
            </div>
            {control === 'admin' && (
                <>
                    <EmptyBar customStyle="h-[20px]" />
                    <RoleChangeCard />
                </>
            )}
            <EmptyBar customStyle="h-[20px]" />
        </div>
    );
};

export default UserControl;

export const superAdminTab = [
    {
        label: '선수관리',
        param: 'player',
    },
    {
        label: '팀관리',
        param: 'team',
    },
    {
        label: '어드민관리',
        param: 'admin',
    },
];

export const adminTab = [
    {
        label: '선수관리',
        param: 'player',
    },
    {
        label: '팀관리',
        param: 'team',
    },
];
