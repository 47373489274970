// hooks
import { useSearchParams } from 'react-router-dom';
// api
import { useApiQuery } from 'hooks/useDynamicApi';
// utils
import { ENDPOINT_MEASUREMENT } from 'utils/consts/apiEndpoint';
import { paramFormChanger } from 'utils/formChanger';
// hand components
import DribbleTwoElement from 'pages/hand/measurement/contents/dribbleTwo/DribbleTwoElement';
// commons
import SoccerBallLodaing from 'components/loading/SoccerBallLodaing';

const DribbleTwo = () => {
    const [searchParams] = useSearchParams();
    const MEASUREDAT = searchParams.get('measuredAt') || '';
    const DEPARTMENT = searchParams.get('department') || '';

    // react qury
    const getDribbleTwo = useApiQuery(
        ['getDribbleTwo', MEASUREDAT, DEPARTMENT],
        ENDPOINT_MEASUREMENT.dribbleTwoParams(
            paramFormChanger({
                measuredAt: MEASUREDAT,
                department: DEPARTMENT,
            })
        ),
        'get'
    );
    return (
        <div className="p-[30px] bg-primary-white rounded-[10px]">
            {getDribbleTwo?.isPending && <SoccerBallLodaing />}
            <div className="flex bg-primary-black text-primary-white text-context-regular rounded-[8px] h-[50px]">
                {columns.map((el: { key: string; label: string }) => (
                    <div
                        key={el.key}
                        className="flex items-center justify-center w-full"
                    >
                        {el?.label}
                    </div>
                ))}
                <div className="flex items-center justify-center w-full">초기화</div>
            </div>
            {getDribbleTwo?.data?.map((el: any, idx: number) => (
                <DribbleTwoElement
                    {...el}
                    key={el?.measurementId}
                    idx={idx}
                />
            ))}
        </div>
    );
};
export const columns = [
    { key: 'playerName', label: '이름' },
    { key: 'cone1passed', label: '콘1' },
    { key: 'cone2passed', label: '콘2' },
    { key: 'cone3passed', label: '콘3' },
    { key: 'cone4passed', label: '콘4' },
    { key: 'cone5passed', label: '콘5' },
    { key: 'cone6passed', label: '콘6' },
    { key: 'cone7passed', label: '콘7' },
    { key: 'cone8passed', label: '콘8' },
    { key: 'cone9passed', label: '콘9' },
    { key: 'cone1passed0', label: '콘10' },
    { key: 'lapTime', label: '랩타임' },
];

export default DribbleTwo;
