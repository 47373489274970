const ProgressBar = ({
  label,
  achievementRate,
  targetRate,
  customColor = "#5651E3",
}: ProgressBarType) => {
  const progress = (achievementRate / targetRate) * 100;

  return (
    <div className="flex gap-[25px] items-center">
      {label && (
        <div className="flex-shrink-0 w-[60px] text-secondary-dGrayLight text-end bg-red text-[14px]">
          {label}
        </div>
      )}
      <div
        className="relative h-[14px] w-full rounded-[4px]"
        style={{ backgroundColor: `${customColor}30` }}
      >
        <div
          className="h-[14px] absolute left-0 top-0 rounded-[4px]"
          style={{
            backgroundColor: customColor,
            width: `${progress}%`,
          }}
        >
          <div
            className="px-[9px] py-[1px] absolute right-[-8px] h-[29px] rounded-[80px] border-[3px] top-[-8px] text-[14px] font-bold bg-primary-white text-center"
            style={{
              borderColor: customColor,
              color: customColor,
            }}
          >
            {achievementRate}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProgressBar;

interface ProgressBarType {
  label?: string;
  achievementRate: number;
  targetRate: number;
  customColor?: string;
}
