// hooks
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useInfiniteQuery } from '@tanstack/react-query';
import { useApiMutation } from 'hooks/useDynamicApi';
import axiosInstance from 'api/axiosInstance';
import { useInView } from 'react-intersection-observer';
// commons
import CustomTable from 'components/table/CustomTable';
import ConfirmModal from 'components/modals/ConfirmModal';
import { showToast } from 'components/toast/Default';
import DefaultModal from 'components/modals/DefaultModal';
// utils
import { ENDPOINT_CAREER } from 'utils/consts/apiEndpoint';
import { ageMatcher } from 'utils/matchers/userInfoMatcher';
// icons
import { ReactComponent as PlusIcon } from 'assets/svgs/plus.svg';
import { ReactComponent as EditPenIcon } from 'assets/svgs/editPen.svg';
import { ReactComponent as DeleteIcon } from 'assets/svgs/delete.svg';

const CareerDetailCard = () => {
    const { playerId = '' } = useParams();
    const { ref, inView } = useInView();
    const [isAdd, setIsAdd] = useState(false);
    const [deleteModalShow, setDeleteModalShow] = useState(false);
    const [deleteCareerId, setDeleteCareerId] = useState('');
    const [addCareerInfo, setAddCareerInfo] = useState({
        careerDate: '',
        age: '',
        teamName: '',
        totalMatches: '',
        totalGoals: '',
        totalAssists: '',
        averageRating: '',
    });
    const [editingCareerInfo, setEditingCareerInfo] = useState({
        id: '',
        careerDate: '',
        age: '',
        teamName: '',
        totalMatches: '',
        totalGoals: '',
        totalAssists: '',
        averageRating: '',
    });
    const handleAddCareerInfo = (updateData: any) => {
        setAddCareerInfo((prev) => ({
            ...prev,
            ...updateData,
        }));
    };
    const handleEditCareerInfo = (updateData: any) => {
        setEditingCareerInfo((prev) => ({
            ...prev,
            ...updateData,
        }));
    };
    const columns = [
        { key: 'careerDate', label: '경력 시작일' },
        { key: 'age', label: '나이' },
        { key: 'teamName', label: '팀명' },
        { key: 'totalMatches', label: '총 경기', sortable: false },
        { key: 'totalGoals', label: '득점' },
        { key: 'totalAssists', label: '도움' },
        { key: 'averageRating', label: '평균 평점', sortable: false },
        { key: 'edit', label: '수정', sortable: false },
        { key: 'delete', label: '삭제', sortable: false },
    ];
    const handleInitCareerInfo = (type = 'edit') => {
        if (type === 'edit')
            setEditingCareerInfo({
                id: '',
                careerDate: '',
                age: '',
                teamName: '',
                totalMatches: '',
                totalGoals: '',
                totalAssists: '',
                averageRating: '',
            });
        else if (type === 'add') {
            setAddCareerInfo({
                careerDate: '',
                age: '',
                teamName: '',
                totalMatches: '',
                totalGoals: '',
                totalAssists: '',
                averageRating: '',
            });
        }
    };
    // useInfiniteQuery
    const { data, error, fetchNextPage, hasNextPage, isFetching, isFetchingNextPage, status } = useInfiniteQuery({
        queryKey: ['carrers', playerId],
        queryFn: ({ pageParam = 1 }) =>
            axiosInstance
                .get(`${ENDPOINT_CAREER.player(playerId)}?page=${pageParam}&size=10`)
                .then((response) => response.data),
        initialPageParam: 1,
        getNextPageParam: (lastPage) => (lastPage?.sliceInfo?.hasNext ? lastPage?.sliceInfo?.page + 1 : undefined),
    });

    useEffect(() => {
        if (inView && hasNextPage && !isFetchingNextPage) {
            fetchNextPage();
        }
    }, [inView, hasNextPage, isFetchingNextPage]);

    // useApiMutation
    const postCareerHistory = useApiMutation(ENDPOINT_CAREER.career, 'post', {
        onSuccess: (data) => {
            showToast('경력정보가 추가되었습니다.', 'success');
            handleInitCareerInfo('add');
            setIsAdd(false);
        },
        onError: (error) => {
            // 에러 메시지 추출
            const errorMessage = error?.data?.detailedMessage || error || '경력정보 추가 실패했습니다.';
            showToast(`${errorMessage}`, 'error');
        },
    });
    const patchCareerHistory = useApiMutation(ENDPOINT_CAREER.history(editingCareerInfo?.id), 'patch', {
        onSuccess: (data) => {
            showToast('경력정보가 수정되었습니다.', 'success');
            handleInitCareerInfo('edit');
        },
        onError: (error) => {
            // 에러 메시지 추출
            const errorMessage = error?.data?.detailedMessage || error || '경력정보 수정 실패했습니다.';
            showToast(`${errorMessage}`, 'error');
        },
    });
    // useApiMutation
    const deleteCareerHistory = useApiMutation(ENDPOINT_CAREER.history(deleteCareerId), 'delete', {
        onSuccess: (data) => {
            showToast('경력정보가 삭제되었습니다.', 'success');
            // handleInitCareerInfo('');
        },
        onError: (error) => {
            // 에러 메시지 추출
            const errorMessage = error?.data?.detailedMessage || error || '경력정보 삭제 실패했습니다.';
            showToast(`${errorMessage}`, 'error');
        },
    });

    const createCareerHistory = () => {
        if (!postCareerHistory?.isPending) {
            showToast('경력정보 추가 중입니다', 'info');
            postCareerHistory?.mutate({
                playerId: playerId,
                careerDate: addCareerInfo?.careerDate,
                teamName: addCareerInfo?.teamName,
                totalMatches: addCareerInfo?.totalMatches,
                totalGoals: addCareerInfo?.totalGoals,
                totalAssists: addCareerInfo?.totalAssists,
                averageRating: addCareerInfo?.averageRating,
            });
        }
    };
    const updateCareerHistory = () => {
        if (!patchCareerHistory?.isPending) {
            showToast('경력정보 수정 중입니다', 'info');
            patchCareerHistory?.mutate({
                careerDate: editingCareerInfo?.careerDate,
                teamName: editingCareerInfo?.teamName,
                totalMatches: editingCareerInfo?.totalMatches,
                totalGoals: editingCareerInfo?.totalGoals,
                totalAssists: editingCareerInfo?.totalAssists,
                averageRating: editingCareerInfo?.averageRating,
            });
        }
    };
    const remoteCareerHistory = () => {
        if (!deleteCareerHistory?.isPending) {
            showToast('경력정보 삭제 중입니다', 'info');
            deleteCareerHistory?.mutate({});
        }
    };
    // tableData
    const flattenedRows = data?.pages.flatMap((page) => page.data) || [];
    const transformedRows = handleRows(
        flattenedRows,
        editingCareerInfo,
        handleEditCareerInfo,

        setEditingCareerInfo,
        handleInitCareerInfo,
        updateCareerHistory,

        setDeleteModalShow,
        setDeleteCareerId
    );
    return (
        <div className="bg-primary-white rounded-[10px] p-[30px] ">
            <ConfirmModal
                isShow={deleteModalShow}
                setIsShow={setDeleteModalShow}
                title="현재 경력정보를 삭제하시겠습니까?"
                activeFunc={remoteCareerHistory}
            />
            <div className="mb-[24px] flex justify-between ">
                <div className="text-subtitle-medium-bold text-secondary-dGrayMid">경력정보</div>
            </div>
            {/* overflow-y-scroll */}
            <CustomTable
                columns={columns}
                rows={transformedRows}
                equalWidth={false}
            />
            {isAdd ? (
                <div className="flex mt-[10px]">
                    <div className="w-[140px] m-auto">
                        <input
                            type="date"
                            className="text-center inputCommonStyle inputDefaultStyle"
                            value={addCareerInfo.careerDate}
                            onChange={(e) => handleAddCareerInfo({ careerDate: e.target.value })}
                        />
                    </div>
                    <div className="w-[130px] m-auto">
                        <input
                            placeholder="나이"
                            className="text-center inputCommonStyle inputDefaultStyle"
                            value={addCareerInfo.age}
                            onChange={(e) => handleAddCareerInfo({ age: e.target.value })}
                        />
                    </div>

                    <div className="w-[140px] m-auto">
                        <input
                            placeholder="팀명"
                            className="text-center inputCommonStyle inputDefaultStyle"
                            value={addCareerInfo.teamName}
                            onChange={(e) => handleAddCareerInfo({ teamName: e.target.value })}
                        />
                    </div>
                    <div className="w-[140px] m-auto">
                        <input
                            placeholder="총 경기"
                            className="text-center inputCommonStyle inputDefaultStyle"
                            value={addCareerInfo.totalMatches}
                            onChange={(e) => handleAddCareerInfo({ totalMatches: e.target.value })}
                        />
                    </div>
                    <div className="w-[140px] m-auto">
                        <input
                            placeholder="총 골"
                            className="text-center inputCommonStyle inputDefaultStyle"
                            value={addCareerInfo.totalGoals}
                            onChange={(e) => handleAddCareerInfo({ totalGoals: e.target.value })}
                        />
                    </div>

                    <div className="w-[140px] m-auto">
                        <input
                            placeholder="총 도움"
                            className="text-center inputCommonStyle inputDefaultStyle"
                            value={addCareerInfo.totalAssists}
                            onChange={(e) => handleAddCareerInfo({ totalAssists: e.target.value })}
                        />
                    </div>
                    <div className="w-[140px] m-auto">
                        <input
                            placeholder="평균 평점"
                            className="text-center inputCommonStyle inputDefaultStyle"
                            value={addCareerInfo.averageRating}
                            onChange={(e) => handleAddCareerInfo({ averageRating: e.target.value })}
                        />
                    </div>
                    <div className="w-[160px] m-auto ">
                        <div className="flex gap-[20px]">
                            <button
                                onClick={createCareerHistory}
                                className="shrink-0 w-[75px] p-[12px] text-primary-white bg-primary-purple rounded-[8px] text-context-bold"
                            >
                                추가
                            </button>
                            <button
                                onClick={() => setIsAdd(false)}
                                className="shrink-0 w-[75px] p-[12px] text-primary-white bg-secondary-lGrayDark rounded-[8px] text-context-bold"
                            >
                                취소
                            </button>
                        </div>
                    </div>
                </div>
            ) : (
                <button
                    onClick={() => setIsAdd(true)}
                    className="w-full h-[44px] mt-[10px] rounded-[8px] text-context-regular text-secondary-lGrayDark gap-[10px] border border-secondary-lGrayMid flex items-center justify-center"
                >
                    <PlusIcon />
                    경력 추가
                </button>
            )}
            <div
                ref={ref}
                className="h-4"
            />
            {isFetching && !isFetchingNextPage && (
                <div className="py-2 text-center text-secondary-lGrayDark">Loading...</div>
            )}
            {isFetchingNextPage && (
                <div className="py-2 text-center text-secondary-lGrayDark">다음 페이지 로딩 중...</div>
            )}
            {!isFetching && !isFetchingNextPage && !hasNextPage && (
                <div className="py-2 text-center text-secondary-lGrayDark">마지막 데이터 입니다.</div>
            )}
        </div>
    );
};

export default CareerDetailCard;

export const handleRows = (
    rows: any[],
    editingCareerInfo: any,
    handleEditCareerInfo: (value: any) => void,

    setEditingCareerInfo: (value: any) => void,
    handleInitCareerInfo: (value: string) => void,
    updateCareerHistory: () => void,

    setDeleteModalShow: (show: boolean) => void,
    setDeleteCareerId: (id: string) => void
) => {
    return rows?.map((row) => ({
        careerDate: (
            <div className="w-[140px] m-auto">
                {editingCareerInfo?.id === row?.id ? (
                    <input
                        type="date"
                        className="text-center inputCommonStyle inputDefaultStyle"
                        value={editingCareerInfo.careerDate}
                        onChange={(e) => handleEditCareerInfo({ careerDate: e.target.value })}
                    />
                ) : (
                    <span>{row?.careerDate}</span>
                )}
            </div>
        ),
        teamName: (
            <div className="w-[140px] m-auto">
                {editingCareerInfo?.id === row?.id ? (
                    <input
                        className="text-center inputCommonStyle inputDefaultStyle"
                        value={editingCareerInfo.teamName}
                        onChange={(e) => handleEditCareerInfo({ teamName: e.target.value })}
                    />
                ) : (
                    <span>{row?.teamName}</span>
                )}
            </div>
        ),
        age: (
            <div className="w-[140px] m-auto">
                {editingCareerInfo?.id === row?.id ? (
                    <input
                        className="text-center inputCommonStyle inputDefaultStyle"
                        value={editingCareerInfo.age}
                        onChange={(e) => handleEditCareerInfo({ age: e.target.value })}
                    />
                ) : (
                    <span>{`${row?.age}세 (${ageMatcher(row?.age)})`}</span>
                )}
            </div>
        ),
        totalMatches: (
            <div className="w-[140px] m-auto">
                {editingCareerInfo?.id === row?.id ? (
                    <input
                        className="text-center inputCommonStyle inputDefaultStyle"
                        value={editingCareerInfo.totalMatches}
                        onChange={(e) => handleEditCareerInfo({ totalMatches: e.target.value })}
                    />
                ) : (
                    <span>{row?.totalMatches}</span>
                )}
            </div>
        ),
        totalGoals: (
            <div className="w-[140px] m-auto">
                {editingCareerInfo?.id === row?.id ? (
                    <input
                        className="text-center inputCommonStyle inputDefaultStyle"
                        value={editingCareerInfo.totalGoals}
                        onChange={(e) => handleEditCareerInfo({ totalGoals: e.target.value })}
                    />
                ) : (
                    <span>{row?.totalGoals}</span>
                )}
            </div>
        ),

        totalAssists: (
            <div className="w-[140px] m-auto">
                {editingCareerInfo?.id === row?.id ? (
                    <input
                        className="text-center inputCommonStyle inputDefaultStyle"
                        value={editingCareerInfo.totalAssists}
                        onChange={(e) => handleEditCareerInfo({ totalAssists: e.target.value })}
                    />
                ) : (
                    <span>{row?.totalAssists}</span>
                )}
            </div>
        ),
        averageRating: (
            <div className="w-[140px] m-auto">
                {editingCareerInfo?.id === row?.id ? (
                    <input
                        className="text-center inputCommonStyle inputDefaultStyle"
                        value={editingCareerInfo.averageRating}
                        onChange={(e) => handleEditCareerInfo({ averageRating: e.target.value })}
                    />
                ) : (
                    <span>{row?.averageRating?.toFixed(1)}</span>
                )}
            </div>
        ),
        edit: (
            <div className="w-[110px] m-auto ">
                {editingCareerInfo?.id === row?.id ? (
                    <div className="flex gap-[10px]">
                        <button
                            onClick={updateCareerHistory}
                            className="shrink-0 p-[10px] text-primary-white bg-primary-purple rounded-[8px] text-context-bold"
                        >
                            수정
                        </button>
                        <button
                            onClick={() => handleInitCareerInfo('edit')}
                            className="shrink-0 p-[10px] text-primary-white bg-secondary-lGrayDark rounded-[8px] text-context-bold"
                        >
                            취소
                        </button>
                    </div>
                ) : (
                    <button onClick={() => setEditingCareerInfo(row)}>
                        <EditPenIcon className="text-primary-purple" />
                    </button>
                )}
            </div>
        ),
        delete: (
            <button
                onClick={() => {
                    setDeleteModalShow(true);
                    setDeleteCareerId(row?.id);
                }}
                className="text-status-error p-[6px]"
            >
                <DeleteIcon />
            </button>
        ),
    }));
};
