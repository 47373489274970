// hooks
import { useState, useEffect, useMemo } from 'react';
import { debounce } from 'lodash';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';
// api
import { useApiMutation, useApiQuery } from 'hooks/useDynamicApi';
// commons
import EmptyBar from 'components/emptyBar';
import { showToast } from 'components/toast/Default';
// report components
import ReportPostSubHear from 'pages/staff/control/schedule/report/post/ReportPostSubHear';
import ReportProfile from 'pages/staff/control/schedule/report/post/ReportProfile';
import ReportPlayer from 'pages/staff/control/schedule/report/post/reportPlayer';
import ReportRating from 'pages/staff/control/schedule/report/post/reportRating';
import ReportReview from 'pages/staff/control/schedule/report/post/ReportReview';
// utils
import { ENDPOINT_REPORT } from 'utils/consts/apiEndpoint';

const ReportPost = () => {
    const [searchParams] = useSearchParams();
    const location = useLocation();
    const {
        id,
        matchDateTime = '',
        matchType,
        competitionName,
        opponentTeam,
        teamScore,
        opponentScore,
        penaltyShootoutTeamScore,
        penaltyShootoutOpponentScore,
        matchResult,
    } = location?.state || {};

    // api
    const getReport = useApiQuery(['getReport', id], ENDPOINT_REPORT.report(id), 'get');
    const today = new Date();
    const koreaTime = today.toLocaleString('ko-KR', { timeZone: 'Asia/Seoul' });
    const [now, setNow] = useState(koreaTime);
    // 전달된 state 데이터
    const paramAgeGroup = searchParams.get('ageGroup') || '';
    const paramYear = searchParams.get('year') || '';
    const [reportProfile, setReportProfile] = useState({
        matchDateTime: matchDateTime,
        opponentTeam: opponentTeam,
        matchType: matchType,
        competitionName: competitionName,
        teamScore: teamScore,
        opponentScore: opponentScore,
        penaltyShootoutTeamScore: penaltyShootoutTeamScore,
        penaltyShootoutOpponentScore: penaltyShootoutOpponentScore,
        matchDuration: '',
        matchResult: matchResult,
        formation: '',
        overallReview: '',
        seasonYear: '',
    });
    useEffect(() => {
        if (getReport?.data && getReport?.isSuccess) {
            setReportProfile({
                matchDateTime: getReport?.data?.matchDateTime,
                opponentTeam: getReport?.data?.opponentTeam,
                matchType: getReport?.data?.matchType,
                competitionName: getReport?.data?.competitionName,
                teamScore: getReport?.data?.teamScore,
                opponentScore: getReport?.data?.opponentScore,
                penaltyShootoutTeamScore: getReport?.data?.penaltyShootoutTeamScore,
                penaltyShootoutOpponentScore: getReport?.data?.penaltyShootoutOpponentScore,
                matchResult: getReport?.data?.matchResult,
                matchDuration: getReport?.data?.matchDuration,
                formation: getReport?.data?.formation,
                overallReview: getReport?.data?.overallReview,
                seasonYear: getReport?.data?.seasonYear,
            });
        }
    }, [getReport?.data, getReport?.isSuccess]);
    const handleInfo = (type: string, updateData: any) => {
        if (type === 'profile') {
            setReportProfile((prev) => ({
                ...prev,
                ...updateData,
            }));
        }
    };

    const getLineup = useApiQuery(['getLineup'], ENDPOINT_REPORT.lineup(id), 'get');
    // useApiMutation
    const patchMatchReport = useApiMutation(ENDPOINT_REPORT.report(id), 'patch', {
        onSuccess: (data) => {
            // showToast('리포트가 수정되었습니다', 'success');
            const now = new Date();
            const koreaTime = now.toLocaleString('ko-KR', { timeZone: 'Asia/Seoul' });
            setNow(koreaTime);
        },
        onError: (error) => {
            const errorMessage = error?.data?.detailedMessage || error || '리포트 수정에 실패했습니다.';
            showToast(`${errorMessage}`, 'error');
        },
    });
    const submitReport = () => {
        if (!reportProfile?.matchDuration) showToast(`경기 시간은 필수로 입력해야 합니다.`, 'error');
        else {
            if (!patchMatchReport.isPending && getReport?.data) {
                patchMatchReport.mutate({
                    ...reportProfile,
                    penaltyShootoutTeamScore:
                        reportProfile?.penaltyShootoutTeamScore === '0'
                            ? null
                            : reportProfile?.penaltyShootoutTeamScore,
                    penaltyShootoutOpponentScore:
                        reportProfile?.penaltyShootoutOpponentScore === '0'
                            ? null
                            : reportProfile?.penaltyShootoutOpponentScore,
                });
            }
        }
    };

    const debouncedSubmitReport = useMemo(
        () =>
            debounce(() => {
                submitReport();
            }, 1000), // 1초 뒤에 실행
        [patchMatchReport] // 의존성에서 필요 요소만 포함
    );

    useEffect(() => {
        // `editManagerComment` 또는 `editRating` 변경 시 debounce 호출
        if (reportProfile?.matchDateTime) {
            debouncedSubmitReport();
        }

        // 컴포넌트 언마운트 시 debounce 정리
        return () => {
            debouncedSubmitReport.cancel();
        };
    }, [reportProfile]);

    // 스크롤 이벤트 처리
    const [isFixed, setIsFixed] = useState(false);
    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 270) {
                setIsFixed(true);
            } else {
                setIsFixed(false);
            }
        };
        // scroll 이벤트 리스너 추가
        window.addEventListener('scroll', handleScroll);

        // 컴포넌트 언마운트 시 이벤트 리스너 제거
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div className="wrapper">
            <ReportPostSubHear />
            <EmptyBar customStyle="h-[38px]" />
            <div className="bg-primary-white rounded-[10px] p-[30px]">
                <div
                    className={`mb-[12px] flex justify-between ${
                        isFixed && 'bg-white pb-[10px] pt-[30px] z-[10] fixed w-[1340px] top-0'
                    }`}
                >
                    <div className="text-secondary-dGrayMid text-subtitle-medium-bold">{paramYear} 일정</div>
                    <div className="flex gap-[10px] items-center">
                        <div className="text-secondary-lGrayDark">{`${now?.slice(0, 24)} 최종수정`}</div>
                        <button
                            onClick={submitReport}
                            className="p-[12px] text-context-bold bg-primary-purple text-white rounded-[8px]"
                        >
                            리포트 저장
                        </button>
                    </div>
                </div>
                <div>
                    <ReportProfile
                        stateInfo={location?.state}
                        reportProfile={reportProfile}
                        handleInfo={handleInfo}
                    />
                </div>
                <ReportPlayer lineup={getLineup?.data} />
                <ReportRating lineup={getLineup?.data} />
                <ReportReview overallReview={reportProfile?.overallReview} />
            </div>
            <EmptyBar customStyle="h-[20px]" />
        </div>
    );
};

export default ReportPost;
