export function handleDateTime(dateTime: string): string {
    const inputDate = new Date(dateTime);
    const currentDate = new Date();
    const timeDiff = currentDate.getTime() - inputDate.getTime();
    const seconds = Math.floor(timeDiff / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    // 1분 이내
    if (seconds < 60) {
        return '방금 전';
    }

    // 1시간 이내
    if (minutes < 60) {
        return `${minutes}분 전`;
    }

    // 24시간 이내
    if (hours < 24) {
        return `${hours}시간 전`;
    }

    // 24시간 이후 (날짜 포맷팅)
    const year = inputDate.getFullYear().toString().slice(-2);
    const month = String(inputDate.getMonth() + 1).padStart(2, '0');
    const date = String(inputDate.getDate()).padStart(2, '0');
    const hour = String(inputDate.getHours()).padStart(2, '0');
    const minute = String(inputDate.getMinutes()).padStart(2, '0');

    return `${year}.${month}.${date}  ${hour}:${minute}`;
}
