export const skills = [
    { value: '드리블', label: '드리블' },
    { value: '패스', label: '패스' },
    { value: '슈팅', label: '슈팅' },
    { value: '퍼스트터치', label: '퍼스트터치' },
];

export const skillTab = [
    {
        label: '드리블',
    },
    {
        label: '패스',
    },
    {
        label: '슈팅',
    },
    {
        label: '퍼스트터치',
    },
];

export const sortings = [
    { value: 'latest', label: '최신순' },
    { value: 'best', label: '가장높은순' },
];

export const departmentOptions = [
    { value: 'PANGYO', label: '판교점' },
    { value: 'SUWON', label: '수원점' },
];

export const dribbleOneHistoryOptions = [
    { value: 'ID_DESC', label: '최신순' },
    { value: 'ID_ASC', label: '오래된순' },
    { value: 'SCORE_DESC', label: '가장높은순' },
    { value: 'SCORE_ASC', label: '가장낮은순' },
];

export const skillDescriptions = [
    {
        skill: '상체 밸런스',
        description:
            '상체 밸런스의 중요성: 팔을 펼쳐 균형을 잡을 수 있고 수비수와 거리를 인식할 수 있고 수비를 밀어낼 수 있다.',
    },
    {
        skill: '무릎 거리',
        description: '무릎 거리의 중요성: 무릎거리가 멀지 않아야 , 발안에서 세밀하게 플레이 할 수 있다.',
    },
    { skill: '무게축', description: `무게축의 중요성: 상체 기울기를 통해 낮은 자세로 세밀하게 드리블을 할 수 있다.` },
    {
        skill: '무게중심',
        description: `무게중심의 중요성: 하체 밸런스를 유지하며 쉽게 밸런스를 잃지 않고 드리블 할 수 있다.`,
    },
    {
        skill: '시야',
        description:
            '시야의 중요성: 볼만 쳐다보는 것이 아닌 헤드업을 통해 시야확보를 하며 상황인식을 통해 우리팀이나 수비를 인식해 미리 플레이를 계획하며 빠른템포로 세밀하게 플레이 할 수 있다.',
    },
    { skill: '잔발', description: `잔발의 중요성: 잔발이 빨라야 매번터치하며 볼을 세밀하게 다룰 수 있다.` },
    {
        skill: '뒤꿈치',
        description: `뒤꿈치의 중요성: 뒤꿈치가 뗴어진 상태로 드리블을 해야 짧고 세밀하게 빠르게 터치 할 수 있다. `,
    },
    {
        skill: '탄력성',
        description: `탄력성의 중요성: 순간적으로 탄력성있게 스피드변화를 가져가며 치고 나가야 수비수와 거리가 멀어져서 더 공격적으로 플레이하거나 소유할 수 있다.`,
    },
    {
        skill: '최고 속도',
        description: `최고속도의 중요성: 폭발적인 스프린트나 최고 드리블 속도를 통해 상대를 돌파 할 수 있다.`,
    },
    {
        skill: '평균 속도',
        description: `평균속도의 중요성: 평균속도가 높아야 볼 관리 할때도 상대가 더 수비해야 하는 범위와 양이 많고 볼이 없을 때도 2선과 3선등 주변선수들과 간격유지하기에도 도움이 된다. `,
    },
    {
        skill: '정확도',
        description: `정확도의 중요성: 내가 볼을 다루고자 하는 대로 세밀하게 볼을 다룰 수 있어야 상대 발이나 공격적인 위치로 드리블을 하기가 용이하다.`,
    },
    {
        skill: '볼터치',
        description: `볼터치의 중요성: 볼터치 간격이 짧아야 매번 터치하며 볼을 세밀하게 관리 할 수 있다.`,
    },
];
