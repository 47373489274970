import React, { useState, useEffect, memo } from 'react';

import ApexChart from 'components/chart/ApexChart';
import { areaOptions as defaultAreaOptions } from 'pages/player/measurement/analysis/areaOpt';
import { measurementWordMatcher } from 'utils/matchers/iconMatcher';

const AnalysisChart = ({ category, chartData }: any) => {
    return (
        <div className="w-full">
            <ApexChart
                customType={'area'}
                customOptions={{
                    ...defaultAreaOptions,
                    // xaxis: {
                    // categories: xAxisData,
                    // title: { text: 'Time (seconds)' },
                    // },
                    // yaxis: { title: { text: 'Value' } },
                }}
                customSeries={[
                    {
                        name: measurementWordMatcher(category),
                        data: chartData,
                    },
                ]}
                height={435}
            />
        </div>
    );
};

export default memo(AnalysisChart);
