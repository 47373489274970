// apexChart
import ApexChart from 'components/chart/ApexChart';
import { barSeries, barOptions } from 'pages/admin/report/reportPrint/groupReport/barOpt';

const ComparisonGraph = ({ isPrint }: any) => {
    return (
        <div
            id="graph-bar-chart"
            className="flex flex-col gap-[10px]"
        >
            <div className="text-subtitle-medium-bold text-secondary-dGrayDark">사용자 비교 그래프</div>
            <div
                className={`p-[12px] ${
                    isPrint ? 'w-[840px]' : 'w-[1340px]'
                }  flex justify-center items-center mt-[5px]`}
            >
                <ApexChart
                    customType={'bar'}
                    customOptions={barOptions}
                    customSeries={barSeries}
                    height={314}
                />
            </div>
        </div>
    );
};

export default ComparisonGraph;
