// hooks
import { useState, useEffect, useMemo } from 'react';
import { debounce } from 'lodash';
// api
import { useApiMutation } from 'hooks/useDynamicApi';
// commons
import SqureCheckbox from 'components/checkbox/SqureCheckbox';
import { showToast } from 'components/toast/Default';
// utils
import { ENDPOINT_MEASUREMENT } from 'utils/consts/apiEndpoint';
// icons
import { ReactComponent as EditPenIcon } from 'assets/svgs/editPen.svg';
import { ReactComponent as ResetIcon } from 'assets/svgs/reset.svg';
// utils
import { firstTouchStateMatcher } from 'utils/matchers/measurementMatcher';
const FirstTouch = ({
    measurementId,
    firstTouchId,
    playerName,
    attempt1Result,
    attempt2Result,
    attempt3Result,
    attempt4Result,
    attempt5Result,
    attempt6Result,
}: FirstTouchType) => {
    const attempState = ['FAILED', 'TOUCH_ONLY', 'TOUCH_AND_TURN'];
    const [editFirstTouchInfo, setEditFirstTouchInfo] = useState({
        measurementId: measurementId,
        firstTouchId: firstTouchId,
        playerName: playerName,
        attempt1Result: attempt1Result,
        attempt2Result: attempt2Result,
        attempt3Result: attempt3Result,
        attempt4Result: attempt4Result,
        attempt5Result: attempt5Result,
        attempt6Result: attempt6Result,
    });
    const handleEditFirstTouchInfo = (updateData: any) => {
        setEditFirstTouchInfo((prev) => ({
            ...prev,
            ...updateData,
        }));
    };
    // useApiMutation
    const patchFirstTouchMeasurement = useApiMutation(
        ENDPOINT_MEASUREMENT.firstTouch(editFirstTouchInfo?.firstTouchId),
        'patch',
        {
            onSuccess: (data) => {
                // showToast("퍼스트터치 기록을 수정했습니다.", "success");
            },
            onError: (error) => {
                const errorMessage = error?.data?.detailedMessage || error || '퍼스트터치 기록수정에 실패했습니다.';
                showToast(errorMessage, 'error');
            },
        }
    );
    const patchFirstTouchReset = useApiMutation(
        ENDPOINT_MEASUREMENT.firstTouchReset(editFirstTouchInfo?.firstTouchId),
        'patch',
        {
            onSuccess: (data) => {
                showToast('퍼스트터치 기록을 초기화했습니다.', 'success');
                handleEditFirstTouchInfo({
                    attempt1Result: 'FAILED',
                    attempt2Result: 'FAILED',
                    attempt3Result: 'FAILED',
                    attempt4Result: 'FAILED',
                    attempt5Result: 'FAILED',
                    attempt6Result: 'FAILED',
                });
            },
            onError: (error) => {
                const errorMessage = error?.data?.detailedMessage || error || '퍼스트터치 기록 초기화에 실패했습니다.';
                showToast(errorMessage, 'error');
            },
        }
    );
    const editFirstTouchMeasurement = () => {
        if (!patchFirstTouchMeasurement?.isPending) {
            patchFirstTouchMeasurement.mutate({
                attempt1Result: editFirstTouchInfo?.attempt1Result,
                attempt2Result: editFirstTouchInfo?.attempt2Result,
                attempt3Result: editFirstTouchInfo?.attempt3Result,
                attempt4Result: editFirstTouchInfo?.attempt4Result,
                attempt5Result: editFirstTouchInfo?.attempt5Result,
                attempt6Result: editFirstTouchInfo?.attempt6Result,
            });
        }
    };
    const editFirstTouchReset = () => {
        if (!patchFirstTouchReset?.isPending) {
            showToast('퍼스트터치 측정데이터를 초기화하는 중입니다.', 'info');
            patchFirstTouchReset.mutate({});
        }
    };

    // debounce
    const debouncedPatchFirstTouch = useMemo(
        () =>
            debounce(() => {
                editFirstTouchMeasurement();
            }, 500), // 1초 딜레이
        [patchFirstTouchMeasurement]
    );
    useEffect(() => {
        if (
            editFirstTouchInfo?.attempt1Result === attempt1Result &&
            editFirstTouchInfo?.attempt2Result === attempt2Result &&
            editFirstTouchInfo?.attempt3Result === attempt1Result &&
            editFirstTouchInfo?.attempt4Result === attempt4Result &&
            editFirstTouchInfo?.attempt5Result === attempt5Result &&
            editFirstTouchInfo?.attempt6Result === attempt6Result
        ) {
            return;
        }
        debouncedPatchFirstTouch();
        return () => {
            debouncedPatchFirstTouch.cancel();
        };
    }, [editFirstTouchInfo]);
    return (
        <>
            {attempState?.map((el, idx) => (
                <div
                    key={`${attempState}-${idx}`}
                    className={`w-full flex justify-between rounded-[8px] h-[50px] ${
                        idx % 2 === 1 ? 'bg-secondary-mGrayLight' : 'bg-primary-white'
                    }`}
                >
                    <div className="flex items-center justify-center flex-1">{firstTouchStateMatcher(el)}</div>
                    <SqureCheckbox
                        checked={editFirstTouchInfo?.attempt1Result === el}
                        onChange={() => handleEditFirstTouchInfo({ attempt1Result: el })}
                        className="justify-center flex-1"
                    />
                    <SqureCheckbox
                        checked={editFirstTouchInfo?.attempt2Result === el}
                        onChange={() => handleEditFirstTouchInfo({ attempt2Result: el })}
                        className="justify-center flex-1"
                    />
                    <SqureCheckbox
                        checked={editFirstTouchInfo?.attempt3Result === el}
                        onChange={() => handleEditFirstTouchInfo({ attempt3Result: el })}
                        className="justify-center flex-1"
                    />
                    <SqureCheckbox
                        checked={editFirstTouchInfo?.attempt4Result === el}
                        onChange={() => handleEditFirstTouchInfo({ attempt4Result: el })}
                        className="justify-center flex-1"
                    />
                    <SqureCheckbox
                        checked={editFirstTouchInfo?.attempt5Result === el}
                        onChange={() => handleEditFirstTouchInfo({ attempt5Result: el })}
                        className="justify-center flex-1"
                    />
                    <SqureCheckbox
                        checked={editFirstTouchInfo?.attempt6Result === el}
                        onChange={() => handleEditFirstTouchInfo({ attempt6Result: el })}
                        className="justify-center flex-1"
                    />
                </div>
            ))}
            <div className="flex justify-end text-secondary-lGrayDark m-[10px]">
                <button
                    className="flex items-center gap-[5px]"
                    onClick={editFirstTouchReset}
                >
                    초기화
                    <ResetIcon className=" h-[20px] m-auto" />
                </button>
            </div>
        </>
    );
};
export default FirstTouch;
interface FirstTouchType {
    measurementId: string;
    firstTouchId: string;
    playerName: string;
    attempt1Result: 'FAILED' | 'TOUCH_ONLY' | 'TOUCH_AND_TURN';
    attempt2Result: 'FAILED' | 'TOUCH_ONLY' | 'TOUCH_AND_TURN';
    attempt3Result: 'FAILED' | 'TOUCH_ONLY' | 'TOUCH_AND_TURN';
    attempt4Result: 'FAILED' | 'TOUCH_ONLY' | 'TOUCH_AND_TURN';
    attempt5Result: 'FAILED' | 'TOUCH_ONLY' | 'TOUCH_AND_TURN';
    attempt6Result: 'FAILED' | 'TOUCH_ONLY' | 'TOUCH_AND_TURN';
}
