// icons
import { ReactComponent as EmptyFileIcon } from 'assets/svgs/emptyFile.svg';
import { ReactComponent as MiniVideoIcon } from 'assets/svgs/miniVideo.svg';
import { ReactComponent as LapTimeIcon } from 'assets/svgs/lapTime.svg';
// player component
import ProgressBar from 'components/progressBar';

const ShootingTwoCard = ({ isPrint, shootingTwoAnalysis }: any) => {
    const {
        failCount = 0,
        failRate = 0,
        finalScore = 0,
        lapTime = 0,
        partialSuccessCount = 0,
        partialSuccessRate = 0,
        shootingTwoId,
        successCount = 0,
        successRate = 0,
    } = shootingTwoAnalysis || {};
    const TOTAL_COUNT = 6;

    return (
        <div className="flex flex-col gap-[20px]">
            <div className="flex items-center justify-between">
                <div className="text-subtitle-small-bold text-secondary-dGrayDark item">동적 슈팅</div>
                {!isPrint && (
                    <button className="cursor-default text-secondary-lGrayDark text-context-regular">
                        <div className=" flex gap-[10px] p-[10px] rounded-[8px] border border-secondary-lGrayMid ml-[2px]">
                            <MiniVideoIcon />
                            준비중
                        </div>
                    </button>
                )}
            </div>
            <div className="grid grid-cols-2">
                <div className="flex gap-[8px] items-center  justify-center">
                    <span className="flex gap-[5px] items-center text-secondary-lGrayDark">
                        <EmptyFileIcon />총 점수
                    </span>
                    <span className="flex gap-[5px] items-center">
                        <span className="text-[34px] font-bold text-primary-black">{finalScore}</span>
                        <span className="text-secondary-lGrayDark">점</span>
                    </span>
                </div>
                <div className="flex gap-[8px] items-center  justify-center">
                    <span className="flex gap-[5px] items-center text-secondary-lGrayDark">
                        <LapTimeIcon />
                        랩타임
                    </span>
                    <span className="flex gap-[5px] items-center">
                        <span className="text-[34px] font-bold text-primary-black">{lapTime}</span>
                        <span className="text-secondary-lGrayDark">sec</span>
                    </span>
                </div>
            </div>

            <div className="w-full flex flex-col gap-[10px] justify-center h-[135px]">
                <ProgressBar
                    label="성공"
                    targetRate={TOTAL_COUNT}
                    achievementRate={successCount}
                    customColor="#5651E3"
                />
                <ProgressBar
                    label="부분성공"
                    targetRate={TOTAL_COUNT}
                    achievementRate={partialSuccessCount}
                    customColor="#19DE93"
                />

                <ProgressBar
                    label="실패"
                    targetRate={TOTAL_COUNT}
                    achievementRate={failCount}
                    customColor="#79788E"
                />
            </div>

            <div className="grid grid-cols-3 h-[100px] bg-[#F8F8F8] rounded-[14px]">
                <div className="flex flex-col items-center justify-center">
                    <div className="text-secondary-dGrayLight">성공률</div>
                    <div>
                        <span className="text-title-small-bold text-primary-purple">{successRate}</span>
                        <span className="text-[#7c7c7c] text-context-bold">%</span>
                    </div>
                </div>
                <div className="flex flex-col items-center justify-center">
                    <div className="text-secondary-dGrayLight">부분성공률</div>
                    <div>
                        <span className="text-title-small-bold text-[#19DE93]">{partialSuccessRate}</span>
                        <span className="text-[#7c7c7c] text-context-bold">%</span>
                    </div>
                </div>

                <div className="flex flex-col items-center justify-center">
                    <div className="text-secondary-dGrayLight">실패율</div>
                    <div>
                        <span className="text-title-small-bold text-secondary-mGrayDark">{failRate}</span>
                        <span className="text-[#7c7c7c] text-context-bold">%</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ShootingTwoCard;
