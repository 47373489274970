const AreaChartTip = ({ value }: { value?: string }) => {
    return (
        <div className="w-[175px] h-[60px] relative">
            <div className="w-[175px] absolute shadow-sm items-center h-[60px] shadow-[#00000050] flex justify-between text-[#6B5BFF] font-bold text-[16ox] gap-[10px] py-[6px] px-[19px] bg-[#ffffff] border-[#6B5BFF] border-2 rounded-[12px]">
                <span>측정결과</span>
                <button className="bg-[#6B5BFF] h-fit text-[#ffffff] text-[12px] font-bold rounded-[80px] px-[12px] py-[8px] cursor-pointer">
                    분석하기
                </button>
            </div>
            <div className="bg-[#ffffff] absolute left-[47%] w-[12px] h-[12px] -rotate-45 bottom-[-5px] border-l-[#6B5BFF] border-b-[#6B5BFF] border-l-2 border-b-2"></div>
            <div className="h-[270px] border-l ml-[87px] border-secondary-mGrayDark border-dashed"></div>
        </div>
    );
};

export default AreaChartTip;
