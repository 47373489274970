// hooks
import { useState, useEffect } from 'react';
// api
import { useApiQuery } from 'hooks/useDynamicApi';
// commons
import EmptyBar from 'components/emptyBar';
import PasswordChange from 'components/modals/PasswordChange';
// assets
import graySymbol from 'assets/logos/symbol/graySymbol.png';
// staff components
import MypageSubHeader from 'pages/staff/mypage/MySubHeader';
// icons
import { ReactComponent as EditPenIcon } from 'assets/svgs/editPen.svg';
// redux
import { useSelector } from 'react-redux';
import { RootState } from 'store'; // Redux Store 타입 정의
// utils
import { ENDPOINT } from 'utils/consts/apiEndpoint';
import { roleMatcher } from 'utils/matchers/userInfoMatcher';

interface MyInfoField {
    value: string | string[] | null; // teamNames가 배열일 수 있으므로
    state: 'disabled' | 'default' | 'error';
}

interface MyInfo {
    [key: string]: MyInfoField;
}

const Mypage = () => {
    const {
        name,
        id,
        email,
        profileImageUrl,
        role, // null 허용
        staffRole, // 이미 null 허용됨
        status,
    } = useSelector((state: RootState) => state.userInfo); // 인증 상태를 token 유무로 판단
    const getMyInfo = useApiQuery(['getMyInfo'], ENDPOINT.user.user, 'get'); // 상세정보 조회 아직 안함

    const [isEdit, setIsEdit] = useState(false);

    const playerInputs = [
        { label: '이름', value: 'name' },
        { label: '이메일', value: 'email' },
        // { label: '주소', value: 'address' },
        // { label: '성별', value: 'gener' },
        // { label: '신장', value: 'height' },
        // { label: '몸무게', value: 'weight' },
        // { label: '등번호', value: 'uniformNumber' },
        // { label: '포지션', value: 'position' },
        // { label: '주 발', value: 'mainFoot' },
        // { label: '지역', value: 'region' },
        { label: '소속팀', value: 'teams' },
        { label: '역할', value: 'role' },
    ];
    const staffInputs = [
        { label: '이름', value: 'name' },
        { label: '이메일', value: 'email' },
        { label: '역할', value: 'role' },
        { label: '상세역할', value: 'staffRole' },
    ];
    const [myInfo, setMyInfo] = useState<MyInfo>({
        name: { value: name, state: 'default' },
        email: { value: email, state: 'default' },
        address: { value: '', state: 'default' },
        gener: { value: '', state: 'default' },
        height: { value: '', state: 'default' },
        weight: { value: '', state: 'default' },
        uniformNumber: { value: '', state: 'default' },
        position: { value: '', state: 'default' },
        mainFoot: { value: '', state: 'default' },
        region: { value: '', state: 'default' },
        teams: { value: [], state: 'disabled' },
        role: { value: role, state: 'disabled' },
    });
    useEffect(() => {
        if (getMyInfo?.data && getMyInfo?.isSuccess) {
            setMyInfo((prev) => ({
                ...prev,
                name: { value: getMyInfo?.data?.name, state: 'default' },
                email: { value: getMyInfo?.data?.email, state: 'default' },
                teams: { value: getMyInfo?.data?.teams?.map((el: any) => el?.name)?.join(','), state: 'disabled' },
                role: { value: getMyInfo?.data?.role, state: 'disabled' },
            }));
        }
    }, [getMyInfo?.data, getMyInfo?.isSuccess]);

    const handleInputChange = (field: string, value: string) => {
        setMyInfo((prev) => ({
            ...prev,
            [field]: {
                ...prev[field],
                value: value,
            },
        }));
    };
    return (
        <div className="wrapper">
            <EmptyBar customStyle={'h-[35px]'} />
            <MypageSubHeader />
            <EmptyBar customStyle={'h-[35px]'} />
            <div className="bg-primary-white rounded-[10px] p-[30px] flex gap-[30px]">
                <div className="flex justify-center items-center rounded-[8px] w-[230px] h-[280px] bg-secondary-mGrayLight">
                    <img
                        src={profileImageUrl || graySymbol}
                        alt="graySymbol"
                        className={`${
                            profileImageUrl ? 'w-[230px] h-[280px] object-cover rounded-[8px]' : 'w-[80px]'
                        } cursor-pointer`}
                    />
                </div>
                <div className="flex-col flex gap-[24px] w-[1080px]">
                    <div className="h-[64px] mb-[24px] flex justify-between items-end">
                        <div>
                            <div className="text-title-small-bold">{name}</div>
                            <div className="text-[#868686] text-[20px] font-semibold flex  items-center">
                                <div className="flex gap-[10px]">
                                    {myInfo?.teams?.value && myInfo?.teams?.value?.length > 0 && (
                                        <span>{myInfo?.teams?.value}</span>
                                    )}
                                    <span>{roleMatcher(role || '')}</span>
                                    <button
                                        // onClick={() => setIsEdit(!isEdit)} 임시 비활성화, 추후 마이페이지 정보 직접 변경시 오픈.
                                        className={`ml-[4px] ${
                                            isEdit ? 'text-primary-purple' : 'text-secondary-mGrayDark'
                                        }`}
                                    >
                                        <EditPenIcon />
                                    </button>
                                </div>
                            </div>
                        </div>
                        {isEdit && (
                            <div className="flex gap-[8px]">
                                <button
                                    onClick={() => setIsEdit(false)}
                                    className="w-[80px] h-[44px] bg-primary-purple p-[12px] rounded-[8px] text-primary-white text-context-bold"
                                >
                                    저장하기
                                </button>
                                <button
                                    onClick={() => setIsEdit(false)}
                                    className="w-[80px] h-[44px] bg-secondary-dGrayLight p-[12px] rounded-[8px] text-primary-white text-context-bold"
                                >
                                    취소하기
                                </button>
                            </div>
                        )}
                    </div>
                    <div className="grid grid-cols-2 gap-[14px]">
                        {role === 'PLAYER'
                            ? playerInputs.map((el) => (
                                  <div className="flex gap-[14px] items-center">
                                      <div className="text-subtitle-small-bold w-[100px] text-center">{el.label}</div>
                                      <input
                                          readOnly={!isEdit || myInfo[el.value].state === 'disabled'}
                                          value={myInfo[el.value].value || ''} // null 체크
                                          onChange={(e) => handleInputChange(el.value, e.target.value)}
                                          className={`${
                                              !isEdit || myInfo[el.value].state === 'disabled'
                                                  ? 'inputDisabledStyle'
                                                  : 'inputDefaultStyle'
                                          } inputCommonStyle`}
                                      />
                                  </div>
                              ))
                            : staffInputs.map((el) => (
                                  <div className="flex gap-[14px] items-center">
                                      <div className="text-subtitle-small-bold w-[100px] text-center">{el.label}</div>
                                      <input
                                          readOnly={!isEdit || myInfo[el.value].state === 'disabled'}
                                          value={myInfo[el.value].value || ''} // null 체크
                                          onChange={(e) => handleInputChange(el.value, e.target.value)}
                                          className={`${
                                              !isEdit || myInfo[el.value].state === 'disabled'
                                                  ? 'inputDisabledStyle'
                                                  : 'inputDefaultStyle'
                                          } inputCommonStyle`}
                                      />
                                  </div>
                              ))}
                    </div>
                    {status === 'PLAYER_PENDING_PASSWORD' && (
                        <div className="flex justify-end w-full">
                            <PasswordChange
                                modalBtn={
                                    <button className="p-[12px] rounded-[8px] bg-primary-purple text-primary-white font-bold">
                                        비밀번호 변경
                                    </button>
                                }
                            />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};
export default Mypage;
