// hooks
import { useEffect, useState } from 'react';
// commons
import EmptyBar from 'components/emptyBar';
import DatePicker from 'components/datePicker';
import Dropdown from 'components/dropdown/Default';
// utils
import { matchDurationOptions, matchTypeOptions } from 'utils/consts/reportInfo';
import { matchDurationMatcher, matchTypeMatcher } from 'utils/matchers/reportMatcher';
// icons
import { ReactComponent as CalendarIcon } from 'assets/svgs/calendar.svg';
const ReportProfile = ({ stateInfo, reportProfile }: ReportProfileTypeType) => {
    const [date, setDate] = useState(stateInfo?.matchDateTime);
    const [result, setResult] = useState('');
    useEffect(() => {
        if (reportProfile?.opponentScore < reportProfile?.teamScore) {
            setResult('승');
        } else if (reportProfile?.opponentScore === reportProfile?.teamScore) {
            if (!reportProfile?.penaltyShootoutOpponentScore || !reportProfile?.penaltyShootoutTeamScore)
                setResult('무');
            else {
                if (reportProfile?.penaltyShootoutOpponentScore < reportProfile?.penaltyShootoutTeamScore) {
                    setResult('승');
                } else if (reportProfile?.penaltyShootoutOpponentScore > reportProfile?.penaltyShootoutTeamScore) {
                    setResult('패');
                } else setResult('무');
            }
        } else if (reportProfile?.opponentScore > reportProfile?.teamScore) {
            setResult('패');
        } else {
            setResult('무');
        }
    }, [
        reportProfile?.opponentScore,
        reportProfile?.teamScore,
        reportProfile?.penaltyShootoutOpponentScore,
        reportProfile?.penaltyShootoutTeamScore,
    ]);
    return (
        <div className="h-[216px] border-y border-secondary-lGrayMid flex">
            <div className="w-[150px] shrink-0 h-full bg-secondary-mGrayLight text-secondary-lGrayDark text-context-regular flex justify-center items-center">
                경기정보
            </div>
            <div className="p-[24px]">
                <div className="flex gap-[24px]">
                    <div className="flex items-center gap-[10px] ">
                        <div className="text-center w-[100px] flex-shrink-0 text-context-regular text-secondary-dGrayMid">
                            경기일시
                        </div>
                        <div className="inputDefaultStyle p-[12px] w-[168px] h-[44px] text-context-regular rounded-[8px] flex justify-between items-center">
                            {date?.slice(0, 10) || '경기일시'}
                            <CalendarIcon />
                        </div>

                        <div className="w-[130px]">
                            <input
                                value={date?.slice(11, 16)}
                                className="inputDefaultStyle inputCommonStyle"
                            />
                        </div>
                    </div>
                    <div className="flex items-center gap-[10px]">
                        <div className="text-center w-[100px] flex-shrink-0 text-context-regular text-secondary-dGrayMid">
                            경기시간
                        </div>
                        <div className="w-[200px]">
                            <input
                                value={matchDurationMatcher(reportProfile?.matchDuration)}
                                className="inputDefaultStyle inputCommonStyle"
                            />
                        </div>
                    </div>
                </div>
                <EmptyBar customStyle="h-[18px]" />

                <div className="flex items-center gap-[24px]">
                    <div className="flex items-center gap-[10px] w-[420px]">
                        <div className="text-center shrink-0 w-[100px] text-context-regular text-secondary-dGrayMid">
                            상대팀
                        </div>
                        <input
                            maxLength={100}
                            placeholder="상대팀"
                            value={reportProfile?.opponentTeam}
                            className="inputDefaultStyle inputCommonStyle"
                        />
                    </div>
                    <div className="flex items-center gap-[10px]">
                        <div className="text-center w-[100px] shrink-0 text-context-regular text-secondary-dGrayMid">
                            대회/리그 이름
                        </div>

                        <div className="w-[100px]">
                            <input
                                value={matchTypeMatcher(reportProfile?.matchType)}
                                className="inputDefaultStyle inputCommonStyle"
                            />
                        </div>

                        <div className="w-[450px]">
                            <input
                                placeholder="대회/리그 이름"
                                value={reportProfile?.competitionName}
                                className="inputDefaultStyle inputCommonStyle"
                            />
                        </div>
                    </div>
                </div>
                <EmptyBar customStyle="h-[18px]" />
                <div className="flex gap-[24px]">
                    <div className="flex items-center gap-[10px] w-[420px]">
                        <span className="text-center w-[100px] shrink-0 text-context-regular text-secondary-dGrayMid">
                            <div>경기결과</div>
                            <div>(우리:상대)</div>
                        </span>
                        <input
                            placeholder="우리"
                            value={reportProfile?.teamScore}
                            className="inputDefaultStyle inputCommonStyle"
                        />
                        <div>:</div>
                        <input
                            placeholder="상대"
                            value={reportProfile?.opponentScore}
                            className="inputDefaultStyle inputCommonStyle"
                        />
                        <button
                            className={`px-[12px] py-[4px] rounded-[80px] shrink-0 ${
                                result === '승'
                                    ? 'bg-primary-purple text-white'
                                    : result === '패'
                                    ? 'bg-status-error text-white'
                                    : 'bg-[#d2d2d2] text-[#111111]'
                            }`}
                        >
                            {result ? result : '미정'}
                        </button>
                    </div>

                    <div className="flex items-center gap-[10px] w-[420px]">
                        <span className="text-center w-[100px] shrink-0 text-context-regular text-secondary-dGrayMid">
                            <div>승부차기</div>
                            <div>(우리:상대)</div>
                        </span>
                        <input
                            placeholder="우리"
                            value={reportProfile?.penaltyShootoutTeamScore || ''}
                            className="inputDefaultStyle inputCommonStyle"
                        />
                        <div>:</div>
                        <input
                            placeholder="상대"
                            value={reportProfile?.penaltyShootoutOpponentScore || ''}
                            className="inputDefaultStyle inputCommonStyle"
                        />
                    </div>

                    <div className="flex items-center gap-[10px] w-[261px]">
                        <span className="text-center shrink-0 w-[100px] text-context-regular text-secondary-dGrayMid">
                            포메이션
                        </span>
                        <input
                            placeholder="포메이션"
                            value={reportProfile?.formation}
                            className="inputDefaultStyle inputCommonStyle"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ReportProfile;

interface ReportProfileTypeType {
    stateInfo: {
        id: string;
        matchDateTime: string;
        matchType: string;
        seasonYear: string;
        teamName: string;
    };
    reportProfile: {
        matchDateTime: string;
        opponentTeam: string;
        matchType: string;
        competitionName: string;
        teamScore: number;
        opponentScore: number;
        penaltyShootoutTeamScore: number | string | null;
        penaltyShootoutOpponentScore: number | string | null;
        matchDuration: string;
        matchResult: string;
        formation: string;
        overallReview: string;
        seasonYear: string;
    };
    handleInfo: (type: string, value: any) => void;
}
