// hooks
import { useState } from 'react';
import { useParams } from 'react-router-dom';
// api
import { useApiMutation } from 'hooks/useDynamicApi';
// icons
import { ReactComponent as InfoBoxIcon } from 'assets/svgs/infoBox.svg';
// reudx
import { useSelector } from 'react-redux';
import { RootState } from 'store'; // Redux Store 타입 정의
// endPoint
import { ENDPOINT_MEASUREMENT } from 'utils/consts/apiEndpoint';
// common
import { showToast } from 'components/toast/Default';
// utils
import { skillDescriptions } from 'utils/consts/measurement';

interface FeedbackCardType {
    title: string;
    type: 'best' | 'worst';
    skill: string;
    defaultfeedback: string;
    showInfoCard: boolean;
    setShowInfoCard: (showInfoCard: boolean) => void;
}
const FeedbackCard = ({
    title = '가장 우수한 기능은',
    type = 'best',
    skill = '준비중',
    defaultfeedback = '',
    showInfoCard,
    setShowInfoCard,
}: FeedbackCardType) => {
    const bestLabelStyle = 'bg-primary-green text-secondary-dGrayMid ';
    const worstLabelStyle = 'bg-status-error text-primary-white';
    const { role, staffRole } = useSelector((state: RootState) => state.userInfo); // 인증 상태를 token 유무로 판단
    const isFeedbackEdit = role === 'SUPER_ADMIN' || role === 'DATA_ANALYST';
    const [feedback, setFeedback] = useState(defaultfeedback);
    const { measurementId = '' } = useParams();
    const description = skillDescriptions.find((el) => el.skill === skill);

    // useApiMutation
    // const postFeedback = useApiMutation(ENDPOINT_MEASUREMENT.feedback(measurementId), 'post', {
    //     onSuccess: (data) => {
    //         showToast('피드백 업데이트 완료되었습니다.', 'success');
    //     },
    //     onError: (error) => {
    //         // 에러 메시지 추출
    //         const errorMessage = error?.data?.detailedMessage || error || '피드백 업데이트에 실패했습니다.';
    //         showToast(`${errorMessage}`, 'error');
    //     },
    // });
    // const submitFeedback = () => {
    //     if (measurementId && isFeedbackEdit && !postFeedback?.isPending) {
    //         showToast('피드백 업데이트 중입니다.', 'info');
    //         postFeedback.mutate({
    //             content: feedback,
    //         });
    //     }
    // };
    return (
        <div className="bg-secondary-dGrayMid rounded-[14px] w-full flex flex-col gap-[18px] p-[18px] relative z-[1] justify-center items-center">
            <div className="flex gap-[8px] justify-center items-center">
                <span className="text-context-regular text-primary-white">{title}</span>
                <span
                    className={`text-[14px] font-bold  rounded-[80px] px-[12px] py-[4px] ${
                        type === 'best' ? bestLabelStyle : worstLabelStyle
                    }`}
                >
                    {skill}
                </span>
                <button onClick={() => setShowInfoCard(!showInfoCard)}>
                    <InfoBoxIcon />
                </button>
            </div>
            <textarea
                className="w-[620px] h-[215px] resize-none p-[12px] rounded-[8px]"
                value={description?.description}
                // onChange={(e) => setFeedback(e.target.value)}
                readOnly
            ></textarea>
            {/* {measurementId && isFeedbackEdit && (
        <div className="w-full">
          <button
            onClick={submitFeedback}
            className="bg-primary-purple rounded-[8px] w-full px-[12px] py-[8px] text-white font-bold"
          >
            피드백 작성 완료
          </button>
        </div>
      )} */}
        </div>
    );
};

export default FeedbackCard;
