export function resultMatcher(result: string) {
    switch (result) {
        case 'DRAW':
            return '무';
        case 'WIN':
            return '승';
        case 'LOSE':
            return '패';
        case '무':
            return 'DRAW';
        case '승':
            return 'WIN';
        case '패':
            return 'LOSE';
        default:
            return result;
    }
}

export function matchTypeMatcher(matchType: string) {
    switch (matchType) {
        case 'LEAGUE':
            return '리그';
        case 'TOURNAMENT':
            return '대회';
        case 'FRIENDLY':
            return '친선';
        case '리그':
            return 'LEAGUE';
        case '대회':
            return 'TOURNAMENT';
        case '친선':
            return 'FRIENDLY';
        default:
            return matchType;
    }
}

export function matchDurationMatcher(duration: string) {
    switch (duration) {
        case 'MINUTES_40':
            return '40 분';
        case 'MINUTES_50':
            return '50 분';
        case 'MINUTES_60':
            return '60 분';
        case 'MINUTES_70':
            return '70 분';
        case 'MINUTES_80':
            return '80 분';
        case 'MINUTES_90':
            return '90 분';
        case '40 분':
            return 'MINUTES_40';
        case '50 분':
            return 'MINUTES_50';
        case '60 분':
            return 'MINUTES_60';
        case '70 분':
            return 'MINUTES_70';
        case '80 분':
            return 'MINUTES_80';
        case '90 분':
            return 'MINUTES_90';
        default:
            return duration;
    }
}

export const formatDateTimeMatcher = (dateTime: string): string => {
    if (!dateTime) return '';
    // Date 객체 생성
    const date = new Date(dateTime);

    // 년, 월, 일, 시, 분 추출
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // 월은 0부터 시작
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    // 형식에 맞춰 문자열 리턴
    return `${year}.${month}.${day} ${hours}시${minutes}분`;
};

export const VALID_GRADES = ['S', 'A', "A'", 'B', "B'", 'C', "C'", 'D', 'F']; // 유효한 입력 값
export const VALID_DESCRIPTION_GRADES = ['S', 'A', 'A_SUB', 'B', 'B_SUB', 'C', 'C_SUB', 'D', 'F'];

export const gradeMatcher = (grade: string): string => {
    switch (grade) {
        case 'S':
            return 'S';
        case 'A':
            return 'A';
        case 'B':
            return 'B';
        case 'C':
            return 'C';
        case 'D':
            return 'D';
        case 'F':
            return 'F';
        case "A'":
            return 'A_SUB';
        case "B'":
            return 'B_SUB';
        case "C'":
            return 'C_SUB';
        default:
            return grade;
    }
};
export const gradeDescriptionMatcher = (grade: string): string => {
    switch (grade) {
        case 'S':
            return 'S';
        case 'A':
            return 'A';
        case 'B':
            return 'B';
        case 'C':
            return 'C';
        case 'D':
            return 'D';
        case 'F':
            return 'F';
        case 'A_SUB':
            return "A'";
        case 'B_SUB':
            return "B'";
        case 'C_SUB':
            return "C'";
        default:
            return grade;
    }
};
