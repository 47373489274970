// hooks
import { useState, useEffect } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
// api
import { useApiQuery } from "hooks/useDynamicApi";
// icons
import { ReactComponent as HomeIcon } from "assets/svgs/home.svg";
import { ReactComponent as CircleArrowIcon } from "assets/svgs/circleArrow.svg";
// common
import StatusLabel from "components/label/Default";
import Dropdown from "components/dropdown/Default";
// utils
import { useParamNavigate } from "hooks/useDynamicNavigate";
import { seasonYearOptions } from "utils/consts/controlInfo";
import {
  ENDPOINT_TEAM,
  ENDPOINT_REPORT,
  ENDPOINT_MANAGEMENT_PROFILE,
} from "utils/consts/apiEndpoint";
import {
  dropDownFormChanger,
  dropDownFormChangerType,
} from "utils/formChanger";
// redux
import { RootState } from "store";
import { useSelector } from "react-redux";
const ScheduleSubHeader = () => {
  const { staffRole } = useSelector((state: RootState) => state.userInfo);

  const handleParamNavigate = useParamNavigate();
  const navigate = useNavigate();
  const curYear = new Date().getFullYear();

  const [searchParams] = useSearchParams();
  const paramAgeGroup = searchParams.get("ageGroup") || "";
  const paramYear = searchParams.get("year") || `${curYear}`;

  const [year, setYear] = useState(paramYear);
  const [ageGroup, setAgeGroup] = useState(paramAgeGroup);
  const [ageGroupOpts, setAgeGroupsOpts] = useState<dropDownFormChangerType[]>(
    []
  );
  // react useApiQuery
  const { data, isLoading, isSuccess, isError, error, refetch } = useApiQuery(
    ["ageGroup"],
    ENDPOINT_TEAM.staff.team.ageGroups,
    "get"
  );
  const getSeasonStat = useApiQuery(
    ["getSeasonStat", paramYear],
    ENDPOINT_REPORT.seasonStat(paramYear, {
      ageGroup: staffRole === "OFFICE" ? paramAgeGroup : "",
    }),
    "get"
  );

  useEffect(() => {
    if (isSuccess && data) {
      const { teamName, ageGroups } = data;
      localStorage.setItem("teamName", teamName);
      setAgeGroup(ageGroups[0]);
      const newAgeGroupOpts = dropDownFormChanger(ageGroups);
      setAgeGroupsOpts(newAgeGroupOpts);
    }
  }, [data]);
  useEffect(() => {
    // 그냥 이렇게 해도 파라미터 값만 바뀜
    if (ageGroup)
      handleParamNavigate({
        ageGroup: ageGroup,
      });
  }, [ageGroup]);
  useEffect(() => {
    // 그냥 이렇게 해도 파라미터 값만 바뀜
    if (year)
      handleParamNavigate({
        year: year,
      });
  }, [year]);
  const handleHomeNavigate = () => {
    if (paramAgeGroup && paramYear)
      handleParamNavigate(
        {
          ageGroup: paramAgeGroup,
          year: paramYear,
        },
        false,
        `/staff/control/lineUp`
      );
  };

  return (
    <div className="h-[80px]">
      <div className="flex items-center justify-between ">
        <div className="flex flex-col gap-[15px]">
          <div className="flex items-center gap-[8px]">
            <button onClick={handleHomeNavigate}>
              <HomeIcon className="text-white" />
            </button>
            <div className="w-[3px] h-[3px] rounded-full bg-secondary-lGrayDark"></div>

            <Dropdown
              options={ageGroupOpts}
              value={ageGroup}
              defaultValue={ageGroup}
              onChange={setAgeGroup}
              className=" text-label-regular border rounded-[8px] border-primary-white"
              innerClassName="px-[6px] py-[8px]"
            />
          </div>
          <div className="flex items-center gap-[10px]">
            <button onClick={() => navigate(-1)}>
              <CircleArrowIcon className="text-white" />
            </button>
            <div className="text-[26px] font-bold text-white">경기관리</div>
          </div>
        </div>
        <div className="flex w-fit gap-[80px] ">
          <div className="min-w-[311px] flex gap-[17px]  h-[50px] text-[#868686]">
            <div className="w-[5px] bg-primary-green rounded-[5px]"></div>
            <Dropdown
              options={seasonYearOptions}
              value={year}
              defaultValue={year}
              onChange={setYear}
              className={"w-[93px] border rounded-[8px] border-primary-white"}
            />
            <div className="flex gap-[15px] items-center">
              <div
                className="w-[88px]  text-[20px] font-semibold leading-[24px]"
                style={{ wordBreak: "keep-all", overflowWrap: "break-word" }} //한국어 띄어쓰기 단위로 줄바꿈
              >
                {`${year}시즌 승률`}
              </div>
              <div className="flex items-center gap-[12px]">
                <div className="flex items-center gap-[5px]">
                  <span className="text-[50px] text-primary-white font-bold">
                    {getSeasonStat?.data?.winRate?.toFixed(1)}
                  </span>
                  <span className="text-[22px]">{"%"}</span>
                </div>
                <div>
                  <StatusLabel
                    status={"up"}
                    customColor="bg-primary-green text-primary-black font-bold  text-[16px]"
                  >
                    {getSeasonStat?.data?.winRateChange?.toFixed(1)}%
                  </StatusLabel>
                </div>
              </div>
            </div>
          </div>
          <div className=" flex gap-[18px]  h-[48px] text-[#868686]">
            <div className="w-[5px] bg-primary-green rounded-[5px]"></div>
            <div className="flex gap-[15px] items-center">
              <div
                className="w-[72px] font-bold leading-[24px] text-[20px]"
                style={{ wordBreak: "keep-all", overflowWrap: "break-word" }} //한국어 띄어쓰기 단위로 줄바꿈
              >
                {"총 시즌 정보"}
              </div>
              <div className="flex items-center gap-[25px] ">
                <div className="flex items-center gap-[5px]">
                  <span className="text-[50px] text-primary-green font-bold">
                    {getSeasonStat?.data?.wins}
                  </span>
                  <span className="text-[22px]">{"승"}</span>
                </div>
                <div className="flex items-center gap-[5px]">
                  <span className="text-[50px] text-primary-white font-bold">
                    {getSeasonStat?.data?.draws}
                  </span>
                  <span className="text-[22px]">{"무"}</span>
                </div>
                <div className="flex items-center gap-[5px]">
                  <span className="text-[50px] text-status-error font-bold">
                    {getSeasonStat?.data?.losses}
                  </span>
                  <span className="text-[22px]">{"패"}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScheduleSubHeader;
