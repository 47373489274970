import { useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

export function useLogout() {
    const queryClient = useQueryClient();
    const navigate = useNavigate();

    const logoutFunc = () => {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('id');
        localStorage.removeItem('name');
        localStorage.removeItem('email');
        localStorage.removeItem('profileImageUrl');
        localStorage.removeItem('role');
        localStorage.removeItem('staffRole');
        localStorage.removeItem('status');
        localStorage.removeItem('ageGroup');
        localStorage.removeItem('teamId');
        localStorage.removeItem('teamName');
        queryClient.clear();
        navigate('/'); // 로그아웃 후 홈 화면으로 이동
    };

    return logoutFunc;
}

export function useHandLogout() {
    const queryClient = useQueryClient();
    const navigate = useNavigate();

    const logoutFunc = () => {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('id');
        localStorage.removeItem('name');
        localStorage.removeItem('email');
        localStorage.removeItem('profileImageUrl');
        localStorage.removeItem('role');
        localStorage.removeItem('staffRole');
        localStorage.removeItem('status');
        localStorage.removeItem('ageGroup');
        localStorage.removeItem('teamId');
        localStorage.removeItem('teamName');
        queryClient.clear();
        navigate('/hand/signIn'); // 로그아웃 후 홈 화면으로 이동
    };

    return logoutFunc;
}

export function useClear() {
    const queryClient = useQueryClient();
    const clearFunc = () => {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('id');
        localStorage.removeItem('name');
        localStorage.removeItem('email');
        localStorage.removeItem('profileImageUrl');
        localStorage.removeItem('role');
        localStorage.removeItem('staffRole');
        localStorage.removeItem('status');
        localStorage.removeItem('ageGroup');
        localStorage.removeItem('teamId');
        localStorage.removeItem('teamName');
        queryClient.clear();
    };

    return clearFunc;
}
