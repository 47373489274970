import React, { useState, useRef } from 'react';
// icons
import { ReactComponent as LapTimeIcon } from 'assets/svgs/lapTime.svg';
import { ReactComponent as ResetIcon } from 'assets/svgs/reset.svg';

interface StopwatchType {
    lapTime: number | string;
    setLapTime: (time: number | string) => void;
}
const Stopwatch = ({ lapTime, setLapTime }: StopwatchType) => {
    const [time, setTime] = useState(0); // 타이머 시간 (초, 소숫점 포함)
    const [isRunning, setIsRunning] = useState(false); // 타이머 실행 여부
    const timerRef = useRef<number | null>(null); // 타이머 참조
    const startTimeRef = useRef<number | null>(null); // 시작 시간 참조
    // 타이머 시작
    const handleStartStop = () => {
        if (isRunning) {
            cancelAnimationFrame(timerRef.current!); // 타이머 중지
            setIsRunning(false);
        } else {
            startTimeRef.current = performance.now() - time * 1000; // 시작 시간 조정
            const updateTimer = () => {
                const elapsed = (performance.now() - startTimeRef.current!) / 1000; // 경과 시간 계산
                setTime(elapsed); // 초단위 업데이트
                timerRef.current = requestAnimationFrame(updateTimer); // 재귀 호출
            };
            timerRef.current = requestAnimationFrame(updateTimer);
            setIsRunning(true);
        }
    };

    // 초기화
    const handleReset = () => {
        cancelAnimationFrame(timerRef.current!);
        setTime(0);
        setLapTime('');
        setIsRunning(false);
        startTimeRef.current = null;
    };

    // 랩타임 추가
    const handleAddLapTime = () => {
        setLapTime(time.toFixed(2)); // 소숫점 2자리까지 포맷
    };

    return (
        <div className="flex items-center font-bold gap-[5px] p-4">
            {/* 스톱워치 표시 */}
            <LapTimeIcon className="text-secondary-lGrayDark" />
            <div className="text-[20px] text-secondary-lGrayDark w-[130px]">{time.toFixed(2)} 초</div>

            {/* 버튼 */}
            <button
                className="px-4 py-2 rounded-[8px] bg-primary-black text-primary-white ry-green"
                onClick={handleStartStop}
            >
                {isRunning ? 'STOP' : 'START'}
            </button>
            <button
                className="px-4 py-2 rounded-[8px] text-white bg-secondary-lGrayDark"
                onClick={handleReset}
            >
                <ResetIcon />
            </button>
            <button
                className="px-4 py-2 border rounded-[8px] bg-primary-purple text-primary-white"
                onClick={handleAddLapTime}
            >
                기록
            </button>

            {/* 인풋 필드 */}
            {/* <input
                type="text"
                value={lapTime}
                readOnly
                className="w-40 h-10 text-center border border-gray-300 rounded"
            /> */}
        </div>
    );
};

export default Stopwatch;
