// hooks
import { useParamNavigate } from "hooks/useDynamicNavigate";
import { useLocation, useNavigate } from "react-router-dom";
// common
import StatusLabel from "components/label/Default";
// icons
import { ReactComponent as HomeIcon } from "assets/svgs/home.svg";
import { ReactComponent as CircleArrowIcon } from "assets/svgs/circleArrow.svg";

interface SubMainHeaderType {
  rankingInfo: {
    overallRank: {
      currentRank: number | null;
      previousRank: number | null;
      totalCount: number | null;
      rankChange: number | null;
    };
    sameAgeRank: {
      currentRank: number | null;
      previousRank: number | null;
      totalCount: number | null;
      rankChange: number | null;
    };
    bestScore: {
      score: number | null;
      topPercentile: number | null;
    };
  };
}
const SubMainHeader = ({ name }: { name: string | null }) => {
  const handleParamNavigate = useParamNavigate();
  const navigate = useNavigate();
  const location = useLocation().pathname;
  const locationList = location.split("/");

  const handleHomeNavigate = () => {
    handleParamNavigate({}, false, `/admin/measurement`);
  };
  return (
    <div className="h-[80px]">
      <div className="flex items-center justify-between">
        <div className="flex">
          <div className="flex flex-col gap-[15px]">
            <div className="flex gap-[8px] items-center">
              <button onClick={handleHomeNavigate}>
                <HomeIcon className="text-white" />
              </button>
              <div className="w-[3px] h-[3px] rounded-full bg-secondary-lGrayDark"></div>
              <div className="text-white text-label-regular">측정</div>
            </div>
            {locationList[3] ? (
              <div className="flex items-center gap-[6px]">
                <button onClick={() => navigate(-1)}>
                  <CircleArrowIcon className="text-white" />
                </button>
                <div className="text-[26px] text-white font-bold">분석</div>
              </div>
            ) : (
              <div className="text-[26px] text-white font-bold">측정</div>
            )}
          </div>
        </div>
        <div className="flex ">
          <div className="flex w-[970px]">
            {playerSummarizedData.map((el) => (
              <div
                className="min-w-[311px] flex gap-[18px]  h-[48px] text-[#868686]"
                key={el.title}
              >
                <div className="w-[5px] bg-primary-green rounded-[5px]"></div>
                <div className="flex gap-[15px] items-center">
                  <div
                    className="text-[20px] w-[72px] font-bold leading-[24px]"
                    style={{
                      wordBreak: "keep-all",
                      overflowWrap: "break-word",
                    }} //한국어 띄어쓰기 단위로 줄바꿈
                  >
                    {el.title}
                  </div>
                  <div className="flex items-center gap-[12px]">
                    <div className="flex items-center gap-[5px]">
                      <span className="text-[50px] text-primary-white font-bold">
                        {el.score}
                      </span>
                      <span className="text-[22px]">{el.unit}</span>
                    </div>
                    <div>
                      <StatusLabel
                        status={
                          el.unit === "등" && el.growth > 0
                            ? "up"
                            : el.unit === "등" && el.growth <= 0
                            ? "down"
                            : null
                        }
                        customColor="bg-primary-green text-primary-black font-bold text-[16px]"
                      >
                        {el.label}
                      </StatusLabel>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          {locationList[3] === "analysis" && (
            <button
              onClick={() => navigate("/player/measurement/report/1")}
              className="ml-[30px] w-[146px] h-[44px] text-context-bold items-center justify-center flex bg-primary-green text-primary-black rounded-[8px]"
            >
              리포트 발행하기
              <CircleArrowIcon className="rotate-180" />
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default SubMainHeader;

export const playerSummarizedData = [
  { title: "최근 등수결과", score: 3, unit: "등", growth: 2, label: `3등` },
  {
    title: "또래 중 등수",
    score: 2,
    unit: "등",
    growth: -1,
    label: "1등",
  },
  {
    title: "역대 최고점수",
    score: 92.8,
    unit: "%",
    growth: 10,
    label: `+ 상위10%`,
  },
];
