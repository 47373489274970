// hooks
import { useState } from 'react';
import { useParams, useSearchParams, useLocation, useNavigate } from 'react-router-dom';
import { useParamNavigate } from 'hooks/useDynamicNavigate';
// utils
import { measurementIconMatcher } from 'utils/matchers/iconMatcher';
import { dribbleCardColorMatcher } from 'utils/matchers/measurementMatcher';
// icons
import { ReactComponent as LineChartIcon } from 'assets/svgs/lineChart.svg';
import { ReactComponent as MiniVideoIcon } from 'assets/svgs/miniVideo.svg';
// redux
import { RootState } from 'store'; // Redux Store 타입 정의
import { useSelector } from 'react-redux';

const Scores = ({ isPrint, dribbleOneAnalysis, playerId, measuredAt }: any) => {
    const { role, id = '' } = useSelector((state: RootState) => state.userInfo); // 인증 상태를 token 유무로 판단
    const { measurementId = '' } = useParams();
    const [searchParams] = useSearchParams();
    const dateTime = searchParams.get('dateTime') || '';
    const dribbleOneId = searchParams.get('dribbleOneId') || '';
    const locationList = useLocation()?.pathname?.split('/');

    const scoreData = [
        { skill: '상체 밸런스', score: dribbleOneAnalysis?.armScore },
        { skill: '시야', score: dribbleOneAnalysis?.chinScore },
        { skill: '무게중심', score: dribbleOneAnalysis?.bendKneeScore },
        { skill: '무게축', score: dribbleOneAnalysis?.upperBodyScore },
        { skill: '뒤꿈치', score: dribbleOneAnalysis?.steppingScore },
        { skill: '무릎거리', score: dribbleOneAnalysis?.kneeScore },
        { skill: '잔발', score: dribbleOneAnalysis?.stepScore },
        { skill: '탄력성', score: dribbleOneAnalysis?.dashScore },
        { skill: '최고속도', score: dribbleOneAnalysis?.lapMaxSpeedScore },
        { skill: '평균속도', score: dribbleOneAnalysis?.lapAvgSpeedScore },
        { skill: '정확도', score: dribbleOneAnalysis?.lineDeviationScore },
        { skill: '볼터치', score: dribbleOneAnalysis?.ballTouchScore },
    ];
    const scores = scoreData.map((el) => el.score).filter((score) => score !== undefined);
    const [maxScore, minScore] = [Math.max(...scores), Math.min(...scores)];
    const labeledScoreData = scoreData.map((el) => ({
        ...el,
        label: el.score === maxScore ? 'Best' : el.score === minScore ? 'Worst' : undefined,
    }));
    const handleParamNavigate = useParamNavigate();
    const navigate = useNavigate();
    const handleNavigate = () => {
        // PANGYO 이후 추가 지점이 되면 하드코딩에서 변경필요
        if (locationList[1] === 'hand') {
            navigate(
                `/hand/measurement/analysis/${
                    measurementId || dribbleOneId
                }?playerId=${playerId}&measuredAt=${measuredAt}`
            );
        }

        const NavigateURL =
            role === 'PLAYER'
                ? `/player/measurement/analysis/${measurementId || dribbleOneId}`
                : role === 'TEAM_STAFF'
                ? `/staff/measurement/report/analysis/${measurementId || dribbleOneId}`
                : `/admin/control/measurement/analysis/${measurementId || dribbleOneId}`;

        handleParamNavigate(
            {
                dateTime: dateTime,
                measurementId: measurementId,
            },
            false,
            NavigateURL
        );
    };
    return (
        <div className="">
            <div className="flex flex-col gap-[20px]">
                <div className="flex items-center justify-between">
                    <div className="text-subtitle-small-bold text-secondary-dGrayDark item">에잇 라운드</div>
                    <div className="text-secondary-lGrayDark text-context-regular flex gap-[8px] items-center">
                        <span className="flex gap-[5px]">
                            <LineChartIcon />
                            평균점수
                        </span>
                        <span className="flex gap-[5px]">
                            <span className="text-[34px] font-bold text-primary-black">
                                {dribbleOneAnalysis?.averageScore}
                            </span>
                            <span>점</span>
                        </span>
                        {!isPrint && (
                            <button
                                onClick={() => handleNavigate()}
                                className="flex gap-[10px] p-[10px] rounded-[8px] border border-primary-purple text-primary-purple ml-[2px]"
                            >
                                <MiniVideoIcon />
                                영상보기
                            </button>
                        )}
                    </div>
                </div>
                <div className="grid grid-rows-4 grid-flow-col gap-x-[10px] gap-y-[12px]">
                    {labeledScoreData?.map((el: any) => {
                        const Icon = measurementIconMatcher(el.skill);

                        return (
                            <div
                                className={`h-[60] w-full px-[18px] py-[10px] rounded-[8px]  flex gap-[12px] items-center ${dribbleCardColorMatcher(
                                    el.label
                                )}`}
                            >
                                <div className={`w-full text-start text-context-regular items-center`}>
                                    <div
                                        className="w-[60px]"
                                        style={{
                                            wordBreak: 'keep-all',
                                            overflowWrap: 'break-word',
                                        }} //한국어 띄어쓰기 단위로 줄바꿈
                                    >
                                        {el.skill}
                                    </div>
                                </div>
                                <div
                                    className={`w-full text-title-medium-regular text-center justify-center flex items-center gap-[12px]`}
                                >
                                    {el?.label && (
                                        <span
                                            className={`px-[8px] text-context-regular py-[5px] text-primary-white bg-[#13141950] rounded-[7px]`}
                                        >
                                            {el?.label}
                                        </span>
                                    )}
                                    {el.score?.toFixed(1)}
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default Scores;
