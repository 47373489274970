// hooks
import { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
// icons
import { ReactComponent as DeleteIcon } from 'assets/svgs/delete.svg';

// api
import { useApiQuery, useApiMutation } from 'hooks/useDynamicApi';
import { showToast } from 'components/toast/Default';
// utils
import { ENDPOINT_REPORT } from 'utils/consts/apiEndpoint';
// commons
import Dropdown from 'components/dropdown/Default';

const ShootoutsElement = ({ shootouts }: any) => {
    const [editKickOrder, setEditKickOrder] = useState(shootouts?.kickOrder);
    const [editShootoutsPlayer, setEditShootoutsPlayer] = useState(shootouts?.kicker?.playerId);
    const [editSuccess, setEditSuccess] = useState(shootouts?.success ? 'success' : 'fail');

    return (
        <div className="flex gap-[10px]">
            <div className="w-[70px] flex justify-center rounded-[8px] bg-primary-black text-white items-center">
                {editKickOrder}
            </div>

            <div className="w-[500px]">
                <input
                    readOnly
                    value={editShootoutsPlayer}
                    className="inputDefaultStyle inputCommonStyle"
                />
            </div>
            <div className="w-[130px]">
                <input
                    readOnly
                    value={editSuccess}
                    className="inputDefaultStyle inputCommonStyle"
                />
            </div>
        </div>
    );
};
export default ShootoutsElement;
