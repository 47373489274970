// hooks
import React, { useState, useEffect } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { useInfiniteQuery } from '@tanstack/react-query';
import { useInView } from 'react-intersection-observer';
// api
import axiosInstance from 'api/axiosInstance';
import { useApiMutation } from 'hooks/useDynamicApi';
// commons
import { showToast } from 'components/toast/Default';
import EmptyBar from 'components/emptyBar';
import CustomTable from 'components/table/CustomTable';
import ConfirmModal from 'components/modals/ConfirmModal';
// utils
import { useParamNavigate } from 'hooks/useDynamicNavigate';
import { ENDPOINT_REPORT } from 'utils/consts/apiEndpoint';
import { resultMatcher, matchTypeMatcher } from 'utils/matchers/reportMatcher';
import { formatDateTimeMatcher } from 'utils/matchers/reportMatcher';
// schedule components
import ScheduleSubHeader from 'pages/staff/control/schedule/ScheduleSubHeader';
import MatchDatePickerModal from 'pages/staff/control/schedule/report/MatchDatePickerModal';
// icons
import { ReactComponent as EditPenIcon } from 'assets/svgs/editPen.svg';
import { ReactComponent as DelteIcon } from 'assets/svgs/delete.svg';
// redux
import { RootState } from 'store';
import { useSelector } from 'react-redux';

const Schedule = () => {
    const { staffRole } = useSelector((state: RootState) => state.userInfo);
    const handleParamNavigate = useParamNavigate();
    const { ref, inView } = useInView();
    const location = useLocation().pathname;
    const [searchParams] = useSearchParams();
    const [reportCreateModal, setReportCreateModal] = useState(false);
    const curYear = new Date().getFullYear();
    const columns = [
        { key: 'matchDateTime', label: '경기 날짜', fixedWidth: 'w-[236px]' },
        { key: 'matchType', label: '유형', fixedWidth: 'w-[236px]' },
        {
            key: 'competitionName',
            label: '대회/리그 이름',
            fixedWidth: 'w-[250px]',
        },
        {
            key: 'opponentTeam',
            label: '상대팀',
            sortable: false,
            fixedWidth: 'w-[236px]',
        },
        { key: 'matchResult', label: '경기결과', fixedWidth: 'w-[236px]' },
        { key: 'edit', label: '수정', fixedWidth: 'w-[80px]' },
        { key: 'delete', label: '삭제', fixedWidth: 'w-[80px]' },
    ];

    const paramPlayerId = searchParams.get('player') || '';
    const paramEvaluationId = searchParams.get('evaluation') || '';
    const paramAgeGroup = searchParams.get('ageGroup') || '';
    const paramYear = searchParams.get('year') || `${curYear}`;

    const handleNavigate = (chileRoute: string, data: any) => {
        if (paramAgeGroup && paramYear)
            handleParamNavigate(
                {
                    ageGroup: paramAgeGroup,
                    year: paramYear,
                },
                false,
                `${location}${chileRoute}`,
                data
            );
    };
    const handleRows = (rows: any[]) => {
        return rows?.map((row) => ({
            matchDateTime: `${formatDateTimeMatcher(row?.matchDateTime)}`,
            matchType: matchTypeMatcher(row?.matchType),
            competitionName: row?.competitionName || '미입력',
            opponentTeam: row?.opponentTeam || '미입력', // 포지션이 배열이므로 쉼표로 구분
            matchResult: `${row?.teamScore}:${row?.opponentScore} (${resultMatcher(row?.matchResult)})`,
            edit: (
                <button
                    onClick={() =>
                        staffRole === 'OFFICE'
                            ? handleNavigate(`/read/${row?.id}`, row)
                            : handleNavigate(`/${row?.id}`, row)
                    }
                >
                    <EditPenIcon className="text-secondary-lGrayDark" />
                </button>
            ),
            delete: (
                <button
                    onClick={() => {
                        setDeleteReportModal(true);
                        setDeleteTargetId(row?.id);
                    }}
                >
                    <DelteIcon className="text-status-error" />
                </button>
            ),
        }));
    };

    // useInfiniteQuery
    const { data, error, fetchNextPage, hasNextPage, isFetching, isFetchingNextPage, status } = useInfiniteQuery({
        queryKey: ['report', 'season', paramYear],
        queryFn: ({ pageParam = 1 }) =>
            axiosInstance
                .get(
                    staffRole === 'OFFICE'
                        ? `${ENDPOINT_REPORT.season(paramYear)}?page=${pageParam}&size=10&ageGroup=${paramAgeGroup}`
                        : `${ENDPOINT_REPORT.season(paramYear)}?page=${pageParam}&size=10`
                )
                .then((response) => response.data),
        initialPageParam: 1,
        getNextPageParam: (lastPage) => (lastPage?.sliceInfo?.hasNext ? lastPage?.sliceInfo?.page + 1 : undefined),
    });

    useEffect(() => {
        if (inView && hasNextPage && !isFetchingNextPage) {
            fetchNextPage();
        }
    }, [inView, hasNextPage, isFetchingNextPage]);
    const flattenedRows = handleRows(data?.pages.flatMap((page) => page.data) || []);

    // useApiMutation
    const postReport = useApiMutation(ENDPOINT_REPORT.report(), 'post', {
        onSuccess: (data) => {
            showToast('경기 리포트가 생성되었습니다.', 'success');
            setTimeout(() => {
                handleNavigate(`/${data?.id}`, data);
            }, 100);
        },
        onError: (error) => {
            // 에러 메시지 추출
            const errorMessage = error?.data?.detailedMessage || error || `경기 리포트가 생성이 실패했습니다.`;
            showToast(errorMessage, 'error');
        },
    });

    const [deleteTargetId, setDeleteTargetId] = useState('');
    const [deleteReason, setDeleteReason] = useState('');
    const [deleteReportModal, setDeleteReportModal] = useState(false);
    const deleteReport = useApiMutation(
        ENDPOINT_REPORT.report(deleteTargetId), // 동적 URL 생성
        'delete',
        {
            onSuccess: () => {
                showToast('경기 리포트가 삭제되었습니다.', 'success');
                setDeleteReportModal(false);
            },
            onError: (error) => {
                const errorMessage = error?.data?.detailedMessage || error || `경기 리포트 삭제가 실패했습니다.`;
                setDeleteReportModal(false);
                showToast(errorMessage, 'error');
            },
        }
    );

    // hadle
    const createReport = (dateTime: any) => {
        if (!postReport.isPending && dateTime) {
            showToast('경기 리포트를 생성중입니다.', 'info');
            postReport.mutate({ matchDateTime: dateTime });
        }
    };

    const handleDeleteReport = () => {
        if (deleteTargetId)
            deleteReport.mutate({
                reason: '리포트삭제',
            }); // 삭제를 트리거하는 함수
    };

    return (
        <div className="wrapper">
            <ConfirmModal
                isShow={deleteReportModal}
                setIsShow={setDeleteReportModal}
                title={
                    <div className="flex flex-col gap-[14px] w-[400px] text-center">
                        <div>
                            해당리포트 삭제시, <span className="font-bold text-status-error">복구할 수 없습니다.</span>
                        </div>
                        <div className="w-full ">
                            <input
                                value={deleteReason}
                                onChange={(e) => setDeleteReason(e.target.value)}
                                maxLength={500}
                                placeholder="리포트 삭제 사유를 입력해주세요"
                                className="inputDefaultStyle inputCommonStyle"
                            />
                        </div>
                    </div>
                }
                activeFunc={handleDeleteReport}
            />
            <ScheduleSubHeader />
            <EmptyBar customStyle={'h-[35px]'} />

            <div className="w-full  bg-white rounded-[10px] p-[30px] ">
                <div className="flex gap-[20px] items-center justify-between">
                    <div className="flex gap-[5px] text-[20px] font-semibold">
                        <span className="text-secondary-dGrayMid text-subtitle-medium-bold">{paramYear} 일정</span>
                    </div>

                    <MatchDatePickerModal
                        isShow={reportCreateModal}
                        setIsShow={setReportCreateModal}
                        modalBtn={
                            <button
                                onClick={() => setReportCreateModal(true)}
                                className="bg-primary-purple rounded-[8px] text-context-bold text-primary-white w-[98px] h-[44px] "
                            >
                                리포트 작성
                            </button>
                        }
                        activeFunc={createReport}
                    />
                </div>
                <EmptyBar customStyle={'h-[20px]'} />

                <div className="w-full h-fit ">
                    <CustomTable
                        columns={columns}
                        rows={flattenedRows}
                        equalWidth={false}
                        isPartfixedWidth={true}
                    />
                </div>
                <div
                    ref={ref}
                    className="h-4"
                />
                {isFetching && !isFetchingNextPage && (
                    <div className="py-2 text-center text-secondary-lGrayDark">Loading...</div>
                )}
                {isFetchingNextPage && (
                    <div className="py-2 text-center text-secondary-lGrayDark">다음 페이지 로딩 중...</div>
                )}
                {!isFetching && !isFetchingNextPage && !hasNextPage && (
                    <div className="py-2 text-center text-secondary-lGrayDark">마지막 데이터 입니다.</div>
                )}
            </div>

            <EmptyBar customStyle={'h-[20px]'} />
        </div>
    );
};

export default Schedule;
