import axios from 'axios';
import store from '../store';

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    timeout: 5000,
    headers: {
        'Content-Type': 'application/json',
    },
});

axiosInstance.interceptors.request.use(
    (config) => {
        const accessToken = store.getState().auth.accessToken;
        if (accessToken) {
            config.headers.Authorization = `Bearer ${accessToken}`;
        }
        return config;
    },
    (error) => Promise.reject(error)
);
// 권한별 request의 경우 아래와 같이 코드 수정
// axiosInstance.interceptors.request.use(
//     (config) => {
//         const { refreshToken,accessToken, role, subRole } = store.getState().auth;
//         if (accessToken) {
//             config.headers.Authorization = `Bearer ${accessToken}`;
//         }
//         if (role) {
//             config.headers['X-Role'] = role;
//         }
//         if (subRole) {
//             config.headers['X-SubRole'] = subRole;
//         }
//         return config;
//     },
//     (error) => Promise.reject(error)
// );

axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
        console.error('[Response Error]', error.response || error.message);
        if (error.response?.status === 401) {
            // 로그아웃 로직 추가 필요
            console.log('권한이 없습니다.');
            localStorage.removeItem('accessToken');
            localStorage.removeItem('refreshToken');
            localStorage.removeItem('id');
            localStorage.removeItem('name');
            localStorage.removeItem('email');
            localStorage.removeItem('profileImageUrl');
            localStorage.removeItem('role');
            localStorage.removeItem('staffRole');
            localStorage.removeItem('status');
            localStorage.removeItem('ageGroup');
            localStorage.removeItem('teamId');
            localStorage.removeItem('teamName');
            // window.location.assign("/");
        } else if (error.response?.status === 403) {
            // 로그아웃 로직 추가 필요
            localStorage.removeItem('accessToken');
            localStorage.removeItem('refreshToken');
            localStorage.removeItem('id');
            localStorage.removeItem('name');
            localStorage.removeItem('email');
            localStorage.removeItem('profileImageUrl');
            localStorage.removeItem('role');
            localStorage.removeItem('staffRole');
            localStorage.removeItem('status');
            localStorage.removeItem('ageGroup');
            localStorage.removeItem('teamId');
            localStorage.removeItem('teamName');
            window.location.assign('/');
        } else {
            const customError = {
                status: error.response?.status || 'Unknown',
                message: error.response?.data?.message || error.message,
                reason: error.response?.data?.errors?.[0]?.reason || error.response,
            };
            return Promise.reject(customError); // 수정된 에러 객체 반환
        }
        return Promise.reject(error);
    }
);

export default axiosInstance;
