// hooks
import React from 'react';
// apexchart
import ReactApexChart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import { options } from 'components/chart/ApexChart/options';
import { series } from 'components/chart/ApexChart/series';

type SeriesType = {
    name: string;
    data: any[];
};
type ChartType = 'line' | 'bar' | 'area' | 'pie' | 'donut' | 'radar' | 'scatter' | 'radialBar'; // 추가

type ApexChartProps = {
    customType?: ChartType;
    customOptions?: any | ApexOptions;
    customSeries?: SeriesType[] | number[];
    height?: number;
};
const ApexChart: React.FC<ApexChartProps> = ({ customOptions, customSeries, customType = 'line', height = 350 }) => {
    return (
        <div className="w-full">
            {/* type을 line, bar, area, pie 등으로 변경 가능. */}
            <ReactApexChart
                options={customOptions || options}
                series={customSeries || series}
                type={customType}
                height={height}
            />
        </div>
    );
};

export default ApexChart;
