export const FormDataKey = {
    idKey: 'entry.2065778277',
    roleKey: 'entry.728582387',
    subRoleKey: 'entry.5737053',
};

export const LandingDataKey = {
    nameKey: 'entry.50609497',
    teamKey: 'entry.370657252',
    roleKey: 'entry.1710152924',
    phoneKey: 'entry.1090681987',
};
