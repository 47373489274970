//commons
import MainHeader from "pages/player/components/MainHeader";
import EmptyBar from "components/emptyBar";
// assets
import aisoccerWhiteSymbol from "assets/logos/symbol/aisoccer-white-symbol.webp";
const PlayerLayout = () => {
  return (
    <div className="wrapper">
      <MainHeader />
      <EmptyBar customStyle={"h-[40px]"} />
      {/* <div className="fixed z-[-1] w-screen h-screen  left-0 top-0">
        <div className="h-[400px] bg-primary-black"></div>
        <div className="h-2/3 bg-secondary-mGrayMid"></div>
      </div> */}
      <div className="absolute top-0 left-0 z-[-1] w-full h-full min-w-[1400px]">
        <div className="h-[400px] bg-primary-black min-w-[1400px]"></div>
        <img
          src={aisoccerWhiteSymbol}
          alt="aisoccerWhiteSymbol"
          className="absolute z-0 top-0 right-0 w-[470px]"
        />
      </div>
    </div>
  );
};

export default PlayerLayout;
