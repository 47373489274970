// report components

import SubstitutionRating from 'pages/staff/control/schedule/report/post/reportRating/SubstitutionRating';
import StartingRating from 'pages/staff/control/schedule/report/post/reportRating/StartingRating';
const ReportRating = ({ lineup }: { lineup: LineupType }) => {
    return (
        <div className="flex h-full ">
            <div className="flex w-full">
                <div className="w-[150px] border-b border-secondary-lGrayMid  h-full shrink-0  bg-secondary-mGrayLight text-secondary-lGrayDark text-context-regular flex justify-center items-center">
                    개인평가
                </div>
                <div className="w-full">
                    <div className="p-[24px] flex items-center w-full border-b border-secondary-lGrayMid">
                        <div className="text-center shrink-0 w-[100px] h-full text-context-regular text-secondary-dGrayMid">
                            선발
                        </div>
                        <div className="flex flex-col gap-[10px]">
                            <div className="flex gap-[10px]">
                                <div className="text-center w-[200px] text-context-regular text-secondary-dGrayMid">
                                    선수
                                </div>
                                <div className="text-center w-[569px] text-context-regular text-secondary-dGrayMid">
                                    평가
                                </div>
                                <div className="text-center w-[200px] text-context-regular text-secondary-dGrayMid">
                                    평점
                                </div>
                            </div>
                            {lineup?.startingPlayers?.map((el) => (
                                <StartingRating
                                    {...el}
                                    key={el?.playerId}
                                />
                            ))}
                        </div>
                    </div>
                    <div className="p-[24px] flex items-center w-full border-b border-secondary-lGrayMid">
                        <div className="text-center shrink-0 w-[100px] h-full text-context-regular text-secondary-dGrayMid">
                            교체
                        </div>
                        <div className="flex flex-col gap-[10px]">
                            <div className="flex gap-[10px]">
                                <div className="text-center w-[200px] text-context-regular text-secondary-dGrayMid">
                                    선수
                                </div>
                                <div className="text-center w-[569px] text-context-regular text-secondary-dGrayMid">
                                    평가
                                </div>
                                <div className="text-center w-[200px] text-context-regular text-secondary-dGrayMid">
                                    평점
                                </div>
                            </div>
                            {lineup?.substitutePlayers?.map((el) => (
                                <SubstitutionRating
                                    {...el}
                                    key={el?.playerId}
                                />
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ReportRating;
interface LineupType {
    startingPlayers: {
        lineupId: string;
        playerId: string;
        playerName: string;
        uniformNumber: string;
        position: string;
        rating: string;
        managerComment: string;
    }[];
    substitutePlayers: {
        lineupId: string;
        playerId: string;
        playerName: string;
        uniformNumber: string;
        position: string;
        rating: string;
        managerComment: string;
    }[];
}
