// common
import ApexChart from 'components/chart/ApexChart';
// icons
import { ReactComponent as EmptyFileIcon } from 'assets/svgs/emptyFile.svg';
import { ReactComponent as MiniVideoIcon } from 'assets/svgs/miniVideo.svg';
import { ReactComponent as LapTimeIcon } from 'assets/svgs/lapTime.svg';
// player component
import { radialBarSeries, radialBarOptions } from 'pages/player/report/personalReport/passCard/radialBarThreeOpt';

const PassTwoCard = ({ isPrint, passTwoAnalysis }: any) => {
    const {
        failCount = 0,
        failRate = 0,
        finalScore = 0,
        insideSuccessCount = 0,
        insideSuccessRate = 0,
        outsideSuccessCount = 0,
        outsideSuccessRate = 0,
        passTwoId,
        lapTime = 0,
    } = passTwoAnalysis || {};
    return (
        <div className="flex flex-col gap-[20px]">
            <div className="flex items-center justify-between">
                <div className="text-subtitle-small-bold text-secondary-dGrayDark item">동적 패스</div>
                {!isPrint && (
                    <button className="cursor-default text-secondary-lGrayDark text-context-regular">
                        <div className="flex gap-[10px] p-[10px] rounded-[8px] border border-secondary-lGrayMid ml-[2px]">
                            <MiniVideoIcon />
                            준비중
                        </div>
                    </button>
                )}
            </div>
            <div className="flex gap-[20px]">
                <div className="w-[122px] flex items-center justify-center flex-col gap-[20px]">
                    <div className="flex flex-col gap-[8px]">
                        <div className="flex items-center gap-[5px] justify-center text-secondary-lGrayDark">
                            <EmptyFileIcon />총 점수
                        </div>
                        <div className="flex gap-[5px] items-center justify-center">
                            <span className="text-[34px] font-bold">{finalScore}</span>
                            <span className="text-[#868686] text-context-regular">점</span>
                        </div>
                    </div>
                    <div className="flex flex-col gap-[8px]">
                        <div className="flex items-center gap-[5px] justify-center text-secondary-lGrayDark">
                            <LapTimeIcon />
                            랩타임
                        </div>
                        <div className="flex gap-[5px] items-center justify-center">
                            <span className="text-[34px] font-bold">{lapTime}</span>
                            <span className="text-[#868686] text-context-regular">Sec</span>
                        </div>
                    </div>
                </div>
                <div className="h-[248px] w-[485.5px] relative ">
                    <div className="mt-[-20px]  relative">
                        <ApexChart
                            customType="donut"
                            customOptions={radialBarOptions}
                            customSeries={[insideSuccessRate, outsideSuccessRate, failRate]}
                            height={300}
                        />
                        <div className="absolute w-full justify-center flex gap-[10px] top-[90px] items-center">
                            <div className="flex flex-col gap-[2px] items-center">
                                <div className="text-title-medium-bold text-primary-purple">{insideSuccessCount}</div>
                                <div className="text-secondary-mGrayDark">성공</div>
                            </div>
                            <div className="text-secondary-lGrayLight">/</div>
                            <div className="flex flex-col gap-[2px] items-center">
                                <div className="text-title-medium-bold text-status-correct">{outsideSuccessCount}</div>
                                <div className="text-secondary-mGrayDark">부분성공</div>
                            </div>
                            <div className="text-secondary-lGrayLight">/</div>
                            <div className="flex flex-col gap-[2px] items-center">
                                <div className="text-title-medium-bold text-secondary-mGrayDark ">{failCount}</div>
                                <div className="text-secondary-mGrayDark">실패</div>
                            </div>
                        </div>
                    </div>
                    <div className="grid grid-cols-3 absolute bottom-0 h-[80px] w-[485.5px] bg-[#F8F8F8] rounded-[14px]">
                        <div className="flex flex-col items-center justify-center">
                            <div className="text-secondary-dGrayLight">성공률</div>
                            <div>
                                <span className="text-title-small-bold text-primary-purple">{insideSuccessRate}</span>
                                <span className="text-[#7c7c7c] text-context-bold">%</span>
                            </div>
                        </div>
                        <div className="flex flex-col items-center justify-center">
                            <div className="text-secondary-dGrayLight">부분성공률</div>
                            <div>
                                <span className="text-title-small-bold text-status-correct">{outsideSuccessRate}</span>
                                <span className="text-[#7c7c7c] text-context-bold">%</span>
                            </div>
                        </div>
                        <div className="flex flex-col items-center justify-center">
                            <div className="text-secondary-dGrayLight">실패율</div>
                            <div>
                                <span className="text-title-small-bold text-[#9C99EF]">{failRate}</span>
                                <span className="text-[#7c7c7c] text-context-bold">%</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PassTwoCard;
