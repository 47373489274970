import React, { forwardRef, useEffect } from 'react';
import { EXTERNAL_ENDPOINT } from 'utils/consts/apiEndpoint';

interface VideoPlayerProps {
    token: string;
    videoId: string;
    showProgressBar?: boolean;
    onReady?: () => void;
}

const VideoPlayer = forwardRef<HTMLVideoElement, VideoPlayerProps>(
    ({ token, videoId, showProgressBar = true, onReady }, ref) => {
        useEffect(() => {
            const videoElement = ref as React.MutableRefObject<HTMLVideoElement>;
            if (!videoElement.current || !onReady) return;

            const handleLoadedData = () => {
                onReady();
            };

            videoElement.current.addEventListener('loadeddata', handleLoadedData);

            if (videoElement.current.readyState >= 2) {
                onReady();
            }

            return () => {
                if (videoElement.current) {
                    videoElement.current.removeEventListener('loadeddata', handleLoadedData);
                }
            };
        }, [ref, onReady]);

        const videoSrc = `${EXTERNAL_ENDPOINT.videoStreaming(videoId, token)}`;

        return (
            <video
                ref={ref}
                src={videoSrc}
                controls={showProgressBar}
                preload="metadata"
                style={{ width: '100%', height: '100%', margin: 'auto' }}
                playsInline
            />
        );
    }
);

VideoPlayer.displayName = 'VideoPlayer';
export default VideoPlayer;
