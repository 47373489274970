// hooks
import { useEffect, useState } from "react";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
// api
import { useApiQuery } from "hooks/useDynamicApi";
// common
import Dropdown from "components/dropdown/Default";
// utils
import { useParamNavigate } from "hooks/useDynamicNavigate";
import {
  ENDPOINT_TEAM,
  ENDPOINT_MANAGEMENT_PROFILE,
} from "utils/consts/apiEndpoint";
import {
  dropDownFormChanger,
  dropDownFormChangerType,
} from "utils/formChanger";
// icons
import { ReactComponent as HomeIcon } from "assets/svgs/home.svg";
import { ReactComponent as CircleArrowIcon } from "assets/svgs/circleArrow.svg";
// redux
import { RootState } from "store";
import { useSelector } from "react-redux";

const LineUpIdSubNavHeader = () => {
  const { staffRole } = useSelector((state: RootState) => state.userInfo);
  const navigate = useNavigate();
  const location = useLocation().pathname;
  const locationList = location.split("/");
  const [searchParams] = useSearchParams();
  const paramAgeGroup = searchParams.get("ageGroup") || "";
  const paramPlayerId = searchParams.get("player") || "";
  const paramYear = searchParams.get("year");

  const handleParamNavigate = useParamNavigate();

  const childRouters = [
    { value: "career", label: "경력정보" },
    { value: "injury", label: "부상정보" },
    { value: "season", label: "시즌통계" },
  ];
  const [player, setPlayer] = useState("");
  const [dropdownPlayerOpts, setDropdownPlayerOpts] = useState<
    PlayerDropdownOptsType[]
  >([]);
  const [teamName, setTeamName] = useState("TEAM");
  const [ageGroup, setAgeGroup] = useState("");
  const [ageGroupOpts, setAgeGroupsOpts] = useState<dropDownFormChangerType[]>(
    []
  );

  const [childRouter, setChildRouter] = useState(locationList[5]);

  // react useApiQuery
  const getAgeGroup = useApiQuery(
    ["ageGroup"],
    ENDPOINT_TEAM.staff.team.ageGroups,
    "get",
    {
      enabled: staffRole === "OFFICE",
    }
  );
  const getManagementPlayerProfiles = useApiQuery(
    ["getManagementPlayerProfiles", paramAgeGroup],
    ENDPOINT_MANAGEMENT_PROFILE.season.players(`${locationList[4]}`, {
      ageGroup:
        staffRole === "OFFICE" && paramAgeGroup ? paramAgeGroup : undefined,
    }),
    "get"
  );
  const getPlayerProfile = useApiQuery(
    ["getPlayerProfile", paramPlayerId],
    ENDPOINT_MANAGEMENT_PROFILE.player(paramPlayerId),
    "get"
  );

  useEffect(() => {
    if (getAgeGroup?.isSuccess && getAgeGroup?.data) {
      const { teamName, ageGroups } = getAgeGroup?.data;
      setTeamName(teamName);
      setAgeGroup(ageGroups[0]);
      const newAgeGroupOpts = dropDownFormChanger(ageGroups);
      setAgeGroupsOpts(newAgeGroupOpts);
    }
  }, [getAgeGroup?.data]);

  useEffect(() => {
    if (
      getManagementPlayerProfiles.isSuccess &&
      getManagementPlayerProfiles.data
    ) {
      const { players } = getManagementPlayerProfiles?.data;
      setDropdownPlayerOpts(handlePlayerDropdownOpts(players));
    }
  }, [getManagementPlayerProfiles.isSuccess, getManagementPlayerProfiles.data]);

  useEffect(() => {
    if (ageGroup && player && paramYear)
      handleParamNavigate({
        ageGroup: ageGroup,
        player: player,
        year: paramYear,
      });
  }, [player]);

  const handleHomeNavigate = () => {
    if (ageGroup && paramYear)
      handleParamNavigate(
        {
          ageGroup: ageGroup,
          year: paramYear,
        },
        false,
        `/staff/control/lineUp`
      );
  };

  const handleChildNavigate = (childRoute: string) => {
    if (ageGroup && paramYear)
      handleParamNavigate(
        {
          ageGroup: ageGroup,
          year: paramYear,
          player: paramPlayerId,
        },
        false,
        `/${locationList[1]}/${locationList[2]}/${locationList[3]}/${locationList[4]}/${childRoute}`
      );
  };
  return (
    <div className="mb-[40px]">
      <div className="flex items-center justify-between">
        <div className="flex flex-col gap-[15px]">
          <div className="flex gap-[8px] items-center">
            <button onClick={handleHomeNavigate}>
              <HomeIcon className="text-white" />
            </button>
            <div className="w-[3px] h-[3px] rounded-full bg-secondary-lGrayDark"></div>

            <Dropdown
              options={ageGroupOpts}
              value={ageGroup}
              placeholder="연령"
              onChange={setAgeGroup}
              activeFunc={handleHomeNavigate}
              className={
                " text-label-regular border rounded-[8px] border-primary-white"
              }
              innerClassName="px-[6px] py-[8px] text-label-regular"
            />
            <div className="w-[3px] h-[3px] rounded-full bg-secondary-lGrayDark"></div>
            <Dropdown
              options={dropdownPlayerOpts}
              value={player}
              placeholder={
                getPlayerProfile?.data?.playerInfo?.uniformNumber
                  ? `${getPlayerProfile?.data?.playerInfo?.uniformNumber}. ${getPlayerProfile?.data?.playerInfo?.name}`
                  : "선수명"
              }
              onChange={setPlayer}
              className={
                " text-label-regular w-[110px] border rounded-[8px] border-primary-white"
              }
              innerClassName="px-[6px] py-[8px] text-label-regular"
            />
            <div className="w-[3px] h-[3px] rounded-full bg-secondary-lGrayDark"></div>
            <Dropdown
              options={childRouters}
              value={childRouter}
              defaultValue={childRouter}
              activeFunc={handleChildNavigate}
              onChange={setChildRouter}
              className={
                " text-label-regular w-[100px] border rounded-[8px] border-primary-white"
              }
              innerClassName="px-[6px] py-[8px] text-label-regular"
            />
          </div>
          <div className="flex items-center gap-[10px]">
            <button onClick={() => navigate(-1)}>
              <CircleArrowIcon className="text-white" />
            </button>
            <div className="text-[26px] font-bold text-white">
              {childRouter === "season"
                ? "시즌통계"
                : childRouter === "career"
                ? "경력정보"
                : "부상정보"}
            </div>
          </div>
        </div>

        {childRouter === "season" && (
          <div className="flex gap-[18px]  h-[48px] text-[#868686]">
            <div className="w-[5px] bg-primary-green rounded-[5px]"></div>

            <div className="flex gap-[15px] items-center">
              <div className="flex items-center gap-[35px] ">
                <div className="flex items-center gap-[5px]">
                  <span className="text-[22px] ">{"출장"}</span>
                  <span className="text-[50px] text-primary-green font-bold">
                    {
                      getPlayerProfile?.data?.seasonStats?.totalStats
                        ?.totalAppearances
                    }
                  </span>
                </div>
                <div className="flex items-center gap-[5px]">
                  <span className="text-[22px]">{"평점"}</span>
                  <span className="text-[50px] text-primary-white font-bold">
                    {
                      getPlayerProfile?.data?.seasonStats?.totalStats
                        ?.totalAverageRating
                    }
                  </span>
                </div>
                <div className="flex items-center gap-[5px]">
                  <span className="text-[22px]">{"득점"}</span>
                  <span className="text-[50px] text-status-error font-bold">
                    {
                      getPlayerProfile?.data?.seasonStats?.totalStats
                        ?.totalGoals
                    }
                  </span>
                </div>
                <div className="flex items-center gap-[5px]">
                  <span className="text-[22px]">{"도움"}</span>
                  <span className="text-[50px] text-primary-white font-bold">
                    {
                      getPlayerProfile?.data?.seasonStats?.totalStats
                        ?.totalAssists
                    }
                  </span>
                </div>
                {/* <MoreNavBtn navigateUrl="/staff/control/lineUp/schedule" /> */}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default LineUpIdSubNavHeader;

export const handlePlayerDropdownOpts = (rows: any[]) => {
  return rows?.map((row) => ({
    value: row?.playerId,
    label: `${row?.uniformNumber}. ${row?.name}`,
  }));
};

interface PlayerDropdownOptsType {
  value: string;
  label: string;
}
