export const coupleBarOptions = {
    chart: {
        type: 'bar',
        toolbar: {
            show: false,
        },
        zoom: {
            enabled: false, // 확대 비활성화
        },
    },
    plotOptions: {
        bar: {
            horizontal: true, // 가로 막대 차트로 변경
            barHeight: '12px', // 막대의 두께
            borderRadius: 3,
            colors: {
                hover: {
                    // opacity: 1, // 호버 시 opacity 변경
                },
            },
        },
    },
    states: {
        hover: {
            filter: {
                type: 'none', // hover 시 흐려짐 효과 제거
            },
        },
        active: {
            allowMultipleDataPointsSelection: false,
            filter: {
                type: 'none', // 선택 시 흐려짐 효과 제거
            },
        },
    },
    forecastDataPoints: {},
    legend: {
        show: false, // "최근 측정", "이전 측정" 텍스트 제거
    },
    xaxis: {
        categories: [
            '드리블', // 날짜 형식 변경
            '패스',
            '슈팅',
            '퍼스트 터치',
        ],
        tooltip: {
            enabled: true,
        },
        labels: {
            show: false, // y축 라벨 표시
        },
        axisBorder: {
            show: false, // X축 경계선 표시
            color: '#E0E0E0', // X축 경계선 색상
        },
        axisTicks: {
            show: false, // X축 눈금 제거
        },
    },

    yaxis: {
        show: true,
        min: 0,
        max: 100, // Y축 최대값
        tickAmount: 5, // 0, 20, 40, 60, 80, 100 표시
        labels: {
            style: {
                color: '#434250',
                fontSize: '14px', // 텍스트 크기를 14px로 설정
                // fontWeight: 'bold', // 선택 사항: 텍스트 굵기 설정
            },
        },
    },
    annotations: {
        xaxis: [
            {
                x: 0, // Y축 첫 번째 선 위치 (첫 번째 카테고리 위치)
                borderColor: '#E0E0E0', // 선 색상
                strokeDashArray: 0, // 실선
                label: {
                    show: false, // 선 옆에 텍스트 비활성화
                },
            },
        ],
    },
    fill: {
        type: 'fill', // 단색 설정
        colors: ['#B1B1B1', '#5651E3'], // 막대 색상 지정
    },

    title: {
        show: false, // 제목 숨김
    },
    grid: {
        show: true, // 그리드를 보이게 할지 여부
        borderColor: '#E0E0E0', // 그리드 선 색상
        strokeDashArray: 0, // 선 스타일 (0 = 실선)
        xaxis: {
            lines: {
                show: false, // 세로선 제거
            },
        },
        yaxis: {
            lines: {
                show: false, // 가로선 유지
            },
        },
        padding: {
            left: -1, // 왼쪽 선 위치 조정 (필요시 사용)
            right: 0,
        },
    },

    dataLabels: { enabled: false },
    stroke: {
        show: true,
        width: 1,
        colors: ['#fff'],
    },
    tooltip: {
        enabled: true,
        custom: function ({ series, seriesIndex, dataPointIndex, w }: any) {
            // 툴팁에 표시할 값 가져오기
            const value = series[seriesIndex][dataPointIndex];

            return `
            <div>
                <div 
                style="
                    position: relative; 
                    display: inline-block;
                     background: #111111; 
                    color: white;
                    padding: 8px 10px;
                    border-radius: 8px; 
                    font-size: 14px; 
                    font-weight: bold; 
                    text-align: center;
                    white-space: nowrap;
                ">
                    <span>${value}</span>
           
                </div>
                <!-- 삼각형 화살표 -->
                <div 
                style="margin-top:-5px; margin-left:13px;
                ">
                <svg width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8.61747 8.77598C7.81877 9.87418 6.18122 9.87418 5.38253 8.77598L1.31007 3.17634C0.348657 1.8544 1.29297 -2.44633e-07 2.92754 -1.01734e-07L11.0725 6.10318e-07C12.707 7.53217e-07 13.6513 1.85441 12.6899 3.17634L8.61747 8.77598Z" fill="#111111"/>
</svg>
                </div>
                </div>
            `;
        },
    },
};
export const coupleBarSeries = [
    {
        name: '이전측정',
        data: [42, 32, 33, 72],
    },
    {
        name: '최근측정',
        data: [44, 55, 41, 64],
    },
];
