// hooks
import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setAuthState } from '../../../store/authSlice';
import { setUserInfoState } from 'store/userInfoSlice';

export default function Success() {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const TOKEN = searchParams.get('token');
    const userSeq = searchParams.get('userSeq');
    const name = searchParams.get('name');
    const loginPage = sessionStorage?.getItem('loginPage');
    const handleRedux = () => {
        if (TOKEN) {
            dispatch(
                setAuthState({
                    accessToken: TOKEN,
                    refreshToken: '',
                })
            );
            dispatch(
                setUserInfoState({
                    id: Number(userSeq),
                    name: name,
                    email: null,
                    profileImageUrl: null,
                    role: null,
                    staffRole: null,
                    status: null,
                })
            );

            if (loginPage === 'hand') {
                navigate('/hand/initial');
            } else if (loginPage === 'ai') {
                navigate('/initial');
            } else {
                navigate('/initial');
            }
        }
    };
    useEffect(() => {
        handleRedux();
    }, []);
    return (
        <div className="inset-0 flex justify-center items-center fixed z-10 bg-[rgb(0,0,0,0.3)]">
            <div className="w-fit m-2 p-5 z-[11] bg-white text-base lg:text-lg text-y-gold rounded-lg">
                <div className="mt-5 text-sm text-center text-y-brown">
                    <div>로그인 중입니다.</div>
                    <div>잠시만 기다려 주세요</div>
                </div>
            </div>
        </div>
    );
}
