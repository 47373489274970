// hooks
import { useState, useEffect, useRef } from 'react';
import { useParams, useLocation } from 'react-router-dom';
// api
import { useApiQuery, useApiMutation, useApiFormDataMutation } from 'hooks/useDynamicApi';
// commons
import { positions, majorFootOptions, genderOptions } from 'utils/consts/userInfo';
import DatePicker from 'components/datePicker';
import SoccerBallLodaing from 'components/loading/SoccerBallLodaing';
import { showToast } from 'components/toast/Default';
// assets
import graySymbol from 'assets/logos/symbol/graySymbol.png';
// icons
import { ReactComponent as EditPenIcon } from 'assets/svgs/editPen.svg';
import { ReactComponent as CalendarIcon } from 'assets/svgs/calendar.svg';
// redux
import { useSelector } from 'react-redux';
import { RootState } from 'store'; // Redux Store 타입 정의
// utils
import { ENDPOINT } from 'utils/consts/apiEndpoint';
import Dropdown from 'components/dropdown/Default';
import { majorFootMatcher, genderMatcher } from 'utils/matchers/userInfoMatcher';

interface MyInfoField {
    value: string | string[];
    state: 'disabled' | 'default' | 'error';
}

interface MyInfo {
    name: MyInfoField & { value: string };
    phoneParent: MyInfoField & { value: string };
    birthday: MyInfoField & { value: string };
    address: MyInfoField & { value: string };
    gender: MyInfoField & { value: string };
    height: MyInfoField & { value: string };
    weight: MyInfoField & { value: string };
    bodyFatPercentage: MyInfoField & { value: string };
    uniformNumber: MyInfoField & { value: string };
    position: MyInfoField & { value: string[] }; // position만 string[]
    majorFoot: MyInfoField & { value: string };
    region: MyInfoField & { value: string };
    teams: MyInfoField & { value: string };
    role: MyInfoField & { value: string };
}
const ProfileDetailCard = () => {
    const { role, staffRole } = useSelector((state: RootState) => state.userInfo); // 인증 상태를 token 유무로 판단

    const { playerId = '' } = useParams() || {};
    const [isEdit, setIsEdit] = useState(false);
    const [myInfo, setMyInfo] = useState<MyInfo>({
        name: { value: '', state: 'default' },
        phoneParent: { value: '', state: 'default' },
        birthday: { value: '', state: 'default' },
        address: { value: '', state: 'default' },
        gender: { value: '', state: 'default' },
        height: { value: '', state: 'default' },
        weight: { value: '', state: 'default' },
        bodyFatPercentage: { value: '', state: 'default' },
        uniformNumber: { value: '', state: 'default' },
        position: { value: [], state: 'default' },
        majorFoot: { value: '', state: 'default' },
        region: { value: '', state: 'default' },
        teams: { value: '', state: 'disabled' },
        role: { value: '', state: 'disabled' },
    });
    const playerInputs: { label: string; value: keyof MyInfo }[] = [
        { label: '이름', value: 'name' },
        { label: '부모님번호', value: 'phoneParent' },
        { label: '생년월일', value: 'birthday' },
        { label: '주소', value: 'address' },
        { label: '성별', value: 'gender' },
        { label: '신장', value: 'height' },
        { label: '몸무게', value: 'weight' },
        { label: '체지방률', value: 'bodyFatPercentage' },
        { label: '등번호', value: 'uniformNumber' },
        { label: '주 발', value: 'majorFoot' },
        { label: '포지션', value: 'position' },

        // { label: '지역', value: 'region' },
        // { label: '소속팀', value: 'teams' },
        // { label: '역할', value: 'role' },
    ];
    // useApiMutation
    const getPlayerDetail = useApiQuery(['getPlayerDetail', playerId], ENDPOINT.user.detail(playerId), 'get', {
        enabled: !!playerId,
    });
    useEffect(() => {
        if (getPlayerDetail?.data && getPlayerDetail?.isSuccess) {
            if (getPlayerDetail?.data?.profileImageUrl) setPreviewImage(getPlayerDetail?.data?.profileImageUrl);
            setMyInfo((prev) => ({
                ...prev,
                name: { value: getPlayerDetail?.data?.name, state: 'default' },
                phoneParent: {
                    value: getPlayerDetail?.data?.phoneParent,
                    state: 'default',
                },
                birthday: { value: getPlayerDetail?.data?.birthday, state: 'default' },
                address: { value: getPlayerDetail?.data?.address, state: 'default' },
                gender: { value: getPlayerDetail?.data?.gender, state: 'default' },
                height: { value: getPlayerDetail?.data?.height, state: 'default' },
                weight: { value: getPlayerDetail?.data?.weight, state: 'default' },
                bodyFatPercentage: {
                    value: getPlayerDetail?.data?.bodyFatPercentage || '',
                    state: 'default',
                },
                uniformNumber: {
                    value: getPlayerDetail?.data?.uniformNumber,
                    state: 'default',
                },
                position: { value: getPlayerDetail?.data?.positions, state: 'default' },
                majorFoot: {
                    value: getPlayerDetail?.data?.majorFoot,
                    state: 'default',
                },
                profileImageUrl: {
                    value: getPlayerDetail?.data?.profileImageUrl,
                    state: 'default',
                },

                // region: { value: '', state: 'default' },
                // teams: { value: '', state: 'disabled' },
                // role: { value: '', state: 'disabled' },
            }));
        }
    }, [getPlayerDetail?.data, getPlayerDetail?.isSuccess]);
    // useApiMutation
    const patchPlayerDetail = useApiMutation(ENDPOINT.user.detail(playerId), 'patch', {
        onSuccess: (data) => {
            showToast('선수 정보를 수정했습니다.', 'success');
            setIsEdit(false);
        },
        onError: (error) => {
            const errorMessage = error?.data?.detailedMessage || error || '정보 수정 실패했습니다.';
            showToast(`${errorMessage}`, 'error');
        },
    });
    const patchPlayerProfileImage = useApiFormDataMutation(ENDPOINT.user.profileImage(playerId), 'patch', {
        onSuccess: (data) => {
            showToast('선수 프로필 이미지를 등록했습니다.', 'success');
            // setIsEdit(false);
            handleInputChange('position', data?.s3Url);
        },
        onError: (error) => {
            const errorMessage = error?.data?.detailedMessage || error || '이미지 등록 실패했습니다.';
            showToast(`${errorMessage}`, 'error');
        },
    });
    const handleSubmit = () => {
        if (!patchPlayerDetail?.isPending) {
            showToast('선수 정보를 수정하는중입니다.', 'info');
            patchPlayerDetail.mutate({
                phoneParent: myInfo.phoneParent.value,
                address: myInfo.address.value,
                birthday: myInfo.birthday.value,
                gender: myInfo.gender.value,
                height: myInfo.height.value,
                weight: myInfo.weight.value,
                bodyFatPercentage: myInfo.bodyFatPercentage.value || 0,
                uniformNumber: myInfo.uniformNumber.value,
                positions: myInfo.position.value,
                majorFoot: myInfo.majorFoot.value,
            });
        }
    };

    const handleInputChange = (field: keyof MyInfo, value: string | string[]) => {
        setMyInfo((prev) => ({
            ...prev,
            [field]: {
                ...prev[field],
                value: value,
            },
        }));
    };
    const handlePosition = (selectPos: string) => {
        // options.positions 배열에서 선택한 포지션이 이미 존재하는지 확인
        const isPositionExists = myInfo?.position?.value.includes(selectPos);
        const updatedPositions = isPositionExists
            ? myInfo?.position?.value?.filter((pos: string) => pos !== selectPos) // 제거
            : [...myInfo?.position?.value, selectPos]; // 업데이트된 배열을 options에 반영
        handleInputChange('position', updatedPositions);
    };

    // 이미지 업로드
    const [previewImage, setPreviewImage] = useState<string | null>(null);
    const fileInputRef = useRef<HTMLInputElement | null>(null);
    const handleImageClick = () => {
        if (staffRole !== 'MANAGER' && staffRole !== 'COACH') {
            showToast('선수와 같은 팀에 속한 감독이나 선수만 프로필 이미지 수정이 가능합니다.', 'error');
        }
        if (fileInputRef.current && isEdit) {
            fileInputRef.current.click(); // 숨겨진 input 클릭
        }
    };
    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            // 프리뷰 이미지 설정
            const reader = new FileReader();
            reader.onload = () => {
                setPreviewImage(reader.result as string);
            };
            reader.readAsDataURL(file);

            // FormData 생성 및 API 요청
            const formData = new FormData();
            formData.append('image', file);

            patchPlayerProfileImage.mutate(formData);
        }
    };
    return (
        <div className="bg-primary-white rounded-[10px] p-[30px] ">
            {getPlayerDetail?.isPending && <SoccerBallLodaing />}
            <div className="mb-[24px] flex justify-between ">
                <div className=" text-[20px] font-semibold flex  items-center">
                    <span>{myInfo?.name?.value} 프로필</span>
                    <button
                        onClick={() => setIsEdit(!isEdit)}
                        className={`ml-[4px] ${isEdit ? 'text-primary-purple' : 'text-secondary-mGrayDark'}`}
                    >
                        <EditPenIcon />
                    </button>
                </div>
                {isEdit && (
                    <div className="flex gap-[8px]">
                        <button
                            onClick={() => handleSubmit()}
                            className="w-[80px] h-[44px] bg-primary-purple p-[12px] rounded-[8px] text-primary-white text-context-bold"
                        >
                            저장하기
                        </button>
                        <button
                            onClick={() => setIsEdit(false)}
                            className="w-[80px] h-[44px] bg-secondary-dGrayLight p-[12px] rounded-[8px] text-primary-white text-context-bold"
                        >
                            취소하기
                        </button>
                    </div>
                )}
            </div>
            <div className="flex gap-[10px]">
                <button
                    onClick={handleImageClick}
                    className="flex justify-center items-center rounded-[8px] w-[230px] h-[280px] bg-secondary-mGrayLight"
                >
                    <img
                        src={previewImage || graySymbol}
                        alt="graySymbol"
                        className={`${
                            previewImage ? 'w-[230px] h-[280px] object-cover rounded-[8px]' : 'w-[80px]'
                        } cursor-pointer`}
                    />
                    {isEdit && (
                        <input
                            type="file"
                            accept=".jpg, .jpeg, .png, .webp, .heic, .heif"
                            className="hidden "
                            ref={fileInputRef}
                            onChange={handleFileChange}
                        />
                    )}
                </button>
                <div className="grid grid-cols-2 gap-[14px] w-[1080px]">
                    {playerInputs?.map((el) => (
                        <div className="flex gap-[14px] items-center">
                            {isEdit && el.label === '생년월일' ? (
                                <div className="flex items-center  gap-[10px]">
                                    <div className="text-subtitle-small-bold w-[100px] text-center   shrink-0">
                                        생년월일
                                    </div>
                                    <DatePicker
                                        modalBtn={
                                            <div className="flex items-center justify-between p-[10px] gap-[14px] w-[421px] bg-secondary-mGrayLight rounded-[8px]">
                                                {myInfo?.birthday?.value || '생년월일'}
                                                <CalendarIcon />
                                            </div>
                                        }
                                        defaultDate={myInfo?.birthday?.value}
                                        selected={myInfo?.birthday?.value}
                                        setSelected={(value) => handleInputChange('birthday', value)}
                                    />
                                </div>
                            ) : isEdit && el.label === '성별' ? (
                                <div className="flex items-center  gap-[10px]">
                                    <div className="text-subtitle-small-bold w-[100px] text-center   shrink-0">
                                        성별
                                    </div>
                                    <Dropdown
                                        placeholder="성별"
                                        options={genderOptions}
                                        value={myInfo?.gender?.value}
                                        onChange={(value) => handleInputChange('gender', value)}
                                        className="w-[421px]"
                                    />
                                </div>
                            ) : isEdit && el.label === '주 발' ? (
                                <div className="flex items-center  gap-[10px]">
                                    <div className="text-subtitle-small-bold w-[100px] text-center   shrink-0">
                                        주발
                                    </div>
                                    <Dropdown
                                        placeholder="주발"
                                        options={majorFootOptions}
                                        value={myInfo?.majorFoot?.value}
                                        onChange={(value) => handleInputChange('majorFoot', value)}
                                        className="w-[421px]"
                                    />
                                </div>
                            ) : myInfo['position']?.value && el.label === '포지션' ? (
                                <div
                                    key={'포지션'}
                                    className="flex items-center gap-[14px] w-full flex-wrap"
                                >
                                    <div className="text-subtitle-small-bold w-[100px] text-center   shrink-0">
                                        포지션
                                    </div>
                                    <div className="flex gap-[5px] w-[400px]  flex-wrap">
                                        {positions?.map((el) => (
                                            <button
                                                className={`${
                                                    myInfo['position']?.value?.includes(el)
                                                        ? 'bg-secondary-dGrayDark text-white'
                                                        : 'bg-secondary-mGrayLight text-primary-black'
                                                } rounded-[80px] px-[12px] py-[4px] text-center text-context-regular `}
                                                onClick={() => isEdit && handlePosition(el)}
                                                key={el}
                                            >
                                                {el}
                                            </button>
                                        ))}
                                    </div>
                                </div>
                            ) : (
                                <div
                                    key={el?.label}
                                    className="flex items-center gap-[14px] w-full"
                                >
                                    <div className="text-subtitle-small-bold w-[100px] text-center   shrink-0">
                                        {el.label}
                                    </div>
                                    <input
                                        readOnly={!isEdit || myInfo[el.value]?.state === 'disabled'}
                                        value={
                                            myInfo[el.value]?.value && el?.value === 'gender'
                                                ? genderMatcher(myInfo[el?.value]?.value) || ''
                                                : myInfo[el.value]?.value && el?.value === 'majorFoot'
                                                ? majorFootMatcher(myInfo[el?.value]?.value) || ''
                                                : myInfo[el.value]?.value || ''
                                        } // null 체크
                                        onChange={(e) => handleInputChange(el.value, e.target.value)}
                                        className={`${
                                            !isEdit || myInfo[el.value]?.state === 'disabled'
                                                ? 'inputDisabledStyle'
                                                : 'inputDefaultStyle'
                                        } inputCommonStyle `}
                                    />
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};
export default ProfileDetailCard;
