import loading from 'assets/gif/loading.gif';
import loadinBall from 'assets/gif/loadingBall.gif';
import soccerball_purple from 'assets/imgs/balls/soccerball_purple.png';
import soccerball_gradient from 'assets/imgs/balls/soccerball_gradient.png';

const LongSoccerBallLoading = ({
    isFixed = true,
    textStyle = 'text-primary-black',
    content = 'Loading...',
}: {
    isFixed?: boolean;
    textStyle?: string;
    content?: string;
}) => {
    return (
        <div
            className={`${
                isFixed ? 'fixed top-0 left-0 z-[10] w-screen h-screen' : ''
            } flex items-center justify-center `}
        >
            <div className="flex flex-col items-center justify-center w-full space-y-5 ">
                {/* 축구공 */}
                <img
                    src={loading}
                    alt="loadinBall"
                    className="w-[150px]"
                />
                {/* <img
                    src={soccerball_gradient}
                    alt="loadingBall"
                    className=" top-[-20px] ml-[-110px] w-[100px]  animate-soccer-roll "
                /> */}
                {/* Progress Bar */}
                <div className="relative w-[150px] h-[10px] bg-gray-300 rounded-full overflow-hidden">
                    {/* Progress Fill */}
                    <div className="h-full bg-primary-purple animate-progress-bar"></div>
                </div>
                {/* 로딩 텍스트 */}
                <div className={`flex justify-center space-x-1 ${textStyle}`}>
                    {content.split('').map((char, index) => (
                        <span
                            key={index}
                            className="animate-bounce-letter"
                            style={{
                                animationDelay: `${index * 0.1}s`,
                            }}
                        >
                            {char}
                        </span>
                    ))}
                </div>
                {/* <div className={`flex justify-center space-x-1 ${textStyle}`}>{content}</div> */}
            </div>
        </div>
    );
};

export default LongSoccerBallLoading;
