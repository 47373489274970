// commons
import EmptyBar from "components/emptyBar";
// admin components
import ProfileDetailCard from "pages/admin/mypage/playerDetail/ProfileDetailCard";
import PlayerDetailSubHeader from "pages/admin/mypage/playerDetail/PlayerDetailSubHeader";
const PlayerDetail = () => {
  return (
    <div className="wrapper">
      <PlayerDetailSubHeader />
      <EmptyBar customStyle={"h-[35px]"} />
      <ProfileDetailCard />
    </div>
  );
};

export default PlayerDetail;
