// hooks
import { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import axiosInstance from 'api/axiosInstance';
import { useInView } from 'react-intersection-observer';
import { useInfiniteQuery } from '@tanstack/react-query';
// commons
import CustomTable from 'components/table/CustomTable';
import LineUpIdSubNavHeader from 'pages/staff/control/lineUp/[id]/LineUpIdSubNavHeader';
import EmptyBar from 'components/emptyBar';
// utils
import { ENDPOINT_CAREER } from 'utils/consts/apiEndpoint';

const Career = () => {
    const { ref, inView } = useInView();
    const [searchParams] = useSearchParams();
    const paramPlayerId = searchParams.get('player') || '';
    const columns = [
        { key: 'careerDate', label: '경력 시작일' },
        { key: 'teamName', label: '팀명' },
        { key: 'age', label: '나이' },
        { key: 'totalMatches', label: '총 경기', sortable: false },
        { key: 'totalGoals', label: '득점' },
        { key: 'totalAssists', label: '도움' },
        { key: 'averageRating', label: '평균 평점', sortable: false },
    ];
    // useInfiniteQuery
    const { data, error, fetchNextPage, hasNextPage, isFetching, isFetchingNextPage, status } = useInfiniteQuery({
        queryKey: ['carrers', paramPlayerId],
        queryFn: ({ pageParam = 1 }) =>
            axiosInstance
                .get(`${ENDPOINT_CAREER.player(paramPlayerId)}?page=${pageParam}&size=10`)
                .then((response) => response.data),
        initialPageParam: 1,
        getNextPageParam: (lastPage) => (lastPage?.sliceInfo?.hasNext ? lastPage?.sliceInfo?.page + 1 : undefined),
    });

    useEffect(() => {
        if (inView && hasNextPage && !isFetchingNextPage) {
            fetchNextPage();
        }
    }, [inView, hasNextPage, isFetchingNextPage]);
    const flattenedRows = data?.pages.flatMap((page) => page.data) || [];

    return (
        <div className="wrapper">
            <LineUpIdSubNavHeader />

            {/* 경력정보 */}
            <div className="w-full bg-white rounded-[10px] p-[30px] flex flex-col gap-[20px] ">
                <div className="flex items-cent first-line:er gap-[10px]">
                    <div className="text-subtitle-medium-bold text-secondary-dGrayMid">경력정보</div>
                </div>
                <div className="w-full">
                    {/* overflow-y-scroll */}
                    <CustomTable
                        columns={columns}
                        rows={flattenedRows}
                        equalWidth={true}
                        minWidth={'min-w-[116px]'}
                    />
                </div>
                <div
                    ref={ref}
                    className="h-4"
                />
                {isFetching && !isFetchingNextPage && (
                    <div className="py-2 text-center text-secondary-lGrayDark">Loading...</div>
                )}
                {isFetchingNextPage && (
                    <div className="py-2 text-center text-secondary-lGrayDark">다음 페이지 로딩 중...</div>
                )}
                {!isFetching && !isFetchingNextPage && !hasNextPage && (
                    <div className="py-2 text-center text-secondary-lGrayDark">마지막 데이터 입니다.</div>
                )}
            </div>
            <EmptyBar customStyle={'h-[20px]'} />
        </div>
    );
};

export default Career;
