// hooks
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useRef, useEffect } from 'react';
// utils
import { LandingDataKey } from 'utils/consts/googleFormKey';
// assets
import LandingZero from 'assets/landing/landing_0.webp';
import LandingFirst from 'assets/landing/landing_1.webp';
import LandingSecond from 'assets/landing/landing_2.webp';
import LandingThird from 'assets/landing/landing_3.webp';
import LandingFourth from 'assets/landing/landing_4.webp';
import aisoccerWhiteFont from 'assets/logos/font-logo/aisoccer-white-font.webp';
// commons
import EmptyBar from 'components/emptyBar';
// icons
import { ReactComponent as CircleArrowIcon } from 'assets/svgs/circleArrow.svg';
import { useState } from 'react';
const Landing = () => {
    const navigate = useNavigate();
    const [name, setName] = useState('');
    const [team, setTeam] = useState('');
    const [role, setRole] = useState('');
    const [phone, setPhone] = useState('');

    const [showButton, setShowButton] = useState(false);
    const formRef = useRef<HTMLDivElement>(null);
    const sectionRef = useRef<HTMLDivElement>(null);

    // IntersectionObserver로 버튼 표시 여부 제어
    useEffect(() => {
        const handleScroll = () => {
            if (sectionRef.current) {
                const sectionBottom = sectionRef.current.offsetTop + sectionRef.current.offsetHeight;

                // 화면 높이에 따라 조정
                const offset = window.innerHeight < 768 ? 100 : 50; // 화면이 작으면 더 큰 offset 적용
                setShowButton(window.scrollY > sectionBottom - offset);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);
    const handleScrollToForm = () => {
        if (formRef.current) {
            formRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };
    const handleSubmit = async () => {
        const googleFormUrl =
            'https://docs.google.com/forms/d/15e0Jl2a5ERd3JNSw4Z4FoB-QYK1pcios65pPv8UfYPo/formResponse';
        const formData = new URLSearchParams();

        // 구글 폼의 각 입력 필드 ID와 사용자 입력 값을 매핑
        formData.append(LandingDataKey.nameKey, name); // "이름" 필드 ID로 대체
        formData.append(LandingDataKey.teamKey, team); // "소속" 필드 ID로 대체
        formData.append(LandingDataKey.roleKey, role); // "직책" 필드 ID로 대체
        formData.append(LandingDataKey.phoneKey, phone); // "연락처" 필드 ID로 대체
        if (!name || !team || !role! || !phone) {
            alert('필수 항목을 입력해주세요');
            return;
        }
        const norifyData = `
        무료체험 신청 알림!
        이름:${name}
        소속:${team}
        직책:${role}
        연락처:${phone}
        `;
        try {
            await axios.post(googleFormUrl, formData, {
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            });

            sendTelegramNotification(norifyData);
            alert('문의가 성공적으로 접수되었습니다.');
        } catch (error) {
            sendTelegramNotification(norifyData);
            alert('문의가 성공적으로 접수되었습니다.');
            // console.error('폼 제출 중 오류 발생:', error);
            // alert('문의 접수에 실패했습니다. 다시 시도해주세요.');
        }
    };
    async function sendTelegramNotification(message: string) {
        const TELEGRAM_BOT_TOKEN = '7871745989:AAFJI7AgpRLVzjjvVO7TGi5F_Bn1ZSiF7zI';
        const telegramUrl = `https://api.telegram.org/bot${TELEGRAM_BOT_TOKEN}/sendMessage`;
        try {
            const TELEGRAM_CHAT_ID = '-4605523536';
            const response = await axios.post(telegramUrl, {
                chat_id: TELEGRAM_CHAT_ID,
                text: message,
            });
        } catch (error) {
            console.error('텔레그램 알림 전송 실패:', error);
        }
    }
    const handlePhoneChange = (e: any) => {
        let value = e.target.value;

        // 숫자만 남기기
        value = value.replace(/[^0-9]/g, '');

        // 정규식을 사용해 전화번호 형식 맞추기
        if (value.length <= 3) {
            // 3자리 이하일 때
            value = value;
        } else if (value.length <= 6) {
            // 4~6자리일 때
            value = value.replace(/(\d{3})(\d{1,3})/, '$1-$2');
        } else if (value.length <= 10) {
            // 7~10자리일 때 (지역번호 포함)
            value = value.replace(/(\d{3})(\d{3,4})(\d{1,4})/, '$1-$2-$3');
        } else {
            // 11자리 이상일 때
            value = value.replace(/(\d{3})(\d{4})(\d{1,4})/, '$1-$2-$3');
        }

        setPhone(value);
    };
    return (
        <div className="w-full max-w-[1400px] m-auto">
            <div>
                <div
                    className="relative w-full"
                    ref={sectionRef}
                >
                    <img
                        src={LandingZero}
                        alt="랜딩페이지"
                    />
                    <div
                        ref={formRef}
                        className="absolute  text-[16px] sm:text-[18px] md:text-[20px] lg:text-[25px] xl:text-[30px] w-full justify-center left-0 items-center bottom-[6%] lg:bottom-[5%] flex flex-col gap-[10px] sm:gap-[20px] lg:gap-[30px] xl:gap-[40px]"
                    >
                        <input
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            placeholder="이름"
                            className="w-[70%] h-[30px]  sm:h-[50px] md:h-[70px] lg:h-[80px] xl:h-[120px] px-[10px] xl:px-[20px] rounded-[5px] lg:rounded-[10px]"
                        />
                        <input
                            value={team}
                            onChange={(e) => setTeam(e.target.value)}
                            placeholder="소속"
                            className="w-[70%] h-[30px]  sm:h-[50px] md:h-[70px] lg:h-[80px] xl:h-[120px] px-[10px] xl:px-[20px] rounded-[5px] lg:rounded-[10px]"
                        />
                        <input
                            value={role}
                            onChange={(e) => setRole(e.target.value)}
                            placeholder="직책"
                            className="w-[70%] h-[30px]  sm:h-[50px] md:h-[70px] lg:h-[80px] xl:h-[120px] px-[10px] xl:px-[20px] rounded-[5px] lg:rounded-[10px]"
                        />
                        <input
                            value={phone}
                            onChange={handlePhoneChange}
                            placeholder="연락처"
                            className="w-[70%] h-[30px]  sm:h-[50px] md:h-[70px] lg:h-[80px] xl:h-[120px] px-[10px] xl:px-[20px] rounded-[5px] lg:rounded-[10px]"
                        />
                        <button
                            onClick={handleSubmit}
                            className="bg-primary-black  font-bold  text-primary-white w-[70%] h-[30px] sm:h-[40px] md:h-[50px] lg:h-[80px] xl:h-[120px] rounded-[5px] lg:rounded-[10px] px-[10px] xl:px-[20px]"
                        >
                            문의하기
                        </button>
                    </div>
                </div>
                <div className="relative">
                    <img
                        src={LandingFirst}
                        alt="랜딩페이지 이미지1"
                    />
                    {showButton && (
                        <div
                            className="fixed bottom-[20px] flex justify-center w-full left-0"
                            onClick={handleScrollToForm}
                        >
                            <button
                                className="
                                font-bold
                            text-[14px] sm:text-[18px] md:text-[24px] lg:text-[25px] xl:text-[40px]
                            bg-[#4553D2] text-primary-white max-w-[1200px] w-[70%] h-[40px] sm:h-[50px] md:h-[60px] lg:h-[80px] xl:h-[120px] rounded-[5px] lg:rounded-[10px] px-[10px] xl:px-[20px] "
                            >
                                <span className=" animate-blink">무료체험 신청하기</span>
                            </button>
                        </div>
                    )}
                </div>
                <img
                    src={LandingSecond}
                    alt="랜딩페이지 이미지2"
                />
                <img
                    src={LandingThird}
                    alt="랜딩페이지 이미지3"
                />
                <img
                    src={LandingFourth}
                    alt="랜딩페이지 이미지4"
                />
            </div>
            {/* <div className="fixed bottom-[20px] flex items-center justify-center w-full left-0">
        <button
          onClick={() => navigate("/signIn")}
          className="bg-primary-purple text-primary-white px-[30px] py-[8px] rounded-[80px] text-[18px] font-bold flex gap-[5px] items-center"
        >
          AISoccer 시작하기
          <CircleArrowIcon className="rotate-180" />
        </button>
      </div> */}
            <EmptyBar customStyle="h-[100px] lg:h-[200px] bg-[#F0F0F0]" />
        </div>
    );
};

export default Landing;
