//commons
import HandHeader from 'pages/hand/components/HandHeader';
import EmptyBar from 'components/emptyBar';
// assets
import aisoccerWhiteSymbol from 'assets/logos/symbol/aisoccer-white-symbol.webp';
const HandLayout = () => {
    return (
        <div className="handWrapper">
            {/* <HandHeader /> */}
            <EmptyBar customStyle={'h-[50px]'} />

            <div className="absolute top-0 left-0 z-[-1] w-full h-full ">
                <div className="h-[400px] bg-primary-black  w-full"></div>
                <img
                    src={aisoccerWhiteSymbol}
                    alt="aisoccerWhiteSymbol"
                    className="absolute z-0 top-0 right-0 w-[470px]"
                />
            </div>
        </div>
    );
};

export default HandLayout;
