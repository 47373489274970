export const dropDownFormChanger = (valueArr: string[], labelArr?: string[]) => {
    //  [{ value: 'value1', label: 'label1' },...
    if (labelArr) {
        return valueArr.map((value, index) => ({
            value: value,
            label: labelArr[index] || value, // labelArr의 값이 없으면 value로 대체
        }));
    } else if (!labelArr) {
        return valueArr.map((value) => ({
            value: value,
            label: value,
        }));
    } else return [];
};
export interface dropDownFormChangerType {
    value: string;
    label: string;
}

export const stringDropDownFormChanger = (strValue: string | string[]) => {
    if (Array.isArray(strValue)) {
        return strValue.map((value) => ({
            value,
            label: value, // labelArr의 값이 없으면 value로 대체
        }));
    } else {
        return [
            {
                value: strValue,
                label: strValue,
            },
        ];
    }
};

export const paramFormChanger = (options: Record<string, any>) => {
    const filteredParams = Object.entries(options).reduce((acc, [key, value]) => {
        // 필터링 조건: 빈 문자열 또는 빈 배열 무시
        if (Array.isArray(value)) {
            if (value.length > 0) {
                acc[key] = value;
            }
        } else if (value !== '') {
            acc[key] = value;
        }
        return acc;
    }, {} as Record<string, any>);
    return filteredParams;
};
