// commons
import EmptyBar from 'components/emptyBar';

const Medical = () => {
    return (
        <div className="wrapper">
            <EmptyBar customStyle={'h-[35px]'} />
            <div className="bg-primary-white rounded-[10px] font-bold text-[18px] p-[30px] h-[200px] items-center justify-center flex gap-[30px]">
                의료센터 준비중입니다.
            </div>
        </div>
    );
};

export default Medical;
