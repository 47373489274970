// icons
import { ReactComponent as EditPenIcon } from 'assets/svgs/editPen.svg';
import { ReactComponent as DeleteIcon } from 'assets/svgs/delete.svg';

const VideoCard = ({ title, tags }: { title: string; tags: string[] }) => {
    return (
        <div className="flex flex-col gap-[20px] group">
            <div className="w-[433.3px] h-[220px] relative group-hover:border-2 group-hover:border-primary-purple rounded-[14px] group-hover:bg-white bg-[#d2d2d2]">
                <div className="flex gap-[5px] absolute right-[19.5px] top-[20px]">
                    <button className="w-[40px] h-[40px] rounded-[6px] bg-white text-secondary-lGrayDark flex justify-center items-center">
                        <EditPenIcon />
                    </button>
                    <button className="w-[40px] h-[40px] rounded-[6px] bg-white text-status-error flex justify-center items-center">
                        <DeleteIcon />
                    </button>
                </div>
            </div>
            <div className="flex flex-col gap-[8px]">
                <div className="text-title-small-bold">{title}</div>
                <div className="flex gap-[8px]">
                    {tags?.map((el, idx) => (
                        <span
                            key={idx}
                            className="bg-[#d2d2d2] text-[#111111] text-[14px] font-bold px-[12px] py-[4px] rounded-[80px]"
                        >
                            {el}
                        </span>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default VideoCard;
