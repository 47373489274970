// hooks
import React, { useRef, useState, MouseEvent, useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
// commons
import EmptyBar from 'components/emptyBar';
import DatePicker from 'components/datePicker';
import SoccerBallLodaing from 'components/loading/SoccerBallLodaing';
// api
import { useApiQuery } from 'hooks/useDynamicApi';
// icons
import { ReactComponent as PrintIcon } from 'assets/svgs/print.svg';
import { ReactComponent as CalendarIcon } from 'assets/svgs/calendar.svg';
// player components
import ProfileCard from 'pages/player/report/personalReport/profileCard';
import DataSummaryCard from 'pages/player/report/personalReport/dataSummaryCard';
import DribbleCard from 'pages/player/report/personalReport/dribbleCard';
import FirstTouchCard from 'pages/player/report/personalReport/firstTouchCard';
import PassCard from 'pages/player/report/personalReport/passCard';
import ShootingCard from 'pages/player/report/personalReport/shootingCard';
import PersonalReportHeader from 'pages/player/report/PersonalReportHeader';
// utils
import { DASHBOARD, ENDPOINT_MEASUREMENT } from 'utils/consts/apiEndpoint';
import { formatDateTimeMatcher } from 'utils/matchers/reportMatcher';
// redux
import { RootState } from 'store'; // Redux Store 타입 정의
import { useSelector } from 'react-redux';

const Report = () => {
    const [searchParams] = useSearchParams();
    const dateTime = searchParams.get('dateTime') || '';
    const [department, setDepartment] = useState('판교점');
    const [isPrint, setIsPrint] = useState(false);
    const contentRef = useRef<HTMLDivElement>(null);
    const reactToPrintFn = useReactToPrint({
        contentRef, // 이렇게 화살표 함수로 변경
        onBeforePrint: () => {
            setIsPrint(true);
            return Promise.resolve();
        },
        onAfterPrint: () => {
            setIsPrint(false);
            return Promise.resolve();
        },
    });

    useEffect(() => {
        if (isPrint) {
            reactToPrintFn();
        }
    }, [isPrint, reactToPrintFn]);

    const handlePrintClick = () => {
        setIsPrint(true);
    };
    const { role, id = '' } = useSelector((state: RootState) => state.userInfo); // 인증 상태를 token 유무로 판단
    const { measurementId = '' } = useParams();
    // const getPlayerDashboard = useApiQuery(
    //   ["getPlayerDashboard", `${id}`],
    //   DASHBOARD.player(`${id}`),
    //   "get",
    //   {
    //     enabled: !!id,
    //   }
    // );
    // const getDashboardDetail = useApiQuery(
    //   ["getDashboardDetail", `${id}`],
    //   DASHBOARD.playerDetail(`${id}`),
    //   "get",
    //   {
    //     enabled: !!id,
    //   }
    // );
    const getMeasurementAnalysis = useApiQuery(
        ['getMeasurementAnalysis', `${measurementId}`],
        DASHBOARD.measurement(`${measurementId}`),
        'get',
        {
            enabled: !!measurementId,
        }
    );

    const {
        dribbleOneAnalysis,
        dribbleTwoAnalysis,
        firstTouchAnalysis,
        passOneAnalysis,
        passTwoAnalysis,
        physicalInfo,
        playerInfo,
        shootingOneAnalysis,
        shootingTwoAnalysis,
        skillAnalysis,
    } = getMeasurementAnalysis?.data || {};

    return (
        <div>
            <PersonalReportHeader playerName={playerInfo?.playerName} />
            <EmptyBar customStyle="h-[35px]" />
            <div className="wrapper p-[30px] rounded-[10px] bg-primary-white ">
                {getMeasurementAnalysis?.data ? (
                    <div ref={contentRef}>
                        <div className="flex flex-col gap-[20px]">
                            <div className="flex justify-between">
                                <div className="text-subtitle-medium-bold text-secondary-dGrayDark flex gap-[20px] items-center">
                                    <div className="text-subtitle-large-bold">분석결과표</div>
                                </div>
                                {!isPrint && (
                                    <button
                                        onClick={() => setIsPrint(true)}
                                        className="flex justify-center items-center gap-[10px] w-[110px] h-[44px] rounded-[8px] bg-primary-green text-context-bold"
                                    >
                                        <span>출력하기</span>
                                        <PrintIcon />
                                    </button>
                                )}
                            </div>

                            <div className="grid grid-cols-2 gap-[20px]">
                                <div className="flex items-center w-full ">
                                    <span className="w-[100px] text-context-regular">평가 일시</span>
                                    {/* <DatePicker
                                modalBtn={ */}
                                    <div className="flex items-center text-context-bold justify-between p-[10px] w-[550px] gap-[14px] border border-primary-purple rounded-[8px]">
                                        {formatDateTimeMatcher(dateTime) || '평가날짜'}
                                        <CalendarIcon className="text-primary-purple" />
                                    </div>
                                    {/* }
                                selected={measurementDateTime}
                                setSelected={(value) => setMeasurementDateTime(value)}
                            /> */}
                                </div>
                                <div className="flex items-center">
                                    <span className="w-[100px] text-context-regular">지점</span>
                                    <input
                                        className="inputCommonStyle inputDisabledStyle"
                                        readOnly
                                        value={department}
                                    />
                                </div>
                            </div>

                            <ProfileCard
                                playerInfo={playerInfo}
                                physicalInfo={physicalInfo}
                            />
                            <DataSummaryCard
                                skillAnalysis={skillAnalysis}
                                isPrint={isPrint}
                            />
                        </div>
                        <EmptyBar customStyle="h-[48px]" />
                        <div className={`flex flex-col ${isPrint ? 'gap-[43px]' : 'gap-[62px]'}`}>
                            <DribbleCard
                                isPrint={isPrint}
                                dribbleAvgScore={skillAnalysis?.dribbleAvgScore}
                                dribbleOneAnalysis={dribbleOneAnalysis}
                                dribbleTwoAnalysis={dribbleTwoAnalysis}
                                playerId={playerInfo?.playerId}
                                measuredAt={playerInfo?.measuredAt}
                            />

                            <FirstTouchCard
                                isPrint={isPrint}
                                firstTouchAnalysis={firstTouchAnalysis}
                                firstTouchScore={skillAnalysis?.firstTouchScore}
                            />
                            {isPrint && <EmptyBar customStyle="h-[30px]" />}

                            <PassCard
                                isPrint={isPrint}
                                passAvgScore={skillAnalysis?.passAvgScore}
                                passOneAnalysis={passOneAnalysis}
                                passTwoAnalysis={passTwoAnalysis}
                            />
                            <ShootingCard
                                isPrint={isPrint}
                                shootingAvgScore={skillAnalysis?.shootingAvgScore}
                                shootingOneAnalysis={shootingOneAnalysis}
                                shootingTwoAnalysis={shootingTwoAnalysis}
                            />
                        </div>
                    </div>
                ) : getMeasurementAnalysis?.isError ? (
                    <div>완료되지 않은 측정입니다.</div>
                ) : (
                    <SoccerBallLodaing />
                )}
            </div>
            <EmptyBar customStyle="h-[20px]" />
        </div>
    );
};

export default Report;

export const columns = [
    { key: 'index', label: 'No.' },
    { key: 'name', label: '이름' },
    { key: 'ranking', label: '랭크' },
    { key: 'total', label: '전체 점수' },
    { key: 'footAction', label: '잔발' },
    { key: 'bestSpeed', label: '최고 속도' },
    { key: 'averageSpeed', label: '평균 속도' },
    { key: 'accuracy', label: '볼터치' },
    { key: 'bounce', label: '정확도' },
];

export const average = [
    {
        index: '',
        name: '팀평균',
        ranking: '',
        total: 70,
        footAction: 59.1,
        bestSpeed: 59.1,
        averageSpeed: <span className="text-primary-purple">80.5</span>,
        accuracy: <span className="text-status-error">80.5</span>,
        bounce: 80.6,
    },
];

export const rows = [
    {
        index: 1,
        name: '홍길동',
        ranking: 1,
        total: 50,
        footAction: 59.1,
        bestSpeed: 59.1,
        averageSpeed: 80.5,
        accuracy: 70,
        bounce: 80.6,
    },
    {
        index: 1,
        name: '홍길동',
        ranking: 1,
        total: 50,
        footAction: 59.1,
        bestSpeed: 59.1,
        averageSpeed: 80.5,
        accuracy: 70,
        bounce: 80.6,
    },
    {
        index: 1,
        name: '홍길동',
        ranking: 1,
        total: 50,
        footAction: 59.1,
        bestSpeed: 59.1,
        averageSpeed: 80.5,
        accuracy: 70,
        bounce: 80.6,
    },
    {
        index: 1,
        name: '홍길동',
        ranking: 1,
        total: 50,
        footAction: 59.1,
        bestSpeed: 59.1,
        averageSpeed: 80.5,
        accuracy: 70,
        bounce: 80.6,
    },
    {
        index: 1,
        name: '홍길동',
        ranking: 1,
        total: 50,
        footAction: 59.1,
        bestSpeed: 59.1,
        averageSpeed: 80.5,
        accuracy: 70,
        bounce: 80.6,
    },
    {
        index: 1,
        name: '홍길동',
        ranking: 1,
        total: 50,
        footAction: 59.1,
        bestSpeed: 59.1,
        averageSpeed: 80.5,
        accuracy: 70,
        bounce: 80.6,
    },
    {
        index: 1,
        name: '홍길동',
        ranking: 1,
        total: 50,
        footAction: 59.1,
        bestSpeed: 59.1,
        averageSpeed: 80.5,
        accuracy: 70,
        bounce: 80.6,
    },
    {
        index: 1,
        name: '홍길동',
        ranking: 1,
        total: 50,
        footAction: 59.1,
        bestSpeed: 59.1,
        averageSpeed: 80.5,
        accuracy: 70,
        bounce: 80.6,
    },
    {
        index: 1,
        name: '홍길동',
        ranking: 1,
        total: 50,
        footAction: 59.1,
        bestSpeed: 59.1,
        averageSpeed: 80.5,
        accuracy: 70,
        bounce: 80.6,
    },
    {
        index: 1,
        name: '홍길동',
        ranking: 1,
        total: 50,
        footAction: 59.1,
        bestSpeed: 59.1,
        averageSpeed: 80.5,
        accuracy: 70,
        bounce: 80.6,
    },
    {
        index: 1,
        name: '홍길동',
        ranking: 1,
        total: 50,
        footAction: 59.1,
        bestSpeed: 59.1,
        averageSpeed: 80.5,
        accuracy: 70,
        bounce: 80.6,
    },
    {
        index: 1,
        name: '홍길동',
        ranking: 1,
        total: 50,
        footAction: 59.1,
        bestSpeed: 59.1,
        averageSpeed: 80.5,
        accuracy: 70,
        bounce: 80.6,
    },
    {
        index: 1,
        name: '홍길동',
        ranking: 1,
        total: 50,
        footAction: 59.1,
        bestSpeed: 59.1,
        averageSpeed: 80.5,
        accuracy: 70,
        bounce: 80.6,
    },
    {
        index: 1,
        name: '홍길동',
        ranking: 1,
        total: 50,
        footAction: 59.1,
        bestSpeed: 59.1,
        averageSpeed: 80.5,
        accuracy: 70,
        bounce: 80.6,
    },
    {
        index: 1,
        name: '홍길동',
        ranking: 1,
        total: 50,
        footAction: 59.1,
        bestSpeed: 59.1,
        averageSpeed: 80.5,
        accuracy: 70,
        bounce: 80.6,
    },
    {
        index: 1,
        name: '홍길동',
        ranking: 1,
        total: 50,
        footAction: 59.1,
        bestSpeed: 59.1,
        averageSpeed: 80.5,
        accuracy: 70,
        bounce: 80.6,
    },
    {
        index: 1,
        name: '홍길동',
        ranking: 1,
        total: 50,
        footAction: 59.1,
        bestSpeed: 59.1,
        averageSpeed: 80.5,
        accuracy: 70,
        bounce: 80.6,
    },
    {
        index: 1,
        name: '홍길동',
        ranking: 1,
        total: 50,
        footAction: 59.1,
        bestSpeed: 59.1,
        averageSpeed: 80.5,
        accuracy: 70,
        bounce: 80.6,
    },
];

export const analysisData = {
    id: '1',
    number: 1,
    date: '24.10.27',
    score: 82.0,
    increase: 5.7,
    summary: {
        best: '드리블',
        worst: '퍼스트터치',
    },
    feedback:
        '훈련하시느라 수고하셨습니다. 이번 훈련에서는 무릎 점수가 많이 나아진 것으로 보이네요. 지금처럼 무릎을 신경쓰며 트레이닝 해보세요! 다만 스텝의 보폭이 너무 부족합니다.',
    skillAnalysis: {
        best: '드리블',
        bestFeedback:
            '훈련하시느라 수고하셨습니다. 이번 훈련에서는 무릎 점수가 많이 나아진 것으로 보이네요.이번 훈련에서는 무릎 점수가 많이 나아진 것으로 보이네요.이번 훈련에서는 무릎 점수가 많이 나아진 것으로 보이네요.이번 훈련에서는 무릎 점수가 많이 나아진 것으로 보이네요.이번 훈련에서는 무릎 점수가 많이 나아진 것으로 보이네요.이번 훈련에서는 무릎 점수가 많이 나아진 것으로 보이네요.이번 훈련에서는 무릎 점수가 많이 나아진 것으로 보이네요.이번 훈련에서는 무릎 점수가 많이 나아진 것으로 보이네요. 지금처럼 무릎을 신경쓰며 트레이닝 해보세요! 다만 스텝의 보폭이 너무 부족합니다.',
        worst: '퍼스트터치',
        worstFeedback:
            '훈련하시느라 수고하셨습니다. 이번 훈련에서는 무릎 점수가 많이 나아진 것으로 보이네요. 지금처럼 무릎을 신경쓰며 트레이닝 해보세요! 다만 스텝의 보폭이 너무 부족합니다.',
    },
    detailPart: [
        { skill: '상체밸런스', score: 85.3 },
        { skill: '시야', score: 85.3 },
        { skill: '무게중심', score: 85.3 },
        { skill: '무게축', score: 85.3 },
        { skill: '뒤꿈치', score: 85.3 },
        { skill: '무릎거리', score: 85.3 },
        { skill: '잔발', score: 85.3 },
        { skill: '탄력성', score: 85.3 },
        { skill: '최고속도', score: 85.3 },
        { skill: '평균속도', score: 85.3 },
        { skill: '볼터치', score: 85.3 },
        { skill: '정확도', score: 85.3 },
    ],
    comparisonScore: {
        averageScore: {
            playerScore: 62.23,
            teamScore: 62.23,
            nationwideScore: 83,
        },
        ranking: {
            playerRanking: '3%',
            teamRanking: '3%',
            nationwideRanking: '5%',
        },
    },
    recommandData: {
        videoNumber: [24, 22, 20],
        solution: '각 매일 3세트식 운동',
        solutionTime: '20분 소요',
    },
};
