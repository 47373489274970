// hooks
import React, { useState, useEffect } from "react";
// utils
import {
  measurementIconMatcher,
  measurementWordMatcher,
} from "utils/matchers/iconMatcher";
// hooks
import AnalysisChart from "pages/player/measurement/analysis/analysisCard/AnalysisChart";

const AnalysisDetail = ({ detailPart, totalDuration, progress = 0 }: any) => {
  const AnalysisDuration = totalDuration;
  const [currentData, setCurrentData] = useState<{ [key: string]: number }>(
    detailPart[0] || {} // 초기 상태는 detailPart의 0초 데이터
  );
  const [leftPartData, setLeftPartData] = useState<[string, number][]>([]);
  const [rightPartData, setRightPartData] = useState<[string, number][]>([]);
  const [curData, setCurData] = useState<[string, number] | null>(null);

  const [curCategory, setCurCategory] = useState("armScore");
  const [chartData, setChartData] = useState<number[]>([]); // 차트 데이터
  // 초기 상태 설정
  useEffect(() => {
    if (detailPart && detailPart[0]) {
      const initialData = detailPart[0]; // 0초 데이터
      setCurrentData(initialData);

      const detailArray = Object.entries(initialData) as [string, number][];
      const halfLen = Math.round(detailArray.length / 2);
      setLeftPartData(detailArray.slice(0, halfLen));
      setRightPartData(detailArray.slice(halfLen));
      setCurData(detailArray[0]); // 기본 선택 데이터
    }
  }, [detailPart]);

  useEffect(() => {
    if (progress && detailPart) {
      const roundedProgress = Math.round(progress); // progress 반올림

      const currentProgressData = detailPart[roundedProgress]; // 현재 progress에 해당하는 데이터
      setCurrentData(currentProgressData);

      const detailArray = Object.entries(currentProgressData) as [
        string,
        number
      ][];
      const halfLen = Math.round(detailArray.length / 2);
      setLeftPartData(detailArray.slice(0, halfLen));
      setRightPartData(detailArray.slice(halfLen));

      // 선택 데이터 유지
      setCurData(detailArray[0]);
    }
  }, [progress, detailPart]);

  useEffect(() => {
    if (!detailPart || !curCategory) return;

    const xAxis = Array.from({ length: totalDuration }, (_, i) => i);
    const yAxis = xAxis.map((time) => {
      const value = detailPart[time]?.[curCategory];
      return value !== undefined && value > -1 ? value : null; // -1 이하 값은 null로 변환
    });

    setChartData(yAxis);
  }, [detailPart, curCategory, totalDuration]);

  // progress를 기준으로 이동 위치 계산
  const progressLeftPosition = `${(progress / AnalysisDuration) * 100}%`;

  return (
    <div className="flex justify-between">
      {/* 왼쪽 데이터 */}
      <div className="flex flex-col gap-[12px] rounded-[8px]">
        {leftPartData?.map(([key, value]: [string, number]) => {
          const Icon = measurementIconMatcher(key);

          return (
            <button
              key={key}
              onClick={() => {
                setCurCategory(key);
                setCurData([key, value]);
              }}
              className={`h-[75.33px] w-[280px] px-[18px] bg-[#f8f8f8] rounded-[8px] text-[#111111] flex gap-[12px] items-center ${
                curCategory === key && "border-2 border-primary-purple"
              }`}
            >
              <div className="w-[40px] h-[40px] bg-primary-black flex rounded-[6px] justify-center items-center">
                {typeof Icon === "string" ? (
                  Icon
                ) : (
                  <Icon className="text-primary-white" />
                )}
              </div>
              <div
                className={`w-[90px] text-start ${
                  curCategory === key
                    ? "text-context-bold"
                    : "text-context-regular"
                }`}
              >
                {key ? measurementWordMatcher(key) : "key"}
              </div>
              <div
                className={` ${
                  curCategory === key
                    ? "text-title-medium-bold"
                    : "text-title-medium-regular"
                }`}
              >
                {value === -1 ? "-" : value}
              </div>
            </button>
          );
        })}
      </div>

      {/* 중앙 차트 */}
      <div id="analysis-area-chart" className="w-[750px]">
        <div className="h-[40px] text-[#868686] text-[20px] font-semibold flex gap-[18px] mb-[20px] items-center">
          <div className="w-[5px] h-full bg-primary-purple rounded-[8px]"></div>
          <div>
            {curCategory ? measurementWordMatcher(curCategory) : "No Data"}
          </div>
        </div>
        <div
          id="analysis"
          className="w-[750px] flex justify-center items-center mt-[5px] relative"
        >
          <AnalysisChart chartData={chartData} />
          <div className="absolute h-[500px] w-[93%]  left-[6%]">
            <div className="bottom-0 w-full h-[90%] relative">
              <div
                className="w-[2px] h-[83%] mt-[7%] bg-secondary-mGrayMid absolute
                                "
                style={{ left: progressLeftPosition }}
              ></div>
            </div>
          </div>
        </div>
      </div>

      {/* 오른쪽 데이터 */}
      <div className="flex flex-col gap-[12px] rounded-[8px]">
        {rightPartData?.map(([key, value]: [string, number]) => {
          const Icon = measurementIconMatcher(key);

          return (
            <button
              key={key}
              onClick={() => {
                setCurCategory(key);
                setCurData([key, value]);
              }}
              className={`h-[75.33px] w-[280px] px-[18px] bg-[#f8f8f8] rounded-[8px] text-[#111111] flex gap-[12px] items-center ${
                curCategory === key && "border-2 border-primary-purple"
              }`}
            >
              <div className="w-[40px] h-[40px] bg-primary-black flex rounded-[6px] justify-center items-center">
                {typeof Icon === "string" ? (
                  Icon
                ) : (
                  <Icon className="text-primary-white" />
                )}
              </div>
              <div
                className={`w-[90px] text-start ${
                  curCategory === key
                    ? "text-context-bold"
                    : "text-context-regular"
                }`}
              >
                {key ? measurementWordMatcher(key) : "key"}
              </div>
              <div
                className={` ${
                  curCategory === key
                    ? "text-title-medium-bold"
                    : "text-title-medium-regular"
                }`}
              >
                {value < 0 ? "-" : value}
              </div>
            </button>
          );
        })}
      </div>
    </div>
  );
};

export default AnalysisDetail;
