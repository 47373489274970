// hooks
import { useState } from 'react';
// commons
import DatePicker from 'components/datePicker';
import { showToast } from 'components/toast/Default';

// icons
import { ReactComponent as CalendarIcon } from 'assets/svgs/calendar.svg';
import { ReactComponent as CloseIcon } from 'assets/svgs/close.svg';

const MatchDatePickerModal = ({
    isShow,
    setIsShow,
    modalBtn,

    activeFunc,
}: {
    isShow: boolean;
    setIsShow: (value: boolean) => void;
    modalBtn: React.ReactNode;
    activeFunc: (value: any) => void;
}) => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');

    const [date, setDate] = useState(`${year}-${month}-${day}`);
    const [time, setTime] = useState('');
    const handleDateTime = (date: string, time: string) => {
        if (!date) {
            showToast('경기 날짜를 선택해주세요', 'error');
            return;
        } else if (!time) {
            showToast('경기 시간을 선택해주세요', 'error');
            return;
        } else {
            const localDateTime = `${date}T${time}`;
            activeFunc(localDateTime);
        }
    };
    return (
        <div>
            <div onClick={() => setIsShow(!isShow)}>{modalBtn}</div>
            {isShow && (
                <div className="fixed top-0 left-0 w-screen h-screen flex justify-center items-center bg-[#00000050]">
                    <div className="p-[30px] rounded-[10px] shadow-md bg-white flex flex-col gap-[10px]">
                        <div className="text-subtitle-medium-bold mb-[10px] flex justify-between items-center">
                            경기 일시
                            <button onClick={() => setIsShow(false)}>
                                <CloseIcon className="w-[40px] h-[40px] text-secondary-lGrayDark" />
                            </button>
                        </div>
                        <div className="flex items-center gap-[10px]">
                            <DatePicker
                                modalBtn={
                                    <div className="flex items-center w-[270px] justify-between inputDefaultStyle p-[10px] gap-[14px] ">
                                        {date ? date : '경기일시'}
                                        <CalendarIcon />
                                    </div>
                                }
                                selected={date}
                                setSelected={setDate}
                            />

                            <div className="bg-primary-black relative text-white rounded-[8px]">
                                <input
                                    type="time"
                                    value={time}
                                    onChange={(e) => setTime(e.target.value)}
                                    className="p-[12px] w-[140px] h-[44px] text-white bg-transparent"
                                    style={{
                                        colorScheme: 'dark', // 아이콘과 드롭다운 UI를 다크 테마처럼 변경
                                    }}
                                />
                            </div>
                        </div>

                        <div className="flex gap-[4px] justify-end mt-[14px]">
                            <button
                                onClick={() => handleDateTime(date, time)}
                                className="p-[12px] w-full bg-primary-purple text-white text-context-bold rounded-[8px]"
                            >
                                리포트 작성
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default MatchDatePickerModal;
