// hooks
import React, { useState, useRef, useEffect } from "react";
import { useInfiniteQuery } from "@tanstack/react-query";
import axiosInstance from "api/axiosInstance";
import { useInView } from "react-intersection-observer";
// icons
import { ReactComponent as CheckIcon } from "assets/svgs/check.svg";
import { ReactComponent as ArrowIcon } from "assets/svgs/arrow.svg";
import { ReactComponent as SearchIcon } from "assets/svgs/search.svg";
// utils
import { paramFormChanger } from "utils/formChanger";
import { ENDPOINT_ADMIN } from "utils/consts/apiEndpoint";

interface PlayerSearchDropdownProps {
  value?: string;
  defaultValue?: string;
  paramKey?: string;
  onChange: (value: string) => void;
  activeFunc?: (value: string) => void;
  placeholder?: string;
  disabled?: boolean;
  className?: string;
  innerClassName?: string;
}

const PlayerSearchDropdown: React.FC<PlayerSearchDropdownProps> = ({
  value,
  defaultValue,
  onChange,
  activeFunc,
  placeholder = "선택해주세요",
  disabled = false,
  className = "",
  innerClassName = " px-[12px] py-[12px]",
}) => {
  const { ref, inView } = useInView();
  const [isOpen, setIsOpen] = useState(false);
  const [playerName, setplayerName] = useState("");

  const PlayerSearchdropdownRef = useRef<HTMLDivElement>(null);
  // 외부 클릭 시 드롭다운 닫기
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        PlayerSearchdropdownRef.current &&
        !PlayerSearchdropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  // useInfiniteQuery
  const {
    data,
    error,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isSuccess,
    isFetchingNextPage,
    status,
  } = useInfiniteQuery({
    queryKey: ["playerSearch", playerName],
    queryFn: ({ pageParam = 1 }) =>
      axiosInstance
        .get(
          ENDPOINT_ADMIN.playerParams(
            paramFormChanger({
              playerName: playerName,
              page: pageParam,
              size: 3,
            })
          )
        )
        .then((response) => response.data),
    initialPageParam: 1,
    getNextPageParam: (lastPage) =>
      lastPage.pageInfo.page < lastPage.pageInfo.totalPages
        ? lastPage.pageInfo.page + 1
        : undefined,
  });
  // useInView로 무한 스크롤
  useEffect(() => {
    if (inView && hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [inView, hasNextPage, isFetchingNextPage, fetchNextPage]);

  const flattenedOptions = handleDropdownOpts(
    data?.pages.flatMap((page) => page.data) || []
  );
  const selectedOption = flattenedOptions.find(
    (option) => option.value === value
  );
  const handleToggle = () => {
    if (!disabled) {
      setIsOpen(!isOpen);
    }
  };
  const handleOptionSelect = (optionValue: string) => {
    if (!disabled) {
      onChange(optionValue);
      if (activeFunc) activeFunc(optionValue);
      setIsOpen(false);
    }
  };

  // 드롭다운 상태에 따른 스타일
  const getPlayerSearchDropdownStyles = () => {
    if (disabled) {
      return "bg-secondary-mGrayLight border border-secondary-mGrayDark text-secondary-mGrayDark ";
    }
    if (error) {
      return "bg-primary-white border border-status-error text-status-error ";
    }
    if (isOpen) {
      return "bg-primary-purple text-primary-white ";
    }
    return "bg-primary-black text-primary-white ";
  };

  return (
    <div
      ref={PlayerSearchdropdownRef}
      className={`relative   ${className ? className : "w-full"}`}
    >
      {/* 드롭다운 헤더 */}
      <div
        onClick={handleToggle}
        className={`
          flex justify-between items-center gap-[12px]
          rounded-[8px]
          cursor-pointer
          transition-all duration-200
          ${innerClassName ? innerClassName : "text-context-bold"}
          ${getPlayerSearchDropdownStyles()}
        `}
      >
        <span>
          {selectedOption
            ? selectedOption.label
            : defaultValue
            ? defaultValue
            : placeholder}
        </span>
        <ArrowIcon
          className={`
            transition-transform duration-200
            ${isOpen ? "rotate-180" : "rotate-0"}
          `}
        />
      </div>

      {/* 드롭다운 리스트 */}
      {isOpen && (
        <div
          className="
            absolute z-10 w-full
            border border-primary-purple
            rounded-[10px]
            max-h-60 overflow-y-auto mt-[5px]
          "
        >
          <div className="relative w-full bg-primary-white">
            <input
              className="pl-[12px] pr-[40px] w-full h-[44px] text-context-regular rounded-[8px] text-black focus:outline-none focus:bg-[#EDECFF50]"
              placeholder="이름을 검색해주세요"
              value={playerName}
              onChange={(e) => setplayerName(e.target.value)}
            />
            <SearchIcon className="absolute top-[12px] right-[12px]" />
          </div>
          {flattenedOptions.map((option) => (
            <div
              key={option.value}
              onClick={() => handleOptionSelect(option.value)}
              className={`
                ${
                  innerClassName ? innerClassName : "px-[12px] py-[12px]"
                }  text-context-regular
                flex justify-between items-center gap-[10px]
                cursor-pointer
                hover:bg-[#EDECFF] text-primary-purple
                transition-colors duration-200
                ${value === option.value ? "bg-[#EDECFF]" : "bg-primary-white"}
              `}
            >
              {option.label}
              {value === option.value && (
                <CheckIcon className="flex-shrink-0 " />
              )}
            </div>
          ))}
          <div className="bg-primary-white">
            <div ref={ref} className="h-4" />
            {isFetching && !isFetchingNextPage && (
              <div className="pb-2 text-center text-secondary-lGrayDark">
                Loading...
              </div>
            )}
            {isFetchingNextPage && (
              <div className="pb-2 text-center text-secondary-lGrayDark">
                다음 페이지 로딩 중...
              </div>
            )}
            {!isFetching && !isFetchingNextPage && !hasNextPage && (
              <div className="pb-2 text-center text-secondary-lGrayDark">
                마지막 데이터 입니다.
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default PlayerSearchDropdown;

export const handleDropdownOpts = (arr: any[]) => {
  return arr?.map((arr) => ({
    value: arr?.playerId,
    label: `${arr?.playerName}`, //${arr?.type}
  }));
};
