// hooks
import { useState, useEffect, useMemo } from 'react';
// api
import { useApiQuery, useApiMutation } from 'hooks/useDynamicApi';
// icons
import { ReactComponent as SearchIcon } from 'assets/svgs/search.svg';
import { showToast } from 'components/toast/Default';
// common
import EmptyBar from 'components/emptyBar';
import CustomTable from 'components/table/CustomTable';
import DefaultModal from 'components/modals/DefaultModal';
import Tabs from 'components/tab/Default';
import ConfirmModal from 'components/modals/DefaultModal';
import Pagination from 'components/pagenation';
import SoccerBallLodaing from 'components/loading/SoccerBallLodaing';
// utils
import { ENDPOINT_TEAM } from 'utils/consts/apiEndpoint';
import { paramFormChanger } from 'utils/formChanger';
import Dropdown from 'components/dropdown/Default';
import { classTypeMatcher, regionMatcher } from 'utils/matchers/userInfoMatcher';
import { joinStatusMatcher } from 'utils/matchers/userInfoMatcher';
import { formatDateTimeMatcher } from 'utils/matchers/reportMatcher';
import { departmentMatcher } from 'utils/matchers/measurementMatcher';
import { regionOptions, ageGroupOptions, soccerTypeOptions } from 'utils/consts/userInfo';

interface TeamSearchTableType {
    setHasTeam: (hasTeam: any) => void;
}

const TeamSearchTable = ({ setHasTeam }: TeamSearchTableType) => {
    const joinTab = [{ label: '팀검색' }, { label: '내요청목록' }];
    const [deleteId, setDeleteId] = useState('');
    const [deleteRequestModal, setDeleteRequestModal] = useState(false);
    const [requestTeamModal, setRequestTeamModal] = useState(false);
    const [teamId, setTeamId] = useState('');
    const [reason, setReason] = useState('');
    const [page, setPage] = useState(1);
    const [pagInfo, setPageInfo] = useState({
        page: 1,
        size: 10,
        totalElements: 1,
        totalPages: 1,
    });
    const [teamName, setTeamName] = useState('');
    const [fixedTeamName, setFixedTeamName] = useState('');
    const [sortType, setSortType] = useState('');
    const [options, setOptions] = useState<OptionsType>({
        region: '',
        ageGroup: '',
        type: '',
    });
    // useApiQuery => 백엔드 403에러 해결 필요
    const getSearchTeam = useApiQuery(
        [
            'getSearchTeam',
            fixedTeamName,
            options?.region,
            options?.ageGroup,
            options?.type,
            `${page}`,
            `${pagInfo.size}`,
        ],
        ENDPOINT_TEAM.teamSearchParams(
            paramFormChanger({
                ...options,
                name: fixedTeamName,
                sortType: sortType,
                page: page,
                size: pagInfo?.size,
            })
        ),
        'get'
    );
    const getPlayerRequest = useApiQuery(['getPlayerRequest'], ENDPOINT_TEAM.player.membership, 'get');
    useEffect(() => {
        if (getPlayerRequest?.isSuccess && getPlayerRequest?.data) {
            getPlayerRequest.data.forEach((el: any) => {
                if (el?.status === 'ACCEPTED') {
                    setHasTeam(el);
                    return; // forEach 내부에서 조기 종료는 불가능, 하지만 이후 실행 방지를 위해 return 추가
                }
            });
        }
    }, [getPlayerRequest?.isSuccess, getPlayerRequest?.data]);
    // useApiMutation
    const postTeamRequest = useApiMutation(ENDPOINT_TEAM.player.request(), 'post', {
        onSuccess: (data) => {
            showToast('팀가입을 요청했습니다.', 'success');
        },
        onError: (error) => {
            // 에러 메시지 추출
            const errorMessage = error?.data?.detailedMessage || error || '팀가입 요청 실패했습니다.';
            showToast(`${errorMessage}`, 'error');
        },
    });
    const deleteTeamRequest = useApiMutation(ENDPOINT_TEAM.player.request(deleteId), 'delete', {
        onSuccess: (data) => {
            showToast('팀가입을 요청을 삭제했습니다.', 'success');
        },
        onError: (error) => {
            // 에러 메시지 추출
            const errorMessage = error?.data?.detailedMessage || error || '팀가입 요청 삭제를 실패했습니다.';
            showToast(`${errorMessage}`, 'error');
        },
    });

    const submitTeamRequest = () => {
        if (!postTeamRequest?.isPending) {
            showToast('팀가입 요청중입니다.', 'info');
            postTeamRequest?.mutate({
                teamId: teamId,
                reason: reason,
            });
        }
    };

    const removeTeamRequest = () => {
        if (!deleteTeamRequest?.isPending) {
            showToast('팀가입 요청 삭제중입니다.', 'info');
            deleteTeamRequest?.mutate({});
        }
    };

    const handleOptions = (updateData: any) => {
        setOptions((prev: any) => ({
            ...prev,
            ...updateData,
        }));
    };

    const teamColums = [
        { key: 'name', label: '팀 이름' },
        { key: 'ageGroup', label: '연령' },
        { key: 'region', label: '지역' },
        { key: 'type', label: '팀 유형' },
        { key: 'department', label: '지점' },
        { key: 'staffCount', label: '스태프 수' },
        { key: 'playerCount', label: '선수 수' },
        { key: 'request', label: '요청' },
    ];
    const myJoinColums = [
        { key: 'teamName', label: '팀 이름' },
        { key: 'teamAgeGroup', label: '연령' },
        { key: 'createdAt', label: '요청일' },
        { key: 'status', label: '상태' },
        { key: 'request', label: '요청' },
    ];
    useEffect(() => {
        if (getSearchTeam?.isSuccess && getSearchTeam?.data && getSearchTeam?.data?.pageInfo?.page === 1) {
            setPageInfo(getSearchTeam?.data?.pageInfo);
        }
    }, [getSearchTeam?.isSuccess, getSearchTeam?.data]);

    const teamRows = handleTeamRows(getSearchTeam?.data?.data, setRequestTeamModal, setTeamId);
    const myJoinRows = handleMyJoinRows(getPlayerRequest?.data, setDeleteRequestModal, setDeleteId);
    return (
        <div className="bg-primary-white rounded-[10px] p-[30px]">
            {getSearchTeam?.isPending && <SoccerBallLodaing />}
            <ConfirmModal
                isShow={deleteRequestModal}
                setIsShow={setDeleteRequestModal}
                title={'가입 요청을 삭제하시겠습니까?'}
                content={''}
                activeFunc={removeTeamRequest}
            />
            <DefaultModal
                isShow={requestTeamModal}
                setIsShow={setRequestTeamModal}
                title={'가입 요청을 보내시겠습니까?'}
                content={
                    <div>
                        <input
                            value={reason}
                            onChange={(e) => setReason(e.target.value)}
                            className="inputDefaultStyle inputCommonStyle"
                            placeholder="가입 요청 사유 (100글자 이하)"
                            maxLength={100}
                        />
                    </div>
                }
                activeFunc={submitTeamRequest}
            />

            {myJoinRows?.length === 0 ? (
                <div className="">
                    <div className="text-subtitle-medium-bold">팀을 검색하고 가입요청을 보내보세요!</div>
                    <div className="flex gap-x-[10px] gap-y-[20px] flex-wrap mt-[10px]">
                        <div className="w-[400px] relative">
                            <input
                                value={teamName}
                                onChange={(e) => setTeamName(e.target.value)}
                                className="w-[400px] inputDefaultStyle  text-context-regular rounded-[8px] text-black items-center pl-[12px] pr-[40px] h-[44px] "
                                placeholder="팀 이름을 검색하세요"
                            />
                            <button
                                onClick={() => setFixedTeamName(teamName)}
                                className="absolute top-[12px] right-[12px]"
                            >
                                <SearchIcon />
                            </button>
                        </div>
                        <div className="flex items-center w-[300px] gap-[10px]">
                            <div className="shrink-0 text-context-bold text-center w-[60px]">지역</div>
                            <Dropdown
                                placeholder="지역"
                                options={[{ value: '', label: '전 지역' }, ...regionOptions]}
                                value={options?.region}
                                onChange={(value) => handleOptions({ region: value })}
                                className="w-[250px]"
                            />
                        </div>
                        <div className="flex items-center w-[300px] gap-[10px]">
                            <div className="shrink-0 text-context-bold text-center w-[60px]">연령</div>
                            <Dropdown
                                placeholder="연령"
                                options={[{ value: '', label: '전 연령' }, ...ageGroupOptions]}
                                value={options?.ageGroup}
                                onChange={(value) => handleOptions({ ageGroup: value })}
                                className="w-[250px]"
                            />
                        </div>
                        <div className="flex items-center w-[300px] gap-[10px]">
                            <div className="shrink-0 text-context-bold text-center w-[60px]">팀 유형</div>
                            <Dropdown
                                placeholder="팀 유형"
                                options={[{ value: '', label: '전 팀유형' }, ...soccerTypeOptions]}
                                value={options?.type}
                                onChange={(value) => handleOptions({ type: value })}
                                className="w-[250px]"
                            />
                        </div>
                    </div>
                    <EmptyBar customStyle="h-[32px]" />
                    <CustomTable
                        columns={teamColums}
                        rows={teamRows}
                    />
                    <Pagination
                        page={page}
                        size={pagInfo?.size}
                        totalElement={pagInfo?.totalElements}
                        totalPages={pagInfo?.totalPages}
                        onPageChange={setPage}
                    />
                    <EmptyBar customStyle="h-[10px]" />
                </div>
            ) : (
                <div className="">
                    <div className="text-subtitle-medium-bold">내 가입 요청 목록</div>

                    <EmptyBar customStyle="h-[32px]" />
                    <CustomTable
                        columns={myJoinColums}
                        rows={myJoinRows}
                    />
                    <EmptyBar customStyle="h-[10px]" />
                </div>
            )}
        </div>
    );
};

export default TeamSearchTable;
interface OptionsType {
    region: string;
    ageGroup: string;
    type: string;
    sortType?: string;
}
export const handleTeamRows = (
    rows: any[],
    setRequestTeamModal: (show: boolean) => void,
    setTeamId: (id: string) => void
) => {
    return rows?.map((row) => ({
        id: row?.id,
        name: row?.name,
        ageGroup: row?.ageGroup,
        type: classTypeMatcher(row?.type),
        region: regionMatcher(row?.region),
        department: departmentMatcher(row?.department),
        staffCount: row?.staffCount,
        playerCount: row?.playerCount,
        request: (
            <button
                onClick={() => {
                    setRequestTeamModal(true);
                    setTeamId(row?.id);
                }}
                className="relative text-primary-purple"
            >
                요청하기
                <span className="border-b border-primary-purple absolute bottom-0 w-[58px] left-0"></span>
            </button>
        ),
    }));
};

export const handleMyJoinRows = (
    rows: any[],
    setDeleteRequestModal: (show: boolean) => void,
    setDeleteId: (requestId: string) => void
) => {
    return rows?.map((row) => ({
        teamName: row?.teamName,
        teamAgeGroup: row?.teamAgeGroup,
        status: joinStatusMatcher(row?.status),
        createdAt: formatDateTimeMatcher(row?.createdAt),
        request: (
            <button
                onClick={() => {
                    setDeleteRequestModal(true);
                    setDeleteId(row?.requestId);
                }}
                className="relative text-secondary-dGrayLight"
            >
                요청취소
                <span className="border-b border-secondary-dGrayLight absolute bottom-0 w-[58px] left-0"></span>
            </button>
        ),
    }));
};
