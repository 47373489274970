// icons
import { ReactComponent as PrintIcon } from 'assets/svgs/tooltipPoint.svg';

const Tooltip = ({ tip }: { tip: string }) => {
    return (
        <div className="">
            <div className="m-auto min-w-[30px] px-[12px] w-fit py-[4px] bg-secondary-dGrayMid text-[14px] font-bold text-white rounded-[7px]">
                {tip}
            </div>
            <PrintIcon className="m-auto  mt-[-5px]" />
        </div>
    );
};

export default Tooltip;
