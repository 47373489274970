// icons
import { ReactComponent as EmptyFileIcon } from 'assets/svgs/emptyFile.svg';
import { ReactComponent as MiniVideoIcon } from 'assets/svgs/miniVideo.svg';
// player component
import ProgressBar from 'components/progressBar';

const ShootingOneCard = ({ isPrint, shootingOneAnalysis }: any) => {
    const {
        attemptCount = 0,
        attemptRate = 0,
        finalScore = 0,
        shootingOneId,
        signalFailCount = 0,
        signalFailRate = 0,
        successCount = 0,
        successRate = 0,
        tapeHitCount = 0,
        tapeHitRate = 0,
    } = shootingOneAnalysis || {};
    const TOTAL_COUNT = 6;
    return (
        <div className="flex flex-col gap-[20px]">
            <div className="flex items-center justify-between">
                <div className="text-subtitle-small-bold text-secondary-dGrayDark item">정적 슈팅</div>
                {!isPrint && (
                    <button className="cursor-default text-secondary-lGrayDark text-context-regular">
                        <div className="flex gap-[10px] p-[10px] rounded-[8px] border border-secondary-lGrayMid ml-[2px]">
                            <MiniVideoIcon />
                            준비중
                        </div>
                    </button>
                )}
            </div>
            <div className="flex gap-[8px] items-center mr-[5px] justify-center">
                <span className="flex gap-[5px] items-center text-secondary-lGrayDark">
                    <EmptyFileIcon />총 점수
                </span>
                <span className="flex gap-[5px] items-center">
                    <span className="text-[34px] font-bold text-primary-black">{finalScore}</span>
                    <span className="text-secondary-lGrayDark">점</span>
                </span>
            </div>
            <div className="w-full flex flex-col gap-[10px] h-[135px] justify-center">
                <ProgressBar
                    label="성공"
                    targetRate={TOTAL_COUNT}
                    achievementRate={successCount}
                    customColor="#5651E3"
                />
                <ProgressBar
                    label="부분성공"
                    targetRate={TOTAL_COUNT}
                    achievementRate={tapeHitCount}
                    customColor="#9C99EF"
                />
                <ProgressBar
                    label="슈팅시도"
                    targetRate={TOTAL_COUNT}
                    achievementRate={attemptCount}
                    customColor="#19DE93"
                />
                <ProgressBar
                    label="실패"
                    targetRate={TOTAL_COUNT}
                    achievementRate={signalFailCount}
                    customColor="#79788E"
                />
            </div>

            <div className="grid grid-cols-4 h-[100px] bg-[#F8F8F8] rounded-[14px]">
                <div className="flex flex-col items-center justify-center">
                    <div className="text-secondary-dGrayLight">성공률</div>
                    <div>
                        <span className="text-title-small-bold text-primary-purple">{successRate}</span>
                        <span className="text-[#7c7c7c] text-context-bold">%</span>
                    </div>
                </div>
                <div className="flex flex-col items-center justify-center">
                    <div className="text-secondary-dGrayLight">부분성공률</div>
                    <div>
                        <span className="text-title-small-bold text-[#9C99EF]">{tapeHitRate}</span>
                        <span className="text-[#7c7c7c] text-context-bold">%</span>
                    </div>
                </div>
                <div className="flex flex-col items-center justify-center">
                    <div className="text-secondary-dGrayLight">시도율</div>
                    <div>
                        <span className="text-title-small-bold text-status-correct">{attemptRate}</span>
                        <span className="text-[#7c7c7c] text-context-bold">%</span>
                    </div>
                </div>
                <div className="flex flex-col items-center justify-center">
                    <div className="text-secondary-dGrayLight">실패율</div>
                    <div>
                        <span className="text-title-small-bold text-secondary-mGrayDark">{signalFailRate}</span>
                        <span className="text-[#7c7c7c] text-context-bold">%</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ShootingOneCard;
