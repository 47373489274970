// icons
import { ReactComponent as MenuIcon } from 'assets/svgs/menu.svg';
// redux
import { useSelector } from 'react-redux';
import { RootState } from 'store'; // Redux Store 타입 정의
const MypageSubHeader = () => {
    const { staffRole, name, role } = useSelector((state: RootState) => state.userInfo);

    return (
        <div className="h-[80px]">
            <div className="flex items-center justify-between">
                <div className="flex gap-[14px] items-center">
                    <div className="text-primary-white text-title-small-bold">
                        {name}
                        {role === 'TEAM_STAFF' || role === 'PLAYER'}
                        님, 마이페이지
                    </div>
                </div>
                <div className="flex w-[962px] gap-[80px]">
                    <div className=" flex gap-[18px]  h-[48px] text-[#868686]">
                        {/* <div className="w-[5px] bg-primary-green rounded-[5px]"></div> */}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MypageSubHeader;
