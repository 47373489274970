// hooks
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useHandLogout } from 'hooks/useLogout';
// api
import { useApiQuery } from 'hooks/useDynamicApi';
// assets
import aisoccerWhiteSymbol from 'assets/logos/symbol/aisoccer-white-symbol.webp';
// common
import MainHeader from 'pages/player/components/MainHeader';
import EmptyBar from 'components/emptyBar';
import MainSubHeader from 'pages/initialPage/MainSubHeader';
import Toggle from 'components/toggle';

// redux
import { useDispatch, useSelector } from 'react-redux';
import { setUserInfoState } from 'store/userInfoSlice';
import { logout } from 'store/authSlice';
import { userInfoRemove } from 'store/userInfoSlice';
import { teamInfoRemove } from 'store/teamInfoSlice';
import { RootState } from 'store';

const InitalPage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { name, status } = useSelector((state: RootState) => state.userInfo);
    const tabs = ['선수', '스태프'];
    const [state, setState] = useState('');

    const [tab, setTab] = useState('선수');

    // useHandLogout
    const logoutFunc = useHandLogout();
    const handleLogout = () => {
        dispatch(logout());
        dispatch(userInfoRemove());
        dispatch(teamInfoRemove());
        logoutFunc();
    };
    // api
    const { data, isLoading, isSuccess, isError, error, refetch } = useApiQuery(['getUser'], '/api/user', 'get');
    useEffect(() => {
        if (isSuccess && data) {
            const { id, name, email, profileImageUrl, role, staffRole, status } = data;
            dispatch(
                setUserInfoState({
                    id,
                    name,
                    email,
                    profileImageUrl,
                    role,
                    staffRole,
                    status,
                })
            );

            if (status === 'PLAYER_ACTIVE' || status === 'PLAYER_PENDING_PASSWORD') {
                if (role === 'PLAYER') navigate('/player/measurement');
            } else if (status === 'TEAM_STAFF_ACTIVE' || status === 'TEAM_STAFF_PENDING_PASSWORD') {
                if (role === 'TEAM_STAFF' && staffRole === 'COACH') {
                    navigate('/staff/control/lineUp');
                } else if (role === 'TEAM_STAFF' && staffRole === 'MANAGER') {
                    navigate('/staff/control/lineUp');
                } else if (role === 'TEAM_STAFF' && staffRole === 'OFFICE') {
                    navigate('/staff/control/lineUp');
                }
            } else if (status === 'SUPER_ADMIN_ACTIVE' || status === 'SUPER_ADMIN_PENDING_PASSWORD') {
                navigate('/hand/records');
            } else if (status === 'SYSTEM_ADMIN_ACTIVE' || status === 'SYSTEM_ADMIN_PENDING_PASSWORD') {
                navigate('/hand/records');
            } else if (status === 'DATA_ANALYST_ACTIVE' || status === 'DATA_ANALYST_PENDING_PASSWORD') {
                navigate('/hand/records');
            }
        }
    }, [isSuccess, data]);

    return (
        <div className="h-screen overflow-hidden wrapper">
            {/* 배경과 심볼 */}
            <div className="absolute top-0 left-0 z-[-1] w-full h-full">
                {/* 배경색 나누기 */}
                <div className="h-[598px] bg-primary-black"></div>
                {/* 심볼 이미지 */}
                <img
                    src={aisoccerWhiteSymbol}
                    alt="aisoccerWhiteSymbol"
                    className="absolute z-0 top-0 right-0 w-[470px]"
                />
            </div>
            <div className="w-screen h-full overflow-y-scroll z-[10] bg-[#00000060] fixed top-0 left-0 flex flex-col items-center justify-center">
                {isLoading ? (
                    <div className="w-[520px]  bg-white py-[50px] px-[60px] rounded-[10px] text-center text-[16px]">
                        접속중입니다. <br />
                        <div className="font-bold">잠시만 기다려주세요.</div>
                        <div className="mt-[20px]">
                            <button
                                onClick={handleLogout}
                                className="bg-primary-purple rounded-[8px] w-[400px] h-[44px] text-white"
                            >
                                로그아웃
                            </button>
                        </div>
                    </div>
                ) : status === 'TEAM_STAFF_PENDING' ? (
                    <div className="w-[520px]  bg-white py-[50px] px-[60px] rounded-[10px] text-center text-[16px]">
                        권한신청 반영중입니다. <br />
                        <div className="font-bold">잠시후 재접속 해주세요.</div>
                        <div className="mt-[20px]">
                            <button
                                onClick={handleLogout}
                                className="bg-primary-purple rounded-[8px] w-[400px] h-[44px] text-white"
                            >
                                로그아웃
                            </button>
                        </div>
                    </div>
                ) : (
                    <div className="w-[520px]  bg-white py-[50px] px-[60px] rounded-[10px] text-center text-[16px]">
                        수기측정에는 인가된 관리자만 접속할 수 있습니다. <br />
                        <div className="font-bold">권한이 없는 사용자는 이용할 수 없습니다.</div>
                        <div className="mt-[20px] flex gap-[10px]">
                            <button
                                onClick={() => navigate('/initial')}
                                className="bg-secondary-dGrayLight rounded-[8px] w-[400px] h-[44px] text-white"
                            >
                                AISoccer로 가기
                            </button>
                            <button
                                onClick={handleLogout}
                                className="bg-primary-purple rounded-[8px] w-[400px] h-[44px] text-white"
                            >
                                로그아웃
                            </button>
                        </div>
                    </div>
                )}
            </div>

            {/* 아래는 백그라운드 코드 */}
            <MainHeader />
            <EmptyBar customStyle={'h-[40px]'} />
            <MainSubHeader name={name} />
            <EmptyBar customStyle={'h-[35px]'} />
            <div className="fixed z-[-1] w-screen h-screen  left-0 top-0">
                <div className="h-[400px] bg-primary-black"></div>
                <div className="h-2/3 bg-secondary-mGrayMid"></div>
            </div>
            <div className="absolute top-0 left-0 z-[-1] w-full h-full">
                <div className="h-[400px] bg-primary-black"></div>
                <img
                    src={aisoccerWhiteSymbol}
                    alt="aisoccerWhiteSymbol"
                    className="absolute z-0 top-0 right-0 w-[470px]"
                />
            </div>
            <div className="flex flex-col gap-[1rem] ">
                <div className="flex justify-between w-full">
                    <div className="w-[927px] h-[360px] bg-white rounded-[10px] flex">
                        <div className="text-subtitle-medium-bold text-[20px] pl-[30px] py-[30px] shrink-0">
                            종합측정
                        </div>
                    </div>
                    <div className=" w-[453px] h-[360px] bg-white rounded-[10px] ">
                        <div className="p-[30px]  w-[453px] flex justify-between items-center">
                            <span className="text-subtitle-medium-bold">비교측정</span>
                        </div>
                    </div>
                </div>
                <div className=" bg-white rounded-[10px] mb-[15px] h-[434px]">
                    <div className="text-subtitle-medium-bold text-[20px] pl-[30px] pr-[30px] pt-[30px] pb-[20px] shrink-0 flex justify-between items-center">
                        <span>기술별 점수</span>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default InitalPage;
