// hooks
import { useEffect, useState } from "react";
import { useSearchParams, useLocation, useNavigate } from "react-router-dom";
//icons
import { ReactComponent as HomeIcon } from "assets/svgs/home.svg";
import { ReactComponent as CircleArrowIcon } from "assets/svgs/circleArrow.svg";
// common
import Dropdown from "components/dropdown/Default";
// api
import { useApiQuery } from "hooks/useDynamicApi";
// utils
import { useParamNavigate } from "hooks/useDynamicNavigate";
import {
  dropDownFormChanger,
  dropDownFormChangerType,
} from "utils/formChanger";
import { ENDPOINT_TEAM, ENDPOINT_EVALUATION } from "utils/consts/apiEndpoint";
// redux
import { RootState } from "store";
import { useSelector } from "react-redux";

const RatingIdSubHeader = ({
  playerName,
  uniformNumber,
}: {
  playerName: string;
  uniformNumber: string;
}) => {
  const handleParamNavigate = useParamNavigate();
  const { staffRole } = useSelector((state: RootState) => state.userInfo);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const locationList = useLocation().pathname.split("/");
  const periodId = locationList[4];
  const paramPlayerId = searchParams.get("player") || "";
  const paramEvaluationId = searchParams.get("evaluation") || "";
  const paramAgeGroup = searchParams.get("ageGroup") || "";
  const paramSeason = searchParams.get("season") || "";
  const paramYear = searchParams.get("year") || "";
  const [ageGroup, setAgeGroup] = useState(paramAgeGroup);
  const [ageGroupOpts, setAgeGroupsOpts] = useState<dropDownFormChangerType[]>(
    []
  );

  // react useApiQuery
  const { data, isLoading, isSuccess, isError, error, refetch } = useApiQuery(
    ["ageGroup"],
    ENDPOINT_TEAM.staff.team.ageGroups,
    "get",
    {
      enabled: staffRole === "OFFICE",
    }
  );
  useEffect(() => {
    if (isSuccess && data) {
      const { teamName, ageGroups } = data;
      const newAgeGroupOpts = dropDownFormChanger(ageGroups);
      setAgeGroupsOpts(newAgeGroupOpts);
    }
  }, [data]);

  const [dropdownPlayerOpts, setDropdownPlayerOpts] = useState<
    PlayerDropdownOptsType[]
  >([]);

  const [player, setPlayer] = useState("19");

  // react useApiQuery
  const getEvaluationPeriodId = useApiQuery(
    ["getEvaluationPeriodId", periodId],
    ENDPOINT_EVALUATION.period(`${periodId}`, {
      ageGroup:
        staffRole === "OFFICE" && paramAgeGroup ? paramAgeGroup : undefined,
    }),
    "get"
  );

  useEffect(() => {
    if (getEvaluationPeriodId.isSuccess && getEvaluationPeriodId.data) {
      const { playerEvaluations } = getEvaluationPeriodId?.data;
      setDropdownPlayerOpts(handlePlayerDropdownOpts(playerEvaluations));
    }
  }, [getEvaluationPeriodId.isSuccess, getEvaluationPeriodId.data]);
  const handleHomeNavigate = () => {
    if (paramAgeGroup && paramYear)
      handleParamNavigate(
        {
          ageGroup: paramAgeGroup,
          year: paramYear,
        },
        false,
        `/staff/control/lineUp`
      );
  };

  const handleNavigate = () => {
    if (paramAgeGroup && paramYear)
      handleParamNavigate(
        {
          ageGroup: paramAgeGroup,
          year: paramYear,
        },
        false,
        `/staff/control/rating`
      );
  };
  const handlePlyerNavigate = (playerId: string) => {
    const { playerEvaluations } = getEvaluationPeriodId?.data;
    const selectedEvaluationId = playerEvaluations.find(
      (el: any) => el.playerId === playerId
    );
    if (paramAgeGroup && paramYear && selectedEvaluationId?.evaluationId)
      handleParamNavigate(
        {
          ageGroup: paramAgeGroup,
          year: paramYear,
          evaluation: selectedEvaluationId?.evaluationId,
        },
        false,
        `/staff/control/rating/${periodId}`
      );
  };

  return (
    <div className="">
      <div className="flex flex-col gap-[15px]">
        <div className="flex gap-[8px] items-center">
          <button onClick={handleHomeNavigate}>
            <HomeIcon className="text-white" />
          </button>
          <div className="w-[3px] h-[3px] rounded-full bg-secondary-lGrayDark"></div>

          <Dropdown
            options={ageGroupOpts}
            value={ageGroup}
            defaultValue={ageGroup}
            onChange={setAgeGroup}
            activeFunc={handleNavigate}
            className={
              " text-label-regular border rounded-[8px] border-primary-white"
            }
            innerClassName="px-[6px] py-[8px] text-label-regular"
          />
          <div className="w-[3px] h-[3px] rounded-full bg-secondary-lGrayDark"></div>
          <Dropdown
            options={dropdownPlayerOpts}
            value={player}
            defaultValue={`${uniformNumber}. ${playerName}`}
            onChange={setPlayer}
            activeFunc={handlePlyerNavigate}
            className={
              " text-label-regular w-[130px] border rounded-[8px] border-primary-white"
            }
            innerClassName="px-[6px] py-[8px] text-label-regular"
          />
        </div>
        <div className="flex items-center gap-[10px]">
          <button onClick={() => navigate(-1)}>
            <CircleArrowIcon className="text-white" />
          </button>
          <div className="text-[26px] font-bold text-white">{playerName}</div>
        </div>
      </div>

      <div className="flex items-center justify-between">
        <div className="gap-[7px] flex w-[420px]">
          {/* <Dropdown
              options={branchs}
              value={branch}
              defaultValue={"2024년 1차"}
              onChange={setBranch}
              className={"border rounded-[8px] border-primary-white"}
            /> */}
        </div>
      </div>
    </div>
  );
};

export default RatingIdSubHeader;

export const handlePlayerDropdownOpts = (rows: any[]) => {
  return rows?.map((row) => ({
    value: row?.playerId,
    label: `${row?.uniformNumber}. ${row?.playerName}`,
  }));
};

interface PlayerDropdownOptsType {
  value: string;
  label: string;
}
