// hooks
import { useParams } from 'react-router-dom';
// report components
import Starting from 'pages/staff/control/schedule/report/post/reportPlayer/Starting';
import Substitution from 'pages/staff/control/schedule/report/post/reportPlayer/Substitution';
import Goals from 'pages/staff/control/schedule/report/post/reportPlayer/Goals';
import Shootouts from 'pages/staff/control/schedule/report/post/reportPlayer/Shootouts';

// utils
import { ENDPOINT_REPORT } from 'utils/consts/apiEndpoint';
// api

import { useApiQuery } from 'hooks/useDynamicApi';

const ReportPlayer = ({ lineup }: { lineup: LineupType }) => {
    // api
    const { id } = useParams();
    const getActiveLineup = useApiQuery(['getActiveLineup'], ENDPOINT_REPORT.active(id), 'get');
    const getInActiveLineup = useApiQuery(['getInActiveLineup'], ENDPOINT_REPORT.inactive(id), 'get');

    return (
        <div className="flex h-full border-b border-secondary-lGrayMid ">
            <div className="flex w-full">
                <div className="w-[150px] h-full shrink-0  bg-secondary-mGrayLight text-secondary-lGrayDark text-context-regular flex justify-center items-center">
                    출전선수
                </div>
                <div className="w-full h-full ">
                    <Starting
                        lineup={lineup?.startingPlayers}
                        activeLineup={getActiveLineup?.data?.activePlayers}
                        inactiveLineup={getInActiveLineup?.data?.inactivePlayers}
                    />
                    <Substitution
                        lineup={lineup?.startingPlayers}
                        activeLineup={getActiveLineup?.data?.activePlayers}
                        inactiveLineup={getInActiveLineup?.data?.inactivePlayers}
                    />
                    <Goals
                        lineup={lineup?.startingPlayers}
                        activeLineup={getActiveLineup?.data?.activePlayers}
                        inactiveLineup={getInActiveLineup?.data?.inactivePlayers}
                    />
                    <Shootouts
                        lineup={lineup?.startingPlayers}
                        activeLineup={getActiveLineup?.data?.activePlayers}
                        inactiveLineup={getInActiveLineup?.data?.inactivePlayers}
                    />
                </div>
            </div>
        </div>
    );
};

export default ReportPlayer;
interface LineupType {
    startingPlayers: {
        lineupId: string;
        playerId: string;
        playerName: string;
        uniformNumber: string;
        position: string;
        rating: string;
        managerComment: string;
    }[];
    substitutePlayers: {
        lineupId: string;
        playerId: string;
        playerName: string;
        uniformNumber: string;
        position: string;
        rating: string;
        managerComment: string;
    }[];
}
